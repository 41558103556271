/* Libraries */
import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { User, getAuth } from 'firebase/auth';
import { toast } from 'react-toastify';
import { functions } from '../../config/firebase.config';

/* Components */

/* Services */
import { getCustomer, updateCustomerSocialAccountsLang } from '../../services/customers.service';

/* MUI */
import { Box } from '@mui/system';
import { Avatar, Button, FormControl, FormHelperText, MenuItem, Select, Skeleton, TextField, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* Icons */
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FaCcStripe } from "react-icons/fa";

/* i18N */
// import { loadI18n } from '../../services/i18.service';
// import { AccountAdminSettings_i18 } from './AccountAdminSettings.i18';

/* DTO */
import { Customer } from '../../services/customers.service.dto';

/* CSS */
import './AccountAdminSettings.css';


const textfieldLabelStyled = {
	style: {
		color: '#888',
	}
};

export default function AccountAdminSettings() {

	const [account, setAccount] = useState<User | null>(null);
	const [customer, setCustomer] = useState<Customer | null>(null);
	const [customerLoading, setCustomerLoading] = useState<boolean>(false);
	const [customerPortalLink, setCustomerPortalLink] = useState<string>('');
	// const i18 = loadI18n(AccountAdminSettings_i18);

	useEffect(() => {
		handleLoadAccount();
		handleLoadCustomer();
		handleLoadSubscriptions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const handleLoadAccount = async () => {
		const user = getAuth().currentUser;

		setAccount(user);
	};

	const handleLoadCustomer = async () => {
		setCustomerLoading(true);
		const customer = await getCustomer();

		if (customer) {
			setCustomer(customer);
		} else {
			toast.error('Impossible de charger les informations de votre compte');
			setCustomer(null);
		}

		setCustomerLoading(false);
	};

	const handleLoadSubscriptions = async () => {
		const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
		const { data }: { data: any } = await createPortalLink({ returnUrl: window.location.origin });

		if (data && data?.url) {
			setCustomerPortalLink(data.url);
		}
	};

	const handleChangeLanguage = async (event: any) => {
		setCustomerLoading(true);
		const lang = event.target.value;

		await updateCustomerSocialAccountsLang(lang);
		await handleLoadCustomer();
		setCustomerLoading(false);
		toast.success('Langue mise à jour');
	};

	const handleChangeAvatar = async () => {
		toast.info('Fonctionnalité en cours de développement');
	};


	return (
		<Box className='AccountAdminSettings-container'>
			<Box className='AccountAdminSettings-infos-container'>
				<Box className='AccountAdminSettings-infos-avatar-container'>
					<Avatar
						className='AccountAdminSettings-infos-avatar'
						alt={account?.displayName || ''}
						src={account?.photoURL || ''}
					/>

					<LoadingButton
						className='AccountAdminSettings-infos-avatar-upload-button'
						variant="contained"
						size='small'
						sx={{ fontSize: '10px' }}
						component="label"
						loading={false}
						onClick={handleChangeAvatar}
						disabled
					>
						Changer l'avatar

						{/* // TODO: Add file upload */}
						{/* <input
							type="file"
							accept="image/jpeg, image/png"
							// onChange={handleUploadFile}
							multiple
							hidden
						/> */}
					</LoadingButton>
				</Box>

				<Box className='AccountAdminSettings-infos-username-container'>
					<Tooltip title="Le nom d'utilisateur ne peut pas être modifié" placement='top'>
						<TextField
							variant='standard'
							size='small'
							type='email'
							label='Email'
							value={account?.email || ''}
							InputLabelProps={textfieldLabelStyled}
							disabled
						/>
					</Tooltip>

					{ customerLoading && (
						<Box>
							<Skeleton variant="text" width={125} height={54} />
							<Skeleton variant="text" width={40} height={18} />
						</Box>
					)}

					{ !customerLoading && (
						<FormControl sx={{ minWidth: 120 }} size='small' color='primary'>
							<Select
								variant='outlined'
								// disabled={customerLoading}
								disabled={true}
								value={customer?.lang || ''}
								onChange={handleChangeLanguage}
							>
								<MenuItem value="fr">Français</MenuItem>
								<MenuItem value="en">English</MenuItem>
							</Select>

							<FormHelperText color='primary' component="legend">
								Langue
							</FormHelperText>
						</FormControl>
					)}
				</Box>

				<Box>
					<Button
						variant='contained'
						color='info'
						disabled={!customerPortalLink}
						href={customerPortalLink}
						target='_blank'
						rel='noreferrer'
						startIcon={<OpenInNewIcon />}
						endIcon={<FaCcStripe />}
					>
						Gérer mon abonnement
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
