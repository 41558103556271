/* Libraries */
import { useNavigate } from 'react-router-dom';

/* Components */

/* Services */
import { useAvailableSocialNetworks } from '../../services/socialNetworks.service';

/* MUI */
import { Box } from '@mui/system';
import { Avatar, Badge, Button, Skeleton, Tooltip, Typography } from '@mui/material';

/* Icons */
import AddIcon from '@mui/icons-material/Add';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

/* DTO */
import { SocialAccountSelectionProps } from './SocialAccountSelectionDTO';

/* CSS */
import './SocialAccountSelection.css';


export default function SocialAccountSelection(props: SocialAccountSelectionProps) {

	const {
		checkSelectedAccountIDs = [], // We don't define this on purpose in order for it to be undefined by default
		accountSelection = false,
		newAccountButtonDisabled = false,
		onClick,
	} = props;

	const navigate = useNavigate();
	const [socialNetworks, socialNetworksLoading, socialNetworksError] = useAvailableSocialNetworks();


	const handleRedirectToSocialNetworks = () => {
		navigate('/dashboard/accounts');
	};


	return (
		<Box className='SocialAccountSelection-container'>

			{/* Loading */}
			{ socialNetworksLoading && Array(2).fill(0).map((_, index) => (
				<Box key={`SocialAccountSelection-skeleton-${index}`}
					className='SocialAccountSelection-item-container SocialAccountSelection-item-container-loading'
				>
					<Box className='SocialAccountSelection-badge-container'>
						<Skeleton
							className='SocialAccountSelection-avatar'
							animation='wave'
							variant='circular'
						/>
					</Box>

					<Box className='SocialAccountSelection-item-username'>
						<Skeleton
							width='100%'
							height={14}
						/>
					</Box>
				</Box>
			))}


			{/* Error */}
			{ socialNetworksError && (
				<Typography variant="caption" color="error" sx={{ fontWeight: 600 }}
					className='SocialAccountSelection-item-username'
				>
					Erreur lors du chargement des comptes
				</Typography>
			)}


			{/* Connect new social networks */}
			{ !newAccountButtonDisabled && !socialNetworksError && !socialNetworksLoading && socialNetworks.length > 0 && (
				<Box className='SocialAccountSelection-item-container SocialAccountSelection-item-container-connect'
					onClick={handleRedirectToSocialNetworks}
				>
					<Badge
						className='SocialAccountSelection-badge-container'
						overlap="circular"
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					>
						<Avatar className='SocialAccountSelection-avatar'>
							<AddIcon />
						</Avatar>
					</Badge>

					<Typography
						className='SocialAccountSelection-item-username'
						variant="caption"
						color="text.secondary"
						sx={{ color: '#888', fontWeight: 500, whiteSpace: 'normal !important' }}
					>
						Nouveau compte
					</Typography>
				</Box>
			)}

			{/*  List of social networks */}
			{ !socialNetworksError && !socialNetworksLoading && socialNetworks.sort((a, b) => a.account.username.localeCompare(b.account.username))
				.map((socialNetwork, socialNetworkIndex) => (
				<Box key={`SocialAccountSelection-item-${socialNetworkIndex}`}
					className='SocialAccountSelection-item-container'
					onClick={() => onClick(socialNetwork)}
				>
					<Badge
						className='SocialAccountSelection-badge-container'
						sx={{
							background: socialNetwork.background,
							'& .MuiBadge-badge': {
								width: 60,
								height: 60,
							},
						}}
						overlap="circular"
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						badgeContent={
							<Box className='SocialAccountSelection-badge'>
								<Box sx={{
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									height: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
									{socialNetwork.iconLight}
								</Box>
							</Box>
						}
					>
						<Avatar className='SocialAccountSelection-avatar'
							src={socialNetwork.account.pictureURL}
						/>
					</Badge>

					<Badge
						anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
						invisible={!accountSelection}
						badgeContent={
							<>
							{ checkSelectedAccountIDs.includes(socialNetwork.account.id) ?
								<CheckCircleIcon sx={{ color: '#4caf50' }} /> :
								<RadioButtonUncheckedIcon sx={{ color: '#888' }} />
							}
							</>
						}
						sx={{
							'& .MuiBadge-badge': {
								backgroundColor: '#eee',
								width: 25,
								height: 25,
							},
						}}
					>
						<Tooltip title={socialNetwork.account.username} placement='bottom' arrow>
							<Typography variant="caption" color="text.secondary" sx={{ color: '#222', fontWeight: 500, fontSize: '0.72rem' }}
								className='SocialAccountSelection-item-username'
							>
								{socialNetwork.account.username}
							</Typography>
						</Tooltip>
					</Badge>
				</Box>
			))}

			{ !newAccountButtonDisabled && !socialNetworksError && !socialNetworksLoading && socialNetworks.length === 0 && (
				<Button
					variant='contained'
					color='info'
					size='large'
					onClick={handleRedirectToSocialNetworks}
				>
					Connecter un compte
				</Button>
			)}
		</Box>
	);
}
