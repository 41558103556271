/* Libraries */
import { useEffect, useState } from 'react';

/* Components */

/* Services */
import { CLIENT_STORAGE_LIMIT, getClientStorageUsed } from '../../services/clientStorage.service';

/* MUI */
import { Box } from '@mui/system';
import { Skeleton, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

/* Icons */
import WarningIcon from '@mui/icons-material/Warning';

/* DTO */
import ClientStorageProgressBarDTO from './ClientStorageProgressBarDTO';

/* CSS */
import './ClientStorageProgressBar.css';


export default function ClientStorageProgressBar(props: ClientStorageProgressBarDTO) {
	const [storageUsed, setStorageUsed] = useState(0);
	const [loadingStorageUsed, setLoadingStorageUsed] = useState(true);


	useEffect(() => {
		handleGetAccountStorageUsed();
	}, [props.refresh]);


	const handleGetAccountStorageUsed = async (): Promise<void> => {
		setLoadingStorageUsed(true);
		const size = await getClientStorageUsed();

		if (size !== undefined) {
			setStorageUsed(size);
		}
		setLoadingStorageUsed(false);
	}

	const convertBytesToMb = (size: number): string => {
		return (size / 1000000).toFixed(2);
	}

	const getStorageUsedPercentage = (): number => {
		return Number(((storageUsed / CLIENT_STORAGE_LIMIT) * 100).toFixed(2));
	}

	const convertMetadataSizeToHumanReadable = (size: number): string => {
		const sizeInKb = size / 1000;
		const sizeInMb = sizeInKb / 1000;
		const sizeInGb = sizeInMb / 1000;

		if (sizeInGb > 1) {
			return `${sizeInGb.toFixed(2)}Go`;
		}
		if (sizeInMb > 1) {
			return `${sizeInMb.toFixed(2)}Mo`;
		}
		if (sizeInKb > 1) {
			return `${sizeInKb.toFixed(2)}Ko`;
		}
		return `${size}o`;
	};


	return (
		<Box sx={{ width: '100%' }}>
			{ loadingStorageUsed && (
				<Skeleton variant="rounded" animation='wave' height={24} />
			)}

			{ !loadingStorageUsed && (
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
					<Box sx={{ width: '100%', mr: 1 }}>
						<LinearProgress variant="determinate" value={getStorageUsedPercentage()} color='info' sx={{
							backgroundColor: '#ddd',
							borderRadius: '2px',
						}} />
					</Box>

					<Box sx={{ minWidth: 35 }}>
						<Typography variant="body2" color="text.secondary" sx={{
							fontFamily: 'IBM Plex Mono, Roboto Mono, monospace',
							fontWeight: 500,
						}}>
							{`${getStorageUsedPercentage().toString()}%`} ({convertMetadataSizeToHumanReadable(storageUsed)} / {convertMetadataSizeToHumanReadable(CLIENT_STORAGE_LIMIT)})
						</Typography>
		
						{/* If the user has less than 20% of storage left, we display a warning message */}
						{ getStorageUsedPercentage() > 80 && (
							<Typography variant="body2" sx={{
								color: '#fff',
								fontWeight: 700,
								textAlign: 'center',
								padding: '8px 15px',
								marginTop: '5px',
								backgroundColor: '#FD8D14',
								borderRadius: '12px',
								width: 'fit-content',
								alignItems: 'center',
								display: 'flex',
								gap: '8px',
								boxShadow: '0 5px 20px rgba(0,0,0, .12)',
							}}>
								<WarningIcon /> Vous n'avez que {convertBytesToMb(CLIENT_STORAGE_LIMIT - storageUsed)}MB de stockage restant
							</Typography>
						)}
					</Box>
				</Box>
			)}
		</Box>
	);
}
