/* Libraries */

/* Components */
import TypeformButton from '../../assets/TypeformButton/TypeformButton';

/* MUI */
import { Box, Button, Typography } from '@mui/material';

/* CSS */
import './PriceSection.css';


export default function PriceSection() {
	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			flexWrap: 'wrap',
			width: '100%',
			maxWidth: '1200px',
			margin: '0 auto',
		}}>
			<Box className="PriceSection-pricing-container" sx={{
				maxWidth: { xs: '100%', sm: '320px', lg: '350px' }
			}}>
				<Box className="PriceSection-logo-container">
					<Typography variant="h4" sx={{ fontSize: 38, fontWeight: 800 }}>
						Performance
					</Typography>
				</Box>

				<Box className="PriceSection-price-container">
					<Box>
						<Typography variant="h3" sx={{
							fontSize: 30,
							fontWeight: 800
						}}>
							9,90€
						</Typography>

						<Typography variant="h4">
							/mois
						</Typography>
					</Box>
				</Box>

				<Box className="PriceSection-text-container">
					<Typography variant="h5" sx={{
						fontSize: 16,
						fontFamily: "'IBM Plex Sans', 'Source Serif Pro', 'Source'"
					}}>
						- Accès à l'IA de FREEKL <br/>
						- 1 compte pour chaque réseau <br/>
						- 30 posts par comptes <br/>
						- 5Go de stockage <br/>
					</Typography>
				</Box>

				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
					<TypeformButton>
						C'est parti !
					</TypeformButton>
				</Box>
			</Box>

			<Box className="PriceSection-pricing-container" sx={{
				maxWidth: { xs: '100%', sm: '300px', lg: '350px' }
			}}>
				<Box className="PriceSection-logo-container">
					<Typography variant="h4" sx={{ fontSize: 38, fontWeight: 800 }}>
						Excellence
					</Typography>
				</Box>

				<Box className="PriceSection-price-container">
					<Box>
						<Typography variant="h3" sx={{
							fontSize: 30,
							fontWeight: 800
						}}>
							29,90€
						</Typography>

						<Typography variant="h4">
							/mois
						</Typography>
					</Box>
				</Box>

				<Box className="PriceSection-text-container">
					<Typography variant="h5" sx={{
						fontSize: 16,
						fontFamily: "'IBM Plex Sans', 'Source Serif Pro', 'Source'"
					}}>
						- Accès à l'IA de FREEKL <br/>
						- 3 comptes pour chaque réseau <br/>
						- Posts illimités <br/>
						- Stockage illimité <br/>
						- Accès en avant-première aux nouvelles fonctionnalités <br/>
					</Typography>
				</Box>

				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
					<TypeformButton>
						C'est parti !
					</TypeformButton>
				</Box>
			</Box>

			<Box className="PriceSection-pricing-container" sx={{
				maxWidth: { xs: '100%', sm: '300px', lg: '350px' }
			}}>
				<Box className="PriceSection-logo-container">
					<Typography variant="h4" sx={{ fontSize: 38, fontWeight: 800 }}>
						Corporate
					</Typography>
				</Box>

				<Box className="PriceSection-price-container">
					<Box>
						<Typography variant="h3" sx={{
							fontSize: 30,
							fontWeight: 800
						}}>
							Sur devis
						</Typography>
					</Box>
				</Box>

				<Box className="PriceSection-text-container">
					<Typography variant="h5" sx={{
						fontSize: 16,
						fontFamily: "'IBM Plex Sans', 'Source Serif Pro', 'Source'"
					}}>
						- Tout ce dont vous avez besoin <br/>
						- Offre sur-mesure <br/>
					</Typography>
				</Box>

				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
					<Button
						href='https://ljdu6doz1dv.typeform.com/to/NNFsA1EU'
						target='_blank'
						variant="contained"
						color="primary"
						size="large"
					>
						Contactez-nous
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
