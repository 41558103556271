import { useEffect, useRef } from 'react';
import { getAuth } from 'firebase/auth';
import { createChart, ColorType, UTCTimestamp } from 'lightweight-charts';
import { ILinearGraph } from './ViewsActivityChartDTO';
import moment from 'moment';


interface QuantCorrelationChartProps {
	data: ILinearGraph;
	colors?: any;
}

export const ViewsActivityChart = (props: QuantCorrelationChartProps) => {

	const {
		data,
		colors: {
			backgroundColor = 'transparent',
			lineColor = '#2962FF',
			textColor = '#333',
			areaTopColor = '#2962FF',
			areaBottomColor = 'rgba(41, 98, 255, 0.1)',
		} = {},
	} = props;

	const ref = useRef<any>();
	const auth = getAuth();


	useEffect(
		() => {
			const handleResize = () => {
				chart.applyOptions({ width: ref.current.clientWidth, height: ref.current.clientHeight });
			};


			const chart = createChart(ref.current, {
				layout: {
					background: { type: ColorType.Solid, color: backgroundColor },
					textColor
				},
				grid: {
					vertLines: {
						visible: true,
						color: '#fff2',
						// color: '#9AC33455'
					},
					horzLines: {
						color: '#fff2'
					}
				},
				autoSize: true,
				width: ref.current.clientWidth,
				height: ref.current.clientHeight,
				timeScale: {
					timeVisible: true,
					secondsVisible: false
				}
			});
			chart.timeScale().fitContent();

			const newSeries = chart.addAreaSeries({
				title: data.title,
				lineColor,
				topColor: areaTopColor,
				bottomColor: areaBottomColor,
				priceLineVisible: true,

				// add suffix % to the value
				priceFormat: {
					type: 'custom',
					formatter: (x: number) => {
						if (x >= 1000 && x < 1000000) {
							return (x / 1000).toFixed(0) + 'K';
						}
						if (x >= 1000000 && x < 1000000000) {
							return (x / 1000000).toFixed(0) + 'M';
						}
						if (x >= 1000000000) {
							return (x / 1000000000).toFixed(0) + 'B';
						}

						return x.toFixed(0);
					}
				}
			});


			// construct an array of data for the series that fills the gaps for each days by using the previous value for the missing ones
			let seriesData: {
				tm: number;
				value: number;
			}[] = [];

			data.values.forEach((row, index) => {
				if (index === 0) {
					seriesData = [row];
					return;
				}

				const previousRow = data.values[index - 1];
				const previousRowTime = moment(previousRow.tm);
				const rowTime = moment(row.tm);

				const diff = rowTime.diff(previousRowTime, 'days');
				if (diff > 1) {
					for (let i = 1; i < diff; i++) {
						seriesData.push({
							tm: previousRowTime.add(1, 'days').unix() * 1000,
							// value: previousRow.value,
							value: 0,
						});
					}
				}

				seriesData.push(row);
			});

			newSeries.setData(seriesData.sort((a, b) => a.tm - b.tm).map(row => {
				return {
					time: moment(row.tm).unix() as UTCTimestamp,
					value: row.value,
					topColor: moment(row.tm) >= moment(auth.currentUser?.metadata.creationTime) ? areaTopColor : areaBottomColor
				}
			}));


			// newSeries.setData(data.values.sort((a, b) => a.tm - b.tm).map(row => {
			// 	return {
			// 		time: moment(row.tm).unix() as UTCTimestamp,
			// 		value: row.value,
			// 		topColor: moment(row.tm) >= moment(auth.currentUser?.metadata.creationTime) ? areaTopColor : areaBottomColor
			// 	}
			// }));


			window.addEventListener('resize', handleResize);

			return () => {
				window.removeEventListener('resize', handleResize);

				chart.remove();
			};
		},
		[data, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
	);


	return (
		<>
			<div style={{ width: '100%', height: '100%', minHeight: '150px', display: 'block' }} ref={ref} />
		</>
	);
}

export default ViewsActivityChart;
