/* Libraries */
import { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import parser from 'html-react-parser';
import { toast } from 'react-toastify';

/* Components */
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

/* Services */
import { BlogPostDTO } from '../../services/blog.service.dto';
import { getBlogPost, getSortedBlogPosts } from '../../services/blog.service';

/* MUI */
import { Box, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';

/* Icons */
import LinkIcon from '@mui/icons-material/Link';

/* CSS */
import './BlogPage.css';


export default function BlogPage() {

	// get the blog id from the url
	const { id = '' } = useParams();

	const [blogPost, setBlogPost] = useState<BlogPostDTO | null>(null);
	const [blogPostIsLoading, setBlogPostIsLoading] = useState(false);

	const [relatedPosts, setRelatedPosts] = useState<BlogPostDTO[] | null>([]);
	const [relatedPostsAreLoading, setRelatedPostsAreLoading] = useState(false);


	useEffect(() => {
		loadBlogPost();
		loadBlogPosts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);


	const loadBlogPost = async () => {
		setBlogPostIsLoading(true);
		const post = await getBlogPost(id);

		if (!post) {
			toast.error("Impossible de trouver l'article demandé");
			return;
		}

		// set the page title
		document.title = post.blogTitle;

		setBlogPost(post);
		setBlogPostIsLoading(false);
	};

	const loadBlogPosts = async () => {
		setRelatedPostsAreLoading(true);
		const posts = await getSortedBlogPosts(6);

		if (posts) {
			// remove the current post from the list
			const filtered = posts.filter(post => post.id !== id);
			setRelatedPosts(filtered);
		}
		setRelatedPostsAreLoading(false);
	};

	const handleCopyArticleLink = () => {
		navigator.clipboard.writeText(window.location.href);
		toast.success('Lien copié !');
	};


	return (
		<Fragment>
			<Header />

			<Box className='BlogPage-container'>
				<Box className='BlogPage-content-container'>
					<Box className='BlogPage-content'>
						<Box className='BlogPage-share-buttons-container'>
							<Tooltip title="Copier le lien">
								<IconButton className='BlogPage-copy-link-button'
									onClick={handleCopyArticleLink}
									size='small'
									sx={{ color: '#555' }}
									disabled={blogPostIsLoading}
								>
									<LinkIcon />
								</IconButton>
							</Tooltip>
						</Box>

						{ blogPostIsLoading && (
							<Fragment>
								<Skeleton
									className='BlogPage-title'
									variant='rounded'
									width={380}
									height={40}
									animation='wave'
								/>

								<Skeleton
									className='BlogPage-hero-img'
									variant='rounded'
									height={180}
									animation='wave'
								/>

								{ Array(10).fill(0).map((_, skeletonIndex) => (
									<Skeleton key={`BlogPage-skeleton-${skeletonIndex}`}
										variant='rounded'
										height={20}
										width={skeletonIndex === 9 ? '70%' : '100%'}
										animation='wave'
										sx={{ mt: 1 }}
									/>
								))}
							</Fragment>
						)}

						{ !blogPostIsLoading && blogPost !== null && (
							<Fragment>
								<Typography variant='h1' className='BlogPage-title'>
									{blogPost.blogTitle}
								</Typography>

								<img className='BlogPage-hero-img'
									src={blogPost.heroImageUrl}
									alt='BlogPage-hero-img'
								/>

								{ blogPost.sections.map((section) => {
									if (section.type === 'title') {
										return (
											<h2 className={`BlogPage-content--${section.type}`}>
												{parser(section.content)}
											</h2>
										);
									} else if (section.type === 'body') {
										return (
											<p className={`BlogPage-content--${section.type}`}>
												{parser(section.content)}
											</p>
										);
									} else if (section.type === 'image') {
										return (
											<img className={`BlogPage-content--${section.type}`}
												src={section.content}
												alt='BlogPage-content-img'
											/>
										);
									} else if (section.type === 'video') {
										return (
											<video className={`BlogPage-content--${section.type}`}
												src={section.content}
												controls
											/>
										);
									} else {
										return null;
									}
								})}

								<Typography className='BlogPage-content-author' variant='h6'>
									Par <span style={{ fontWeight: 500 }}>{blogPost.author}</span>, le {blogPost.created_in.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' })}
								</Typography>
							</Fragment>
						)}
					</Box>
				</Box>

				<Box className='BlogPage-content-container BlogPage-relatated-articles-container'>
					<Box className='BlogPage-content'>
						<Box className='BlogPage-related-posts-container'>
							<Typography variant='h4' className='BlogPage-related-posts-title'>
								Derniers articles
							</Typography>

							<Box className='BlogPage-related-posts'>
							{ !relatedPostsAreLoading && relatedPosts !== null && relatedPosts.map((post, index) => (
								<Link to={`/blog/${post.id}`} style={{ textDecoration: 'none' }} key={`BlogPage-related-post-${index}`}>
									<Box className='BlogPage-post-container'>
										<Box className='BlogPage-post-text-container'>
											<Typography variant='h6' className='BlogPage-post-title'>
												{post.blogTitle}
											</Typography>

											<Typography className='BlogPage-post-author' variant='h6'>
												{post.created_in.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' })}
											</Typography>
										</Box>

										<img className='BlogPage-post-img'
											src={post.heroImageUrl}
											alt='BlogPage-post-img'
										/>
									</Box>
								</Link>
							))}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>

			<Footer />
		</Fragment>
	);
}
