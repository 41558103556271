/* Libraries */
import { Fragment, MouseEvent, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

/* Components */
import Logo from '../../assets/Logo/Logo';

/* Services */

/* MUI */
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Menu, MenuItem, Skeleton, Tooltip, Typography, useMediaQuery } from '@mui/material';

/* Icons */
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InsightsIcon from '@mui/icons-material/Insights';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import SupportIcon from '@mui/icons-material/Support';

/* DTO */
import { PlatformDrawerListItemLinkProps } from './PlatformDrawerDTO';

/* CSS */
import './PlatformDrawer.css';


const drawerWidth = 200;

const mainListItems: PlatformDrawerListItemLinkProps[] = [
	{
		title: 'Calendrier',
		icon: <CalendarMonthIcon />,
		href: '/dashboard',
	},
	// {
	// 	title: 'Metrics',
	// 	icon: <InsightsIcon />,
	// 	href: '/dashboard/insights',
	// },
	{
		title: 'Bibliothèque',
		icon: <VideoLibraryIcon />,
		href: '/dashboard/library',
	},
];

const secondaryListItems: PlatformDrawerListItemLinkProps[] = [
	{
		title: 'Mes comptes',
		icon: <ManageAccountsIcon />,
		href: '/dashboard/accounts',
	},
	{
		title: 'Paramètres',
		icon: <SettingsIcon />,
		href: '/dashboard/settings',
	},
];

const footerListItems: PlatformDrawerListItemLinkProps[] = [
	{
		title: 'Support',
		icon: <SupportIcon />,
		href: '/dashboard/support'
	},
	{
		title: `Changelog`,
		icon: <InfoIcon />,
		href: '/dashboard/changelog'
	}
];


const openedMixin = (theme: Theme): CSSObject => ({
	backgroundColor: '#000',
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	backgroundColor: '#000',
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7.5)})`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(7.5)})`,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'left',
	padding: theme.spacing(0, 1),

	// necessary for content to be below app bar
	// ...theme.mixins.toolbar,
	height: '48px',
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && useMediaQuery(theme.breakpoints.up('sm')) && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);


export default function PlatformDrawer() {
	const theme = useTheme();
	const location = useLocation();
	const [open, setOpen] = useState(false);
	const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
	const [auth, authIsLoading] = useAuthState(getAuth());


	const handleLogout = async () => {
		await getAuth().signOut();
		handleDrawerClose();
	};

	const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const listItem = (page: PlatformDrawerListItemLinkProps, marginY: number = 2) => {

		const isSelected = page.href === location.pathname;
		const selectedColor = isSelected ? '#77DDF9' : '#eee';

		return (
			<ListItem
				key={page.title}
				disablePadding
				sx={{
					display: 'block',
					my: marginY,
				}}
			>
				<Tooltip
					title={!open && (
						<Typography sx={{ fontSize: '10px', fontWeight: 500, color: '#eee' }} variant='body1'>
							{page.title}
						</Typography>
					)}
					placement="right"
				>
					<ListItemButton
						component={Link}
						to={page.href}
						sx={{
							borderRadius: '8px',
							py: 0,
							mx: 1,
							minHeight: 40,
							justifyContent: open ? 'initial' : 'center',
							backgroundColor: isSelected ? 'rgba(255,255,255, .1)' : 'transparent',
							'&:hover': {
								backgroundColor: 'rgba(255,255,255, .2)',
							},
						}}
					>
						<ListItemIcon sx={{
							color: selectedColor,
							minWidth: 0,
							mr: open ? 3 : 'auto',
							justifyContent: 'center',
						}}>
							{page.icon}
						</ListItemIcon>

						<ListItemText
							disableTypography
							primary={
								<Typography sx={{ color: selectedColor, fontWeight: 500 }}>
									{page.title}
								</Typography>
							}
							sx={{ opacity: open ? 1 : 0 }}
						/>
					</ListItemButton>
				</Tooltip>
			</ListItem>
		);
	};


	return (
		<Box sx={{
			display: 'flex',
			backgroundColor: 'background.default',
			height: '100%',
			minHeight: '100vh',
		}}>
			<AppBar position="fixed" open={open} elevation={2}>
				<Toolbar variant='dense' sx={{ bgcolor: '#000' }}>

					{/**
					 * Menu bar for desktop screens
					 */}
					{ auth && auth?.emailVerified && (
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{
							marginRight: 5,
							...((open && { display: 'none' }) || { display: {xs: 'none', sm: 'flex'} }),
						}}
					>
						<MenuIcon />
					</IconButton>
					)}


					{/**
					 * Menu bar for phone screens
					 */}
					{ auth && auth?.emailVerified && (
					<Box sx={{ marginRight: 2, display: { xs: 'flex', sm: 'none' } }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="inherit"
						>
							<DragHandleIcon />
						</IconButton>
						
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: 'block', sm: 'none' },
								'& .MuiPaper-root': {
									backgroundColor: '#000',
									borderRadius: '0 0 12px 12px',
								},
							}}
						>
							{ [...mainListItems, ...secondaryListItems].map((page, index) => {
								const isSelected = page.href === location.pathname;
								const selectedColor = isSelected ? '#77DDF9' : '#EEE';

								return (
									<MenuItem key={index + '-mobile-' + page.title}
										onClick={handleCloseNavMenu}
									>
										<Link
											to={page.href}
											rel="noopener noreferrer"
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												color: selectedColor,
												textDecoration: 'none',
												borderRadius: '8px',
												padding: '7px 12px',
												backgroundColor: isSelected ? 'rgba(255,255,255, .15)' : 'transparent',
												width: '100%',
											}}
										>
											<Box sx={{
												display: 'flex',
												alignItems: 'center',
												gap: 2,
											}}>
												{page.icon}
												{page.title}
											</Box>
										</Link>
									</MenuItem>
								)
							})}
						</Menu>
					</Box>
					)}
					

					<Link to='/dashboard'>
						<Logo />
					</Link>

					<Box sx={{ flexGrow: 1 }} />


					{ authIsLoading && (
						<Box sx={{ backgroundColor: 'white', borderRadius: '10px' }}>
							<Skeleton variant='rectangular' width={30} height={30} animation='wave'/>
						</Box>
					)}


					{ auth && auth?.emailVerified && (
						<Link to='/dashboard/login' style={{ textDecoration: 'none' }}>
							<Tooltip title='Déconnexion' placement='left' arrow>
								<IconButton
									size='small'
									sx={{ color: '#ddd' }}
									onClick={handleLogout}
								>
									<LogoutIcon />
								</IconButton>
							</Tooltip>
						</Link>
					)}
				</Toolbar>
			</AppBar>


			<Drawer variant="permanent" open={open} sx={{
				display: {
					xs: 'none',
					sm: !authIsLoading && (!auth || !auth?.emailVerified) ? 'none' : 'block',
				},
				'& .MuiDrawer-paper': {
					borderRadius: 0
				}
			}}>
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose} sx={{ color: 'white' }} size='small'>
						{theme.direction !== 'rtl' ? <ChevronLeftIcon /> : <MenuIcon />}
					</IconButton>
				</DrawerHeader>

				<Divider sx={{ backgroundColor: '#444' }} />

				{ authIsLoading && Array(4).fill(0).map((_, skeletonIndex) => (
					<Box key={'Skeleton-account-id-' + skeletonIndex}
						sx={{ backgroundColor: '#eee', borderRadius: '10px', margin: '18px 14px' }}
					>
						<Skeleton
							variant='rectangular'
							width='100%'
							height={32}
							animation='wave'
						/>
					</Box>
				))}

				{ auth && auth?.emailVerified && (
					<Fragment>
						<List>
							{ mainListItems.map((page) => listItem(page)) }
						</List>

						<Divider sx={{ backgroundColor: '#444' }} />
						
						<List>
							{ secondaryListItems.map((page) => listItem(page)) }
						</List>

						<Box sx={{ flexGrow: 1 }} />

						<List>
							{ footerListItems.map((page) => listItem(page, 0)) }
						</List>
					</Fragment>
				)}
			</Drawer>

			<Box sx={{ flexGrow: 1, padding: '25px' }}>
				<DrawerHeader />

				{/* All the nested routes inside '/dashboard' are rendered here */}
				<Outlet />
			</Box>
		</Box>
	);
}
