/* Libraries */
import { useEffect, useState } from 'react';
import moment from 'moment';

/* Components */
import PageHeader from '../../assets/PageHeader/PageHeader';
import SocialAccountSelection from '../../components/SocialAccountSelection/SocialAccountSelection';
import ViewsActivityChart from '../../components/ViewsActivityChart/ViewsActivityChart';

/* Services */
import { useAvailableSocialNetworks } from '../../services/socialNetworks.service';
import { SocialAccount, SocialNetworkName } from '../../services/socialNetworks.service.dto';
import { getFacebookAllMedias, getFacebookInsightReach, getFacebookOnlineFollowers } from '../../services/facebook.service';
import { useCalendarPosts } from '../../services/calendar.service';
import { getAllTiktokVideos, getTiktokUserInfos } from '../../services/tiktok.service';

/* MUI */
import { Box, Skeleton, Typography } from '@mui/material';

/* Icons */
import Groups2Icon from '@mui/icons-material/Groups2';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BlurOnIcon from '@mui/icons-material/BlurOn';

/* Types */
import { ILinearGraph } from '../../components/ViewsActivityChart/ViewsActivityChartDTO';

/* CSS */
import './InsightsPage.css';


interface IAccountMetrics {
	views: number;
	followers: number;
	reach: number;
	profileViews: number;
	likes: number;
}

export default function InsightsPage() {
	const [accounts, accountsLoading] = useAvailableSocialNetworks();
	const [selectedAccount, setSelectedAccount] = useState<SocialAccount | null>(null);
	const [accountMetrics, setAccountMetrics] = useState<IAccountMetrics | null>(null);
	const [metricsLoading, setMetricsLoading] = useState(false);
	// const [posts, postsLoading, postsError] = useCalendarPosts();
	const [linearViews, setLinearViews] = useState<ILinearGraph>({ title: '', values: [] });


	useEffect(() => {
		loadAccountMetrics();
		// eslint-disable-next-line
	}, [selectedAccount]);


	const loadAccountMetrics = async () => {
		if (!selectedAccount) {
			return;
		}

		setMetricsLoading(true);

		const accessToken = await selectedAccount?.account.token();
		if (!accessToken) {
			setMetricsLoading(false);
			return;
		}

		if (selectedAccount.platformID === SocialNetworkName.INSTAGRAM) {
			const videos = await getFacebookAllMedias(selectedAccount.account.accountID, accessToken);
			console.log('INSTAGRAM videos', videos);
			if (!videos) {
				setMetricsLoading(false);
				return;
			}

			const likeCount = videos.reduce((acc, video) => acc + video.like_count, 0);
			const reach = await getFacebookInsightReach(selectedAccount.account.accountID, accessToken, 'week', 30);
			console.log('reach', reach);

			setAccountMetrics({
				views: 0,
				followers: selectedAccount.account.data?.followers_count || 0,
				reach: 0,
				profileViews: 0,
				likes: likeCount
			});

			setLinearViews({
				title: 'Likes',
				values: videos.map(video => {
					return {
						tm: moment(video.timestamp).unix() * 1000,
						value: video.like_count
					}
				})
			});

		} else if (selectedAccount.platformID === SocialNetworkName.TIKTOK) {
			const userInfos = await getTiktokUserInfos(selectedAccount.account.id);
			console.log('TIKTOK userInfos', userInfos);
			if (!userInfos) {
				setMetricsLoading(false);
				return;
			}

			const videos = await getAllTiktokVideos(selectedAccount.account.id);
			console.log('TIKTOK videos', videos);
			if (!videos) {
				setMetricsLoading(false);
				return;
			}

			const viewsCount = videos.reduce((acc, video) => acc + video.view_count, 0);
			setAccountMetrics({
				views: viewsCount,
				followers: userInfos.data.user.follower_count,
				reach: 0,
				profileViews: 0,
				likes: userInfos.data.user.likes_count
			});

			setLinearViews({
				title: 'Views',
				values: videos.map(video => {
					return {
						tm: moment(video.create_time * 1000).unix() * 1000,
						value: video.view_count
					}
				})
			});
		}

		setMetricsLoading(false);
	}

	const handleAccountSelection = (newSelectedAccount: SocialAccount) => {
		if (selectedAccount && selectedAccount.account.id === newSelectedAccount.account.id) {
			setSelectedAccount(null);
			setAccountMetrics(null);
		} else {
			setSelectedAccount(newSelectedAccount);
		}
	}


	return (
		<Box className="InsightsPage-container">
			<PageHeader title='Insights' divider />

			<SocialAccountSelection
				newAccountButtonDisabled
				accountSelection
				checkSelectedAccountIDs={selectedAccount ? [selectedAccount?.account.id] : []}
				onClick={handleAccountSelection}
			/>

			{ accountsLoading &&
				<Skeleton variant="rectangular" width="100%" height="100%" />
			}

			{ !accountsLoading && selectedAccount && (
				<Box className='InsightsPage-metrics-container'>
					{ metricsLoading && (
						<>
						<Box className='InsightsPage-account-kpis-container'>
							<Skeleton className='InsightsPage-account-kpi-item-container' variant="rounded" />
							<Skeleton className='InsightsPage-account-kpi-item-container' variant="rounded" />
							<Skeleton className='InsightsPage-account-kpi-item-container' variant="rounded" />
						</Box>

						<Box className='InsightsPage-views-graph-container'>
							<Skeleton variant="rounded" width="100%" height="100%" />
						</Box>
						</>
					)}

					{ !metricsLoading && accountMetrics && (
						<>
						<Box className='InsightsPage-account-kpis-container'>
							<Box className='InsightsPage-account-kpi-item-container'>
								<Typography color='primary' className='InsightsPage-account-kpi-item-title'>
									<Groups2Icon /> Followers
								</Typography>

								<Typography color='primary' className='InsightsPage-account-kpi-item-value'>
									{accountMetrics.followers}
								</Typography>
							</Box>

							<Box className='InsightsPage-account-kpi-item-container'>
								<Typography color='primary' className='InsightsPage-account-kpi-item-title'>
									<FavoriteIcon /> Likes
								</Typography>

								<Typography color='primary' className='InsightsPage-account-kpi-item-value'>
									{accountMetrics.likes}
								</Typography>
							</Box>

							<Box className='InsightsPage-account-kpi-item-container'>
								<Typography color='primary' className='InsightsPage-account-kpi-item-title'>
									<BlurOnIcon /> Taux d'engagement
								</Typography>

								<Typography color='primary' className='InsightsPage-account-kpi-item-value'>
									{accountMetrics.reach.toFixed(2)}%
								</Typography>
							</Box>
						</Box>

						<Box className='InsightsPage-views-graph-container'>
							<ViewsActivityChart data={linearViews} />
						</Box>
						</>
					)}

				</Box>
			)}
		</Box>
	);
}
