/* Libraries */
import { Fragment } from 'react';

/* Components */
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import TypeformButton from '../../assets/TypeformButton/TypeformButton';
import PriceSection from '../../components/PriceSection/PriceSection';

/* MUI */
import { Box, Typography } from '@mui/material';

/* CSS */
import './PricingPage.css';


const heroTitleStyled = {
	color: '#222',
	fontWeight: 800,
	fontSize: {
		xs: '24px',
		sm: '32px',
		md: '42px',
	},
};

const heroSubtitleStyled = {
	color: '#000',
	fontWeight: 400,
	fontSize: {
		xs: '15px',
		sm: '17px',
	},
	mb: '52px',
};

const heroPriceStyled = {
	background: "-webkit-linear-gradient(0deg, #5755c8 30%, purple, magenta, #E0144C, #FFB100, #f3c16d, yellow)",
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent",
	color: '#222',
	fontWeight: 800,
	fontSize: {
		xs: '40px',
		sm: '62px',
		md: '78px',
	},
	zIndex: 1,
};

const priceTitleStyled = {
	...heroTitleStyled
};

const priceSubtitleStyled = {
	color: '#000',
	fontWeight: 500,
	fontSize: {
		xs: '17px',
		sm: '22px',
	},
};

const featureSubtitleStyled = {
	color: '#222',
	fontWeight: 500,
	fontSize: {
		xs: '20px',
		sm: '25px',
	},
	textAlign: 'center',
	mb: 3,
};

const featureItemTitleStyled = {
	color: '#444',
	fontWeight: 800,
	fontSize: {
		xs: '14px',
		sm: '16px',
	},
};

const featureItemSubtitleStyled = {
	color: '#000',
	fontWeight: 500,
	fontSize: {
		xs: '13px',
		sm: '15px',
	},
	textAlign: 'left',
};

export default function PricingPage() {
	return (
		<Fragment>
			<Header />

			<Box className='PricingPage-hero-section-container'>
				<Box className='PricingPage-hero-section'>
					<Box className='PricingPage-hero-section-content'>
						<Typography variant="h1" sx={heroTitleStyled}>
							La puissance de l'IA pour tous.
						</Typography>

						<Typography variant="h2" sx={heroSubtitleStyled}>
							Chez FREEKL, on a trois offres d'abonnement simples, efficaces et qui s'adaptent à tous les créateurs.
						</Typography>

						<TypeformButton color='primary' />
					</Box>

					<Box className='PricingPage-hero-section-content'>
						<Typography variant="h2" sx={heroPriceStyled}>
							3 offres
						</Typography>

						<Box className='PricingPage-hero-glass-fx' />
					</Box>
				</Box>
			</Box>

			<Box className="PricingPage-price-container">
				<Typography variant="h3" sx={priceTitleStyled}>
					Comment être sûr que c'est le bon choix ?
				</Typography>

				<Typography variant="h4" sx={priceSubtitleStyled}>
					Pas de soucis, on s'occupe de tout !
				</Typography>

				<Typography variant="h4" sx={{
					background: "-webkit-linear-gradient(0deg, #5755c8 30%, #E0144C, #FFB100, #f3c16d, yellow)",
					WebkitBackgroundClip: "text",
					WebkitTextFillColor: "transparent",
					fontSize: {
						xs: '24px',
						sm: '27px',
						md: '30px',
					},
					fontWeight: 800
				}}>
					1 mois gratuit sur toutes les offres !
				</Typography>

				<PriceSection />

				<Typography variant="h4" sx={priceSubtitleStyled}>
					Une fois testé, vous n'allez plus vouloir vous en passer !
				</Typography>
			</Box>

			<Box className="PricingPage-features-container">
				<Box className="PricingPage-features-title-container">
					<Typography variant="h3" sx={priceTitleStyled}>
						C'est inédit, innovant et incroyable
					</Typography>

					<Typography variant="h6" sx={priceSubtitleStyled}>
						Comme cette rime !
					</Typography>
				</Box>

				<Box className="PricingPage-features">
					<Box>
						<Typography variant="h4" sx={featureSubtitleStyled}>
							<span style={{
								fontWeight: '1000',
								background: "-webkit-linear-gradient(0deg, #5755c8 30%, #E0144C, #FFB100, #f3c16d)",
								WebkitBackgroundClip: "text",
								WebkitTextFillColor: "transparent",
							}}>
								Vous
							</span> allez adorer
						</Typography>

						<Box className="PricingPage-features-box-container">
							<Box className='PricingPage-features-item-container' sx={{
								gridArea: '1 / 1 / 2 / 2'
							}}>
								<Box className='PricingPage-features-item-text-container'>
									<img src='/icons/icons8-time-94.png' alt='play-circle' style={{ width: '40px', height: '40px' }} />

									<Typography variant="h5" sx={featureItemTitleStyled}>
										Le temps gagné
									</Typography>
								</Box>

								<Box>
									<Typography variant='h6' sx={featureItemSubtitleStyled}>
										Grâce à notre planificateur de contenu
									</Typography>
								</Box>
							</Box>

							<Box className='PricingPage-features-item-container' sx={{
								gridArea: '2 / 1 / 3 / 2'
							}}>
								<Box className='PricingPage-features-item-text-container'>
									<img src='/icons/icons8-statistics-94.png' alt='play-circle' style={{ width: '40px', height: '40px' }} />

									<Typography variant="h5" sx={featureItemTitleStyled}>
										Vraiment connaître vos abonnés
									</Typography>
								</Box>

								<Box>
									<Typography variant='h6' sx={featureItemSubtitleStyled}>
										Des métriques bienveillantes et utiles !
									</Typography>
								</Box>
							</Box>

							<Box className='PricingPage-features-item-container' sx={{
								gridArea: '1 / 2 / 3 / 3'
							}}>
								<Box className='PricingPage-features-item-text-container'>
									<img src='/icons/icons8-mind-map-94.png' alt='play-circle' style={{ width: '40px', height: '40px' }} />

									<Typography variant="h5" sx={featureItemTitleStyled}>
										Avoir un impact partout
									</Typography>
								</Box>

								<Box>
									<Typography variant='h6' sx={featureItemSubtitleStyled}>
										Grâce à notre IA qui vous crée <span style={{ fontWeight: 600 }}>votre</span> stratégie de publication
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>

					<Box>
						<Typography variant="h4" sx={featureSubtitleStyled}>
							Votre <span style={{
								fontWeight: '1000',
								background: "-webkit-linear-gradient(0deg, #5755c8 30%, #E0144C, #FFB100, #f3c16d)",
								WebkitBackgroundClip: "text",
								WebkitTextFillColor: "transparent",
							}}>
								communauté
							</span> va adorer
						</Typography>

						<Box className="PricingPage-features-box-container">
							<Box className='PricingPage-features-item-container' sx={{
								gridArea: '1 / 1 / 1 / 2'
							}}>
								<Box className='PricingPage-features-item-text-container'>
									<img src='/icons/icons8-play-94.png' alt='play-circle' style={{ width: '40px', height: '40px' }} />

									<Typography variant="h5" sx={featureItemTitleStyled}>
										Se divertir
									</Typography>
								</Box>

								<Box>
									<Typography variant='h6' sx={featureItemSubtitleStyled}>
										Parce que c'est le plus important finalement
									</Typography>
								</Box>
							</Box>

							<Box className='PricingPage-features-item-container' sx={{
								gridArea: '2 / 1 / 3 / 2'
							}}>
								<Box className='PricingPage-features-item-text-container'>
									<img src='/icons/icons8-group-94.png' alt='play-circle' style={{ width: '40px', height: '40px' }} />

									<Typography variant="h5" sx={featureItemTitleStyled}>
										Un contenu qui leur parle
									</Typography>
								</Box>

								<Box>
									<Typography variant='h6' sx={featureItemSubtitleStyled}>
										Entièrement dédié à vos abonnés
									</Typography>
								</Box>
							</Box>

							<Box className='PricingPage-features-item-container' sx={{
								gridArea: '1 / 2 / 3 / 3'
							}}>
								<Box className='PricingPage-features-item-text-container'>
									<img src='/icons/icons8-link-94.png' alt='play-circle' style={{ width: '40px', height: '40px' }} />

									<Typography variant="h5" sx={featureItemTitleStyled}>
										Un créateur plus proche de ses abonnés
									</Typography>
								</Box>

								<Box>
									<Typography variant='h6' sx={featureItemSubtitleStyled}>
										Avec plus de temps et vrais infos sur eux, vous ne pourrez que mieux leur parler
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>

			<Footer />
		</Fragment>
	);
}
