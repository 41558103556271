/* Libraries */
import { useEffect, useRef } from 'react';

/* Components */

/* MUI */
import { Box, Typography } from '@mui/material';

/* CSS */
import './SecuritySection.css';


const titleStyled = {
	fontSize: { xs: 30, md: 45},
	mb: { xs: '30px', md: '40px' },
}

const monoStyled = {
	color: '#000',
	fontFamily: 'IBM Plex Mono',
	fontSize: { xs: 14, sm: 16, md: 18 },
	fontWeight: 400,
	textAlign: 'center',
	maxWidth: '750px',
}

const imgStyled = {
	width: { xs: '80%', md: '60%', lg: '750px' }
}

export default function SecuritySection() {

	const ref = useRef<any>();
	const img = '/images/freekl-security2.png';


	useEffect(() => {
		window.addEventListener('scroll', lockIsInView);
		return () => window.removeEventListener('scroll', lockIsInView);
	}, []);


	// toggle lock when in view
	const lockIsInView = () => {
		const rect = ref.current.getBoundingClientRect();

		if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
			ref.current.classList.remove('SecuritySection-unlocked');
		} else {
			ref.current.classList.add('SecuritySection-unlocked');
		}
	};


	return (
		<Box className="SecuritySection-container">
			<Typography variant="h2" className="SecuritySection-title" sx={titleStyled}>
				Vous vous souciez de la protection de vos comptes...?
			</Typography>

			<Typography variant="h3" sx={monoStyled}>
				FREEKL fonctionne en collaboration avec les plateformes de réseaux sociaux afin de <span style={{ fontWeight: 700 }}>garantir la sécurité de vos comptes</span>
			</Typography>

			<Box className='SecuritySection-image-container' sx={imgStyled}>
				<img src={img} alt="Security" className="SecuritySection-img" />
				<Box className="SecuritySection-lock-container">
					<span className="SecuritySection-lock SecuritySection-unlocked" ref={ref}/>
				</Box>
			</Box>
		</Box>
	);
}
