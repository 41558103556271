/* Libraries */
import { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

/* Components */
import PageHeader from '../../assets/PageHeader/PageHeader';
import Modal from '../../assets/Modal/Modal';
import LibraryFileList from '../../components/LibraryFileList/LibraryFileList';
import PlanificationCalendar from '../../components/PlanificationCalendar/PlanificationCalendar';
import PlanificationModal from '../../components/PlanificationModal/PlanificationModal';
import SocialAccountSelection from '../../components/SocialAccountSelection/SocialAccountSelection';

/* Services */
import { FileDTO } from '../../services/clientStorage.service.dto';
import { CalendarEvent, defaultEventModal, EventModalType } from '../../services/calendar.service.dto';
import { addGeneratedCalendarPosts, generateUserCalendar, useCalendarPosts } from '../../services/calendar.service';
import { useAvailableSocialNetworks } from '../../services/socialNetworks.service';

/* MUI */
import { Box } from '@mui/system';
import { Skeleton, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* Icons */
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

/* DTO */
import { SocialAccount } from '../../services/socialNetworks.service.dto';

/* CSS */
import './CalendarPage.css';


export default function CalendarPage() {
	const [accounts, accountsLoading] = useAvailableSocialNetworks();
	const [calendarEvents, calendarEventsLoading] = useCalendarPosts();
	
	// Calendar generation
	const [selectedAccounts, setSelectedAccounts] = useState<SocialAccount[]>([]);
	const [generateByIAFileSelector, setGenerateByIAFileSelector] = useState(false);
	const [calendarGenerationLoading, setCalendarGenerationLoading] = useState(false);

	// New event modal
	const [newEventModal, setNewEventModal] = useState<EventModalType>(defaultEventModal);


	const handleGenerateByIAFileSelectorOpen = () => {
		setGenerateByIAFileSelector(true);
	};

	const handleGenerateByIAFileSelectorClose = () => {
		setGenerateByIAFileSelector(false);
		setSelectedAccounts([]);
	};

	const handleGenerationModalAccountSelection = async (account: SocialAccount | null) => {
		setSelectedAccounts((prev) => {
			if (account) {
				if (prev.find((prevAccount) => prevAccount.account.id === account.account.id)) {
					return prev.filter((prevAccount) => prevAccount.account.id !== account.account.id);
				} else {
					return [...prev, account];
				}
			} else {
				return prev;
			}
		});
	};

	const handleGenerateCalendarByAI = async (files: FileDTO[]) => {
		if (selectedAccounts.length === 0) {
			toast.info('Vous devez sélectionner au moins un compte pour générer le calendrier');
			return ;
		}

		if (files.length === 0) {
			toast.info('Vous devez sélectionner au moins un fichier pour générer le calendrier');
			return ;
		}

		handleGenerateByIAFileSelectorClose();
		setCalendarGenerationLoading(true);
		const toastID = toast('Génération du calendrier en cours...', {
			autoClose: false,
			isLoading: true,
			closeOnClick: false,
		});

		const generatedEvents = await generateUserCalendar(files, selectedAccounts);
		console.log('generatedEvents', generatedEvents);

		if (generatedEvents.length === 0) {
			toast.update(toastID, {
				type: toast.TYPE.ERROR,
				render: 'Une erreur est survenue lors de la génération du calendrier',
				isLoading: false,
				closeButton: true,
				autoClose: 5000,
			});
		} else {
			toast.update(toastID, {
				type: toast.TYPE.SUCCESS,
				render: 'Génération du calendrier terminée. Vous devez les compléter afin de les planifier.',
				isLoading: false,
				closeButton: true,
				autoClose: 10000,
			});
		}

		await addGeneratedCalendarPosts(generatedEvents);
		setCalendarGenerationLoading(false);
	};

	const handleOpenNewEventModal = (account: SocialAccount | null, date?: Date) => {
		setNewEventModal({
			open: true,
			account: account,
			event: {
				platformID: account?.platformID,
				accountID: account?.account.accountID,
				date: date ? date : moment().set({second: 0, millisecond: 0}).add(15, 'minutes').toDate(),
			},
		});
	};

	const handleCloseNewEventModal = () => {
		setNewEventModal(defaultEventModal);
	};

	const handleModifyEvent = (newEvent: Partial<CalendarEvent>) => {
		setNewEventModal((prev) => {
			return {
				...prev,
				event: newEvent,
			}
		});
	};


	return (
		<Box className='CalendarPage-container'>
			<PageHeader title='Calendrier' divider fullWidth />

			<PlanificationModal
				open={newEventModal.open}
				onClose={handleCloseNewEventModal}
				setEvent={handleModifyEvent}
				event={newEventModal.event}
				account={newEventModal.account}
			/>

			<Box className='CalendarPage-calendar-container'>
				<Box className='CalendarPage-header-container'>
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<SocialAccountSelection onClick={handleOpenNewEventModal} />
					</Box>

					<Box className='CalendarPage-calendar-header-buttons-container'>
						{ (calendarEventsLoading || accountsLoading) && (
							<Skeleton
								className='CalendarPage-calendar-ai-generation-button'
								variant='rounded'
								animation='wave'
								width={105}
								height={19}
							/>
						)}

						{ !calendarEventsLoading && !accountsLoading && (
							<Tooltip
								title='Connectez-vous à un réseau social pour pouvoir générer des posts'
								disableHoverListener={accounts.length !== 0}
								open={accounts.length === 0}
								placement='bottom-start'
								arrow
							>
								<span>
									<LoadingButton
										className='CalendarPage-calendar-ai-generation-button'
										disabled={calendarEventsLoading || accountsLoading || accounts.length === 0}
										color='primary'
										variant='outlined'
										onClick={handleGenerateByIAFileSelectorOpen}
										loading={calendarGenerationLoading}
										startIcon={<AutoFixHighIcon />}
									>
										Planifier par IA
									</LoadingButton>

									{/* <LoadingButton
										disabled={calendarEventsLoading || accountsLoading || accounts.length === 0}
										color='info'
										variant='outlined'
										onClick={handleGenerateByIAFileSelectorOpen}
										loading={calendarGenerationLoading}
										startIcon={<AutoFixHighIcon />}
									>
										Planifier par IA
									</LoadingButton> */}
								</span>
							</Tooltip>
						)}
					</Box>
				</Box>

				<PlanificationCalendar
					loading={calendarEventsLoading || accountsLoading}
					events={calendarEvents || []}
					openNewEventModal={handleOpenNewEventModal}
				/>
			</Box>

			<Modal open={generateByIAFileSelector}
				setClose={handleGenerateByIAFileSelectorClose}
				disableAnimation
				className='CalendarPage-event-file-selector-modal'
			>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<SocialAccountSelection
						onClick={handleGenerationModalAccountSelection}
						checkSelectedAccountIDs={selectedAccounts.map((account) => account.account.id)}
						accountSelection
						newAccountButtonDisabled
					/>
				</Box>

				<LibraryFileList
					disableSearchBar
					disableStorageProgress
					enforceSelectionMode
					onValidateSelection={handleGenerateCalendarByAI}
				/>
			</Modal>
		</Box>
	);
}
