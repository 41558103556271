/* Libraries */

/* Components */

/* CSS */
import './TikTokLogo.css';


interface TikTokLogoProps {
	dark?: boolean;
	width?: number;
	className?: string;
	style?: React.CSSProperties;
}

export default function TikTokLogo(props: TikTokLogoProps) {

	const logo = props.dark ? "/icons/tiktok-logo-glyph-light.png" : "/icons/tiktok-logo-glyph-dark.png";

	return (
		<img src={logo}
			className={props.className}
			style={props.style}
			alt="TikTok Logo"
			width={ props.width ? props.width : 20 }
		/>
	);
}
