/* Firebase */
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getStripePayments } from "@stripe/firestore-stripe-payments";


// Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyB4hc6JJCWbrzdDHb14hXQHzcuaoAhn4Zk",
	authDomain: "freekl.firebaseapp.com",
	projectId: "freekl",
	storageBucket: "freekl.appspot.com",
	messagingSenderId: "531382514324",
	appId: "1:531382514324:web:5214c61b34f8367c33ba81",
	measurementId: "G-QD3J3K7YQ6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const appCheck = initializeAppCheck(app, {
	provider: new ReCaptchaV3Provider('6LfFrNgkAAAAAOqBK7dZgX2HMiu-ZYeAhXzmV7Df'),
	isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const payments = getStripePayments(app, {
	customersCollection: 'customers_details',
	productsCollection: 'pricing_plans',
});
