/* Libraries */
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getCurrentUserSubscriptions } from '@stripe/firestore-stripe-payments';
import { payments } from '../../config/firebase.config';

/* Components */

/* Services */

/* MUI */
import { Box, CircularProgress } from '@mui/material';

/* CSS */
import './PrivateRoute.css';


interface PrivateRouteProps {
	children: any;
};

export default function PrivateRoute(props: PrivateRouteProps) {
	const [auth, authIsLoading, authError] = useAuthState(getAuth());
	const [subscribtionLoading, setSubscribtionLoading] = useState(true);
	const [subscribtionValidated, setSubscribtionValidated] = useState(false);


	useEffect(() => {
		if (!auth || !auth.emailVerified || subscribtionValidated) {
			setSubscribtionLoading(false);
			return;
		}
	
		const fetchSubscription = async () => {
			// const a = await getUserSubscription();
			// console.log('a', a);

			const subs = await getCurrentUserSubscriptions(payments, { status: ['active', 'trialing'] });
			if (subs.length > 0) {
				setSubscribtionValidated(true);
			}
			setSubscribtionLoading(false);
		};

		fetchSubscription();
	}, [auth, subscribtionValidated]); // Update dependencies here


	if (authIsLoading || subscribtionLoading) {
		return (
			<Box className='PrivateRoute-container'>
				<CircularProgress disableShrink size={25} />
			</Box>
		)
	}

	if (!subscribtionValidated && !subscribtionLoading) {
		return <Navigate to={`/dashboard/select-subscription?redirect=${encodeURIComponent(window.location.pathname)}`} />;
	}

	if (auth && auth.emailVerified && subscribtionValidated) {
		return props.children;
	}

	if (authError) {
		return <Navigate to="/dashboard/login" />;
	}

	return <Navigate to="/dashboard/login" />;
}
