/* Libraries */
import { useEffect, useState } from 'react';

/* Components */

/* Services */

/* MUI */

/* Icons */

/* DTO */
import { ContentType, ContentWrapperProps } from './ContentWrapperDTO';

/* CSS */
import './ContentWrapper.css';


export default function ContentWrapper(props: ContentWrapperProps) {

	const {
		files,
		className = '',
		style,
	} = props;

	const [contentType, setContentType] = useState<ContentType | null>(null);


	useEffect(() => {
		const type: string | undefined = files[0]?.metadata?.contentType?.split('/')[0];

		if (type === 'image' || type === 'video') {
			setContentType(type);
		} else {
			setContentType(null);
		}
	}, [files]);


	switch (contentType) {
		case 'image':
			return (
				<img className={className} style={style}
					src={files[0]?.thumbnails?.sm?.url || files[0]?.url || ''}
					alt={files[0]?.name || ''}
				/>
			);

		case 'video':
			return (
				<video className={className} style={style}
					src={files[0]?.thumbnails?.sm?.url || files[0]?.thumbnails?.preview?.url || files[0]?.url || ''}
					autoPlay
					muted
					loop
					playsInline
					controls={false}
				/>
			);

		default:
			return <></>;
	}
}
