/* Libraries */
import { useState } from 'react';
import Slider from "react-slick";

/* Components */

/* Services */

/* MUI */
import { Box } from '@mui/material';

/* Icons */
import { BsChevronCompactRight } from 'react-icons/bs';
import { BsChevronCompactLeft } from 'react-icons/bs';

/* DTO */
import { ContentCarrouselProps } from './ContentCarrouselDTO';

/* CSS */
import './ContentCarrousel.css';


export default function ContentCarrousel(props: ContentCarrouselProps) {

	const { files } = props;
	const [ref, setRef] = useState<any>(null);


	const handleNext = () => {
		ref.slickNext();
	};

	const handlePrev = () => {
		ref.slickPrev();
	};


	return (
		<>
			<Slider
				ref={setRef}
				className='ContentCarrousel-inner-container'
				// lazyLoad='anticipated'
				speed={200}
				arrows={false}
				dots
			>
				{ files.map((file, index) => {
					const contentType: string | undefined = file?.metadata?.contentType?.split('/')[0];

					switch (contentType) {
						case 'image':
							return (
								<img className='ContentCarrousel-content' key={index}
									src={file?.thumbnails?.sm?.url || file?.url || ''}
									alt={file?.name || ''}
								/>
							);

						case 'video':
							return (
								<video className='ContentCarrousel-content' key={index}
									src={file?.thumbnails?.md?.url || file?.thumbnails?.sm?.url || file?.thumbnails?.preview?.url || file?.url || ''}
									muted
									loop
									playsInline
									controls={true}
									controlsList='nodownload'
								/>
							);

						default:
							return <></>;
					}
				})}
			</Slider>

			{ files.length > 1 && (
				<>
					<Box className='ContentCarrousel-arrow-container ContentCarrousel-arrow-prev' onClick={handlePrev}>
						<BsChevronCompactLeft className='ContentCarrousel-arrow'/>
					</Box>

					<Box className='ContentCarrousel-arrow-container ContentCarrousel-arrow-next' onClick={handleNext}>
						<BsChevronCompactRight className='ContentCarrousel-arrow' />
					</Box>
				</>
			)}
		</>
	);
}
