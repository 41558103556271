/* Libraries */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/* Components */

/* Services */
import { SocialNetworkName } from '../../services/socialNetworks.service.dto';
import { ALL_SOCIAL_PLATFORMS } from '../../services/socialNetworks.service';
import { MAX_POSTS_PER_WEEK, MIN_POSTS_PER_WEEK, getCustomerSocialAccountsPreference, updateCustomerSocialAccountsPostsPerWeek, updateCustomerSocialAccountsPreferredPlatform } from '../../services/customers.service';

/* MUI */
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { Divider, Skeleton, TextField, Typography } from '@mui/material';

/* Icons */

/* CSS */
import './AccountPostingSettings.css';


const titleStyled = {
	fontWeight: 600,
	fontSize: '1rem',
	color: '#444'
};

const titleDividerStyled = {
	margin: '5px 0 15px 0',
};

const postsDescriptionStyled = {
	color: '#444',
	fontWeight: 400,
	marginBottom: '25px',
};

const postNumberStyled = {
	width: '150px',
	'& .MuiInputBase-input': {
		fontWeight: 800,
		fontSize: '2rem',
	},
};

export default function AccountPostingSettings() {

	const [socialAccountPreference, setSocialAccountPreference] = useState<any | null>(null);
	const [postsPerWeek, setPostsPerWeek] = useState(MIN_POSTS_PER_WEEK); // placeholder, not used
	const [loadingPreference, setLoadingPreference] = useState(true);
	const [loadingPostsPerWeek, setLoadingPostsPerWeek] = useState(true);


	useEffect(() => {
		loadSocialAccountPreference();
	}, []);


	const loadSocialAccountPreference = async () => {
		setLoadingPreference(true);
		const preference = await getCustomerSocialAccountsPreference();

		// add icon to each platform
		const newAccounts = preference.accounts.map((account) => {
			const platform = ALL_SOCIAL_PLATFORMS.find((platform) => platform.platformID === account.platformID);

			return {
				...account,
				name: platform?.name,
				icon: platform?.icon
			};
		});

		const newPreference = {
			...preference,
			accounts: newAccounts
		};

		setSocialAccountPreference(newPreference);
		setPostsPerWeek(preference.postsPerWeek);
		setLoadingPreference(false);
		setLoadingPostsPerWeek(false);
	};

	const handleChangePreferredPlatform = async (platformID: SocialNetworkName) => {
		setLoadingPreference(true);
		await updateCustomerSocialAccountsPreferredPlatform(platformID);

		toast.success('Préférences de posting mises à jour');
		await loadSocialAccountPreference();
		setLoadingPreference(false);
	};

	const handleChangePostsPerWeek = (event: any) => {
		const value: string = event.target.value;
		const numberValue = Number(value);

		// if value is not a number, do nothing
		if (isNaN(numberValue)) {
			return;
		}
		setPostsPerWeek(numberValue);
	};

	const handleUpdatePostsPerWeek = async () => {
		setLoadingPostsPerWeek(true);
		await updateCustomerSocialAccountsPostsPerWeek(postsPerWeek);

		toast.success('Préférences de posting mises à jour');
		setLoadingPostsPerWeek(false);
		
		await loadSocialAccountPreference();
	};


	return (
		<Box className='AccountPostingSettings-container'>
			<Box className='AccountPostingSettings-sub-container'>
				<Typography variant='h5' sx={titleStyled}>
					Plateforme préférée
				</Typography>

				<Divider sx={titleDividerStyled} />

				<Typography variant='h6' sx={postsDescriptionStyled}>
					Choisissez la plateforme sur laquelle vous souhaitez publier le plus de contenu.<br />
					Ce choix déterminera quelle plateforme sera mise en avant dans votre tableau de bord et lors de la génération de votre calendrier de publication.
				</Typography>

				<Box className='AccountPostingSettings-preferred-platform-container'>
				{ !loadingPreference && socialAccountPreference?.accounts.map((account: any, index: number) => {
					return (
						<Box key={`AccountPostingSettings-preferred-platform-item-${index}`}
							className={`AccountPostingSettings-preferred-platform-item ${
								socialAccountPreference.preferred === account.platformID ?
									'AccountPostingSettings-preferred-platform-item-active' : ''
							}`}
							onClick={() => handleChangePreferredPlatform(account.platformID)}
						>
							<Box className='AccountPostingSettings-preferred-platform-item-icon'>
								{account.icon}
							</Box>

							<Typography variant='h6' sx={{ color: '#333', fontWeight: 700 }}>
								{account.name}
							</Typography>
						</Box>
					);
				})}

				{ loadingPreference && Array(3).fill(0).map((_, skeletonIndex) => (
					<Box key={'Skeleton-account-id-' + skeletonIndex} className='AccountPostingSettings-preferred-platform-item'>
						<Skeleton variant="rounded" width={150} />
					</Box>
				))}
				</Box>
			</Box>

			<Box className='AccountPostingSettings-sub-container'>
				<Typography variant='h5' sx={titleStyled}>
					Nombre de posts par semaine
				</Typography>

				<Divider sx={titleDividerStyled} />

				<Typography variant='h6' sx={postsDescriptionStyled}>
					Choisissez le nombre de publications par semaine que vous publiez sur tous vos comptes.<br />
					Ce nombre correspond à du contenu original et non à du contenu réutilisé.
				</Typography>

				<Box className='AccountPostingSettings-posts-per-week-container'>
					{ (loadingPreference || loadingPostsPerWeek) && (
						<Skeleton variant="rounded" width={150} height={84} />
					) }

					{ !loadingPreference && !loadingPostsPerWeek && (
						<TextField
							placeholder='Nombre de posts par semaine'
							helperText={`Nombre de posts par semaine (entre ${MIN_POSTS_PER_WEEK} et ${MAX_POSTS_PER_WEEK})`}
							variant='standard'
							value={postsPerWeek}
							onChange={handleChangePostsPerWeek}
							sx={postNumberStyled}
							size='small'
							inputProps={{ maxLength: 3 }}
						/>
					)}

					<LoadingButton
						loading={loadingPostsPerWeek}
						disabled={loadingPreference || postsPerWeek === socialAccountPreference.postsPerWeek || postsPerWeek < MIN_POSTS_PER_WEEK || postsPerWeek > MAX_POSTS_PER_WEEK}
						variant='contained'
						size='small'
						color='primary'
						onClick={handleUpdatePostsPerWeek}
					>
						Enregistrer
					</LoadingButton>
				</Box>

				{ postsPerWeek < MIN_POSTS_PER_WEEK && (
					<Typography variant='caption' sx={{ color: 'red', textAlign: 'center' }}>
						Le nombre de posts par semaine doit être supérieur ou égal à {MIN_POSTS_PER_WEEK}
					</Typography>
				)}

				{ postsPerWeek > MAX_POSTS_PER_WEEK && (
					<Typography variant='caption' sx={{ color: 'red', textAlign: 'center' }}>
						Le nombre de posts par semaine doit être inférieur ou égal à {MAX_POSTS_PER_WEEK}
					</Typography>
				)}
			</Box>
		</Box>
	);
}
