/* Libraries */
import { MouseEvent, useState } from 'react';

/* Components */
import Logo from '../../assets/Logo/Logo';
import TypeformButton from '../../assets/TypeformButton/TypeformButton';

/* MUI */
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from '@mui/material';

/* Icons */
import DragHandleIcon from '@mui/icons-material/DragHandle';

/* CSS */
import './Header.css';


const pages = [
	{
		title: 'Tarifs',
		href: '/pricing',
		target: ''
	},
	{
		title: 'Blog',
		href: 'https://www.linkedin.com/company/freekl/',
		target: '_blank'
	}
];

export default function Header() {
	const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

	const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};


	return (
		<AppBar position="fixed" className='Header-app-bar-container'>
		<Container maxWidth="xl">
			<Toolbar disableGutters>
				<Box sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '6px',
				}}>
					<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
						<Logo />
					</Box>

					<Typography
						variant="h1"
						noWrap
						component="a"
						href="/"
						sx={{
							mr: 2,
							display: { xs: 'none', md: 'flex' },
							fontFamily: 'monospace',
							fontSize: '13px',
							letterSpacing: '.15rem',
							color: '#333',
							textDecoration: 'none',
						}}
					>
						FREEKL
					</Typography>
				</Box>

				<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
					<IconButton
						size="large"
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleOpenNavMenu}
					>
						<DragHandleIcon />
					</IconButton>
					
					<Menu
						id="menu-appbar"
						anchorEl={anchorElNav}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						open={Boolean(anchorElNav)}
						onClose={handleCloseNavMenu}
						sx={{
							display: { xs: 'block', md: 'none' },
							'& .MuiPaper-root': {
								backgroundColor: '#eee',
								color: '#333',
							},
						}}
					>
						{ pages.map((page, index) => (
							<MenuItem key={index + '-mobile-' + page} onClick={handleCloseNavMenu}>
								<Link
									href={page.href}
									rel="noopener noreferrer"
									target={page.target}
									sx={{ color: '#333', textDecoration: 'none', textTransform: 'none' }}
								>
									{page.title}
								</Link>
							</MenuItem>
						))}
					</Menu>
				</Box>
				

				<Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
					<Logo />
				</Box>
				
				<Typography
					variant="h1"
					noWrap
					component="a"
					href="/"
					sx={{
						mr: 2,
						display: { xs: 'flex', md: 'none' },
						flexGrow: 1,
						fontFamily: 'monospace',
						fontSize: '13px',
						letterSpacing: '.15rem',
						color: '#333',
						textDecoration: 'none',
					}}
				>
					FREEKL
				</Typography>

				<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
					{ pages.map((page, index) => (
						<Button
							key={index + '-' + page}
							onClick={handleCloseNavMenu}
							sx={{ my: 2, color: 'white', display: 'block' }}
						>
							<Link
								href={page.href}
								rel="noopener noreferrer"
								target={page.target}
								sx={{ color: '#333', textDecoration: 'none', textTransform: 'none', fontSize: '14px' }}
							>
								{page.title}
							</Link>
						</Button>
					))}
				</Box>

				<TypeformButton>
					Démarrer
				</TypeformButton>
			</Toolbar>
		</Container>
		</AppBar>
	);
}
