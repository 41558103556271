/* Libraries */

/* Components */

/* MUI */
import { Box, Typography } from '@mui/material';

/* CSS */
import './FeaturesSection.css';


const textStyled = {
	fontSize: '15px',
	fontWeight: 600,
	pt: '15px'
};

export default function FeaturesSection() {

	const features = [
		{
			title: 'Un dashboard regroupant tous vos réseaux',
			src: '/icons/icons8-dashboard-94.png',
		},
		{
			title: 'Un calendrier pour planifier vos posts sur tous les réseaux',
			src: '/icons/icons8-calendar-94.png',
		},
		{
			title: "Des métriques comme s'il en pleuvait, faciles à comprendre et vraiment utiles !",
			src: '/icons/icons8-statistics-94.png',
		},
		{
			title: 'Chargez tous vos contenus sur FREEKL',
			src: '/icons/icons8-storage-94.png',
		},
	];
	
	return (
		<Box className="FeaturesSection-container">
			{ features.map((feature, index) => (
				<Box className="FeaturesSection-feature-container" key={`FeaturesSection-feature-container-${index}`}>
					<img className="FeaturesSection-feature-icon" src={feature.src} alt="feature-icon" />

					<Typography variant="body1" sx={textStyled}>
						{feature.title}
					</Typography>
				</Box>
			))}
		</Box>
	);
}
