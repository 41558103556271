/* Libraries */

/* Components */
import PageHeader from '../../assets/PageHeader/PageHeader';

/* MUI */
import { Box } from '@mui/system';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

/* Icons */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/* CSS */
import './SupportPage.css';


const accordionSx = {
	boxShadow: 'none',
	border: '1px solid #ddd',

	// remove divider
	'&:before': {
		display: 'none'
	},
}

const accordionSummarySx = {
	color: '#333',
	fontWeight: 700,
	fontSize: '1rem'
}

const accordionDetailsSx = {
	color: '#333',
	fontSize: '0.9rem'
}

export default function SupportPage() {
	return (
		<Box className='SupportPage-container'>
			<PageHeader title='FAQ' divider />

			<Box className='SupportPage-faq-container'>
				<Accordion sx={accordionSx}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant='h6' color='primary' sx={accordionSummarySx}>
							Comment FREEKL fonctionne ?
						</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Typography variant='body1' sx={accordionDetailsSx}>
							Grâce à l'intelligence artificielle, FREEKL collecte des données sur votre communauté à partir de différents réseaux sociaux. Cela permet de déterminer le moment optimal pour publier en fonction du jour, du réseau et du contenu. <br />
							En publiant au bon moment, lorsque vos abonnés sont les plus réceptifs, vous augmentez l'engagement et améliorez la portée de vos publications. FREEKL contribue également à fidéliser vos abonnés, accélérant ainsi votre croissance.
						</Typography>
					</AccordionDetails>
				</Accordion>

				<Accordion sx={accordionSx}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant='h6' color='primary' sx={accordionSummarySx}>
							Quelles plateformes sont disponibles ?
						</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Typography variant='body1' sx={accordionDetailsSx}>
							Actuellement, FREEKL prend en charge Instagram et TikTok. <br />
							Nous nous efforçons d'optimiser notre algorithme pour chaque plateforme. Si vous souhaitez voir l'ajout de nouvelles plateformes, faites-le nous savoir à l'adresse support@freekl.com.
						</Typography>
					</AccordionDetails>
				</Accordion>

				<Accordion sx={accordionSx}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant='h6' color='primary' sx={accordionSummarySx}>
							Que peut-on poster avec FREEKL ?
						</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Typography variant='body1' sx={accordionDetailsSx}>
							Sur Instagram : <br />
								- Reels + miniature <br />
								- Posts (Photo) <br />
								- Carrousels (Sélectionnez plusieurs médias lors de la création de post) <br />
								- *Note : Les stories sur Instagram ne sont pas encore prises en charge, mais nous y travaillons activement.* <br />
							
							<br />
							
							Sur TikTok : <br />
								- Vidéos + miniature
						</Typography>
					</AccordionDetails>
				</Accordion>

				<Accordion sx={accordionSx}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant='h6' color='primary' sx={accordionSummarySx}>
							Quels types de formats sont acceptés ?
						</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Typography variant='body1' sx={accordionDetailsSx}>
							<strong style={{ fontSize: '1.25rem', fontWeight: 800 }}>Instagram</strong><br/>
							<strong>Photo:</strong>
							<ul>
								<li><strong>Résolution:</strong> Minimum 320 x 320 pixels, Maximum 1440 x 1440 pixels</li>
								<li><strong>Rapport d'aspect:</strong> 1:1</li>
								<li><strong>Durée:</strong> N/A</li>
								<li><strong>Poids maximal:</strong> 8 Mo</li>
								<li><strong>Formats de fichier:</strong> JPEG</li>
							</ul>

							<strong>Réel:</strong>
							<ul>
								<li><strong>Résolution:</strong> Maximum 1080 x 1920 pixels</li>
								<li><strong>Rapport d'aspect:</strong> 9:16</li>
								<li><strong>Durée:</strong> 60 secondes maximum</li>
								<li><strong>Poids maximal:</strong> 1 Go</li>
								<li><strong>Fréquence d'images:</strong> 30 à 60 fps</li>
								<li><strong>Formats de fichier:</strong> MP4, MOV</li>
							</ul>

							<br/><br/>
							<strong style={{ fontSize: '1.25rem', fontWeight: 800 }}>TikTok</strong><br/>
							<strong>Photo:</strong>
							<ul>
								<li><strong>Résolution:</strong> Maximum 1080 x 1920 pixels</li>
								<li><strong>Rapport d'aspect:</strong> N/A</li>
								<li><strong>Durée:</strong> N/A</li>
								<li><strong>Poids maximal:</strong> 20 Mo</li>
								<li><strong>Formats de fichier:</strong> JPEG</li>
							</ul>
							
							<strong>Vidéos:</strong>
							<ul>
								<li><strong>Résolution:</strong> Maximum 1080 x 1920 pixels</li>
								<li><strong>Rapport d'aspect:</strong> 9:16</li>
								<li><strong>Durée:</strong> 3 minutes maximum (10 minutes pour les comptes vérifiés)</li>
								<li><strong>Poids maximal:</strong> 1 Go</li>
								<li><strong>Fréquence d'images:</strong> 30 à 60 fps</li>
								<li><strong>Formats de fichier:</strong> MP4, MOV</li>
							</ul>

							<br/><br/>
							<strong style={{ fontSize: '1.25rem', fontWeight: 800 }}>YouTube</strong><br/>
							<strong style={{ fontSize: '1rem', textDecoration: 'underline' }}>Shorts</strong><br/>

							<strong>Vidéo:</strong>
							<ul>
								<li><strong>Résolution:</strong> 1080x1920 pixels (vertical)</li>
								<li><strong>Rapport d'aspect:</strong> 9:16</li>
								<li><strong>Fréquence d'images:</strong> 24, 30 ou 60 fps</li>
								<li><strong>Débit binaire vidéo:</strong> 6 Mbps minimum, 15 Mbps maximum</li>
								<li><strong>Formats de fichier vidéo:</strong> MP4 (H.264/AVC)</li>
								<li><strong>Durée:</strong> 60 secondes maximum</li>
								<li><strong>Poids maximal:</strong> 100 Mo</li>
							</ul>

							<strong>Audio:</strong>
							<ul>
								<li><strong>Canaux audio:</strong> Stéréo</li>
								<li><strong>Fréquence d'échantillonnage:</strong> 44.1 kHz ou 48 kHz</li>
								<li><strong>Débit binaire audio:</strong> 128 kbps minimum, 320 kbps maximum</li>
								<li><strong>Formats de fichier audio:</strong> AAC, MP3</li>
							</ul>

							<strong style={{ fontSize: '1rem', textDecoration: 'underline' }}>Vidéos longues:</strong><br/>

							<strong>Vidéo:</strong>
							<ul>
								<li><strong>Résolution:</strong> 1280x720 pixels (HD) ou 1920x1080 pixels (Full HD) minimum, 3840x2160 pixels (4K) maximum</li>
								<li><strong>Rapport d'aspect:</strong> 16:9</li>
								<li><strong>Fréquence d'images:</strong> 24, 25, 30 ou 60 fps</li>
								<li><strong>Débit binaire vidéo:</strong> 8 Mbps minimum pour HD, 15 Mbps pour Full HD, 51 Mbps pour 4K</li>
								<li><strong>Formats de fichier vidéo:</strong> MP4 (H.264/AVC), MOV (H.264/AVC), AVI (H.264/AVC), WMV (VC-1, WMV9)</li>
								<li><strong>Durée:</strong> 12 heures maximum (pour les vidéos vérifiées), 60 minutes maximum (pour les vidéos non vérifiées)</li>
								<li><strong>Poids maximal:</strong> 128 Go (pour les vidéos 4K), 25 Go (pour les vidéos Full HD), 10 Go (pour les vidéos HD)</li>
							</ul>

							<strong>Audio:</strong>
							<ul>
								<li><strong>Canaux audio:</strong> Mono, Stéréo, Surround 5.1</li>
								<li><strong>Fréquence d'échantillonnage:</strong> 44.1 kHz, 48 kHz ou 96 kHz</li>
								<li><strong>Débit binaire audio:</strong> 128 kbps minimum, 320 kbps maximum</li>
								<li><strong>Formats de fichier audio:</strong> AAC, MP3, WAV</li>
							</ul>
						</Typography>
					</AccordionDetails>
				</Accordion>

				<Accordion sx={accordionSx}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant='h6' color='primary' sx={accordionSummarySx}>
							Quels type de compte peut être connecté ?
						</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Typography variant='body1' sx={accordionDetailsSx}>
							Pour utiliser FREEKL, vous devez être détenteur d'un compte professionnel ou créateur sur les réseaux sociaux. La connexion ne peut pas être établie sans ces types de comptes.
						</Typography>
					</AccordionDetails>
				</Accordion>

				<Accordion sx={accordionSx}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant='h6' color='primary' sx={accordionSummarySx}>
							Qu'en est-il de la sécurité ?
						</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Typography variant='body1' sx={accordionDetailsSx}>
							FREEKL est partenaire des réseaux sociaux tels qu'Instagram et TikTok. La sécurité de votre compte est assurée directement par ces réseaux. Votre compte ne sera jamais bloqué, et vos données personnelles ainsi que celles de votre communauté sont protégées.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Box>
		</Box>
	);
}
