export interface YoutubeTokenResponse {
	access_token: string;
	expires_in: number; // in seconds
	scope: string;
	token_type: string;
	refresh_token?: string; // only if offline access is requested & the first time the user connects
	error?: string;
}


export interface YoutubeInsightReachRow extends Record<string, string | number> {
}


export interface YoutubeInsightReachColumnHeader {
	name: string;
	columnType: string;
	dataType: string;
}


export interface YoutubeInsightReachResponse {
	kind: string;
	columnHeaders: YoutubeInsightReachColumnHeader[];
	rows: Array<string | number>[];
	error?: any;
}


export enum YoutubePrivacyLevel {
	PUBLIC = 'public',
	UNLISTED = 'unlisted',
	PRIVATE = 'private'
}
