/* Librairies */

/* Components */

/* MUI */
import { Box, IconButton, Tooltip } from '@mui/material';

/* DTO */
import { PlanificationCalendarNavigationProps } from './PlanificationCalendarNavigationDTO';

/* Icons */
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import TodayIcon from '@mui/icons-material/Today';

/* CSS */
import './PlanificationCalendarNavigation.css';
import moment from 'moment';


export default function PlanificationCalendarNavigation({
	MainLeftClick,
	MainRightClick,
	resetWeek,
	startingDate,
} : PlanificationCalendarNavigationProps) {
	return (
		<Box className="PlanificationCalendar-navigation-container">
			<span className="PlanificationCalendar-navigation-text">
				{startingDate.toLocaleDateString('fr-FR', {month: 'long', year: 'numeric'})}
			</span>

			<Box className="PlanificationCalendar-navigation-buttons">

				{/* if this week */}
				{ !moment().startOf('week').isSame(startingDate, 'week') &&
					<Tooltip title="Aujourd'hui" placement='top'>
						<IconButton
							className="PlanificationCalendar-navigation-main-button"
							sx={{
								backgroundColor: '#d5d5d5 !important',
								marginRight: '22px',
							}}
							onClick={resetWeek}
							color='primary'
						>
							<TodayIcon />
						</IconButton>
					</Tooltip>
				}

				{/* <Box sx={{ flexGrow: 1 }}></Box> */}

				<Tooltip title='Semaine précédente' placement='top'>
					<IconButton
						className="PlanificationCalendar-navigation-main-button"
						onClick={MainLeftClick}
					>
						<NavigateBeforeIcon fontSize='medium' color='secondary' />
					</IconButton>
				</Tooltip>


				<Tooltip title='Semaine suivante' placement='top'>
					<IconButton
						className="PlanificationCalendar-navigation-main-button"
						onClick={MainRightClick}
					>
						<NavigateNextIcon fontSize='medium' color='secondary' />
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
	);
}
