/* Libraries */
import { Link } from 'react-router-dom';

/* Components */
import Logo from '../../assets/Logo/Logo';

/* MUI */
import { Box, Typography } from '@mui/material';

/* CSS */
import './LegalPrivacyPage.css';


const brandNameStyled = {
	fontWeight: 750,
	color: '#111',
	fontSize: '1.2rem',
};

const titleStyled = {
	fontWeight: 650,
	color: '#333',
	fontSize: '1.4rem',
};

const legalTextStyled = {
	color: '#333',
	fontWeight: 400,
	fontSize: '.9rem',

	'& a': {
		color: '#0096FF',
		textDecoration: 'none',
	}
};

export default function LegalPrivacyPage() {
	return (
		<Box className='LegalPrivacyPage-container'>
			<Link to='/' style={{ textDecoration: 'none' }}>
				<Box className='LegalPrivacyPage-brand-container'>
					<Logo />

					<Typography variant='h5' sx={brandNameStyled}>
						FREEKL
					</Typography>
				</Box>
			</Link>

			<Box>
				<Typography variant='h5' sx={titleStyled}>
					Politique de confidentialité
				</Typography>
			</Box>

			<Box className='LegalPrivacyPage-text-container'>
				<Typography variant='h5' sx={legalTextStyled}>
				Chez Freekl, nous nous engageons fermement à protéger la vie privée et la sécurité des données de nos utilisateurs. Notre plateforme en ligne est conçue pour permettre aux créateurs de contenu de télécharger facilement leurs photos et vidéos, que nous publions ensuite en leur nom sur divers réseaux sociaux. Cette politique de confidentialité a pour but de vous informer sur les types de données que nous collectons, la manière dont nous les utilisons, les mesures de protection en place, ainsi que vos droits en tant qu'utilisateur de notre service.

				<br/><br/>
				1. Collecte de données <br/>
				Nous collectons les données nécessaires des réseaux sociaux que vous décidez de connecter à votre compte Freekl. Cela inclut, mais sans s'y limiter, votre nom d'utilisateur, adresse e-mail, photo de profil, publications, liste d'amis ou d'abonnés, et toute autre information essentielle pour publier votre contenu sur les réseaux choisis. Les clients de Freekl utilisent les API de YouTube, Meta (Facebook), TikTok, et d'autres réseaux proposés par nos services. Nous collectons également les données directement depuis la bibliothèque de médias de votre appareil pour télécharger le contenu souhaité.

				<br/><br/>
				2. Utilisation des données <br/>
				Nous utilisons les données collectées pour publier votre contenu sur les réseaux sociaux que vous souhaitez choisir. Nous ne partageons vos données personnelles ni avec des tiers, ni avec des modèles d'intelligence artificielle (IA) de tiers, et nous ne les utilisons pas à d'autres fins que celles strictement nécessaires pour se connecter aux API des réseaux sociaux choisis et afin de publier votre contenu. Nous ne vendons pas vos données personnelles à des tiers. Nous ne conservons pas vos données personnelles plus longtemps que nécessaire pour fournir nos services ou que la loi l'exige.

				<br/><br/>
				3. Sécurité des données <br/>
				Freekl prend des mesures de sécurité rigoureuses pour protéger vos informations contre tout accès non autorisé, perte, mauvaise utilisation, divulgation, modification, ou destruction. Bien que nous appliquions des protocoles de sécurité avancés, il est important de noter qu'aucune méthode de transmission ou de stockage de données n'est infaillible.

				<br/><br/>
				4. Durée de conservation des données <br/>
				Notre politique interne de conservation des données a pour limite un mois, après quoi les données peuvent être supprimées sauf si nécessaire pour respecter nos obligations légales, résoudre des litiges, faire appliquer nos accords ou si vous demandiez leur suppression.

				<br/><br/>
				5. Modifications de la politique de confidentialité <br/>
				Freekl se réserve le droit de modifier cette politique de confidentialité. Nous vous informerons de tout changement significatif par e-mail ou via une notification sur notre site. Votre utilisation continue de notre service après ces modifications signifie que vous acceptez la politique révisée.

				<br/><br/>
				6. Politiques de Confidentialité des Réseaux Sociaux partenaires <br/>
				Nous vous encourageons à consulter les politiques de confidentialité des réseaux sociaux auxquels Freekl se connecte via API :
				<br/>
				- <a href='https://www.facebook.com/about/privacy/' target='_blank' rel='noreferrer'>Politique de confidentialité de Facebook</a>
				<br/>
				- <a href='https://policies.google.com/privacy' target='_blank' rel='noreferrer'>Politique de confidentialité de Google</a>
				<br/>
				- Freekl adhère à la Politique de données utilisateur des services API de Google, y compris les exigences d'utilisation limitée. Notre utilisation et notre transfert d'informations reçues des API Google sont effectués conformément à cette politique. Pour plus d'informations, veuillez consulter la <a href='https://developers.google.com/terms/api-services-user-data-policy' target='_blank' rel='noreferrer'>Politique de données utilisateur des services API de Google</a>.
				<br/>
				- <a href='https://www.tiktok.com/legal/page/eea/privacy-policy/fr' target='_blank' rel='noreferrer'>Politique de confidentialité de TikTok</a>
				

				<br/><br/>
				7. Contactez-nous <br/>
				Pour toute question ou préoccupation relative à cette politique de confidentialité ou à l'utilisation de vos données personnelles, n'hésitez pas à nous contacter à <a href='mailto:jonas@freekl.com' target='_blank' rel='noreferrer'>
				jonas@freekl.com</a>.


				<br/><br/>
				En choisissant Freekl pour publier votre contenu sur les réseaux sociaux, vous acceptez les termes de cette politique de confidentialité.

				<br/><br/>
				Merci d'avoir choisi notre plateforme pour publier votre contenu sur les réseaux sociaux en toute sécurité.
				</Typography>
			</Box>
		</Box>
	);
}
