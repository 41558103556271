/* Libraries */
import { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import moment from 'moment';

/* Components */
import PlanificationModal from '../PlanificationModal/PlanificationModal';
import PlanificationCalendarDay from './PlanificationCalendarDay/PlanificationCalendarDay';
import PlanificationCalendarNavigation from './PlanificationCalendarNavigation/PlanificationCalendarNavigation';
import PlanificationCalendarEvent from './PlanificationCalendarEvent/PlanificationCalendarEvent';
import CreateNewEventModal from './CreateNewEventModal/CreateNewEventModal';

/* Services */
import { ALL_SOCIAL_PLATFORMS } from '../../services/socialNetworks.service';

/* MUI */
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';

/* Icons */

/* DTO */
import { CalendarActionType, PlanificationCalendarProps, PlanificationCalendarState } from './PlanificationCalendarDTO';
import { CalendarEvent, CalendarEventStatus, EventModalType, GeneratedEvent, defaultEventModal } from '../../services/calendar.service.dto';
import { SocialAccount } from '../../services/socialNetworks.service.dto';

/* CSS */
import './PlanificationCalendar.css';


/**
 * 
 * @param state - PlanificationCalendarState
 * @param action type: CalendarActionType, payload: any
 * 
 * @param action.type
 * - UPDATE_CALENDAR: Take the events from the payload and filter them to only keep the ones that are in the week of the calendar
 * - NEXT_WEEK: Add 7 days to the startingDate and endingDate
 * - PREVIOUS_WEEK: Remove 7 days to the startingDate and endingDate
 * - NEXT_MONTH: Add 28 days to the startingDate and endingDate
 * - PREVIOUS_MONTH: Remove 28 days to the startingDate and endingDate
 * - DRAG_START: Set the eventDragged to the payload
 * - DRAG_END: If the eventDragged is not null, update the eventDragged date to the payload and set the eventDragged to null
 */
function reducer(state: PlanificationCalendarState, action: {type: CalendarActionType, payload: any}) {
	switch (action.type) {

		/**
		 * @description Take the events from the parent component and filter them to only keep the ones that are in the week of the calendar
		 * @param action.payload.events: CalendarEvent[] - The events to filter
		 */
		case CalendarActionType.UPDATE_CALENDAR: {
			const events: CalendarEvent[] = action.payload.events;
			const newEvents: CalendarEvent[] = events.filter((event) => {
				return event.date.getTime() >= state.startingDate.getTime() && event.date.getTime() <= state.endingDate.getTime();
			}).sort((a: CalendarEvent, b: CalendarEvent) => {
				return a.date.getTime() - b.date.getTime();
			});

			return {
				...state,
				days: newEvents
			}
		}

		/**
		 * @description Add 7 days to the startingDate and endingDate
		 * @param action.payload - null
		 */
		case CalendarActionType.NEXT_WEEK: {
			const newStartingDate = moment(state.startingDate).add(7, 'days').toDate();
			const newEndingDate = moment(state.endingDate).add(7, 'days').toDate();

			return {
				...state,
				startingDate: newStartingDate,
				endingDate: newEndingDate,
			}
		}

		/**
		 * @description Remove 7 days to the startingDate and endingDate
		 * @param action.payload - null
		 */
		case CalendarActionType.PREVIOUS_WEEK: {
			const newStartingDate = moment(state.startingDate).subtract(7, 'days').toDate();
			const newEndingDate = moment(state.endingDate).subtract(7, 'days').toDate();

			return {
				...state,
				startingDate: newStartingDate,
				endingDate: newEndingDate,
			}
		}

		/**
		 * @description Remove 7 days to the startingDate and endingDate
		 * @param action.payload - null
		 */
		case CalendarActionType.RESET_WEEK: {
			const newStartingDate = moment().startOf('week').toDate();
			const newEndingDate = moment().endOf('week').toDate();

			return {
				...state,
				startingDate: newStartingDate,
				endingDate: newEndingDate,
			}
		}

		/**
		 * @description Save the event dragged
		 * @param action.payload.event: CalendarEvent - The event dragged
		 */
		case CalendarActionType.DRAG_START: {
			return {
				...state,
				eventDragged: action.payload.event
			}
		}

		/**
		 * @description Update the event dragged date
		 * @param action.payload.date: Date - The new date of the event
		 */
		case CalendarActionType.DRAG_END: {
			return {
				...state,
				eventDragged: null
			}
		}

		/**
		 * @description Update the createEventModal state to open or close the modal
		 * @param action.payload.open: boolean
		 */
		case CalendarActionType.UPDATE_CREATE_EVENT_MODAL: {
			const open: boolean = action.payload?.open;
			const date: Date = action.payload?.date;

			return {
				...state,
				createEventModal: {
					...state.createEventModal,
					open: open,
					date: date,
				}
			}
		}

		default:
			throw new Error("Action type not found (" + action.type + ").");
	}
}

export const PlanificationCalendarContext = createContext({});

export default function PlanificationCalendar({events, loading, openNewEventModal}: PlanificationCalendarProps) {

	const initialState: PlanificationCalendarState = {
		days: [],
		startingDate: moment.utc().startOf('week').toDate(),
		endingDate: moment.utc().endOf('week').toDate(),
		eventDragged: null,
		createEventModal: {
			open: false,
			date: moment().set({
				second: 0,
				millisecond: 0
			}).add(15, 'minutes').toDate(),
		}
	}
	const [state, dispatch] = useReducer(reducer, initialState);
	const [eventModal, setEventModal] = useState<EventModalType>(defaultEventModal);


	const handleOpenPlanificationModal = async (selectedEvent: GeneratedEvent) => {
		const calendarEvent = new CalendarEvent(selectedEvent);

		const account = await calendarEvent.account();
		if (!account) {
			return ;
		}

		const socialPlatform = ALL_SOCIAL_PLATFORMS.find((platform) => platform.platformID === account?.platformID);
		if (!socialPlatform) {
			return ;
		}

		const socialAccount: SocialAccount = {
			...socialPlatform,
			account: account
		};

		setEventModal({
			open: true,
			event: calendarEvent,
			account: socialAccount
		});
	};


	/* Update events shown on calendar depending on the selected dates */
	useEffect(() => {
		dispatch({type: CalendarActionType.UPDATE_CALENDAR, payload: {events: events}});
	}, [events, state.startingDate, state.endingDate]);

	/* Memo used to avoid re-loading the context when the state is updated */
	const contextReducer = useMemo(() => {
		return { state, dispatch };
	}, [state, dispatch]);


	return (
		<Box className='PlanificationCalendar-container'>
			<Box className="PlanificationCalendar-calendar-container">
				<Box className="PlanificationCalendar-calendar-header">
					<PlanificationCalendarNavigation
						MainLeftClick={() => dispatch({type: CalendarActionType.PREVIOUS_WEEK, payload: null})}
						MainRightClick={() => dispatch({type: CalendarActionType.NEXT_WEEK, payload: null})}
						resetWeek={() => dispatch({type: CalendarActionType.RESET_WEEK, payload: null})}
						startingDate={state.startingDate}
					/>
				</Box>

				<Box className="PlanificationCalendar-calendar-body">
					{ loading && (
						<Box className='PlanificationCalendar-calendar-loading-box'>
							<CircularProgress disableShrink className='PlanificationCalendar-calendar-loading' size={26} />
						</Box>
					)}

					<PlanificationCalendarContext.Provider value={contextReducer}>
						{ Array(7).fill(0).map((_, index) => {
							const dayEvents = state.days.filter((event: CalendarEvent) => {
								const eventDate = moment(event.date);
								const startingDate = moment(state.startingDate).add(index, 'days');
								return eventDate.isSame(startingDate, 'day');
							});
							const date = moment(state.startingDate).add(index, 'days').hours(12).toDate();

							return (
								<PlanificationCalendarDay
									incompleteEvent={dayEvents.filter((event: CalendarEvent) => event.description.length === 0).length > 0}
									errorEvent={dayEvents.filter((event: CalendarEvent) => event.status === CalendarEventStatus.ERROR_PUBLISHING).length > 0}
									key={index}
									date={date}
									isEmpty={dayEvents.length === 0}
									OnEmptyDayClick={() => dispatch({type: CalendarActionType.UPDATE_CREATE_EVENT_MODAL, payload: {
										open: true,
										
										// set the date to the next 15 minutes of this day
										date: moment(date).set({
											hour: moment().hour(),
											second: 0,
											millisecond: 0
										}).add(15, 'minutes').toDate(),
									}})}
								>
									{ dayEvents.map((event: CalendarEvent, index: number) => {
										return (
											<PlanificationCalendarEvent
												incompleteEvent={event.description.length === 0}
												key={index}
												event={event}
												onEventSelection={handleOpenPlanificationModal}
											/>
										)
									})}
								</PlanificationCalendarDay>
							)
						})}
					</PlanificationCalendarContext.Provider>
				</Box>
			</Box>

			<PlanificationModal
				open={eventModal.open}
				event={eventModal.event}
				onClose={() => setEventModal(defaultEventModal)}
				setEvent={handleOpenPlanificationModal}
				account={eventModal.account}
			/>

			<CreateNewEventModal
				open={state.createEventModal.open}
				handleClose={() => dispatch({type: CalendarActionType.UPDATE_CREATE_EVENT_MODAL, payload: {open: false}})}
				openNewEventModal={(event: SocialAccount | null) => openNewEventModal(event, state.createEventModal.date)}
			/>
		</Box>
	);
};
