import {collection, doc, getDoc, getDocs, Timestamp} from "firebase/firestore";
import {db} from "../config/firebase.config";

/* DTOs */
import {BlogPostDTO} from "./blog.service.dto";


/**
 * @description
 * Get a blog post by id
 */
export const getBlogPost = async (id: string): Promise<BlogPostDTO | null> => {
	const docRef = doc(db, "blog", id);
	const docSnap = await getDoc(docRef);
	
	if (docSnap.exists()) {
		const data = docSnap.data();

		// convert the Timestamp to a Date object
		const res: any = {
			...data,
			created_in: new Timestamp(data.created_in.seconds, data.created_in.nanoseconds).toDate()
		}
		return res;
	} else {
		return null;
	}
}


/**
 * @description
 * Get all blog posts
 */
export const getBlogPosts = async (): Promise<BlogPostDTO[] | null> => {
	const querySnapshot = await getDocs(collection(db, "blog"));
	const res: any[] = querySnapshot.docs.map(doc => {
		const data = doc.data();

		return {
			...data,
			created_in: new Timestamp(data.created_in.seconds, data.created_in.nanoseconds).toDate()
		}
	});

	return res;
}


/**
 * @description
 * Get all blog posts sorted by date (newest first)
 * 
 * @param limit if set, the posts will be limited to the given number
 */
export const getSortedBlogPosts = async (limit?: number): Promise<BlogPostDTO[] | null> => {
	const res = await getBlogPosts();

	if (res) {
		// sort the posts by date (newest first)
		res.sort((a, b) => {
			return b.created_in.getTime() - a.created_in.getTime();
		});

		// limit the posts if needed
		if (limit && limit > 0) {
			return res.slice(0, limit);
		} else {
			return res;
		}
	}
	return null;
}
