/* Libraries */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

/* Components */
import PageHeader from '../../assets/PageHeader/PageHeader';
import FacebookLoginStep from '../../components/FacebookLoginStep/FacebookLoginStep';
import TikTokLoginStep from '../../components/TikTokLoginStep/TikTokLoginStep';
import YouTubeLoginStep from '../../components/YouTubeLoginStep/YouTubeLoginStep';

/* Services */
import { ALL_SOCIAL_PLATFORMS, useAvailableSocialNetworks } from '../../services/socialNetworks.service';
import { SocialNetworkName } from '../../services/socialNetworks.service.dto';
import { deepCopy } from '../../services/_utils';

/* MUI */
import { Box } from '@mui/system';
import { Badge, Button, CircularProgress, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/* Icons */
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TikTokLogo from '../../assets/TikTokLogo/TikTokLogo';
import SettingsIcon from '@mui/icons-material/Settings';

/* CSS */
import './AccountsLoginPage.css';


const disclaimerStyled = {
	fontWeight: 400,
	fontSize: {
		xs: '.75rem',
		md: '.85rem'
	},
	marginBottom: '16px',
	color: 'white'
};

const connectWithStyled = {
	fontWeight: 750,
};

export default function AccountsLoginPage() {
	const [accounts, accountsLoading] = useAvailableSocialNetworks();
	const [platforms, setPlatforms] = useState([
		{
			platformID: SocialNetworkName.INSTAGRAM,
			name: 'Instagram',
			className: '',
			theme: 'dark',
			icon: <InstagramIcon className='Accordion-title-icon Accordion-title-icon-dark' />,
			iconSrc: ALL_SOCIAL_PLATFORMS.find((platform) => platform.platformID === SocialNetworkName.INSTAGRAM)?.icon3D ?? '',
			component: <FacebookLoginStep />,
			isConnected: false
		},
		{
			platformID: SocialNetworkName.YOUTUBE,
			name: 'YouTube',
			className: '',
			theme: 'dark',
			icon: <YouTubeIcon className='Accordion-title-icon Accordion-title-icon-dark' />,
			iconSrc: ALL_SOCIAL_PLATFORMS.find((platform) => platform.platformID === SocialNetworkName.YOUTUBE)?.icon3D ?? '',
			component: <YouTubeLoginStep />,
			isConnected: false
		},
		{
			platformID: SocialNetworkName.TIKTOK,
			name: 'TikTok',
			className: 'Accordion-tiktok',
			theme: 'light',
			icon: <TikTokLogo className='Accordion-title-icon Accordion-title-icon-light'
					style={{ marginRight: '13px', marginLeft: '4px'}}
					width={14}
					dark
				/>,
			iconSrc: ALL_SOCIAL_PLATFORMS.find((platform) => platform.platformID === SocialNetworkName.TIKTOK)?.icon3D ?? '',
			component: <TikTokLoginStep />,
			isConnected: false
		}
	]);


	useEffect(() => {
		checkPlatformsConnection();
		// eslint-disable-next-line
	}, [accounts, accountsLoading]);


	const checkPlatformsConnection = async () => {
		const newPlatforms = deepCopy(platforms);

		for (const platform of newPlatforms) {
			const platformAccounts = accounts.filter((account) => account.platformID === platform.platformID);

			for (const account of platformAccounts) {
				const isAvailable = await account.isAvailable();

				if (isAvailable) {
					platform.isConnected = true;
					break;
				}

				platform.isConnected = false;
			}
		}

		setPlatforms(newPlatforms);
	};


	return (
		<Box className='AccountsLoginPage-container'>
			<PageHeader
				title='Mes comptes'
				subtitle='Connectez tous vos comptes de réseaux sociaux'
				fullWidth
			/>

			<Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
				<Box sx={{ flexGrow: 1 }} />

				<Button to='/dashboard/settings'
					component={Link}
					variant='outlined'
					startIcon={<SettingsIcon />}
				>
					Paramètres de posting
				</Button>
			</Box>

			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '5px',
			}}>
				{ platforms.map((platform, index) => (
					<Badge key={`Badge-${platform.name}-${index}`}
						color='error'
						badgeContent=' '
						invisible={platform.isConnected}
						overlap='rectangular'
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						sx={{
							'& .MuiBadge-badge': {
								top: '10px',
							}
						}}
					>
						<Accordion key={`Accordion-${platform.name}-${index}`}
							className={platform.className}
							disabled={accountsLoading}
							sx={{ width: '100%' }}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon className={`Accordion-title-icon-${platform.theme}`} />}>
								<Box className='Accordion-title-container'>
									<img className='Accordion-title-icon'
										src={platform.iconSrc}
										alt={`${platform.name} icon`}
										width={26}
									/>
									{/* {platform.icon} */}

									<Typography sx={{ color: platform.theme === 'dark' ? '#000' : '#fff' }}>
										Se connecter avec <span style={connectWithStyled}>{platform.name}</span>
									</Typography>
								</Box>

								<Box sx={{ flexGrow: 1 }} />

								{accountsLoading && <CircularProgress disableShrink size={15} color='info' />}
							</AccordionSummary>

							<AccordionDetails>
								{platform.component}
							</AccordionDetails>
						</Accordion>
					</Badge>
				))}
			</Box>

			<Box className='AccountsLoginPage-disclaimer-container'>
				<Typography variant='h5' sx={disclaimerStyled}>
					Afin de pouvoir utiliser notre plateforme et, vous devez nous donner accès aux comptes de votre choix.
					<br />
					<br />
					<span style={{ fontWeight: 700 }}>Cela vous permettra, entre autres, de :</span>
					<br />
					- Gérer vos publications <br />
					- Gérer vos campagnes publicitaires <br />
					- Gérer vos abonnés <br />
					- Gérer vos statistiques
				</Typography>

				<Typography variant='h5' sx={disclaimerStyled}>
					Cette connexion est sécurisée et ne nous permettra que de récupérer les informations nécessaires à la gestion de vos campagnes publicitaires.
				</Typography>

				<Typography variant='h5' sx={disclaimerStyled}>
					Cette étape est cruciale pour la bonne utilisation de notre plateforme.
					<br />
					<br />
					<span style={{ fontWeight: 800 }}>Si vous ne souhaitez pas nous donner accès à vos comptes, vous ne pourrez pas utiliser nos services correctement.</span>
				</Typography>
			</Box>
		</Box>
	);
}
