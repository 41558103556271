/* Libraries */

/* Components */

/* DTO */
import { PageHeaderProps } from './PageHeaderDTO';

/* MUI */
import { Box } from '@mui/system';
import { Divider, Fade, Grow, Typography } from '@mui/material';

/* Icons */

/* CSS */
import './PageHeader.css';


const titleStyled = {
	color: '#000',
	fontWeight: 800,
	fontSize: {
		xs: '24px',
		sm: '28px',
		md: '32px',
		xl: '36px',
	},
};

const subtitleStyled = {
	padding: '8px 18px',
	marginTop: '6px',
	borderRadius: '12px',
	backgroundColor: '#f8f8f8',
	// background: 'linear-gradient(0deg, rgb(248,248,248), #fff)',
	// boxShadow: '0 15px 20px -12px rgba(0,0,0, .1)',
	boxShadow: '0 1px 2px rgba(0,0,0, .1)',
	width: 'fit-content',
	color: '#444',
	fontWeight: 500,
	fontSize: {
		xs: '12px',
		sm: '13px',
		md: '15px',
	},
};

export default function PageHeader(props: PageHeaderProps) {

	const {
		title,
		subtitle = '',
		divider = false,
		fullWidth = false,
		sx,
	} = props;

	return (
		<Box
			className={`PageHeader-container ${fullWidth ? 'PageHeader-container-full-width' : ''}`}
			sx={sx}
		>
			<Fade in={true} timeout={500}>
				<Typography variant="h2" sx={titleStyled}>
					{title}
				</Typography>
			</Fade>

			{ subtitle && (
				<Fade in={true} timeout={800} style={{ transitionDelay: '400ms' }}>
					<Typography variant='h4' sx={subtitleStyled}>
						{subtitle}
					</Typography>
				</Fade>
			)}

			{ divider && (
				<Grow in={divider} timeout={1000}>
					<Divider sx={{
						marginTop: '8px',
						border: '2px solid #ddd',
					}}/>
				</Grow>
			)}
		</Box>
	);
}
