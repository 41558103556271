/* Librairies */
import { useContext, useEffect, useState } from 'react';

/* Components */
import { PlanificationCalendarContext } from '../PlanificationCalendar';
import ContentWrapper from '../../ContentWrapper/ContentWrapper';

/* Services */
import { ALL_SOCIAL_PLATFORMS } from '../../../services/socialNetworks.service';
import { getFilesById } from '../../../services/clientStorage.service';
import { deleteCalendarPost } from '../../../services/calendar.service';

/* MUI */
import { Avatar, Box, CircularProgress, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, Tooltip, Typography } from '@mui/material';

/* DTO */
import { CalendarActionType } from '../PlanificationCalendarDTO';
import { PlanificationCalendarEventProps } from './PlanificationCalendarEventDTO';
import { FileDTO } from '../../../services/clientStorage.service.dto';
import { SocialNetworkAccount, SocialPlatform } from '../../../services/socialNetworks.service.dto';
import { CalendarEventStatus } from '../../../services/calendar.service.dto';

/* Icons */
import { AiOutlinePlus } from 'react-icons/ai';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ScheduleIcon from '@mui/icons-material/Schedule';
import BlurOffIcon from '@mui/icons-material/BlurOff';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import DeleteIcon from '@mui/icons-material/Delete';

/* Styles */
import './PlanificationCalendarEvent.css';


export default function PlanificationCalendarEvent({
	event,
	incompleteEvent,
	onEventSelection,
} : PlanificationCalendarEventProps) {

	const {dispatch}: any = useContext(PlanificationCalendarContext);

	const [platforms, setPlatforms] = useState<SocialPlatform[] | null>(null);
	const [files, setFiles] = useState<FileDTO[]>([]);
	const [account, setAccount] = useState<SocialNetworkAccount | null>(null);
	const [rightClickContextMenu, setRightClickContextMenu] = useState<null | HTMLElement>(null);
	const rightClickContextMenuOpen = Boolean(rightClickContextMenu);


	useEffect(() => {
		loadFiles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [event]);


	const loadFiles = async () => {

		// if the fileIds are links to external files, we don't need to load them
		if (event.filesIds.length > 0 && event.filesIds[0].startsWith('http')) {
			const files = event.filesIds.map((url) => {

				// get the file type from the url
				let type = 'image/jpeg';

				if (url.includes('.mp4')) {
					type = 'video/mp4';
				} else if (url.includes('.webm')) {
					type = 'video/webm';
				} else if (url.includes('.mov')) {
					type = 'video/mov';
				} else if (url.includes('.gif')) {
					type = 'image/gif';
				} else if (url.includes('.png')) {
					type = 'image/png';
				} else if (url.includes('.webp')) {
					type = 'image/webp';
				} else if (url.includes('.jpg')) {
					type = 'image/jpeg';
				} else if (url.includes('.jpeg')) {
					type = 'image/jpeg';
				} else if (url.includes('.svg')) {
					type = 'image/svg+xml';
				}

				return {
					id: '',
					url: url,
					name: '',
					fullPath: '',
					basicMetadata: { width: 0, height: 0, duration: 0 },
					metadata: {
						contentType: type
					} as any,
				};
			});
			setFiles(files);
		} else {
			const files = await getFilesById(event.filesIds || []);
			if (files) {
				setFiles(files);
			}
		}

		const acc = await event.account();
		const plt = ALL_SOCIAL_PLATFORMS.find((platform) => platform.platformID === acc?.platformID);
		setAccount(acc || null);
		setPlatforms(plt ? [plt] : null);
	};

	const getEventStatusIcon = (status: CalendarEventStatus) => {
		switch (status) {
			case CalendarEventStatus.PROCESSING:
				return <Skeleton variant="circular"
							width={12}
							height={12}
							sx={{ backgroundColor: '#888' }}
						/>;

			case CalendarEventStatus.SCHEDULED:
				return <ScheduleIcon fontSize='small' />;

			case CalendarEventStatus.PUBLISHING:
				return <Box>
						<CircularProgress disableShrink color='primary' size={10} />
					</Box>;

			case CalendarEventStatus.PUBLISHED:
				return <CheckCircleIcon fontSize='small' />;

			case CalendarEventStatus.ERROR_PUBLISHING:
				return <Skeleton variant="circular"
							animation='wave'
							width={12}
							height={12}
							sx={{ backgroundColor: '#f33' }}
						/>;

			default:
				return <BlurOffIcon fontSize='small' />
		}
	};

	const getEventStatusBackground = (status: CalendarEventStatus): string => {
		switch (status) {
			case CalendarEventStatus.PROCESSING:
				return '#ccca';

			case CalendarEventStatus.SCHEDULED:
				return '#a0a0ff';

			case CalendarEventStatus.PUBLISHING:
				return '#ccca';

			case CalendarEventStatus.PUBLISHED:
				return '#4caf50';

			case CalendarEventStatus.ERROR_PUBLISHING:
				return '#fcca'

			default:
				return '#ccca'
		}
	};

	const getEventStatusText = (status: CalendarEventStatus): string => {
		switch (status) {
			case CalendarEventStatus.PROCESSING:
				return 'En cours de traitement';

			case CalendarEventStatus.SCHEDULED:
				return 'Planifié';

			case CalendarEventStatus.PUBLISHING:
				return 'En cours de publication';

			case CalendarEventStatus.PUBLISHED:
				return 'Publié';

			case CalendarEventStatus.ERROR_PUBLISHING:
				return 'Erreur lors de la publication';

			default:
				return 'Erreur inconnue'
		}
	};

	const handleEventClick = (e: any) => {
		const isDeletable = event.status !== CalendarEventStatus.PUBLISHED && event.status !== CalendarEventStatus.PUBLISHING;

		if (e.type === 'contextmenu' && isDeletable) {
			e.preventDefault();
			if (rightClickContextMenu) {
				setRightClickContextMenu(null);
			} else {
				setRightClickContextMenu(e.currentTarget);
			}
		} else if (e.type === 'click' && !rightClickContextMenu) {
			onEventSelection(event);
		}
	};

	const handleCloseContextMenu = () => {
		setRightClickContextMenu(null);
	};

	const handleDeleteEvent = async () => {
		await deleteCalendarPost(event.id);
	};


	return (
		<Box className={`PlanificationCalendar-event-container ${incompleteEvent ? 'PlanificationCalendar-event-incomplete' : ''}`}
			draggable={
				event.status !== CalendarEventStatus.PUBLISHED &&
				event.status !== CalendarEventStatus.PUBLISHING
			}
			onClick={handleEventClick}
			onContextMenu={handleEventClick}
			sx={{ background: platforms?.[0]?.background }}
			onDragStart={() => {
				if (event.status === CalendarEventStatus.PUBLISHED ||
					event.status === CalendarEventStatus.PUBLISHING) {
					return;
				}
				dispatch({type: CalendarActionType.DRAG_START, payload: {event: event}})
			}}
		>
			<Menu
				anchorEl={rightClickContextMenu}
				open={rightClickContextMenuOpen}
				onClose={handleCloseContextMenu}
				sx={{
					'& .MuiPaper-root': {
						backgroundColor: '#444',
						borderRadius: '8px',
						boxShadow: '0 3px 5px rgba(0,0,0, .2)',
						'& .MuiList-root': {
							padding: 0,
						},
					},
				}}
			>
				<MenuItem onClick={handleDeleteEvent}>
					<ListItemIcon>
						<DeleteIcon fontSize="small" sx={{ color: '#faa' }} />
					</ListItemIcon>

					<ListItemText sx={{ '& .MuiListItemText-primary': { color: '#eee' } }}>
						Supprimer
					</ListItemText>
				</MenuItem>
			</Menu>

			<Box className="PlanificationCalendar-event-header">
				<Box className="PlanificationCalendar-event-header-icons">
					{ platforms && platforms.length > 0 && platforms.slice(0, 3).map((plt: SocialPlatform, index: number) => {
						return (
							<Tooltip title={account?.username} placement='right' arrow key={index}>
								<Avatar key={index.toString()}
									className="PlanificationCalendar-event-header-icon"
									src={account?.pictureURL}
								/>
							</Tooltip>
						);
					})}

					{ platforms && platforms.length > 3 && (
						<Box className="PlanificationCalendar-event-header-icon">
							<AiOutlinePlus />
						</Box>
					)}
				</Box>

				<Box className="PlanificationCalendar-event-header-infos">
					<Typography className="PlanificationCalendar-event-header-date">
						{event.date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: 'numeric' })}
					</Typography>

					<Typography className="PlanificationCalendar-event-header-username">
						{account?.username}
					</Typography>
				</Box>
			</Box>

			{ files.length > 0 &&
				<Box className="PlanificationCalendar-event-visual">
					<ContentWrapper files={files} />

					{ files.length > 1 && (
						<Box className="PlanificationCalendar-event-visual-multi">
							<WebStoriesIcon fontSize='small' />
						</Box>
					)}
				</Box>
			}

			<Box className="PlanificationCalendar-event-footer">
				<Typography className="PlanificationCalendar-event-footer-description"
					variant='body2'
					color='primary'
				>
					{event?.description}
				</Typography>

				<Tooltip title={getEventStatusText(event.status)} placement='right'>
					<Box className="PlanificationCalendar-event-footer-status"
						sx={{ backgroundColor: getEventStatusBackground(event.status) }}
					>
						{getEventStatusIcon(event.status)}
					</Box>
				</Tooltip>
			</Box>
		</Box>
	);
}
