/* Libraries */
import { useEffect, useState } from 'react';
import { setDoc, updateDoc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { toast } from 'react-toastify';
import moment, { Moment } from 'moment-timezone';

/* Services */
import { checkDocumentExistence, distributeRatios, getUserDocReference, getUserDocSnapshot } from './_utils';
import { deleteCalendarPost, getCalendarPostsByAccountID } from './calendar.service';
import { getFacebookInsightReach, getFacebookOnlineFollowers } from './facebook.service';
import { getYoutubeInsightReach } from './youtube.service';

/* Types */
import { SocialAccount, SocialNetworkAccount, SocialNetworkAccountData, SocialNetworkName, SocialPlatform } from './socialNetworks.service.dto';
import { CalendarEvent } from './calendar.service.dto';
import { FileDTO } from './clientStorage.service.dto';
import { getAllTiktokVideos } from './tiktok.service';

/* Icons */
import { FaTiktok } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { AiFillYoutube } from 'react-icons/ai';


/**
 * @description
 * return the social_accounts document reference if it exists
 */
export const getSocialAccountsDocReference = () => {
	return getUserDocReference("social_accounts");
};


/**
 * @description
 * return the social_accounts document
 */
export const getSocialAccountsDocSnapshot = async () => {
	return await getUserDocSnapshot(getSocialAccountsDocReference);
};


/**
 * @description
 * this function is used to add or update a social account
 * - if the account doesn't exist, it will be added
 * - if the account already exists, it will be updated
 * - if an account with the same accountID already exists, it will not be added
 * 
 * @param {SocialNetworkAccount} newAccount the account to add or update
 * 
 * @returns {Promise<void>}
 */
export const updateSocialAccount = async (newAccount: SocialNetworkAccount): Promise<void> => {
	const docRef = getSocialAccountsDocReference();
	if (!docRef) {
		return;
	}

	const docSnap = await getUserDocSnapshot(docRef);
	if (!docSnap || !docSnap.exists()) {
		await setDoc(docRef, {
			accounts: [{...newAccount}]
		}, { merge: true });
		return;
	}

	const docData = docSnap.data();
	if (!docData) {
		return;
	}

	const accounts = await getSocialAccounts();
	if (!accounts) { // If there is no account yet, we create the document
		await setDoc(docRef, {
			accounts: [{...newAccount}]
		}, { merge: true });


	// If there is already accounts, we update the document
	} else {
		const account = accounts.find((account: SocialNetworkAccount) => account.id === newAccount.id);

		// If the account already exists, we update it
		if (account) {
			account.merge(newAccount);

			await updateDoc(docRef, {
				accounts: [
					...accounts.filter((account: SocialNetworkAccount) => account.id !== newAccount.id).map((account: SocialNetworkAccount) => account.accountData()),
					account.accountData()
				]
			});

		// If the account doesn't exist, we add it to the list
		} else {

			// check if there's an account with the same accountID in the list
			const checkAccountID = accounts.find((account: SocialNetworkAccount) => account.accountID === newAccount.accountID);

			// if it exists, we don't add it
			if (checkAccountID) {
				toast.error("Un compte avec le même identifiant existe déjà");
				return;
			}

			await updateDoc(docRef, {
				accounts: [
					...accounts.map((account: SocialNetworkAccount) => account.accountData()),
					newAccount.accountData()
				]
			});
		}
	}
};


/**
 * @description
 * this function is used to delete a social account
 * 
 * @param {string} id the id of the account to delete
 * 
 * @note SocialNetworkAccount.id not SocialNetworkAccount.accountID
 * 
 * @returns {Promise<void>}
 */
export const deleteSocialAccount = async (id: string): Promise<void> => {
	if (!id) {
		return;
	}

	const docRef = getSocialAccountsDocReference();
	if (!docRef) {
		return;
	}

	const docSnap = await getUserDocSnapshot(docRef);
	if (!docSnap || !docSnap.exists()) {
		return;
	}

	const docData = docSnap.data();
	if (!docData) {
		return;
	}

	const accounts = docData?.accounts ? docData.accounts as SocialNetworkAccountData[] : null;
	if (!accounts) {
		return;
	}

	const toDelete = accounts.find((account: SocialNetworkAccountData) => account.id === id);
	if (!toDelete) {
		return;
	}

	/**
	 * delete the account with the given id & accounts with the same
	 * relatedAccountID as the account's id to delete
	 */
	const filteredAccounts = accounts.filter((account: SocialNetworkAccountData) => {
		return account.id !== toDelete.id && account.relatedAccountID !== toDelete.id;
	});

	// get accounts to delete
	const accountsToDelete = accounts.filter((account: SocialNetworkAccountData) => {
		return account.id === toDelete.id || account.relatedAccountID === toDelete.id;
	});


	/**
	 * delete the accounts's calendar posts
	 * 
	 * @note we don't delete the posts if the account doesn't have an accountID
	 * because it means that the account is not linked to a social network
	 */
	for (const account of accountsToDelete) {
		if (!account.accountID) {
			return;
		}

		const posts = await getCalendarPostsByAccountID(account.accountID);
		if (!posts) {
			return;
		}

		const postIDs = posts.map((post: CalendarEvent) => post.id);
		await deleteCalendarPost(postIDs);
	}

	// update the document with the filtered accounts
	await updateDoc(docRef, {
		accounts: [...filteredAccounts]
	});
};


/**
 * @description
 * get the social account with the given id
 * 
 * @param {string} id the id of the account to get
 * 
 * @returns {Promise<SocialNetworkAccount | null>}
 */
export const getSocialAccountById = async (id: string): Promise<SocialNetworkAccount | null> => {
	if (!id) {
		return null;
	}

	const accounts = await getSocialAccounts();
	if (!accounts) {
		return null;
	}

	const account = accounts.find((account: SocialNetworkAccount) => account.id === id);
	if (!account) {
		return null;
	} else {
		return account;
	}
};


/**
 * @description
 * get the social account with the given accountID
 * 
 * @param {string} accountID the accountID of the account to get
 * 
 * @returns {Promise<SocialNetworkAccount | null>}
 */
export const getSocialAccountByAccountId = async (accountID: string): Promise<SocialNetworkAccount | null> => {
	if (!accountID) {
		return null;
	}

	const accounts = await getSocialAccounts();
	if (!accounts) {
		return null;
	}

	const account = accounts.find((account: SocialNetworkAccount) => account.accountID === accountID);
	if (!account) {
		return null;
	} else {
		return account;
	}
};


/**
 * @description
 * get the token of the social account with the given id
 * 
 * @param {string} id the id of the account to get
 * 
 * @returns {Promise<string | undefined>}
 */
export const getSocialAccountTokenById = async (id: string): Promise<string | undefined> => {
	if (!id) {
		return undefined;
	}

	const accounts = await getSocialAccounts();
	if (!accounts) {
		return undefined;
	}

	const account = accounts.find((account: SocialNetworkAccount) => account.id === id);
	if (!account) {
		return undefined;
	} else {
		return await account.token();
	}
};


/**
 * @description
 * get the token of the social account with the given accountID
 * 
 * @param {string} accountID the accountID of the account to get
 * 
 * @returns {Promise<string | undefined>}
 */
export const getSocialAccountTokenByAccountId = async (accountID: string): Promise<string | undefined> => {
	if (!accountID) {
		return undefined;
	}

	const accounts = await getSocialAccounts();
	if (!accounts) {
		return undefined;
	}

	const account = accounts.find((account: SocialNetworkAccount) => account.accountID === accountID);
	if (!account) {
		return undefined;
	} else {
		return await account.token();
	}
};


/**
 * @description
 * get all the social accounts
 * 
 * @returns {Promise<SocialNetworkAccount[] | null>}
 */
export const getSocialAccounts = async (): Promise<SocialNetworkAccount[] | null> => {
	const docSnap = await getSocialAccountsDocSnapshot();

	if (!docSnap || !docSnap.exists()) {
		return null;
	}

	const docData = docSnap.data();
	if (!docData) {
		return null;
	}

	const accounts = docData?.accounts ? docData.accounts as SocialNetworkAccountData[] : null;
	if (!accounts) {
		return null;
	}

	return accounts.map((account: SocialNetworkAccountData) => {
		return new SocialNetworkAccount(account);
	}) as SocialNetworkAccount[];
};


/**
 * @description
 * get all the social accounts of the given platform
 * 
 * @param {SocialNetworkName} platformID the id of the platform
 * 
 * @returns {Promise<SocialNetworkAccount[] | null>}
 */
export const getSocialAccountsByPlatform = async (platformID: SocialNetworkName): Promise<SocialNetworkAccount[] | null> => {
	const accounts = await getSocialAccounts();
	if (!accounts) {
		return null;
	}

	return accounts.filter((account: SocialNetworkAccount) => account.platformID === platformID);
};


/**
 * @description
 * check availability of every social network platforms.
 * we check the ability to post on a social network platform by checking if the
 * user is connected to it.
 * 
 * @returns
 * - availableSocialNetworks: list of available social network platforms
 * - loading: true if the function is loading
 * - error: true if an error occurred
 */
export const useAvailableSocialNetworks = (): [SocialAccount[], boolean, boolean] => {
	const [socialPlatformsData, socialPlatformsLoading, socialPlatformsError] = useDocumentData(getSocialAccountsDocReference());

	const [availableSocialNetworks, setAvailableSocialNetworks] = useState<SocialAccount[]>([]);
	const [loading, setLoading] = useState<boolean>(socialPlatformsLoading);
	const [error, setError] = useState<boolean>(false);
	const [firstLoad, setFirstLoad] = useState<boolean>(true);


	useEffect(() => {
		checkDocumentExistence(getSocialAccountsDocReference);
	}, []);

	useEffect(() => {
		refreshAvailableSocialNetworks();
		// eslint-disable-next-line
	}, [socialPlatformsData]);

	useEffect(() => {
		setError(!!socialPlatformsError);
	}, [socialPlatformsError]);


	const refreshAvailableSocialNetworks = async () => {
		if (loading && !firstLoad) {
			return ;
		}

		const beforeUnloadListener = (event: BeforeUnloadEvent) => {
			event.preventDefault();
			event.returnValue = '';
		};
		window.addEventListener('beforeunload', beforeUnloadListener);

		setError(false);
		setLoading(true);

		const accounts = _getAccounts();
		if (!accounts) {
			window.removeEventListener('beforeunload', beforeUnloadListener);
			return;
		}

		setFirstLoad(false);

		const availableSocialNetworkList: SocialAccount[] = [];

		/**
		 * @description
		 * check every social network platform availability and add it to the
		 * list of available social network platforms.
		 * We also add the platform basic information to the account object.
		 * This is useful to display the platform icon and its name.
		 */
		for (const platform of ALL_SOCIAL_PLATFORMS) {
			if (platform.implemented && await platform.isAvailable()) {
				const platformAccounts = accounts.filter((account: SocialNetworkAccount) => account.platformID === platform.platformID);

				const newPlatformAccounts: SocialAccount[] = platformAccounts.map((account): SocialAccount => {
					return {
						...platform,
						account: account,
					};
				});

				// TODO: to verify if it is necessary
				// refresh the token of the accounts & infos if expired
				await Promise.all(newPlatformAccounts.map(async (account: SocialAccount) => {
					await account.account.token();
				}));

				availableSocialNetworkList.push(...newPlatformAccounts);
			}
		}

		setAvailableSocialNetworks(availableSocialNetworkList);
		setLoading(false);

		window.removeEventListener('beforeunload', beforeUnloadListener);
	};

	const _getAccounts = (): SocialNetworkAccount[] | null => {
		if (!socialPlatformsData?.accounts && !socialPlatformsLoading && !socialPlatformsError) {
			setLoading(false);
			return null;
		}

		if (socialPlatformsLoading || socialPlatformsError || !socialPlatformsData?.accounts) {
			return null;
		}

		return socialPlatformsData.accounts.map((account: SocialNetworkAccountData) => {
			return new SocialNetworkAccount(account);
		});
	};

	return [availableSocialNetworks, loading, error];
};



/**
 * AVAILABILITY CHECKS
 */

const _checkInstagramAvailability = async (): Promise<boolean> => {
	const accounts = await getSocialAccounts();
	if (!accounts) {
		return false;
	}

	const facebookAccounts = accounts.filter((account: SocialNetworkAccount) => account.platformID === SocialNetworkName.FACEBOOK);
	if (facebookAccounts.length === 0) {
		return false;
	}

	const instagramAccounts = accounts.filter((account: SocialNetworkAccount) => account.platformID === SocialNetworkName.INSTAGRAM);
	for (const account of facebookAccounts) {
		const instagramAccount = instagramAccounts.find((instagramAccount: SocialNetworkAccount) => instagramAccount.relatedAccountID === account.id);

		if (instagramAccount) {
			return true;
		}
	}

	return false;
};

const _checkYoutubeAvailability = async (): Promise<boolean> => {
	const accounts = await getSocialAccounts();

	if (!accounts) {
		return false;
	}

	const youtubeAccounts = accounts.filter((account: SocialNetworkAccount) => account.platformID === SocialNetworkName.YOUTUBE);
	return youtubeAccounts.length > 0;
};

const _checkTiktokAvailability = async (): Promise<boolean> => {
	const accounts = await getSocialAccounts();

	if (!accounts) {
		return false;
	}

	const tiktokAccounts = accounts.filter((account: SocialNetworkAccount) => account.platformID === SocialNetworkName.TIKTOK);
	return tiktokAccounts.length > 0;
};


/**
 * REPARTITION BY DAY
 */

const _getInstagramPostsRepartitionByDay = async (account: SocialNetworkAccount, files: readonly FileDTO[]): Promise<number[]> => {
	const generatedDays: number[] = Array<number>(7).fill(0);
	const accessToken = await account.token();

	if (!accessToken) {
		return generatedDays;
	}

	const reach = await getFacebookInsightReach(account.accountID, accessToken, 'week');
	if (reach.error || !reach.data || reach.data.length === 0) {
		toast.error(`Error while getting reach for ${account.username} (${account.platformID})`)
		return generatedDays;
	}

	const onlineFollowers = await getFacebookOnlineFollowers(account.accountID, accessToken);
	if (onlineFollowers.error || !onlineFollowers.data) {
		toast.error(`Error while getting online followers for ${account.username} (${account.platformID})`)
		return generatedDays;
	}
	if (onlineFollowers.data.length === 0) {
		onlineFollowers.data.push({
			id: '',
			title: 'online_followers',
			period: 'day',
			description: '',
			name: 'online_followers',
			values: Array(7).fill({
				end_time: new Date().toISOString(),
				value: {
					0: 0,
					1: 0,
					2: 0,
					3: 0,
					4: 0,
					5: 0,
					6: 0,
				},
			}),
		});
	}

	for (let i = 0; i < 7; i++) {
		const onlineFollowersByDay: number[] = Object.values(onlineFollowers.data[0].values[i].value);
		const onlineFollowersSum: number = onlineFollowersByDay.reduce((a: number, b: number) => a + b, 0);
		const onlineFollowersAvg: number = onlineFollowersSum / onlineFollowersByDay.length || 0; // in case of NaN

		// get current day index to start from
		const dayIndex = moment(onlineFollowers.data[0].values[i].end_time).day();

		// /3 because we normally would have 3 metrics
		generatedDays[dayIndex] = (reach.data[0].values[i].value + onlineFollowersAvg) / 3;
	}

	// normalize the values
	const generatedDaysSum = generatedDays.reduce((a: number, b: number) => a + b, 0);
	const generatedDaysNormalized = generatedDays.map((value) => value / generatedDaysSum);

	// compute the number of publications for each day
	const postRepartitionByDay = distributeRatios(files.length, generatedDaysNormalized);


	// TODO: to check with FACEBOOK API
	// *: THE HOUR IN THE RESULT IS IN +00:00 TIMEZONE AND STARTS AT 7AM
	// *: SO YOU HAVE TO CONVERT THE RESULTS TO -7h

	return postRepartitionByDay;
};

const _getYoutubePostsRepartitionByDay = async (account: SocialNetworkAccount, files: readonly FileDTO[]): Promise<number[]> => {
	const defaultRatios = [
		0.3,
		0.1,
		0.1,
		0.15,
		0.05,
		0.15,
		0.15,
	];
	const defaultGeneratedDays = distributeRatios(files.length, defaultRatios);

	const generatedDays: number[] = Array<number>(7).fill(0);

	const insights = await getYoutubeInsightReach(account.accountID, 'year'); // TODO: check if it's the right period
	if (!insights || insights.length === 0) {
		return defaultGeneratedDays;
	}

	for (let i = 0; i < 7; i++) {
		/**
		 * get all rows for this day
		 * we get this day by getting the day of the week on each row
		 */
		const dayInsights = insights.filter((row) => moment(row.day).day() === i);

		const sumShares = dayInsights.reduce((acc, row) => acc + Number(row?.shares || 0), 0);
		const sumLikes = dayInsights.reduce((acc, row) => acc + Number(row?.likes || 0), 0);
		const sumComments = dayInsights.reduce((acc, row) => acc + Number(row?.comments || 0), 0);
		const sumViews = dayInsights.reduce((acc, row) => acc + Number(row?.views || 0), 0);

		const tmpResult = sumViews / (sumLikes + sumShares + sumComments);

		if (tmpResult === Infinity || isNaN(tmpResult)) {
			generatedDays[i] = 0;
		} else {
			generatedDays[i] = tmpResult;
		}
	}

	// normalize the values
	const generatedDaysSum = generatedDays.reduce((a: number, b: number) => a + b, 0);
	if (generatedDaysSum === 0) {
		return defaultGeneratedDays;
	}

	const generatedDaysNormalized = generatedDays.map((value) => value / generatedDaysSum);

	// compute the number of publications for each day
	return distributeRatios(files.length, generatedDaysNormalized);
};


/**
 * REPARTITION BY HOUR
 */

const _getInstagramPostsRepartition = async (account: SocialNetworkAccount, files: readonly FileDTO[]): Promise<Date[]> => {
	const accessToken = await account.token();
	if (!accessToken) {
		return [];
	}

	const onlineFollowers = await getFacebookOnlineFollowers(account.accountID, accessToken);
	if (onlineFollowers.error || !onlineFollowers.data) {
		toast.error(`Error while getting online followers for ${account.username} (${account.platformID})`)
		return [];
	}
	if (onlineFollowers.data.length === 0) {
		toast.warning(`You don't have a sufficient number of followers to get the best posting time for ${account.username} (${account.platformID})`, {
			autoClose: false,
		});
		return [];
	}

	const repartitionByDay = await _getInstagramPostsRepartitionByDay(account, files);

	/**
	 * create the array with the distribution of the posts based on the best
	 * hours for each day
	 */
	let dailyData: Array<number[]> = Array<number[]>(7).fill(Array<number>(24).fill(0));
	for (let i = 0; i < 7; i++) {
		const onlineFollowersByDay: number[] = Object.values(onlineFollowers.data[0].values[i].value);
		const onlineFollowersSum: number = onlineFollowersByDay.reduce((a: number, b: number) => a + b, 0);
		const onlineFollowersNormalized: number[] = onlineFollowersByDay.map((value) => value / onlineFollowersSum);
		
		// get the day index
		const dayIndex = moment(onlineFollowers.data[0].values[i].end_time).day();

		// distribute the ratios
		dailyData[dayIndex] = distributeRatios(repartitionByDay[dayIndex], onlineFollowersNormalized);
	}


	const postRepartitionByTime: Date[] = [];

	// create a new array with the best hour for each day
	dailyData.forEach((hours, i) => {

		// if there is no post to make for this day, do nothing
		if (hours.reduce((a, b) => a + b, 0) === 0) {
			return;
		}

		hours.forEach((value, hourIndex) => {
			// if there is no post to make for this hour, do nothing
			if (value === 0) {
				return;
			}

			/**
			 * the indexes (hours) are in utc+0
			 * so we have to convert them to local time
			 * get local offset
			 */
			const offset = moment().utcOffset() / 60;
			const fixedDate = moment().set({
				day: i,
				hour: hourIndex + offset,
				minute: 0,
				second: 0,
				millisecond: 0,
			});

			postRepartitionByTime.push(fixedDate.toDate());
		});
	});


	// generate the hours for each row
	const hourRepartition: Date[] = postRepartitionByTime.map((date) => {
		// generate random date between `date' and `date' + 1h
		const newHour: Moment = moment(date).set({
			minute: Math.floor(Math.random() * 59),
		});


		/**
		 * if the date is in the past, add 1 week to it
		 */
		if (newHour.isBefore(moment())) {
			newHour.add(1, 'week');

		/**
		 * if the hour is the same as the current hour, add 1 hour to the date
		 * in order to have a little gap when generating the posts
		 */
		} else if (newHour.isSame(moment(), 'hour')) {
			newHour.add(1, 'hour');
		}

		return newHour.toDate();
	});

	return hourRepartition;
};

const _getTiktokPostsRepartition = async (account: SocialNetworkAccount, files: readonly FileDTO[]): Promise<Date[]> => {
	const videos = await getAllTiktokVideos(account.id);
	if (!videos) {
		return [];
	}

	const generatedDays: number[] = Array<number>(7).fill(0);
	for (let i = 0; i < 7; i++) {
		const dayVideos = videos.filter((video) => {
			const videoDay = moment(video.create_time * 1000).day();
			return videoDay === i;
		});

		if (dayVideos.length === 0) {
			continue;
		}

		const videoViewsSum = dayVideos.reduce((acc, video) => acc + video.view_count, 0);
		const videoSharesSum = dayVideos.reduce((acc, video) => acc + video.share_count, 0);
		const videoLikesSum = dayVideos.reduce((acc, video) => acc + video.like_count, 0);
		const videoCommentsSum = dayVideos.reduce((acc, video) => acc + video.comment_count, 0);

		const videoViewsAvg = videoViewsSum / dayVideos.length;
		const videoSharesAvg = videoSharesSum / dayVideos.length;
		const videoLikesAvg = videoLikesSum / dayVideos.length;
		const videoCommentsAvg = videoCommentsSum / dayVideos.length;

		const videoViewsRatio = videoViewsAvg / (videoSharesAvg + videoLikesAvg + videoCommentsAvg);

		generatedDays[i] = videoViewsRatio;
	}

	// normalize the values
	const generatedDaysSum = generatedDays.reduce((a: number, b: number) => a + b, 0);
	const generatedDaysNormalized = generatedDays.map((value) => value / generatedDaysSum);

	// compute the number of publications for each day
	const postRepartitionByDay = distributeRatios(files.length, generatedDaysNormalized);


	// create a new array with the best hour for each day
	const postRepartitionByTime: Date[] = [];

	postRepartitionByDay.forEach((value, i) => {
		// if there is no post to make for this day, do nothing
		if (value === 0) {
			return;
		}

		// // get the videos of this day of the week
		// const dayVideos = videos.filter((video) => {
		// 	const videoDay = moment(video.create_time * 1000).day();
		// 	return videoDay === i;
		// });


		// /**
		//  * If there is no video for this day, do nothing
		//  * 
		//  * This should never happen because we already checked it in the previous
		//  * loop to get the generate the days.
		//  */
		// if (dayVideos.length === 0) {
		// 	return;
		// }

		// get the videos with the most views
		// TODO: I don't think that view_count is the right KPI to use here
		// const mostViewedVideos = dayVideos.sort((a, b) => b.view_count - a.view_count);

		// // get the video with the most views
		// const mostViewedVideo = mostViewedVideos[0];

		// // get the date of the most viewed video
		// const mostViewedVideoDate = moment(mostViewedVideo.create_time * 1000);

		// // get the hour of the most viewed video
		// const mostViewedVideoHour = mostViewedVideoDate.hour();

		
		// switch for each day of the week
		let mostViewedVideoHour: number = 8;
		switch (i) {
			case 0: // sunday
				// random hour between 17 and 20 or 5 and 8
				mostViewedVideoHour = Math.floor([Math.random() * (8 - 5) + 5, Math.random() * (20 - 17) + 17][Math.round(Math.random())]);
				break;
			case 1: // monday
				// random hour between 7 and 11 or 18 and 21
				mostViewedVideoHour = Math.floor([Math.random() * (11 - 7) + 7, Math.random() * (21 - 18) + 18][Math.round(Math.random())]);
				break;
			case 2: // tuesday
				// random hour between 7 and 11 or 17 and 20
				mostViewedVideoHour = Math.floor([Math.random() * (11 - 7) + 7, Math.random() * (20 - 17) + 17][Math.round(Math.random())]);
				break;
			case 3: // wednesday
				// random hour between 11 and 13 or 16 and 19
				mostViewedVideoHour = Math.floor([Math.random() * (13 - 11) + 11, Math.random() * (19 - 16) + 16][Math.round(Math.random())]);
				break;
			case 4: // thursday
				// random hour between 19 and 22 or 7 and 10
				mostViewedVideoHour = Math.floor([Math.random() * (10 - 7) + 7, Math.random() * (22 - 19) + 19][Math.round(Math.random())]);
				break;
			case 5: // friday
				// random hour between 17 and 20 or 12 and 15
				mostViewedVideoHour = Math.floor([Math.random() * (15 - 12) + 12, Math.random() * (20 - 17) + 17][Math.round(Math.random())]);
				break;
			case 6:	// saturday
				// random hour between 19 and 23
				mostViewedVideoHour = Math.floor(Math.random() * (23 - 19) + 19);
				break;

			default: // should never happen
				break;
		}


		// create a new Date for each post to make for this day
		for (let j = 0; j < value; j++) {
			// generate random date between `date' and `date' + 1h
			const newHour: Moment = moment().set({
				day: i,
				hour: mostViewedVideoHour,
				minute: Math.floor(Math.random() * 59),
				second: 0,
				millisecond: 0,
			});

			/**
			 * if the date is in the past, add 1 week to it
			 */
			if (newHour.isBefore(moment())) {
				newHour.add(1, 'week');

			/**
			 * if the hour is the same as the current hour, add 1 hour to the date
			 * in order to have a little gap when generating the posts
			 */
			} else if (newHour.isSame(moment(), 'hour')) {
				newHour.add(1, 'hour');
			}

			postRepartitionByTime.push(newHour.toDate());
		}
	});

	return postRepartitionByTime;
}


const _getYoutubePostsRepartition = async (account: SocialNetworkAccount, files: readonly FileDTO[]): Promise<Date[]> => {
	const postRepartitionByDay = await _getYoutubePostsRepartitionByDay(account, files);

	// create a new array with the best hour for each day
	const postRepartitionByTime: Date[] = [];

	postRepartitionByDay.forEach((value, i) => {
		// if there is no post to make for this day, do nothing
		if (value === 0) {
			return;
		}

		// switch for each day of the week
		let mostViewedVideoHour: number = 8;
		switch (i) {
			case 0: // sunday
				// random hour between 16 and 18 or 11 and 13
				mostViewedVideoHour = Math.floor([Math.random() * (13 - 11) + 11, Math.random() * (18 - 16) + 16][Math.round(Math.random())]);
				break;
			case 1: // monday
				// random hour between 16 and 18 or 20 and 22
				mostViewedVideoHour = Math.floor([Math.random() * (18 - 16) + 16, Math.random() * (22 - 20) + 20][Math.round(Math.random())]);
				break;
			case 2: // tuesday
				// random hour between 7 and 9 or 20 and 22
				mostViewedVideoHour = Math.floor([Math.random() * (9 - 7) + 7, Math.random() * (22 - 20) + 20][Math.round(Math.random())]);
				break;
			case 3: // wednesday
				// random hour between 13 and 15 or 18 and 20
				mostViewedVideoHour = Math.floor([Math.random() * (15 - 13) + 13, Math.random() * (20 - 18) + 18][Math.round(Math.random())]);
				break;
			case 4: // thursday
				// random hour between 18 and 21
				mostViewedVideoHour = Math.floor(Math.random() * (21 - 18) + 18);
				break;
			case 5: // friday
				// random hour between 20 and 22 or 7 and 9
				mostViewedVideoHour = Math.floor([Math.random() * (9 - 7) + 7, Math.random() * (22 - 20) + 20][Math.round(Math.random())]);
				break;
			case 6:	// saturday
				// random hour between 10 and 12 or 18 and 20
				mostViewedVideoHour = Math.floor([Math.random() * (12 - 10) + 10, Math.random() * (20 - 18) + 18][Math.round(Math.random())]);
				break;

			default: // should never happen
				break;
		}


		// create a new Date for each post to make for this day
		for (let j = 0; j < value; j++) {

			console.log('mostViewedVideoHour', mostViewedVideoHour);

			// generate random date between `date' and `date' + 1h
			const newHour: Moment = moment().set({
				day: i,
				hour: mostViewedVideoHour,
				minute: Math.floor(Math.random() * 59),
				second: 0,
				millisecond: 0,
			}).tz('Europe/Paris');

			console.log('newHour', moment().set({
				day: i,
				hour: mostViewedVideoHour,
				minute: Math.floor(Math.random() * 59),
				second: 0,
				millisecond: 0,
			}));
			console.log('newHour', newHour);
			console.log('newHour', newHour.toDate());
			console.log('newHour utc', newHour.utc().toDate());

			/**
			 * if the date is in the past, add 1 week to it
			 */
			if (newHour.isBefore(moment())) {
				newHour.add(1, 'week');

			/**
			 * if the hour is the same as the current hour, add 1 hour to the date
			 * in order to have a little gap when generating the posts
			 */
			} else if (newHour.isSame(moment(), 'hour')) {
				newHour.add(1, 'hour');
			}

			postRepartitionByTime.push(newHour.toDate());
		}
	});

	return postRepartitionByTime;
}



/**
 * @description
 * all social platforms available in the app (implemented or not)
 * 
 * @note
 * if you want to add a new social platform, you have to add it here
 */
export const ALL_SOCIAL_PLATFORMS: readonly SocialPlatform[] = [
	{
		platformID: SocialNetworkName.INSTAGRAM,
		name: 'Instagram',
		implemented: true,
		icon: <AiFillInstagram fontSize={26} />,
		iconLight: <AiFillInstagram fontSize={26} color='#eee' />,
		icon3D: '/icons/icons8-instagram-94.png',
		background: 'linear-gradient(30deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)',
		isAvailable: _checkInstagramAvailability,
		getPostsRepartition: _getInstagramPostsRepartition,
	},
	{
		platformID: SocialNetworkName.YOUTUBE,
		name: 'YouTube',
		implemented: true,
		icon: <AiFillYoutube fontSize={33} />,
		iconLight: <AiFillYoutube fontSize={33} color='#eee' />,
		icon3D: '/icons/youtube_social_circle_white.png',
		background: '#ea3323',
		isAvailable: _checkYoutubeAvailability,
		getPostsRepartition: _getYoutubePostsRepartition,
	},
	{
		platformID: SocialNetworkName.TIKTOK,
		name: 'TikTok',
		implemented: true,
		icon: <FaTiktok fontSize='large' />,
		iconLight: <FaTiktok fontSize='large' color='#eee' />,
		icon3D: '/icons/icons8-tiktok-94.png',
		background: '#222',
		isAvailable: _checkTiktokAvailability,
		getPostsRepartition: _getTiktokPostsRepartition,
	}
];
