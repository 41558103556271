/* Libraries */
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth';

/* Components */
import Logo from '../../assets/Logo/Logo';

/* MUI */
import { Box } from '@mui/system';
import { Button, Divider, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* Icons */

/* CSS */
import './ForgotPasswordPage.css';


const forgetTitleStyled = {
	color: 'black',
	fontWeight: 'bold',
	fontSize: '1.7rem',
};

export default function ForgotPasswordPage() {

	const [isLogged, setIsLogged] = useState(false);
	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [success, setSuccess] = useState('');
	const [error, setError] = useState({
		code: '',
		message: ''
	});


	useEffect(() => {
		const auth = getAuth();
		const subscriber = onAuthStateChanged(auth, (user) => {
			setIsLogged(!!user && user.emailVerified);
		});

		return subscriber; // unsubscribe on unmount
	}, []);

	const handleForgotPassword = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);
		setError({code: '', message: ''});
		
		const auth = getAuth();
		const baseUrl = process.env.NODE_ENV === 'production' ? 'https://freekl.com' : 'https://localhost:3000';
		const actionCodeSettings = {
			url: `${baseUrl}/dashboard/login/?email=${auth.currentUser?.email}`
		};

		sendPasswordResetEmail(auth, email, actionCodeSettings)
			.then(() => {
				setEmail('');
				setSuccess('Un email vous a été envoyé pour réinitialiser votre mot de passe');
			})
			.catch((error) => {
				setError({
					code: error.code,
					message: error.message
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	};


	if (isLogged) {
		return (
			<Box className='ForgotPasswordPage-container'>
				<Box className='ForgotPasswordPage-form-container'>
					<Box className='ForgotPasswordPage-title-container'>
						<Logo width={50} />

						<Typography variant='h4' sx={forgetTitleStyled}>
							Mot de passe oublié
						</Typography>
					</Box>

					<Box className='ForgotPasswordPage-fields-container'>
						<Typography variant='h5' sx={{color: 'black', fontWeight: 'bold', fontSize: '1.5rem'}}>
							Vous êtes déjà connecté
						</Typography>

						<Divider sx={{ margin: '20px 0 25px 0' }} />

						<Button variant='contained' size='large' color='primary' component={Link} to='/dashboard'>
							Aller sur le dashboard
						</Button>
					</Box>
				</Box>
			</Box>
		);
	};

	return (
		<Box className='ForgotPasswordPage-container'>
			<form className='ForgotPasswordPage-form-container' onSubmit={handleForgotPassword}>
				<Box className='ForgotPasswordPage-title-container'>
					<Logo width={50} />

					<Typography variant='h4' sx={forgetTitleStyled}>
						Mot de passe oublié
					</Typography>
				</Box>

				{ !success && (
					<Fragment>
						<Box className='ForgotPasswordPage-fields-container'>
							<TextField
								variant="outlined"
								size='small'
								type='email'
								label='Email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								InputLabelProps={{ style: { color: '#888' } }}
							/>
						</Box>

						<LoadingButton
							fullWidth
							variant="contained"
							// size='large'
							color="primary"
							type='submit'
							onClick={handleForgotPassword}
							loading={isLoading}
							disabled={email.length < 6}
						>
							Réinitialiser
						</LoadingButton>
					</Fragment>
				)}

				{ success && (
					<Typography variant='body1' color='success' sx={{
						color: 'green',
					}}>
						{success}
					</Typography>
				)}

				{ error.code && (
					<Typography variant='body1' color='error'>
						Erreur: {error.code}
					</Typography>
				)}
			</form>

			<Divider orientation="horizontal" flexItem>
				<Typography variant='body1' color='textSecondary'>
					Pas encore de compte ?
				</Typography>
			</Divider>

			<Button
				component={Link}
				to='/dashboard/register'
				fullWidth
				variant="text"
				size='small'
				sx={{ backgroundColor: '#f5f5f5' }}
			>
				Nous rejoindre
			</Button>
		</Box>
	);
}
