export interface TiktokAccessToken {
	open_id: string;
	scope: string;
	access_token: string;
	expires_in: number;
	refresh_token: string;
	refresh_expires_in: number;
	token_type: string;
}


export interface TiktokAccessTokenResponse {
	data: TiktokAccessToken;
	message: string;
}


export interface TiktokUser {
	open_id: string;
	union_id: string;
	avatar_url: string;
	avatar_url_100: string;
	avatar_large_url: string;
	display_name: string;
	bio_description: string;
	profile_deep_link: string;
	is_verified: boolean;
	follower_count: number;
	following_count: number;
	likes_count: number;
}


export interface TiktokUserResponse {
	data: {
		user: TiktokUser
	};
	error: {
		error: string;
		error_description: string;
		log_id: string;
	};
}


export enum TiktokPrivacyLevelPublic {
	PUBLIC_TO_EVERYONE = 'PUBLIC_TO_EVERYONE',
	MUTUAL_FOLLOW_FRIENDS = 'MUTUAL_FOLLOW_FRIENDS',
	SELF_ONLY = 'SELF_ONLY'
}


export enum TiktokPrivacyLevelPrivate {
	FOLLOWER_OF_CREATOR = 'FOLLOWER_OF_CREATOR',
	MUTUAL_FOLLOW_FRIENDS = 'MUTUAL_FOLLOW_FRIENDS',
	SELF_ONLY = 'SELF_ONLY'
}


// POST /v2/post/publish/creator_info/query/
export interface TiktokCreatorInfoQueryResponse {
	data: {
		creator_avatar_url: string;
		creator_username: string;
		creator_nickname: string;
		privacy_level_options: (TiktokPrivacyLevelPublic | TiktokPrivacyLevelPrivate)[];
		comment_disabled: boolean;
		duet_disabled: boolean;
		stitch_disabled: boolean;
		max_video_post_duration_sec: number;
	};
	error: TiktokError;
}


export interface TiktokVideo {
	id: string;
	create_time: number;
	cover_image_url: string
	share_url: string;
	video_description: string; // Max length: 150
	duration: number; // in seconds
	height: number;
	width: number;
	title: string; // Max length: 150
	embed_html: string;
	embed_link: string;
	like_count: number;
	comment_count: number;
	share_count: number;
	view_count: number;
}


// POST /v2/video/list/
export interface TiktokVideoListResponse {
	error: TiktokError;
	data: {
		videos: TiktokVideo[];
		cursor: number;
		has_more: boolean;
	};
}


export enum TiktokErrorCode {
	ok = 'ok',
	access_token_invalid = 'access_token_invalid',
	internal_error = 'internal_error',
	invalid_file_upload = 'invalid_file_upload',
	invalid_params = 'invalid_params',
	rate_limit_exceeded = 'rate_limit_exceeded',
	scope_not_authorized = 'scope_not_authorized',
	scope_permission_missed = 'scope_permission_missed',
}


export interface TiktokError {
	code: TiktokErrorCode;
	message: string;
	log_id: string;
}
