/* Libraries */
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { createUserWithEmailAndPassword, deleteUser, getAuth, sendEmailVerification } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

/* Components */
import Logo from '../../assets/Logo/Logo';

/* MUI */
import { Box } from '@mui/system';
import { Button, CircularProgress, Divider, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* Icons */
import LaunchIcon from '@mui/icons-material/Launch';

/* CSS */
import './RegisterPage.css';


const registerTitleStyled = {
	color: 'black',
	fontWeight: 'bold',
	fontSize: {
		xs: '1.5rem',
		sm: '2rem'
	}
};

export default function RegisterPage() {

	const [auth, authIsLoading] = useAuthState(getAuth());
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [success, setSuccess] = useState(false);
	const [error, setError] = useState({
		code: '',
		message: ''
	});


	const handleRegister = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);
		setError({code: '', message: ''});

		if (password !== confirmPassword) {
			setError({
				code: 'auth/passwords-not-equal',
				message: 'Passwords are not equal'
			});
			setIsLoading(false);
			return ;
		}

		const auth = getAuth();
		createUserWithEmailAndPassword(auth, email, password)
			.then((userCredential: any) => {
				const auth = getAuth();

				if (auth.currentUser) {
					const baseUrl = process.env.NODE_ENV === 'production' ? 'https://freekl.com' : 'https://localhost:3000';
					const actionCodeSettings = {
						url: `${baseUrl}/dashboard/login/?email=${auth.currentUser?.email}`,
					};

					sendEmailVerification(auth.currentUser, actionCodeSettings)
						.then(() => {
							setSuccess(true);
						})
						.catch((error: any) => {
							if (auth.currentUser) {
								deleteUser(auth.currentUser)
							}
						});
				}
			})
			.catch((error: any) => {
				setError({
					code: error.code,
					message: error.message
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	};


	if (auth && auth.emailVerified) {
		return (
			<Box className='LoginPage-container'>
				<Box className='LoginPage-form-container'>
					<Box className='LoginPage-title-container'>
						<Logo width={50} />

						<Typography variant='h4' sx={registerTitleStyled}>
							Nouveau compte
						</Typography>
					</Box>

					<Typography variant='body1' color='info' sx={{ color: '#464' }}>
						Vous ne pouvez pas créer de compte si vous êtes connecté
					</Typography>

					<Button
						component={Link}
						to='/dashboard'
						variant='contained'
						color='info'
						size='large'
						startIcon={<LaunchIcon />}
					>
						Aller au dashboard
					</Button>
				</Box>
			</Box>
		)
	};

	if (authIsLoading) {
		return (
			<Box className='LoginPage-container'>
				<Box className='LoginPage-form-container'>
					<Box className='LoginPage-title-container'>
						<Logo width={50} />

						<Typography variant='h4' sx={registerTitleStyled}>
							Nouveau compte
						</Typography>
					</Box>

					<CircularProgress disableShrink size={25} />
				</Box>
			</Box>
		)
	}

	return (
		<Box className='RegisterPage-container'>
			<form className='RegisterPage-form-container' onSubmit={handleRegister}>
				<Box className='RegisterPage-title-container'>
					<Logo width={50} />

					<Typography variant='h4' sx={registerTitleStyled}>
						Nouveau compte
					</Typography>
				</Box>

				{ !success &&
					<Fragment>
						<Box className='RegisterPage-fields-container'>
							<TextField
								variant="outlined"
								size='small'
								type='email'
								label='Email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								InputLabelProps={{ style: { color: '#888' } }}
							/>

							<TextField
								variant="outlined"
								size='small'
								type='password'
								label='Mot de passe'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								InputLabelProps={{ style: { color: '#888' } }}
							/>

							<TextField
								variant="outlined"
								size='small'
								type='password'
								label='Confirmation du mot de passe'
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								InputLabelProps={{ style: { color: '#888' } }}
							/>
						</Box>

						<LoadingButton
							fullWidth
							variant="contained"
							size='large'
							color="primary"
							type='submit'
							onClick={handleRegister}
							loading={isLoading}
							disabled={password.length < 6 || confirmPassword.length < 6 || email.length < 6 || success}
						>
							Créer un compte
						</LoadingButton>
					</Fragment>
				}

				{ success &&
					<Typography variant='body1' color='success' sx={{ color: 'green' }}>
						Un email de vérification vous a été envoyé !
					</Typography>
				}

				{ error.code && (
					<Typography variant='body1' color='error'>
						Erreur: {error.code}
					</Typography>
				)}
			</form>

			<Divider orientation="horizontal" flexItem>
				<Typography variant='body1' color='textSecondary'>
					Déjà un compte ?
				</Typography>
			</Divider>

			<Button
				component={Link}
				to='/dashboard/login'
				fullWidth
				variant="text"
				size='small'
				sx={{ backgroundColor: '#f5f5f5' }}
			>
				Se connecter
			</Button>
		</Box>
	);
}
