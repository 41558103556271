export interface FacebookPagesDTO {
	error?: any;
	data: {
		access_token: string;
		category: string;
		category_list: {
			id: string;
			name: string;
		}[];
		name: string;
		id: string;
		tasks: string[];
	}[];
	paging: {
		cursors: {
			before: string;
			after: string;
		}
	}
}


export interface InstagramAccountFieldsDTO {
	id: string;
	username: string;
	profile_picture_url: string;
	followers_count: number;
	follows_count: number;
	media_count: number;
}


export enum InstagramMediaType {
	IMAGE = 'IMAGE',
	VIDEO = 'VIDEO',
	CAROUSEL = 'CAROUSEL',
	REELS = 'REELS',
	CAROUSEL_ALBUM = 'CAROUSEL_ALBUM', // only used on a special response
}


export interface InstagramMediaContainerCommonFieldsDTO {
	access_token: string;
	media_type: InstagramMediaType;

	/**
	 * page ID of the location
	 * TODO: to implement
	 */
	location_id?: string;
}


export interface InstagramImageContainerDTO extends InstagramMediaContainerCommonFieldsDTO {
	/**
	 * max 2200 characters, 30 hashtags & 20 tags @
	 */
	caption?: string;

	/**
	 * url to the image
	 */
	image_url: string;

	/**
	 * if defined, the image will appear in a carousel
	 */
	is_carousel_item?: boolean;

	/**
	 * array of maximum 5 products
	 */
	product_tags?: InstagramProductTagsDTO[];

	user_tags?: InstagramUserTagDTO[];
}


export interface InstagramVideoContainerDTO extends InstagramMediaContainerCommonFieldsDTO {
	/**
	 * max 2200 characters, 30 hashtags & 20 tags @
	 */
	caption?: string;

	/**
	 * if defined, the video will appear in a carousel
	 */
	is_carousel_item?: boolean;

	media_type: InstagramMediaType.VIDEO;

	/**
	 * array of maximum 5 products
	 */
	product_tags?: InstagramProductTagsDTO[];

	/**
	 * in milliseconds from the beginning of the video
	 * if not provided, the first frame will be used
	 */
	thumb_offset?: number;

	user_tags?: InstagramUserTagDTO[];

	/**
	 * url to the video
	 * must be on a public server
	 */
	video_url: string;
}


export interface InstagramReelContainerDTO extends InstagramMediaContainerCommonFieldsDTO {

	/**
	 * name of the audio track
	 * it can be named just once, at the time of the reels' upload
	 */
	audio_name?: string;

	/**
	 * max 2200 characters, 30 hashtags & 20 tags @
	 */
	caption?: string;

	/**
	 * if `cover_url' is provided, `thumb_offset' will be ignored
	 */
	cover_url?: string;

	media_type: InstagramMediaType.REELS;

	/**
	 * if true, the reel MAY appear both in the feed and in the reels tab
	 * if false, the reel MAY appear only in the reels tab
	*/
	share_to_feed?: boolean;

	/**
	 * in milliseconds from the beginning of the video
	 * if not provided, the first frame will be used
	 * if `cover_url' is provided, `thumb_offset' will be ignored
	 */
	thumb_offset?: number;

	user_tags?: InstagramUserTagDTO[];

	/**
	 * url to the video
	 * must be on a public server
	 */
	video_url: string;
}


export interface InstagramCarouselContainerDTO extends InstagramMediaContainerCommonFieldsDTO {
	/**
	 * max 2200 characters, 30 hashtags & 20 tags @
	 */
	caption?: string;

	/**
	 * array of maximum 10 container ids (images and videos)
	 */
	children: string[];

	media_type: InstagramMediaType.CAROUSEL;

	product_tags?: InstagramProductTagsDTO[];
}


export interface InstagramMediaContainerResponseDTO {
	id?: string;
	error?: {
		code: number;
		error_subcode: number;
		error_user_msg: string;
		error_user_title: string;
		fbtrace_id: string;
		is_transient: boolean;
		message: string;
		type: string;
	};
}


/**
 * @example
 * {product_id:'3231775643511089',x: 0.5,y: 0.8}
 */
export interface InstagramProductTagsDTO {
	product_id: string;

	/**
	 * only for images, not mandatory
	 * values between 0.0 and 1.0
	 */
	x?: number; // distance (in percentage) from the left corner of the image
	y?: number; // distance (in percentage) from the top corner of the image
}


/**
 * @description
 * Instagram's user tag
 * Notifies the user that they have been tagged in the post
 * 
 * @example
 * {username:'natgeo',x:0.5,y:1.0}
 */
export interface InstagramUserTagDTO {
	username: string;

	/**
	 * values between 0.0 and 1.0
	 */
	x?: number; // distance (in percentage) from the left corner of the image
	y?: number; // distance (in percentage) from the top corner of the image
}


/**
 * @description
 * data structure generated after requesting the follower demographic from
 * the Facebook API
 */
export interface FacebookFollowerDemographicRow {
	gender: string; // 'F' | 'M' | 'U'
	age: string; // '13-17' | '18-24' | '25-34' | '35-44' | '45-54' | '55-64' | '65+'
	value: number;
}


/**
 * @description
 * data structure returned by the Facebook API when requesting the follower
 * demographic
 * 
 * @note
 * used internally in the services
 */
export interface FacebookFollowerDemographicResponse {
	data?: {
		description: string;
		id: string;
		name: string;
		period: string;
		title: string;
		total_value: {
			breakdowns: {
				dimension_keys: ['age', 'gender'] | string[];
				results: {
					dimension_values: string[];
					value: number;
				}[];
			}[];
		};
	}[];
	error?: {
		message: string;
		type: string;
		code: number;
		fbtrace_id: string;
	};
}

export interface InstagramInsightResponse<T> {
	data?: {
		id: string;
		name: string;
		title: string;
		period: string;
		description: string;
		values: {
			value: T;
			end_time: string;
		}[];
	}[];
	error?: {
		message: string;
		type: string;
		code: number;
		fbtrace_id: string;
	};
}


export interface InstagramUserMediaReponseDTO {
	data: {
		id: string;
	}[];
	paging: {
		cursors: {
			after: string;
			before: string;
		};
		next?: string;
		previous?: string;
	};
}

export interface InstagramMediaResponseDTO {
	id: string;
	caption: string;
	comments_count: number;
	like_count: number;
	media_type: InstagramMediaType;
	media_url?: string;
	permalink: string;
	timestamp: string;
}

export enum InstagramMediaContainerStatusCode {
	EXPIRED = 'EXPIRED',
	ERROR = 'ERROR',
	FINISHED = 'FINISHED',
	IN_PROGRESS = 'IN_PROGRESS',
	PUBLISHED = 'PUBLISHED',
}

export interface InstagramMediaContainerStatusDTO {
	id: string;
	status: string;
	status_code: InstagramMediaContainerStatusCode;
}
