/* Libraries */
import { Fragment, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Product, Subscription, createCheckoutSession, getCurrentUserSubscriptions, getProducts } from '@stripe/firestore-stripe-payments';
import { payments } from '../../config/firebase.config';
import { toast } from 'react-toastify';

/* Components */
import Logo from '../../assets/Logo/Logo';

/* MUI */
import { Box } from '@mui/system';
import { Button, CircularProgress, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* Icons */

/* CSS */
import './SelectSubscriptionPage.css';


const registerTitleStyled = {
	color: 'black',
	fontWeight: 'bold',
	fontSize: {
		xs: '1.5rem',
		sm: '2rem'
	}
};

export default function SelectSubscriptionPage() {
	const [auth, authIsLoading] = useAuthState(getAuth());
	const [products, setProducts] = useState<Product[]>([]);
	const [currentSubscription, setCurrentSubscription] = useState<Subscription[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const redirectURL = new URLSearchParams(window.location.search).get('redirect');


	useEffect(() => {
		if (!auth) {
			return;
		}
		loadCurrentUserSubscriptions();
		loadProducts();
		// eslint-disable-next-line
	}, [auth]);


	const loadCurrentUserSubscriptions = async () => {
		const subs = await getCurrentUserSubscriptions(payments, { status: ['active', 'trialing'] });
		setCurrentSubscription(subs);
	}

	const loadProducts = async () => {
		if (!auth) {
			return;
		}
		const products = await getProducts(payments, { activeOnly: true, includePrices: true });
		setProducts(products);
	}

	const handleSubscribe = async (priceId: string) => {
		if (!auth || !priceId) {
			return;
		}

		setIsLoading(true);
		try {
			const checkoutSession = await createCheckoutSession(payments, {
				price: priceId,
			});
			window.location.href = checkoutSession.url;
		} catch (error) {
			setIsLoading(false);
			toast.error('Une erreur est survenue lors de la création de votre abonnement. Veuillez réessayer plus tard.');
		}
	}


	if (currentSubscription.length > 0) {
		return <Navigate to={
			redirectURL && redirectURL !== window.location.pathname ?
				redirectURL :
				'/dashboard'
		} />;
	}

	if ((!auth || !auth.emailVerified) && !authIsLoading) {
		return (
			<Box className='LoginPage-container'>
				<Box className='LoginPage-form-container'>
					<Box className='LoginPage-title-container'>
						<Logo width={50} />

						<Typography variant='h4' sx={registerTitleStyled}>
							Votre Abonnement
						</Typography>
					</Box>

					<Typography variant='body1' color='info' sx={{ color: '#464' }}>
						Vous ne pouvez pas vous abonner si vous n'êtes pas connecté
					</Typography>

					<Button
						component={Link}
						to='/dashboard/login'
						variant='contained'
						color='info'
						size='large'
					>
						Se connecter
					</Button>
				</Box>
			</Box>
		)
	};

	if (products.length > 0) {
		return (
			<Box className='LoginPage-container'>
				<Box className='LoginPage-form-container'>
					<Box className='LoginPage-title-container'>
						<Logo width={50} />

						<Typography variant='h4' sx={registerTitleStyled}>
							Choisissez votre abonnement
						</Typography>
					</Box>

					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '30px',
						width: '100%',
					}}>
					{ products.map((product, index) => (
						<Box key={index}
						sx={{
							textAlign: 'left',
							display: 'flex',
							flexDirection: 'column',
							gap: '12px',
							padding: '30px 22px',
							borderRadius: '22px',
							backgroundColor: '#f2f2f2',
							boxShadow: '0 2px 3px rgba(0,0,0, .15)',
							width: '100%',
							maxWidth: {
								xs: '230px',
								sm: '350px',
								md: '450px',
							},
							margin: '0 auto',
						}}>
							<Typography variant='h4' color='primary' sx={{ color: '#222', fontSize: '1.6rem', fontWeight: 800 }}>
								{product.name}
							</Typography>

							<Typography variant='body1' sx={{ color: '#444', fontSize: '1rem' }}>
								{product.description?.split('; ').map((line, index) => (
									<Fragment key={index}>
										{'-'} {line}
										<br />
									</Fragment>
								))}
							</Typography>

							<Typography variant='h4' sx={{
								color: '#444',
								fontWeight: 700,
								textAlign: 'right'
							}}>
								{Number((product?.prices?.[0]?.unit_amount || 0) / 100).toFixed(2)} € / mois
							</Typography>

							<LoadingButton
								loading={isLoading}
								variant='contained'
								color='info'
								size='large'
								onClick={() => handleSubscribe(product.prices?.[0]?.id || '')}
							>
								S'abonner
							</LoadingButton>
						</Box>
					))}
					</Box>
				</Box>
			</Box>
		);
	}


	return (
		<Box className='LoginPage-container'>
			<Box className='LoginPage-form-container'>
				<CircularProgress disableShrink size={25} />
			</Box>
		</Box>
	)
}
