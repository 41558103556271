/* Libraries */
import { Fragment } from 'react';

/* Components */
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import HeroSection from '../../components/HeroSection/HeroSection';
import IASection from '../../components/IASection/IASection';
import FeaturesSection from '../../components/FeaturesSection/FeaturesSection';
import PlanificationSection from '../../components/PlanificationSection/PlanificationSection';
import SecuritySection from '../../components/SecuritySection/SecuritySection';
import MetricsSection from '../../components/MetricsSection/MetricsSection';
import SneakPeakSection from '../../components/SneakPeakSection/SneakPeakSection';
import BlogSection from '../../components/BlogSection/BlogSection';

/* CSS */
import './LandingPage.css';


export default function LandingPage() {
	return (
		<Fragment>
			<Header />

			<HeroSection />
			<MetricsSection />
			<PlanificationSection />
			<IASection />
			<FeaturesSection />
			<SecuritySection />
			<SneakPeakSection />
			<BlogSection />

			<Footer />
		</Fragment>
	);
}
