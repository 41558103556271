/* Libraries */
import { useState } from 'react';
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { toast } from 'react-toastify';

/* Components */

/* MUI */
import { Box } from '@mui/system';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* Icons */

/* CSS */
import './AccountUpdatePassword.css';


const textfieldLabelStyled = {
	style: {
		color: '#888'
	}
};

export default function AccountUpdatePassword() {
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [oldPassword, setOldPassword] = useState('');
	const [updatePasswordIsLoading, setUpdatePasswordIsLoading] = useState(false);


	const handleUpdatePassword = async () => {

		if (password !== passwordConfirm || password === '') {
			toast.error('Les mots de passe ne correspondent pas');
			return;
		}

		setUpdatePasswordIsLoading(true);
		const user = getAuth().currentUser;

		if (user) {
			// Reauthenticate user
			const credential = EmailAuthProvider.credential(user?.email || '', oldPassword);

			try {
				await reauthenticateWithCredential(user, credential);
			} catch (error: any) {
				toast.error(error.message);
			}

			try {
				await updatePassword(user, password);
				toast.success('Mot de passe mis à jour');
				setOldPassword('');
				setPassword('');
				setPasswordConfirm('');
			} catch (error: any) {
				toast.error(error.message);
			}
		}
		setUpdatePasswordIsLoading(false);
	};


	return (
		<form className='AccountUpdatePassword-container'>
			<TextField
				variant="outlined"
				size='small'
				type='password'
				label='Ancien mot de passe'
				value={oldPassword}
				onChange={(e) => setOldPassword(e.target.value)}
				InputLabelProps={textfieldLabelStyled}
			/>

			<Box className='AccountUpdatePassword-fields-container'>
				<TextField
					variant="outlined"
					size='small'
					type='password'
					label='Nouveau mot de passe'
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					InputLabelProps={textfieldLabelStyled}
				/>

				<TextField
					variant="outlined"
					size='small'
					type='password'
					label='Confirmation'
					value={passwordConfirm}
					onChange={(e) => setPasswordConfirm(e.target.value)}
					InputLabelProps={textfieldLabelStyled}
				/>
			</Box>

			<LoadingButton
				variant='contained'
				disabled={password !== passwordConfirm || password === ''}
				onClick={handleUpdatePassword}
				loading={updatePasswordIsLoading}
			>
				Mettre à jour
			</LoadingButton>
		</form>
	);
}
