/* Libraries */

/* Components */

/* MUI */
import { Box, Typography } from '@mui/material';

/* Icons */

/* CSS */
import './PlanificationSection.css';


const titleStyled = {
	color: '#fff',
	textAlign: 'center',
	fontSize: {
		xs: '30px',
		sm: '40px',
		md: '50px',
	},
	mb: '50px',
};

const subtitleStyled = {
	color: '#fff',
	textAlign: 'center',
	fontSize: {
		xs: '25px',
		sm: '30px',
		md: '40px',
	},
	margin: '60px 0 30px 0',
};

export default function PlanificationSection() {
	return (
		<Box className="PlanificationSection-container">
			<Typography variant="h2" component="h2" className="PlanificationSection-title" sx={titleStyled}>
				Aucun soucis, on publie tout à votre place.
			</Typography>

			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				width: '100%',
				marginBottom: '100px'
			}}>
				<img src="/images/publication-1.png" alt="Planification-1" style={{
					borderRadius: '35px',
					// borderBottom: '1px solid #aaa',
					// borderTop: '1px solid #aaa',
					// borderLeft: '1px solid #aaa',
					// borderRight: '1px solid #aaa',
					width: '80%',
					maxWidth: '1000px',
				}} />
			</Box>

			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-end',
				width: '100%'
			}}>
				<img src="/images/publication-2.png" alt="Planification-2" style={{
					borderRadius: '35px',
					width: '80%',
					maxWidth: '1000px',
				}} />
			</Box>

			<Typography variant="h3" component="h3" className="PlanificationSection-title" sx={subtitleStyled}>
				C'est aussi simple que ça !
			</Typography>
		</Box>
	);
}
