/* Libraries */
import { Fragment, useEffect, useState } from 'react';

/* Components */

/* Services */
import { SocialNetworkAccount, SocialNetworkName } from '../../services/socialNetworks.service.dto';
import { getSocialAccountsByPlatform } from '../../services/socialNetworks.service';
import { TIKTOK_CLIENT_KEY, revokeTiktokAccess, tiktokLogout } from '../../services/tiktok.service';
import { convertValueToHumanReadable } from '../../services/_utils';

/* MUI */
import { Box } from '@mui/system';
import { Button, Icon, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

/* Icons */
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LogoutIcon from '@mui/icons-material/Logout';

/* DTO */

/* CSS */
import '../../pages/AccountsLoginPage/SocialAccountLogin.css';
import './TikTokLoginStep.css';


const tiktokNameStyled = {
	color: '#000',
	fontWeight: 'bold',
	fontSize: {
		xs: '17px',
		md: '22px'
	}
};

const tiktokAccountInfoStyled = {
	color: 'black',
	fontSize: {
		xs: '11px',
		md: '13px'
	}
};

const tiktokUsernameStyled = {
	color: 'black',
	fontWeight: 'bold',
	fontSize: {
		xs: '15px',
		md: '16px'
	}
};

const tiktokHighlightStyled = {
	color: '#0095f6',
	fontWeight: 'bold'
};

export default function TikTokLoginStep() {

	const [tiktokState, setTiktokState] = useState<SocialNetworkAccount[]>([]);


	useEffect(() => {
		refreshTiktokState();
	}, []);

	
	/**
	 * Load Tiktok account from Firestore
	 * @returns
	 * 		- void
	 */
	const refreshTiktokState = async (): Promise<void> => {
		// TODO: update tiktok user infos in firestore here
		const acc = await getSocialAccountsByPlatform(SocialNetworkName.TIKTOK);

		if (acc) {
			setTiktokState(acc);
		}
	};

	const handleTiktokLogin = async () => {
		// const baseUrl = process.env.NODE_ENV === 'production' ? 'https://freekl.com' : 'https://localhost:3000';

		const baseUrl = 'https://freekl.com';
		const redirectUri = encodeURIComponent(`${baseUrl}/dashboard/_auth/tiktok`);
		const csrfState = Math.random().toString(36).substring(2);

		let url = 'https://www.tiktok.com/v2/auth/authorize/';

		const scope = [
			'user.info.basic',
			'user.info.profile',
			'user.info.stats',
			'video.list',
			'video.upload',
			'video.publish',
		];

		url += `?client_key=${TIKTOK_CLIENT_KEY}`;
		url += '&response_type=code';
		url += `&scope=${scope.join(',')}`;
		url += `&redirect_uri=${redirectUri}`;
		url += '&state=' + csrfState;

		window.open(url, '_self');
	};

	const handleTiktokLogout = async (id: string, accountID: string) => {
		await tiktokLogout(id);
		await revokeTiktokAccess(accountID);
		setTiktokState((prev) => prev && prev.filter((acc) => acc.id !== id));
	};


	return (
		<Box className='SocialAccountLogin-container SocialAccountLogin-container-dark'>
			<Box className='SocialAccountLogin-connection-container'>
				{ tiktokState.length === 0 && (
					<Button
						color='primary'
						sx={{ backgroundColor: 'rgb(234,68,90)' }}
						variant='contained'
						size='large'
						onClick={handleTiktokLogin}
					>
						Se connecter avec TikTok
					</Button>
				)}

				{ tiktokState.length > 0 && (
					<Fragment>
						<Box sx={{ flexGrow: 1 }} />

						<Button
							color='primary'
							sx={{ backgroundColor: 'rgb(234,68,90)' }}
							variant='contained'
							onClick={handleTiktokLogin}
							startIcon={<AddCircleOutlineIcon />}
						>
							Connecter un autre compte
						</Button>
					</Fragment>
				)}
			</Box>

			{ tiktokState.length > 0 && (
				<Box className='SocialAccountLogin-items-container'>
				{ tiktokState.map((tiktokAccount: SocialNetworkAccount, index: number) => (
					<Box className='SocialAccountLogin-item-container' key={index}>
						<Box className='SocialAccountLogin-profile-header'>
							<img className='SocialAccountLogin-profile-picture'
								src={tiktokAccount.data.user.avatar_url_100}
								alt={tiktokAccount.username}
							/>

							<Box className='SocialAccountLogin-profile-infos'>
								<Typography variant="h5" sx={tiktokNameStyled}>
									{tiktokAccount.username}
								</Typography>
							</Box>

							<Box sx={{ flexGrow: 1 }}></Box>

							<Box className='SocialAccountLogin-profile-buttons'>
								<Button
									size='small'
									variant="contained"
									color="primary"
									sx={{ backgroundColor: 'rgb(234,68,90)' }}
									startIcon={<LogoutIcon />}
									onClick={() => handleTiktokLogout(tiktokAccount.id, tiktokAccount.accountID)}
								>
									Déconnexion
								</Button>
							</Box>
						</Box>

						{/* Tiktok account */}
						<Box className='SocialAccountLogin-accounts-list-container'>
							<Box className='SocialAccountLogin-account-container SocialAccountLogin-account-selected'>
								<img className='SocialAccountLogin-account-profile-picture'
									src={tiktokAccount.data.user.avatar_url_100}
									alt="TikTok profile"
								/>

								<Box className='SocialAccountLogin-account-profile-infos-container'>
									<Typography variant="h4" sx={tiktokUsernameStyled}>
										{tiktokAccount.data.user.display_name}
									</Typography>

									{/* profile infos */}
									<Box className='SocialAccountLogin-account-profile-infos'>
										<Typography variant="h4" sx={tiktokAccountInfoStyled}>
											<span style={tiktokHighlightStyled}>
												{convertValueToHumanReadable(tiktokAccount.data.user.follower_count)}
											</span> abonnés
										</Typography>

										<Typography variant="h4" sx={tiktokAccountInfoStyled}>
											<span style={tiktokHighlightStyled}>
												{convertValueToHumanReadable(tiktokAccount.data.user.following_count)}
											</span> abonnements
										</Typography>

										<Typography variant="h4" sx={tiktokAccountInfoStyled}>
											<span style={tiktokHighlightStyled}>
												{convertValueToHumanReadable(tiktokAccount.data.user.likes_count)}
											</span> likes
										</Typography>
									</Box>
								</Box>

								<Tooltip
									title='Compte lié'
									placement="top"
								>
									<Icon color='info'>
										<CheckCircleOutlineIcon fontSize='small' />
									</Icon>
								</Tooltip>
							</Box>
						</Box>
					</Box>
				))}
				</Box>
			)}
		</Box>
	);
}
