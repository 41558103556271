/* Libraries */
import { Route, Routes } from 'react-router-dom';
import { cssTransition, ToastContainer } from 'react-toastify';

/* Components */
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import ScrollToTop from './assets/ScrollToTop/ScrollToTop';
import LandingPage from './pages/LandingPage/LandingPage';
import PricingPage from './pages/PricingPage/PricingPage';
import BlogPage from './pages/BlogPage/BlogPage';
import LegalPrivacyPage from './pages/LegalPrivacyPage/LegalPrivacyPage';
import LegalTermsPage from './pages/LegalTermsPage/LegalTermsPage';
import PlatformDrawer from './components/PlatformDrawer/PlatformDrawer';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import SelectSubscriptionPage from './pages/SelectSubscriptionPage/SelectSubscriptionPage';
import CalendarPage from './pages/CalendarPage/CalendarPage';
import InsightsPage from './pages/InsightsPage/InsightsPage';
import LibraryPage from './pages/LibraryPage/LibraryPage';
import AccountsLoginPage from './pages/AccountsLoginPage/AccountsLoginPage';
import AccountSettingsPage from './pages/AccountSettingsPage/AccountSettingsPage';
import ChangelogPage from './pages/ChangelogPage/ChangelogPage';
import SupportPage from './pages/SupportPage/SupportPage';
import YoutubeServiceCallback from './services/youtube.service.callback';
import TiktokServiceCallback from './services/tiktok.service.callback';

/* MUI */
import { ThemeProvider } from '@mui/material';

/* Theme */
import { theme } from './theme';

/* CSS */
import "animate.css/animate.min.css";
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';


export default function App() {

	const toastTransition = cssTransition({
		enter: "animate__animated animate__fast animate__jackInTheBox", // animate__flipInX
		exit: "animate__animated animate__fast animate__flipOutX"
	});


	return (
		<ThemeProvider theme={theme}>
			{/**
			 * Get to the top of the page when the user navigates to a new page
			 * Default behavior is to stay at the same scroll position
			*/}
			<ScrollToTop />

			<Routes>
				<Route index element={<LandingPage />} />
				<Route path="pricing" element={<PricingPage />} />
				<Route path="blog/:id" element={<BlogPage />} />
				<Route path="legal/privacy" element={<LegalPrivacyPage />} />
				<Route path="legal/terms" element={<LegalTermsPage />} />

				<Route path="dashboard" element={<PlatformDrawer />}>
					<Route index element={
						<PrivateRoute>
							<CalendarPage />
						</PrivateRoute>
					} />

					<Route path="*" element={
						<PrivateRoute>
							<CalendarPage />
						</PrivateRoute>
					} />

					<Route path="login" element={<LoginPage />} />
					<Route path="register" element={<RegisterPage />} />
					<Route path="forgot-password" element={<ForgotPasswordPage />} />
					<Route path="select-subscription" element={<SelectSubscriptionPage />} />

					<Route path="insights" element={
						<PrivateRoute>
							<InsightsPage />
						</PrivateRoute>
					} />

					<Route path="library" element={
						<PrivateRoute>
							<LibraryPage />
						</PrivateRoute>
					} />

					<Route path="accounts" element={
						<PrivateRoute>
							<AccountsLoginPage />
						</PrivateRoute>
					} />

					<Route path="settings" element={
						<PrivateRoute>
							<AccountSettingsPage />
						</PrivateRoute>
					} />

					<Route path="support" element={
						<SupportPage />
					} />

					<Route path="changelog" element={
						<ChangelogPage />
					} />


					{/* CALLBACKS */}
					<Route path="_auth/youtube" element={
						<YoutubeServiceCallback />
					} />

					<Route path="_auth/tiktok" element={
						<TiktokServiceCallback />
					} />
				</Route>

				<Route path="*" element={<LandingPage />} />
			</Routes>

			<ToastContainer
				position="top-right"
				autoClose={4000}
				closeButton={false}
				hideProgressBar
				newestOnTop
				closeOnClick
				pauseOnFocusLoss={false}
				pauseOnHover={false}
				theme="light"
				transition={toastTransition}
				toastStyle={{
					// backgroundColor: '#1e1e1e',
					backgroundColor: '#f1f1f1',
					// background: 'linear-gradient(180deg, #ffffff 0%, #f1f1f1 100%)',
					// color: '#eee',
					color: '#222',
					fontSize: '.8rem',
					fontWeight: 600,
					borderRadius: '20px',
					padding: '0 10px',
					boxShadow: '0 15px 25px -10px rgba(0,0,0, .12)',
					zIndex: '9999999999 !important',
				}}
			/>
		</ThemeProvider>
	);
}
