/* Libraries */

/* Components */
import PageHeader from '../../assets/PageHeader/PageHeader';
import AccountPostingSettings from '../../components/AccountPostingSettings/AccountPostingSettings';
import AccountAdminSettings from '../../components/AccountAdminSettings/AccountAdminSettings';
import AccountUpdatePassword from '../../components/AccountUpdatePassword/AccountUpdatePassword';
import AccountDelete from '../../components/AccountDelete/AccountDelete';

/* MUI */
import { Box } from '@mui/system';
import { Divider, Typography } from '@mui/material';

/* Icons */

/* CSS */
import './AccountSettingsPage.css';


const subtitleStyled = {
	fontWeight: 600,
	fontSize: '1.3rem',
	marginBottom: '5px',
	color: '#333'
};

const dividerStyled = {
	marginBottom: '25px',
	backgroundColor: '#ddd',
};

export default function AccountSettingsPage() {
	return (
		<Box className='AccountSettingsPage-container'>
			<PageHeader title='Paramètres du compte' fullWidth sx={{ marginBottom: '35px' }} />

			<Box className='AccountSettingsPage-account-container'>
				<Box className='AccountSettingsPage-sub-container'>
					<Typography variant='h4' sx={subtitleStyled}>
						Paramètres administrateur
					</Typography>

					<Divider sx={dividerStyled} />

					<AccountAdminSettings />
				</Box>

				<Box className='AccountSettingsPage-sub-container'>
					<Typography variant='h4' sx={subtitleStyled}>
						Paramètres de posting
					</Typography>

					<Divider sx={dividerStyled} />

					<AccountPostingSettings />
				</Box>

				<Box className='AccountSettingsPage-sub-container'>
					<Typography variant='h4' sx={subtitleStyled}>
						Modifier le mot de passe
					</Typography>

					<Divider sx={dividerStyled} />

					<AccountUpdatePassword />
				</Box>

				<Box className='AccountSettingsPage-sub-container AccountSettingsPage-sub-container-red'>
					<Typography variant='h5' sx={{...subtitleStyled, color: 'red'}} >
						Supprimer mon compte
					</Typography>

					<Divider sx={dividerStyled} />

					<AccountDelete />
				</Box>
			</Box>
		</Box>
	);
}
