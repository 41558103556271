/* Libraries */
import { Fragment, useEffect, useState } from 'react';
import { FacebookLoginClient, LoginResponse } from '@greatsumini/react-facebook-login';
import { toast } from 'react-toastify';

/* Components */

/* Services */
import { SocialNetworkAccount, SocialNetworkAccountAuth, SocialNetworkName } from '../../services/socialNetworks.service.dto';
import { deleteSocialAccount, getSocialAccounts, updateSocialAccount } from '../../services/socialNetworks.service';
import {
	FACEBOOK_APP_ID,
	addAllFacebookMedias,
	createLongLivedFacebookAccessToken,
	getFacebookUser,
	getInstagramAccountsLinkedToFacebookPages,
} from '../../services/facebook.service';
import { convertValueToHumanReadable } from '../../services/_utils';

/* MUI */
import { Box } from '@mui/system';
import { Button, CircularProgress, IconButton, Skeleton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Tooltip from '@mui/material/Tooltip';

/* Icons */
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import LogoutIcon from '@mui/icons-material/Logout';

/* CSS */
import '../../pages/AccountsLoginPage/SocialAccountLogin.css';
import './FacebookLoginStep.css';


const facebookNameStyled = {
	color: 'black',
	fontWeight: 'bold',
	fontSize: {
		xs: '17px',
		md: '22px'
	}
};

const facebookEmailStyled = {
	color: 'black',
	fontWeight: 'thinner',
	fontSize: {
		xs: '13px',
		md: '15px'
	}
};

const instaAccountInfoStyled = {
	color: 'black',
	fontSize: {
		xs: '11px',
		md: '13px'
	}
};

const instaUsernameStyled = {
	color: 'black',
	fontWeight: 'bold',
	fontSize: {
		xs: '15px',
		md: '16px'
	}
};

const instaHighlightStyled = {
	color: '#0095f6',
	fontWeight: 'bold'
};

export default function FacebookLoginStep() {

	const [facebookState, setFacebookState] = useState<{
		facebook: SocialNetworkAccount,
		instagramAccounts: {
			account: SocialNetworkAccount,
			selected: boolean
		}[]
	}[]>([]);
	const [loadingFacebookAccount, setLoadingFacebookAccount] = useState(false);
	const [loadingInstagramAccounts, setLoadingInstagramAccounts] = useState(true);
	const [loadingInstagramSelection, setLoadingInstagramSelection] = useState(false);
	const [loadingFacebookState, setLoadingFacebookState] = useState(true);


	useEffect(() => {
		loadFacebookSDK();
	}, []);

	useEffect(() => {
		refreshFacebookState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	/**
	 * Load Facebook SDK
	 * @returns
	 * 		- void
	 */
	const loadFacebookSDK = async (): Promise<void> => {
		FacebookLoginClient.clear();
		await FacebookLoginClient.loadSdk('en_US', true);
		FacebookLoginClient.init({ appId: FACEBOOK_APP_ID, version: 'v16.0' });
	};

	const refreshFacebookState = async (): Promise<void> => {
		const accounts = await getSocialAccounts();
		if (!accounts) {
			setLoadingFacebookState(false);
			return ;
		}

		const newState: {
			facebook: SocialNetworkAccount;
			instagramAccounts: {
				account: SocialNetworkAccount;
				selected: boolean;
			}[];
		}[] = [];

		const facebookAccounts = accounts.filter(account => account.platformID === SocialNetworkName.FACEBOOK);

		for (const facebookAccount of facebookAccounts) {
			// get instagram accounts already in database
			const instagramAccounts = accounts.filter(account => account.platformID === SocialNetworkName.INSTAGRAM && account.relatedAccountID === facebookAccount.id);

			// fetched instagram accounts linked to facebook account
			const linkedInstagramAccounts = await handleFetchInstagramAccounts(facebookAccount) || [];

			newState.push({
				facebook: facebookAccount,
				instagramAccounts: [
					...instagramAccounts.map(account => ({
						account,
						selected: true
					})),
					...linkedInstagramAccounts.filter(linkedAccount => !instagramAccounts.find(account => account.accountID === linkedAccount.accountID)).map(account => ({
						account,
						selected: false
					}))
				]
			});
		}

		setFacebookState(newState);
		setLoadingFacebookState(false);
	};


	const createFacebookAuth = async (): Promise<SocialNetworkAccountAuth | null> => {
		const scope: string[] = [
			'public_profile',
			'email',
			'ads_management',
			'business_management',
			'instagram_basic',
			'instagram_content_publish',
			'pages_read_engagement',
			'pages_show_list',
			'catalog_management',
			'instagram_shopping_tag_products',
			'instagram_manage_insights'
		];

		const response: LoginResponse = await new Promise((resolve, reject) => {
			return FacebookLoginClient.login((res: LoginResponse) => {
				resolve(res);
			}, { scope: scope.join(','), enable_profile_selector: true });
		})

		if (response?.authResponse?.accessToken && response.authResponse.accessToken !== '') {
			return await createLongLivedFacebookAccessToken(response.authResponse.accessToken);
		} else {
			// TODO: set error
			console.error('FacebookLoginClient.login error', response);
		}
		return null;
	};

	const handleFetchInstagramAccounts = async (facebookAccount: SocialNetworkAccount): Promise<SocialNetworkAccount[] | null> => {
		setLoadingInstagramAccounts(true);

		const accessToken = await facebookAccount.token();
		if (!accessToken) {
			setLoadingInstagramAccounts(false);
			return null;
		}

		let res: SocialNetworkAccount[] = [];

		const linkedInstagramAccounts = await getInstagramAccountsLinkedToFacebookPages(accessToken) || [];
		for (const account of linkedInstagramAccounts) {
			res.push(new SocialNetworkAccount({
				platformID: SocialNetworkName.INSTAGRAM,
				accountID: account.id,
				username: account.username,
				relatedAccountID: facebookAccount.id,
				data: {
					...account,
				}
			}))
		}

		setLoadingInstagramAccounts(false);
		return res;
	};

	const handleFacebookLogin = async (): Promise<void> => {

		setLoadingFacebookAccount(true);

		/**
		 * @description
		 * Login with Facebook and create access token
		 */
		const auth = await createFacebookAuth();
		if (!auth) {
			setLoadingFacebookAccount(false);
			toast.error('La connexion à votre compte Facebook a échoué');
			return;
		}

		const user = await getFacebookUser(auth.accessToken);
		if (!user) {
			setLoadingFacebookAccount(false);
			toast.error('La connexion à votre compte Facebook a échoué');
			return;
		}

		await updateSocialAccount(new SocialNetworkAccount({
			accountID: user.id,
			username: user.name,
			platformID: SocialNetworkName.FACEBOOK,
			auth: auth,
			data: {
				user: {
					...user || {}
				}
			}
		}));

		toast.info('Vous pouvez vous connecter à vos comptes Instagram');

		await refreshFacebookState();
		setLoadingFacebookAccount(false);
	};

	const handleFacebookLogout = async (id: string) => {
		setLoadingFacebookAccount(true);

		FacebookLoginClient.logout(() => {});

		await deleteSocialAccount(id);
		setFacebookState((prev) => prev && prev.filter((acc) => acc.facebook.id !== id));
		setLoadingFacebookAccount(false);
	};

	const handleInstagramAccountSelection = async (account: SocialNetworkAccount) => {
		setLoadingInstagramSelection(true);
		const selected = !facebookState.find((acc) => acc.facebook.id === account.relatedAccountID)?.instagramAccounts.find((acc) => acc.account.id === account.id)?.selected;

		if (selected) {
			await updateSocialAccount(account);

			// add all published medias from instagram account to database
			await addAllFacebookMedias(account.accountID);
		} else {
			await deleteSocialAccount(account.id);
		}

		setFacebookState((prev) => {
			const newState = prev.map((acc) => {
				if (acc.facebook.id === account.relatedAccountID) {
					return {
						...acc,
						instagramAccounts: acc.instagramAccounts.map((acc) => {
							if (acc.account.id === account.id) {
								return {
									...acc,
									selected: selected,
								};
							}
							return acc;
						}),
					};
				}
				return acc;
			});

			return newState;
		});

		setLoadingInstagramSelection(false);
	};


	if (loadingFacebookState) {
		return (
			<Box sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100px'
			}}>
				<CircularProgress disableShrink size={20} color='primary' />
			</Box>
		);
	}

	return (
		<Box className='SocialAccountLogin-container'>
			<Box className='SocialAccountLogin-connection-container'>
				{ facebookState.length === 0 && (
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}>
						<Typography variant="body1" sx={{
							marginBottom: '20px',
						}}>
							Afin de vous connecter avec Instagram, vous devez d'abord vous connecter avec votre compte Facebook.
						</Typography>
		
						<LoadingButton
							className="FacebookLoginStep-login-button"
							variant="contained"
							color="primary"
							size="large"
							loading={loadingFacebookAccount}
							onClick={handleFacebookLogin}
						>
							Se connecter avec Facebook
						</LoadingButton>
					</Box>
				)}

				{ facebookState.length > 0 && (
					<Fragment>
						<Box sx={{ flexGrow: 1 }} />

						<Button
							className='FacebookLoginStep-login-button'
							variant='contained'
							color='primary'
							onClick={handleFacebookLogin}
							startIcon={<AddCircleOutlineIcon />}
						>
							Connecter un autre compte
						</Button>
					</Fragment>
				)}
			</Box>


			{ facebookState.length > 0 && (
				<Box className='SocialAccountLogin-items-container'>
				{ facebookState.map((account, index: number) => (
					<Box className='SocialAccountLogin-item-container' key={index}>
						<Box className='SocialAccountLogin-profile-header'>
							<img className='SocialAccountLogin-profile-picture'
								src={account.facebook.data.user.picture.data.url}
								alt={account.facebook.username}
							/>

							<Box className='SocialAccountLogin-profile-infos'>
								<Typography variant="h5" sx={facebookNameStyled}>
									{account.facebook.username}
								</Typography>

								<Typography variant="h4" sx={facebookEmailStyled}>
									{account.facebook.data.user.email}
								</Typography>
							</Box>

							<Box sx={{ flexGrow: 1 }}></Box>

							<Box className='SocialAccountLogin-profile-buttons'>
								<LoadingButton
									size='small'
									variant="contained"
									color="primary"
									loading={loadingFacebookAccount}
									startIcon={<LogoutIcon />}
									onClick={() => handleFacebookLogout(account.facebook.id)}
								>
									Déconnexion
								</LoadingButton>
							</Box>
						</Box>

						<Box>
							{ loadingInstagramAccounts && (
								<IconButton
									sx={{
										width: 'fit-content',
										backgroundColor: '#ddd',
										'&:hover': {
											backgroundColor: '#ddd',
										},
									}}
									color="primary"
									size='small'
									onClick={() => handleFetchInstagramAccounts(account.facebook)}
								>
									<CircularProgress disableShrink size={19} color='primary' />
								</IconButton>
							)}

							{ !loadingInstagramAccounts && (
								<Tooltip title='Recharger la liste des comptes Instagram' placement='right' arrow>
									<IconButton
										sx={{
											width: 'fit-content',
											backgroundColor: '#ddd',
											'&:hover': {
												backgroundColor: '#ddd',
											},
										}}
										color="primary"
										size='small'
										onClick={() => handleFetchInstagramAccounts(account.facebook)}
										// loading={loadingInstagramAccounts}
									>
										<RefreshIcon />
									</IconButton>
								</Tooltip>
							)}
						</Box>

						{/* Instagram accounts */}
						<Box className='SocialAccountLogin-accounts-list-container'>
							{/* display skeletons on loading */}
							{ loadingInstagramAccounts && (
								<Box className='SocialAccountLogin-account-container'>
									<Skeleton variant="rounded" width={250} height={50} />
								</Box>
							)}

							{/* display Instagram accounts */}
							{ account.instagramAccounts.length > 0 && account.instagramAccounts.map((instagramAccount, instagramAccountIndex: number) => {
								return (
									<Box className={'SocialAccountLogin-account-container' + (instagramAccount.selected ? ' FacebookLoginStep-account-selected' : '')}
										key={'SocialAccountLogin-account-container-' + instagramAccountIndex}
									>
										<img className='SocialAccountLogin-account-profile-picture'
											src={instagramAccount.account.data.profile_picture_url}
											alt="instagram profile"
										/>

										<Box className='SocialAccountLogin-account-profile-infos-container'>
											<Typography variant="h4" sx={instaUsernameStyled}>
												{instagramAccount.account.data.username}
											</Typography>

											{/* profile infos */}
											<Box className='SocialAccountLogin-account-profile-infos'>
												<Typography variant="h4" sx={instaAccountInfoStyled}>
													<span style={instaHighlightStyled}>
														{convertValueToHumanReadable(instagramAccount.account.data.followers_count)}
													</span> followers
												</Typography>

												<Typography variant="h4" sx={instaAccountInfoStyled}>
													<span style={instaHighlightStyled}>
														{convertValueToHumanReadable(instagramAccount.account.data.media_count)}
													</span> posts
												</Typography>

												<Typography variant="h4" sx={instaAccountInfoStyled}>
													<span style={instaHighlightStyled}>
														{convertValueToHumanReadable(instagramAccount.account.data.follows_count)}
													</span> following
												</Typography>
											</Box>
										</Box>

										{ loadingInstagramSelection && (
											<IconButton>
												<CircularProgress disableShrink size={15} color='primary' />
											</IconButton>
										)}

										{ !loadingInstagramSelection && (
											<Tooltip
												title={instagramAccount.selected ? "Supprimer ce compte" : "Utiliser ce compte"}
												placement="top"
											>
												<IconButton
													color={instagramAccount.selected ? 'error' : 'success'}
													size='small'
													onClick={() => handleInstagramAccountSelection(instagramAccount.account)}
												>
													{ instagramAccount.selected && <ClearIcon /> }
													{ !instagramAccount.selected && <AddIcon /> }
												</IconButton>
											</Tooltip>
										)}
									</Box>
								);
							})}
						</Box>
					</Box>
					))}
				</Box>
			)}
		</Box>
	);
}
