/* Libraries */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

/* Components */

/* Services */
import { BlogPostDTO } from '../../services/blog.service.dto';
import { getSortedBlogPosts } from '../../services/blog.service';

/* MUI */
import { Box, Typography } from '@mui/material';

/* CSS */
import './BlogSection.css';


const titleStyled = {
	fontSize: { xs: 23, md: 27},
	fontWeight: 600,
}

export default function BlogSection() {

	const [relatedPosts, setRelatedPosts] = useState<BlogPostDTO[] | null>([]);
	const [relatedPostsLoading, setRelatedPostsLoading] = useState(false);

	useEffect(() => {
		loadBlogPosts();
		// eslint-disable-next-line
	}, []);


	const loadBlogPosts = async () => {
		setRelatedPostsLoading(true);
		const posts = await getSortedBlogPosts(3);

		setRelatedPosts(posts);
		setRelatedPostsLoading(false);
	};


	return (
		<Box className="BlogSection-container">
			<Typography variant="h2" className="BlogSection-title" sx={titleStyled}>
				Encore mieux qu'un tuto TikTok
			</Typography>

			<Box className='BlogSection-articles-container'>
			{ !relatedPostsLoading && relatedPosts && relatedPosts.map((article, index) => (
				<Box key={`BlogSection-article-${index}`}
					className='BlogSection-single-article-container'
					style={{ textDecoration: 'none' }}
					component={Link}
					to={`/blog/${article.id}`}
				>
					<Box className='BlogSection-single-article-img-container'>
						<Box className="BlogSection-single-article-img"
							sx={{
								backgroundImage: `url('${article.heroImageUrl}')`,
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
							}}
						/>
					</Box>

					<Box className="BlogSection-single-article-text-container">
						<Typography variant='h6' className="BlogSection-single-article-text">
							{article.blogTitle}
						</Typography>

						<Typography className='BlogSection-single-article-date' variant='h6'>
							{article.created_in.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' })}
						</Typography>
					</Box>
				</Box>
			))}

			{ relatedPostsLoading && new Array(3).fill(null).map((_, index) => (
				<Box className='BlogSection-single-article-container'>
					<Box className='BlogSection-single-article-img-container'>
						<Box className="BlogSection-single-article-img" />
					</Box>

					<Box className="BlogSection-single-article-text-container">
						<Typography variant='h6' className="BlogSection-single-article-text">
							Chargement...
						</Typography>

						<Typography className='BlogSection-single-article-date' variant='h6'>
							...
						</Typography>
					</Box>
				</Box>
			))}
			</Box>
		</Box>
	);
}
