/* Libraries */
import { v4 as uuidv4 } from 'uuid';

/* Services */
import { getSocialAccountByAccountId } from './socialNetworks.service';
import { getFileById, getFilesById } from './clientStorage.service';

/* Types */
import { SocialAccount, SocialNetworkAccount, SocialNetworkName, SocialPlatformEventType } from "./socialNetworks.service.dto";
import { FileAvailabilityDTO, FileDTO } from './clientStorage.service.dto';
import { TiktokPrivacyLevelPrivate, TiktokPrivacyLevelPublic } from './tiktok.service.dto';


/**
 * @description
 * interface when an event is generated by the calendar
 */
// export interface GeneratedEvent<T extends SocialNetworkName> {
export interface GeneratedEvent {
	/**
	 * @description
	 * event unique id
	 */
	id?: string; // uuid

	/**
	 * @description
	 * platform id
	 */
	platformID?: SocialNetworkName | '';

	/**
	 * @description
	 * Date of the event
	 */
	date?: Date;

	/**
	 * @description
	 * files ids
	 * 
	 * @see FileDTO.id
	 */
	filesIds?: string[];

	/**
	 * @description
	 * account id
	 * 
	 * @see
	 * SocialNetworkAccountData.accountID
	 */
	accountID?: string;
}

export interface IInstagram {
	platformID: SocialNetworkName.INSTAGRAM;
	metadata: {
		tags: string[];
	};
}

export interface ITiktok {
	platformID: SocialNetworkName.TIKTOK;
	metadata: {
		privacyLevel: TiktokPrivacyLevelPublic | TiktokPrivacyLevelPrivate;
		discloseVideoContent: boolean;
		brand_content_toggle: boolean;
		brand_organic_toggle: boolean;
		allowComment: boolean;
		allowDuet: boolean;
		allowStitch: boolean;
	};
}

export interface IYoutube {
	platformID: SocialNetworkName.YOUTUBE;
	metadata: {
		// videoLength: number;
	};
}

export type SocialPlatformMetadata = IInstagram | ITiktok | IYoutube;

/**
 * @description
 * interface of a complete event
 * 
 * @var T
 * metadata type of the event that depends on the platform
 */
// export interface CalendarEventData<T extends SocialPlatformMetadata> extends GeneratedEvent<T['platformID']> {
export interface CalendarEventData extends GeneratedEvent {
	/**
	 * @description
	 * title of the event
	 * 
	 * @note
	 * (e.g. the title of a YouTube video)
	 */
	title?: string;

	/**
	 * @description
	 * description of the event
	 * 
	 * @note
	 * (e.g. the description of a YouTube video or the caption of an Instagram post)
	 */
	description?: string;

	/**
	 * @description
	 * type of the event
	 */
	type?: SocialPlatformEventType | '';

	/**
	 * @description
	 * if the event has been scheduled or not
	 */
	status?: CalendarEventStatus;

	/**
	 * @description
	 * if the event has been published, the url of the post
	 */
	permalink?: string;

	/**
	 * @description
	 * postID of the event once published on the platform
	 */
	postID?: string;

	/**
	 * @description
	 * if the event is sponsored or not
	 */
	isSponsored?: boolean;

	/**
	 * @description
	 * thumbnailID of the event
	 */
	thumbnailID?: string;

	/**
	 * @description
	 * metadata of the event, depends on the platform
	 */
	metadata?: Record<string, any>; // T['metadata']
}


export enum CalendarEventStatus {
	/**
	 * @description
	 * the event is being processed or has no defined status
	 */
	PROCESSING = 0,

	/**
	 * @description
	 * the event has been scheduled
	 */
	SCHEDULED = 1,

	/**
	 * @description
	 * the event is being published
	 */
	PUBLISHING = 2,

	/**
	 * @description
	 * the event has been published
	 */
	PUBLISHED = 3,

	/**
	 * @description
	 * the event has failed to be published
	 */
	ERROR_PUBLISHING = 4
}


export interface IPostRepartitionByAccount {
	account: SocialAccount;
	files: FileAvailabilityDTO[];
	platformID: SocialNetworkName;
	priority: number;
	postCount: number;
}


/**
 * @description
 * interface for the event modal
 */
export type EventModalType = {
	open: boolean,
	account: SocialAccount | null,
	event: Partial<CalendarEvent>,
}


/**
 * @description
 * default event modal data
 * 
 * @note
 * used to init & reset the modal
 */
export const defaultEventModal: EventModalType = {
	open: false,
	account: null,
	event: {},
}


export interface EventSchedulableResponse {
	isSchedulable: boolean;
	errorMsg?: string;
}


/**
 * @description
 * class of a complete event
 */
// export class CalendarEvent<T extends SocialPlatformMetadata> {
export class CalendarEvent {
	id: string;
	platformID: SocialNetworkName | ''; // T['platformID']
	date: Date;
	accountID: string;
	filesIds: string[];
	title: string;
	description: string;
	type: SocialPlatformEventType | '';
	status: CalendarEventStatus;
	permalink: string;
	postID: string;
	isSponsored: boolean;
	thumbnailID: string;
	metadata: Record<string, any>; // T['metadata']

	constructor(post: CalendarEventData = {}) {
		this.id = post.id  || uuidv4();
		// this.platformID = post.platformID || '' as T['platformID'];
		this.platformID = post.platformID || '';
		this.date = post.date || new Date();
		this.accountID = post.accountID || '';
		this.filesIds = post.filesIds || [];
		this.title = post.title || '';
		this.description = post.description || '';
		this.type = post.type || '';
		this.status = post.status || CalendarEventStatus.PROCESSING;
		this.permalink = post.permalink || '';
		this.postID = post.postID || '';
		this.isSponsored = post.isSponsored || false;
		this.thumbnailID = post.thumbnailID || '';
		this.metadata = post.metadata || {};
	}

	eventData(): CalendarEventData {
		return {
			id: this.id,
			platformID: this.platformID,
			date: this.date,
			accountID: this.accountID,
			filesIds: this.filesIds,
			title: this.title,
			description: this.description,
			type: this.type,
			status: this.status,
			permalink: this.permalink,
			postID: this.postID,
			isSponsored: this.isSponsored,
			thumbnailID: this.thumbnailID,
			metadata: this.metadata
		}
	}

	/**
	 * @description
	 * get the social network account of the event
	 */
	async account(): Promise<SocialNetworkAccount | null> {
		return await getSocialAccountByAccountId(this.accountID);
	}

	/**
	 * @description
	 * get the files of the event
	 */
	async getFiles(): Promise<FileDTO[] | null> {
		return await getFilesById(this.filesIds);
	}

	/**
	 * @description
	 * get the thumbnail of the event
	 */
	async getThumbnail(): Promise<FileDTO | null> {
		return await getFileById(this.thumbnailID);
	}

	/**
	 * @description
	 * check if the event is schedulable
	 * 
	 * @returns 
	 */
	async isSchedulable(fileList?: Partial<FileDTO>[]): Promise<EventSchedulableResponse> {
		const files = fileList || await this.getFiles();
		if (!files) {
			return {
				isSchedulable: false,
				errorMsg: 'No files found',
			};
		}

		const filesCount = files.length;
		let isSchedulable = true;
		let errorMsg = undefined;

		switch (this.platformID) {
			case SocialNetworkName.INSTAGRAM:
				const imageMaxSize = 8 * 1000 * 1000; // 8Mb
				const imageMinWidth = 320;
				const imageMaxWidth = 1440;
				const imageMinRatio = 4 / 5;
				const imageMaxRatio = 1.91;

				const isReel = filesCount === 1;
				const videoMaxWidth = 1920;
				
				/**
				 * The max size & ratio of a video depends on the type of post:
				 * - 1 file (reel): 1Gb & 0.01 to 10 ratio
				 * - 2+ files (carousel): 100Mb per video & 4/5 to 16/9 ratio
				*/
				let videoMaxSize = 100 * 1000 * 1000; // 100Mb
				let videoMinRatio = 4 / 5;
				let videoMaxRatio = 16 / 9;
				let videoMinDuration = 3; // 3s
				let videoMaxDuration = 60; // 60s
				if (isReel) {
					videoMaxSize = 1000 * 1000 * 1000; // 1Gb
					videoMinRatio = 0.01;
					videoMaxRatio = 10;
					videoMinDuration = 3; // 3s
					videoMaxDuration = 15 * 60; // 15min
				}

				for (const file of files) {

					/**
					 * Check for an image
					 */
					if (file.metadata?.contentType?.includes('image')) {

						/**
						 * Check the size of the image
						 */
						if (file.metadata?.size > imageMaxSize) {
							isSchedulable = false;
							errorMsg = `Image too big (max ${imageMaxSize / 1000 / 1000}Mb)`;
							break;
						}

						/**
						 * Check dimensions of the image
						 */
						if (file.basicMetadata?.width && file.basicMetadata?.height) {
							const ratio = file.basicMetadata.width / file.basicMetadata.height;

							if (ratio < imageMinRatio || ratio > imageMaxRatio) {
								isSchedulable = false;
								errorMsg = `Image ratio out of range (4/5 to ${imageMaxRatio}:1)`;
								break;
							}

							/**
							 * Check the width of the image
							 */
							if (file.basicMetadata.width < imageMinWidth || file.basicMetadata.width > imageMaxWidth) {
								isSchedulable = false;
								errorMsg = `Image width out of range (${imageMinWidth}px to ${imageMaxWidth}px)`;
								break;
							}
						}

					/**
					 * Check for a video
					 */
					} else if (file.metadata?.contentType?.includes('video')) {

						/**
						 * Check the size of the video
						 */
						if (file.metadata?.size > videoMaxSize) {
							isSchedulable = false;
							errorMsg = `Video too big (max ${isReel ? '1Gb' : '100Mb'})`;
							break;
						}

						/**
						 * Check the duration of the video
						 */
						if (file.basicMetadata?.duration) {
							const duration = file.basicMetadata.duration;

							if (duration < videoMinDuration || duration > videoMaxDuration) {
								isSchedulable = false;
								errorMsg = `Video duration out of range (${videoMinDuration}s to ${videoMaxDuration / 60}min)`;
								break;
							}
						}

						/**
						 * Check dimensions of the video
						 */
						if (file.basicMetadata?.width && file.basicMetadata?.height) {
							const ratio = file.basicMetadata.width / file.basicMetadata.height;

							/**
							 * Check the ratio of the video
							 */
							if (ratio < videoMinRatio || ratio > videoMaxRatio) {
								isSchedulable = false;
								errorMsg = `Video ratio out of range (${videoMinRatio.toFixed(2)}:1 to ${videoMaxRatio.toFixed(2)}:1)`;
								break;
							}

							/**
							 * Check the width of the video
							 */
							if (file.basicMetadata.width > videoMaxWidth) {
								isSchedulable = false;
								errorMsg = `Video width too big (max ${videoMaxWidth}px)`;
								break;
							}
						}
					} else {
						isSchedulable = false;
						errorMsg = 'Invalid file type';
						break;
					}
				}
				break;

			case SocialNetworkName.TIKTOK:
				const tiktokImageMaxSize = 20 * 1000 * 1000; // 20Mb
				const tiktokImageMaxWidth = 1920;
				const tiktokImageMaxHeight = 1080;
				const tiktokVideoMinDimension =  360;
				const tiktokVideoMaxDimension = 4096;
				const tiktokVideoMaxSize = 4 * 1000 * 1000 * 1000; // 4Gb

				// TODO: use max_video_post_duration_sec from TikTok API
				const tiktokVideoMaxDuration = 3 * 60; // 3min

				// max of 35 images in a carousel
				if (filesCount > 35) {
					isSchedulable = false;
					errorMsg = 'Too many files';
					break;
				}

				for (let i = 0; i < filesCount; i++) {
					const width = files[i].basicMetadata?.width;
					const height = files[i].basicMetadata?.height;
					const size = files[i].metadata?.size;
					const duration = files[i].basicMetadata?.duration;

					if (!width || !height) {
						isSchedulable = false;
						errorMsg = 'Invalid file';
						break;
					}

					if (files[i]?.metadata?.contentType?.includes('image')) {

						if (size && size > tiktokImageMaxSize) {
							isSchedulable = false;
							errorMsg = `Image too big (max ${tiktokImageMaxSize / 1000 / 1000}Mb)`;
							break;
						}

						if (width > tiktokImageMaxWidth || height > tiktokImageMaxHeight) {
							isSchedulable = false;
							errorMsg = `Image dimensions too big (max ${tiktokImageMaxWidth}x${tiktokImageMaxHeight})`;
							break;
						}

					} else if (files[i]?.metadata?.contentType?.includes('video')) {

						// if there is a video in a carousel
						if (i > 0) {
							isSchedulable = false;
							errorMsg = 'Video in carousel not allowed';
							break;
						}

						if (size && size > tiktokVideoMaxSize) {
							isSchedulable = false;
							errorMsg = `Video too big (max ${tiktokVideoMaxSize / 1000 / 1000}Mb)`;
							break;
						}
						if (duration && duration > tiktokVideoMaxDuration) {
							isSchedulable = false;
							errorMsg = `Video too long (max ${tiktokVideoMaxDuration / 60}min)`;
							break;
						}
						if (width < tiktokVideoMinDimension || height < tiktokVideoMinDimension) {
							isSchedulable = false;
							errorMsg = `Video dimensions too small (min ${tiktokVideoMinDimension}px)`;
							break;
						}
						if (width > tiktokVideoMaxDimension || height > tiktokVideoMaxDimension) {
							isSchedulable = false;
							errorMsg = `Video dimensions too big (max ${tiktokVideoMaxDimension}px)`;
							break;
						}
					}
				}
				break

			case SocialNetworkName.YOUTUBE:
				const youtubeVideoMaxSize = 256 * 1000 * 1000 * 1000; // 256Gb

				if (filesCount > 1) {
					isSchedulable = false;
					errorMsg = 'Only one file allowed';
					break;
				}

				if (files[0]?.metadata?.contentType?.includes('image')) {
					isSchedulable = false;
					errorMsg = 'Image not allowed';
				} else if (files[0]?.metadata?.contentType?.includes('video')) {
					if (files[0].metadata?.size > youtubeVideoMaxSize) {
						isSchedulable = false;
						errorMsg = `Video too big (max ${youtubeVideoMaxSize / 1000 / 1000 / 1000}Gb)`;
						break;
					}
				} else {
					isSchedulable = false;
					errorMsg = 'Invalid file type';
				}
				break;

			default:
				break;
		}

		return {
			isSchedulable,
			errorMsg,
		};
	}

	/**
	 * @description
	 * merge the current event with the given one
	 * 
	 * @param post
	 * event to merge with
	 * 
	 * @note
	 * the given event has priority over the current one
	 */
	merge(post: CalendarEvent): void {
		this.id = post.id || this.id;
		this.platformID = post.platformID || this.platformID;
		this.date = post.date || this.date;
		this.accountID = post.accountID || this.accountID;
		this.filesIds = [
			...post.filesIds,
			...this.filesIds.filter((fileID) => !post.filesIds.includes(fileID)) // avoid duplicates
		];
		this.title = post.title || this.title;
		this.description = post.description || this.description;
		this.type = post.type || this.type;
		this.status = post.status || this.status;
		this.permalink = post.permalink || this.permalink;
		this.postID = post.postID || this.postID;
		this.isSponsored = post.isSponsored ?? this.isSponsored;
		this.thumbnailID = post.thumbnailID || this.thumbnailID;
		this.metadata = post.metadata || this.metadata;
	}

	/**
	 * @description
	 * shallow merge the current event with the given one
	 * filesIds, tags and partnerships are replaced by the given ones
	 * 
	 * @param post
	 * event to merge with
	 * 
	 * @note
	 * the given event has priority over the current one
	 */
	shallowMerge(post: CalendarEvent): void {
		this.id = post.id || this.id;
		this.platformID = post.platformID || this.platformID;
		this.date = post.date || this.date;
		this.accountID = post.accountID || this.accountID;
		this.filesIds = post.filesIds.length > 0 ? post.filesIds : this.filesIds;
		this.title = post.title || this.title;
		this.description = post.description || this.description;
		this.type = post.type || this.type;
		this.status = post.status || this.status;
		this.permalink = post.permalink || this.permalink;
		this.postID = post.postID || this.postID;
		this.isSponsored = post.isSponsored ?? this.isSponsored;
		this.thumbnailID = post.thumbnailID || this.thumbnailID;
		this.metadata = post.metadata || this.metadata;
	}
}
