/* Libraries */
import { functions } from '../config/firebase.config';
import { httpsCallable } from 'firebase/functions';

/* Services */
import { deleteSocialAccount, getSocialAccountTokenByAccountId, getSocialAccountTokenById } from "./socialNetworks.service";

/* Types */
import { TiktokAccessToken, TiktokCreatorInfoQueryResponse, TiktokErrorCode, TiktokUserResponse, TiktokVideo, TiktokVideoListResponse } from './tiktok.service.dto';
import { CalendarEvent, CalendarEventStatus } from './calendar.service.dto';
import { SocialNetworkName } from './socialNetworks.service.dto';
import { addCalendarPosts, getCalendarPostsByAccountID } from './calendar.service';


/**
 * @description
 * tiktok base url.
 * used in tiktok api calls
 */
export const TIKTOK_BASE_URL = `https://open.tiktokapis.com`;


/**
 * @description
 * TikTok client key
 */
export const TIKTOK_CLIENT_KEY = 'awf5elt91t4s3njg';


/**
 * @description
 * update the user's TikTok data in the database
 */
export const tiktokLogout = async (id: string): Promise<void> => {
	await deleteSocialAccount(id);
}


/**
 * @description
 * revoke the TikTok access to the app
 * 
 * @param accountID
 * the TikTok account id to revoke the access from
 */
export const revokeTiktokAccess = async (accountID: string): Promise<void> => {
	const accessToken = await getSocialAccountTokenByAccountId(accountID);
	if (!accessToken) {
		console.log('no access token')
		return ;
	}

	const tiktokRevokeToken = httpsCallable(functions, 'tiktokRevokeTokenV2');
	await tiktokRevokeToken({ accessToken: accessToken });
}


/**
 * @description
 * refresh the TikTok access token
 * 
 * @returns
 * the new TikTok access token
 */
export const refreshTiktokAccessToken = async (refreshToken: string): Promise<TiktokAccessToken | null> => {
	if (!refreshToken) {
		return null;
	}

	const tiktokRefreshToken = httpsCallable(functions, 'tiktokRefreshTokenV2');
	try {
		const req = await tiktokRefreshToken({ refreshToken: refreshToken });
		console.log('tiktokRefreshToken', req.data);
		const response = req.data as TiktokAccessToken;
		return response;
	} catch (error) {
		return null;
	}
}


/**
 * @description
 * get the TikTok channel infos
 */
export const getTiktokUserInfos = async (id: string): Promise<TiktokUserResponse | null> => {
	const accessToken = await getSocialAccountTokenById(id);
	if (!accessToken) {
		return null;
	}

	const tiktokGetUserInfos = httpsCallable(functions, 'tiktokGetUserInfos');
	try {
		const userInfos = await tiktokGetUserInfos({ accessToken: accessToken });
		return userInfos.data as TiktokUserResponse;
	} catch (error) {
		// TODO: check response & return null or undefined on error
		return null;
	}
}


const getTiktokVideos = async (id: string, cursor?: number): Promise<TiktokVideoListResponse | null> => {
	const accessToken = await getSocialAccountTokenById(id);
	if (!accessToken) {
		return null;
	}

	const tiktokGetUserVideos = httpsCallable(functions, 'tiktokGetUserVideos');
	try {
		const userVideos = await tiktokGetUserVideos({ accessToken: accessToken, cursor: cursor?.toString() || '' });
		return userVideos.data as TiktokVideoListResponse;
	} catch (error) {
		// TODO: check response & return null or undefined on error
		console.log('userVideos: error', error);
		return null;
	}
}


export const getAllTiktokVideos = async (id: string): Promise<TiktokVideo[] | null> => {
	if (!id) {
		console.log('getAllTiktokVideos: no id');
		return null;
	}

	let res: TiktokVideoListResponse | null = await getTiktokVideos(id);
	if (!res) {
		return null;
	}

	const videos: TiktokVideo[] = res.data.videos;

	while (res.data.has_more === true) {
		const nextRes: TiktokVideoListResponse | null = await getTiktokVideos(id, res.data.cursor);
		if (!nextRes) {
			return null;
		}
		videos.push(...nextRes.data.videos);
		res = nextRes;
	}

	return videos;
}


export const getTiktokQueryCreatorInfos = async (id: string): Promise<TiktokCreatorInfoQueryResponse['data'] | null> => {
	const accessToken = await getSocialAccountTokenById(id);
	if (!accessToken) {
		return null;
	}

	const tiktokQueryCreatorInfos = httpsCallable(functions, 'tiktokQueryCreatorInfos');
	try {
		const res = await tiktokQueryCreatorInfos({ accessToken: accessToken });
		const data = res.data as TiktokCreatorInfoQueryResponse;

		if (!data || data.error.code !== TiktokErrorCode.ok) {
			return null;
		}
		return data.data;
	} catch (error) {
		console.log('error', error);
		return null;
	}
}


export const addAllTiktokVideos = async (id: string, accountID: string): Promise<void> => {
	const videos = await getAllTiktokVideos(id);
	if (!videos) {
		return ;
	}

	const events: CalendarEvent[] = videos.map((video: TiktokVideo): CalendarEvent => {
		const event: CalendarEvent = new CalendarEvent({
			platformID: SocialNetworkName.TIKTOK,
			date: new Date(video.create_time * 1000),
			accountID: accountID,
			title: video.title,
			description: video.video_description,
			filesIds: [video.cover_image_url],
			permalink: video.share_url,
			status: CalendarEventStatus.PUBLISHED,
			postID: video.id
		});

		return event;
	});

	const calendarPosts = await getCalendarPostsByAccountID(accountID);
	if (!calendarPosts) {
		await addCalendarPosts(events);
	} else {
		const filteredEvents = events.filter((event) => {
			return !calendarPosts.find((post) => {
				return (
					post.accountID === event.accountID &&
					post.date.getTime() === event.date.getTime() &&
					post.platformID === event.platformID &&
					post.description === event.description
				);
			});
		});

		await addCalendarPosts(filteredEvents);
	}
}
