/* Libraries */

/* Components */
import TypeformButton from '../../assets/TypeformButton/TypeformButton';

/* MUI */
import { Box, Typography } from '@mui/material';

/* CSS */
import './HeroSection.css';


const titleStyled = {
	fontSize: {
		xs: 40,
		sm: 60,
		md: 80,
		// lg: 100,
		// xl: 120
	},
	fontWeight: 700,
	textAlign: "center",
	pb: {
		xs: '40px',
		md: '50px'
	},
};

const subtitleStyled = {
	fontSize: {
		xs: 15,
		sm: 17,
		md: 21,
	},
	fontWeight: 500,
	textAlign: "center",
	width: '70%'
}

const partnersStyled = {
	color: "#ddd",
	fontSize: {
		xs: 13,
		sm: 18,
	},
	fontWeight: 600,
};


export default function HeroSection() {

	const icons = [
		{
			src: '/icons/icons8-instagram-94.png',
			alt: "Instagram"
		},
		{
			src: '/icons/icons8-tiktok-94.png',
			alt: "TikTok"
		},
		{
			src: '/icons/youtube_social_icon_red.png',
			alt: "Youtube"
		},
		{
			src: '/icons/icons8-facebook-94.png',
			alt: "Facebook"
		},
	];


	return (
		<Box className="HeroSection-container">
			<Typography variant="h1" component="h1" sx={titleStyled}>
				Maximise ta présence avec l'IA
			</Typography>

			<Box sx={{
				display: 'flex',
				flexDirection: { xs: 'column', md: 'row' },
				justifyContent: { xs: 'center', md: 'center' },
				// alignItems: { xs: 'center', md: 'flex-start' },
				gap: { xs: '50px', md: '0' },
				mt: { xs: '40px', md: '60px' },
				padding: { xs: '0 20px', md: '0 40px' },
			}}>
				<Box sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '20px',
					width: { xs: '100%', md: '50%' },

				}}>
					<Typography variant="h3" component="h3" sx={subtitleStyled}>
						FREEKL est une plateforme d'optimisation conçue pour les créateurs de contenu, offrant toute la puissance de l'intelligence artificielle pour la planification de contenu.
					</Typography>

					<Box sx={{mt: { xs: '100px', md: '120px' }}}>
						<TypeformButton>
							Wow, montre-moi en plus !
						</TypeformButton>
					</Box>
				</Box>

				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: { xs: '100%', md: '50%' },

				}}>
					<img style={{
						width: '100%',
						height: 'auto',
					}}
						src="/images/maximize-your-ia-presence.png"
						alt="Illustration"
					/>
				</Box>
			</Box>

			<Box className='HeroSection-partners-container'>
				<Typography variant="h4" component="h4" sx={partnersStyled}>
					Ils nous font confiance
				</Typography>

				<Box className="HeroSection-icons-container">
				{ icons.map((icon) => (
					<img className="HeroSection-icon"
						key={icon.alt}
						src={icon.src}
						alt={icon.alt}
						width={46}
					/>
				))}
				</Box>
			</Box>
		</Box>
	);
}
