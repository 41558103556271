/* MUI */
import { Box, Typography } from '@mui/material';

/* Components */
import SocialAccountSelection from '../../SocialAccountSelection/SocialAccountSelection';
import Modal from '../../../assets/Modal/Modal';

/* DTO */
import { SocialAccount } from '../../../services/socialNetworks.service.dto';
import { CreateNewEventModalProps } from './CreateNewEventModalDTO';

/* Styles */
import './CreateNewEventModal.css'


const titleStyle = {
	fontWeight: 700,
	textAlign: 'center',
	marginBottom: '16px',
};

export default function CreateNewEventModal({
	open,
	handleClose,
	openNewEventModal,
}: CreateNewEventModalProps) {
	return (
		<Modal
			open={open}
			enableWidthFitContent
			setClose={handleClose}
			sx={{
				backgroundColor: '#eee',
			}}
		>
			<Typography variant="h5" color='primary' sx={titleStyle}>
				Sélectionnez un compte
			</Typography>

			<Box sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: '22px',
			}}>
				<SocialAccountSelection
					onClick={(account: SocialAccount) => {
						openNewEventModal(account);
						handleClose();
					}}
				/>
			</Box>
		</Modal>
	);
}
