/* LIBRAIRIES */

/* MUI */
import { Box, Grow, IconButton, Modal as MuiModal } from '@mui/material';

/* DTO */
import ModalDTO from './ModalDTO';

/* Icons */
import CloseIcon from '@mui/icons-material/Close';

/* CSS */
import './Modal.css';


/**
 * Modal component
 * @param {boolean} props.open -> status of the modal : open or close
 * @param {function} props.setClose -> open or close the modal function
 * @param {boolean} props.disableInnerPadding -> padding for the children (false by default)
 * @param {boolean} props.disableAnimation -> disable the animation (true by default), if true the modal is not unmouted when closed
 * @param {string} props.className -> custom class for the modal
 * @param {SxProps} props.sx -> custom style for the modal (will override any other style applied before)
 * @param {any} props.children -> children of the modal
 * @returns {JSX.Element} -> Modal component
 */

export default function Modal(props: ModalDTO): JSX.Element {

	const {
		open,
		setClose,
		disableInnerPadding = false,
		disableAnimation = true,
		enableWidthFitContent = false,
		disableBackdropClick = false,
		className = '',
		sx,
		children
	} = props;


	const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown'): void => {
		if (disableBackdropClick && reason === 'backdropClick') {
			return;
		}

		setClose();
	}


	const internalComponent = () => {
		return (
			<Box className={`Modal-container ${className} ${enableWidthFitContent && "Modal-container-fit-content"}`} sx={sx}>
				<Box className='Modal-close-btn-container'>
					<IconButton
						className='Modal-close-btn'
						size='small'
						onClick={() => setClose()}
					>
						<CloseIcon className='Modal-close-btn-icon' />
					</IconButton>
				</Box>

				<Box className={`Modal-children-container ${disableInnerPadding && "Modal-children-container-no-padding"}`}>
					{ children }
				</Box>
			</Box>
		);
	};


	if (disableAnimation) {
		return (
			<MuiModal
				open={open}
				onClose={handleClose}
				disableAutoFocus
			>
				{internalComponent()}
			</MuiModal>
		);
	}

	return (
		<Grow in={open}>
			<MuiModal
				open={true}
				onClose={handleClose}
				disableAutoFocus
			>
				{internalComponent()}
			</MuiModal>
		</Grow>
	);
}
