import { createTheme } from '@mui/material';

export const theme = createTheme({
	typography: {
		fontSize: 12,
		fontFamily: [
			'Inter',
			// 'DM Sans',
			'ui-sans-serif',
			'system-ui',
			'-apple-system',
			'BlinkMacSystemFont',
			'Helvetica',
			'Arial',
			'Roboto',
			'IBM Plex Sans',
			'Segoe UI',
			'sans-serif',
			'Apple Color Emoji',
			'Segoe UI Emoji',
			'Segoe UI Symbol',
		].join(','),
		h1: {
			color: '#fff',
			fontWeight: 1000,
		},
		h2: {
			color: '#fff',
			fontWeight: 700,
		},
		h3: {
			color: '#fff',
			fontWeight: 600,
		},
		h4: {
			color: '#fff',
			fontWeight: 400,
			fontSize: '20px',
		},
		h5: {
			color: '#fff',
			fontWeight: 400,
			fontSize: '19px',
		},
		h6: {
			color: '#fff',
			fontWeight: 300,
			fontSize: '12px',
		},
		body1: {
			color: '#000',
		},
		body2: {
			color: '#fff',
		},
		subtitle1: {

		},
		subtitle2: {

		},
		caption: {

		},
	},
	palette: {
		mode: 'light',
		background: {
			default: '#eee',
			// paper: '#eee',
		},
		primary: {
			main: '#000',
			light: '#000',
			dark: '#eee',
		},
		secondary: {
			main: '#fff',
			light: '#fff',
			dark: '#000',
		},
		info: {
			main: '#0096FF',
			light: '#0096FF',
			dark: '#0096FF',
			contrastText: '#fff',
		},
		error: {
			main: '#f00',
			light: '#f00',
			dark: '#f00',
			contrastText: '#fff',
		},
		success: {
			main: '#7A9D54',
			light: '#7A9D54',
			dark: '#7A9D54',
			contrastText: '#fff',
		},
		warning: {
			main: '#FD8D14',
			light: '#FD8D14',
			dark: '#FD8D14',
			contrastText: '#fff',
		},
	},
	components: {
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
				style: {
					textTransform: 'none',
				},
			},
		},
		MuiButtonGroup: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					borderRadius: '10px',
					'& .MuiButtonGroup-grouped': {
						borderRadius: '10px',
						'&:not(:first-of-type)': {
							borderLeft: 'none',
						},
					},
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: 500,
					transition: '.15s ease-in-out',
					boxShadow: '0 1px 1px rgba(0,0,0, .2)',
					WebkitBoxShadow: '0 1px 1px rgba(0,0,0, .2)',
					'&:hover': {
						boxShadow: '0 1px 1px rgba(0,0,0, .2)',
						WebkitBoxShadow: '0 1px 1px rgba(0,0,0, .2)',
					},
				},
				sizeSmall: {
					borderRadius: '10px',
					padding: '6px 11px',
					fontSize: '11px',
				},
				sizeMedium: {
					borderRadius: '13px',
					padding: '8px 18px',
					fontSize: '12px',
					// boxShadow: '0 5px 16px rgba(0,0,0, .08)',
					// WebkitBoxShadow: '0 5px 16px rgba(0,0,0, .08)',
				},
				sizeLarge: {
					borderRadius: '15px',
					padding: '12px 30px',
					fontSize: '14px',
					boxShadow: '0 5px 16px rgba(0,0,0, .2)',
					WebkitBoxShadow: '0 5px 16px rgba(0,0,0, .2)',
				},
				text: {
					backgroundColor: 'transparent',
					boxShadow: 'none',
					WebkitBoxShadow: 'none',
					'&:hover': {
						backgroundColor: 'transparent',
						boxShadow: 'none',
						WebkitBoxShadow: 'none',
					},
				},
				textPrimary: {
					color: '#000',
					'&:hover': {
						color: '#666',
					},
					'&:active': {
						color: '#aaa',
					},
				},
				textSecondary: {
					color: '#000',
					'&:hover': {
						color: '#666',
					},
					'&:active': {
						color: '#aaa',
					},
				},
				outlined: {
					backgroundColor: '#fff',
				},
				outlinedPrimary: {
					color: '#000',
					borderColor: '#888',
					backgroundColor: '#fff',
					'&:hover': {
						color: '#444',
						backgroundColor: '#f5f5f5',
						borderColor: '#888',
					},
					'&:active': {
						color: '#888',
						borderColor: '#bbb',
					}
				},
				outlinedSecondary: {
					color: '#333',
					borderColor: '#bbb',
					backgroundColor: '#fff',
					'&:hover': {
						color: '#444',
						borderColor: '#888',
					},
					'&:active': {
						color: '#888',
						borderColor: '#bbb',
					}
				},
				containedPrimary: {
					backgroundColor: '#000',
					'&:hover': {
						backgroundColor: '#444',
					},
					'&:active': {
						backgroundColor: '#888',
					},
				},
				containedSecondary: {
					backgroundColor: '#f5f5f5',
					'&:hover': {
						backgroundColor: '#ddd',
					},
					'&:active': {
						backgroundColor: '#fff',
					},
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					boxShadow: '0 1px 1px rgba(0,0,0, .15)',
					WebkitBoxShadow: '0 1px 1px rgba(0,0,0, .15)',
					borderRadius: '6px',
				},
				rounded: {
					borderRadius: '12px',
				},
				outlined: {
					// border: '1px solid #ddd',
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					color: '#000',
					'& .MuiOutlinedInput-root': {
						borderRadius: '10px',
						boxShadow: '0 1px 1px rgba(0,0,0, .15)',
						WebkitBoxShadow: '0 1px 1px rgba(0,0,0, .15)',
					},
					'& label': {
						color: '#888',
					},
				},
			}
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					boxShadow: '0 15px 15px -20px rgba(0,0,0, .2)',
					WebkitBoxShadow: '0 15px 15px -20px rgba(0,0,0, .2)',
					// boxShadow: '0 1px 2px rgba(0,0,0, .1)',
					// WebkitBoxShadow: '0 1px 2px rgba(0,0,0, .1)',
					margin: '8px 0',
					backgroundColor: '#fff',
					'&.MuiPaper-root': {
						borderRadius: '10px',
					}
				},
			}
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-root': {
						borderRadius: '10px',
						boxShadow: '0 1px 1px rgba(0,0,0, .15)',
						WebkitBoxShadow: '0 1px 1px rgba(0,0,0, .15)',
					},
					'& label': {
						color: '#888',
					},
				},
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					color: '#000',
				},
			}
		},
		MuiSwitch: {
			styleOverrides: {
				root: {
					width: 41,
					height: 24,
					padding: 0,
				},
				thumb: {
					boxShadow: "0 2px 8px -2px rgb(0,0,0, .5)",
				},
				track: {
					borderRadius: 50,
					opacity: 1,
					backgroundColor: "#d5d5d5",
				},
				switchBase: {
					"&.Mui-checked": {
						color: "#fff",
						"& + .MuiSwitch-track": {
							opacity: 1,
						},
					},
				},
				sizeMedium: {
					width: 47,
					height: 26,

					'& .MuiSwitch-thumb': {
						width: 20,
						height: 20,
						padding: 0,
					},
					'& .MuiSwitch-switchBase': {
						padding: 3,
					},
					'& .MuiSwitch-track': {
						width: 47,
						height: 26,
						borderRadius: 50,
					}
				},
			}
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					disableRipple: true,
					// padding: '5px 9px',
					padding: '0px 9px',

					// disable hover background color
					'&:hover': {
						backgroundColor: 'transparent',
					},

					'& .MuiSvgIcon-fontSizeMedium': {
						fontSize: '22px',
					},

					'& .MuiSvgIcon-fontSizeSmall': {
						fontSize: '18px',
					},
				}
			},
		},
		MuiCircularProgress: {
			styleOverrides: {
				root: {
					animationDuration: '800ms',
				},
			}
		}
	},
});
