/* Libraries */
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react'
import moment from 'moment';
import 'moment/locale/fr'

/* Components */
import LibraryFileList from '../../LibraryFileList/LibraryFileList';
import ContentCarrousel from '../../ContentCarrousel/ContentCarrousel';
import ContentWrapper from '../../ContentWrapper/ContentWrapper';
import DuplicateEventButton from '../DuplicateEventButton/DuplicateEventButton';

/* Services */
import { CalendarEvent, CalendarEventData, CalendarEventStatus, EventSchedulableResponse } from '../../../services/calendar.service.dto';
import { deleteCalendarPost, generateBestPostingTime, updateCalendarPosts } from '../../../services/calendar.service';

/* MUI */
import { Box } from '@mui/system';
import { Avatar, Badge, Button, CircularProgress, Grow, IconButton, InputAdornment, MenuItem, Select, Skeleton, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

/* Icons */
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ImageIcon from '@mui/icons-material/Image';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { SiYoutubeshorts } from "react-icons/si";
import { SiYoutube } from "react-icons/si";

/* DTO */
import { PlanificationModalProps } from '../PlanificationModalDTO';
import { LibraryFileListFilterType } from '../../LibraryFileList/LibraryFileListDTO';
import { FileDTO } from '../../../services/clientStorage.service.dto';
import { YoutubePrivacyLevel } from '../../../services/youtube.service.dto';

/* CSS */
import '../PlanificationModal.css';
import './YoutubePlanification.css';


export default function YoutubePlanification(props: PlanificationModalProps) {

	const {
		// open,
		onClose,
		account,
		event,
		setEvent,
	} = props;

	const [data, setData] = useState<Partial<CalendarEvent>>(event || {});
	const preOpenFileSelector = data?.status === undefined ? true : false;
	const isDisabled = data?.status === CalendarEventStatus.PUBLISHED || data?.status === CalendarEventStatus.PUBLISHING; // || !data?.description;
	const isPublished = data?.status === CalendarEventStatus.PUBLISHED;
	const [files, setFiles] = useState<Partial<FileDTO>[]>([]);
	const [thumbnail, setThumbnail] = useState<FileDTO | null>(null);
	const [selectFilesModalOpen, setSelectFilesModalOpen] = useState(preOpenFileSelector);
	const [selectThumbnailModalOpen, setSelectThumbnailModalOpen] = useState(false);
	const [eventLoading, setEventLoading] = useState(false);
	const [isSchedulable, setIsSchedulable] = useState<EventSchedulableResponse & {loading: boolean}>({isSchedulable: true, errorMsg: '', loading: false});
	const [generatingBestPostingTime, setGeneratingBestPostingTime] = useState(false);

	const isShort = files.length === 1 && files[0].metadata?.contentType?.includes('video') && (files[0].metadata.size || 0) <= 100 * 1000 * 1000 && (files[0].basicMetadata?.duration || 0) <= 60 && (files[0].basicMetadata?.width || 0) <= 1080 && (files[0].basicMetadata?.height || 0) <= 1920 && (files[0].basicMetadata?.width || 0) * (16/9) === (files[0].basicMetadata?.height || 0) && (files[0].basicMetadata?.height || 0) * (9/16) === (files[0].basicMetadata?.width || 0);


	useEffect(() => {
		loadFiles();
		loadThumbnail();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		loadThumbnail();
		// eslint-disable-next-line
	}, [data.thumbnailID]);

	useEffect(() => {
		checkSchedulable();
		// eslint-disable-next-line
	}, [files]);


	const checkSchedulable = async () => {
		setIsSchedulable((prev) => {return {...prev, loading: true}});
		const ev = new CalendarEvent({
			...data,
			filesIds: files.map((file) => file.id || '').filter((id) => id !== ''),
		});
		const res = await ev.isSchedulable(files);
		setIsSchedulable({...res, loading: false});
	};

	const loadFiles = async () => {
		setEventLoading(true);

		// remove duplicates
		const filesIds = data.filesIds?.filter((fileId, index) => data.filesIds?.indexOf(fileId) === index) || [];

		// If the event has no files, we don't need to load them
		if (filesIds.length === 0) {
			setEventLoading(false);
			return ;
		}

		// if the fileIds are links to external files, we don't need to load them
		if (filesIds[0].startsWith('http')) {
			const files = filesIds.map((url) => {
				// get the file type from the url
				let type = 'image/jpeg';

				if (url.includes('.mp4')) {
					type = 'video/mp4';
				} else if (url.includes('.webm')) {
					type = 'video/webm';
				} else if (url.includes('.mov')) {
					type = 'video/mov';
				} else if (url.includes('.gif')) {
					type = 'image/gif';
				} else if (url.includes('.png')) {
					type = 'image/png';
				} else if (url.includes('.webp')) {
					type = 'image/webp';
				} else if (url.includes('.jpg')) {
					type = 'image/jpeg';
				} else if (url.includes('.jpeg')) {
					type = 'image/jpeg';
				} else if (url.includes('.svg')) {
					type = 'image/svg+xml';
				}

				return {
					url: url,
					metadata: {
						contentType: type,
					} as any,
				};
			});
			setFiles(files);
		} else {
			const tmpEvent = new CalendarEvent(data as CalendarEventData);
			const files = await tmpEvent.getFiles();

			if (files) {
				setFiles(files);
			}
		}
		setEventLoading(false);
	};

	const loadThumbnail = async () => {
		if (data) {
			const tmpEvent = new CalendarEvent(data as CalendarEventData);
			const thumb = await tmpEvent.getThumbnail();

			if (thumb) {
				setThumbnail(thumb);
			}
		}
	};

	const eventIsSavable = () => {
		return !!data?.title &&
				((!isShort && (!!data?.description && data?.description.length > 0)) || isShort) &&
				!!data?.date &&
				(data.description !== event.description ||
					data.title !== event.title ||
					moment(data.date).format('DD/MM/YYYY HH:mm') !== moment(event.date).format('DD/MM/YYYY HH:mm') ||
					data.filesIds?.length !== event.filesIds?.length ||
					data.filesIds?.some((fileId, index) => fileId !== event.filesIds?.[index]) ||
					data.thumbnailID !== event.thumbnailID || 
					data?.metadata?.notifySubscribers !== event?.metadata?.notifySubscribers || 
					data?.metadata?.privacy !== event?.metadata?.privacy) &&
				!eventLoading && 
				isSchedulable.loading === false &&
				isSchedulable.isSchedulable;
	};

	const handleValidateSelection = (selectedFiles: FileDTO[]) => {
		setData({
			...data,
			filesIds: selectedFiles.map((file) => file.id),
		});
		setFiles(selectedFiles);
		handleCloseFileSelector();
	};

	const handleValidateThumbnailSelection = (selectedFiles: FileDTO[]) => {
		setData({
			...data,
			thumbnailID: selectedFiles?.[0]?.id,
		});

		if (selectedFiles.length > 0) {
			handleCloseThumbnailFileSelector();
		}
	};
	

	const handleOpenFileSelector = () => {
		setSelectFilesModalOpen(true);
	};

	const handleCloseFileSelector = () => {
		setSelectFilesModalOpen(false);
	};

	const handleOpenThumbnailFileSelector = () => {
		setSelectThumbnailModalOpen(true);
	};

	const handleCloseThumbnailFileSelector = () => {
		setSelectThumbnailModalOpen(false);
	};

	const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
	const handleEmojiPicker = () => {
		setEmojiPickerOpen((prev) => !prev);
	};

	const handleSaveEvent = async () => {
		setEventLoading(true);
		const newEvent = new CalendarEvent({
			...data,
			accountID: account?.account.accountID || '',
			platformID: account?.platformID || '',
		} as CalendarEventData);
		setEvent(newEvent);
		await updateCalendarPosts(newEvent);
		setEventLoading(false);
		onClose();
	};

	const handleDeleteEvent = async () => {
		if (data?.id) {
			setEventLoading(true);
			await deleteCalendarPost(data.id);
			setEvent({});
			// setData({});
			setEventLoading(false);
			onClose();
		} else {
			toast.warning('Impossible de supprimer un post non enregistré');
		}
	};


	return (
		<Box className='Planification-content-container'>
			<Box className='' sx={{
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '100%',
				maxHeight: '650px',
				height: 'fit-content',
				overflowY: 'auto',
				gap: '40px',
				paddingBottom: '35px',
			}}>
				{ selectFilesModalOpen && (
					<Box sx={{ width: '100%' }}>
						{ files.length > 0 && (
							<Box sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								position: 'sticky',
								top: 0,
								right: 0,
								margin: '0 0 18px 0',
								zIndex: 1001,
							}}>
								<Button
									onClick={handleCloseFileSelector}
									color='success'
									variant='contained'
									sx={{
										width: '90%',
										textTransform: 'none',
									}}
									startIcon={<CheckCircleIcon />}
								>
									Valider la sélection
								</Button>
							</Box>
						)}

						{ files.length === 0 && (
							<IconButton
								onClick={handleCloseFileSelector}
								color='primary'
								size='small'
								sx={{
									position: 'sticky',
									top: 0,
									right: 0,
									backgroundColor: '#666',
									zIndex: 1001,
								}}
								>
								<CloseIcon sx={{ color: '#eee' }} />
							</IconButton>
						)}

						<LibraryFileList
							onValidateSelection={handleValidateSelection}
							enforceSelectionMode
							disableSearchBar
							disableStorageProgress
							validateOnClick
							singleFileSelection
							filetypeFilterBy={LibraryFileListFilterType.VIDEO}
						/>
					</Box>
				)}

				{ selectThumbnailModalOpen && (
					<Box sx={{ width: '100%' }}>
						<IconButton
							onClick={handleCloseThumbnailFileSelector}
							color='primary'
							size='small'
							sx={{
								position: 'sticky',
								top: 0,
								right: 0,
								backgroundColor: '#666',
								zIndex: 1001,
							}}
						>
							<CloseIcon sx={{ color: '#eee' }} />
						</IconButton>

						<LibraryFileList
							onValidateSelection={handleValidateThumbnailSelection}
							singleFileSelection
							enforceSelectionMode
							disableSearchBar
							disableStorageProgress
							filetypeFilterBy={LibraryFileListFilterType.IMAGE}
							selectedFilesIDs={[data.thumbnailID || '']}
							validateOnClick
						/>
					</Box>
				)}


				{ !selectFilesModalOpen && !selectThumbnailModalOpen && (
					<Fragment>
						{ eventLoading && files.length === 0 && (
							<CircularProgress disableShrink size={25} color='primary' sx={{ mb: '50px' }}/>
						)}

						{ files.length > 0 && (
							<Box
								sx={{
									position: 'relative',
									display: 'flex',
									width: '100%',
									backgroundColor: '#000',
									borderRadius: '6px',
								}}
							>
								<ContentCarrousel files={files} />
							</Box>
						)}

						{ !isDisabled && (
							<Box className='Planification-infos-island'>
								<Button
									className='Planification-file-selector-button'
									variant='contained'
									color='success'
									disabled={eventLoading}
									startIcon={<ChangeCircleIcon />}
									onClick={handleOpenFileSelector}
								>
									Choisir des fichiers
								</Button>

								{ files.length === 1 && files[0].metadata?.contentType?.includes('video') && (
									<Button
										className='Planification-thumb-selector-button'
										variant='outlined'
										color='secondary'
										size='small'
										disabled={eventLoading}
										startIcon={<ImageIcon />}
										onClick={handleOpenThumbnailFileSelector}
									>
										Miniature
									</Button>
								)}
							</Box>
						)}
					</Fragment>
				)}
			</Box>

			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '15px',
			}}>
				<Box className='Planification-fields-container'>
					<Badge
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						badgeContent={<img src={account?.icon3D} width={28} alt={`${account?.platformID || ''}-logo`} />}
						sx={{
							'& .MuiBadge-badge': {
								top: '10px',
								right: '10px',
								background: account?.background || 'linear-gradient(210deg, #777, #000)',
								width: '36px',
								height: '36px',
								borderRadius: '13px',
								boxShadow: '0 8px 18px -6px rgba(0,0,0, .6)'
							}
						}}
					>
						<Box className='Planification-avatar-container'>
							<Avatar className='Planification-avatar'
								sx={{
									background: account?.background,
									padding: '4px',
									'& img': {
										borderRadius: '50%'
									}
								}}
								src={account?.account.pictureURL}
							/>

							<Typography className='Planification-avatar-name' color='secondary' variant='body1'>
								{account?.account.username}
							</Typography>
						</Box>
					</Badge>

					<Box className='Planification-field-item-container'>
						<Box sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: '10px'
						}}>
							{ isShort ? (
								<Fragment>
									<Typography className='Planification-field-item-title' variant='body1'>
										Short
									</Typography>
									<SiYoutubeshorts fontSize='large' className='Planification-media-type-icon' />
								</Fragment>
							) : (
								<Fragment>
									<Typography className='Planification-field-item-title' variant='body1'>
										Vidéo
									</Typography>
									<SiYoutube fontSize='medium' color='info' className='Planification-media-type-icon' />
								</Fragment>
							)}
						</Box>
					</Box>


					<Box className='Planification-field-item-container'>
						<Typography className='Planification-field-item-title' variant='body1'>
							Date de publication
						</Typography>

						<Box sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: '8px'
						}}>
							<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='fr'>
								<DateTimePicker
									disablePast
									disabled={isDisabled}
									slotProps={{ textField: { size: 'small' } }}
									value={ moment(data?.date) }
									onChange={(date) => {

										/**
										 * if the date is before now, we set the date to now
										 * this is to prevent the user from scheduling a post in the past
										*/
										if (moment(date).isBefore(moment().add(1, 'minute'))) {
											setData({
												...data,
												date: moment().add(1, 'minute').toDate()
											});
										} else {
											setData({
												...data,
												date: date?.toDate()
											});
										}
									}}
									/>
							</LocalizationProvider>

							{ !isDisabled && (
								<Tooltip title="Identifier l'instant optimal pour partager votre contenu." placement='top' arrow>
									<IconButton
										className='Planification-media-type-icon'
										color='primary'
										disabled={isDisabled || files.length === 0 || !account || generatingBestPostingTime}
										sx={{ borderRadius: '12px' }}
										onClick={() => {
											if (!account) {
												return ;
											}

											generateBestPostingTime(
												account,
												new CalendarEvent(data as CalendarEventData),
												setGeneratingBestPostingTime,
												(date) => {
													setData({
														...data,
														date: date
													});
												}
											);
										}}
									>
										{ !generatingBestPostingTime && <AutoFixHighIcon fontSize='small' /> }
										{ generatingBestPostingTime && <CircularProgress disableShrink color='info' size={15} /> }
									</IconButton>
								</Tooltip>
							)}
						</Box>
					</Box>

					<Box className='Planification-field-item-container'>
						<Typography className='Planification-field-item-title' variant='body1'>
							Titre
						</Typography>

						<TextField
							size='small'
							multiline
							fullWidth
							disabled={isDisabled}
							variant="outlined"
							// inputProps={{ maxLength: 2200 }}
							// helperText={
							// 	<Typography variant='caption' color='text.secondary' sx={{
							// 		fontFamily: 'IBM Plex Mono, Roboto Mono, monospace',
							// 	}}>
							// 		{data?.title?.length || 0} / 2200
							// 	</Typography>
							// }
							value={data?.title || ''}
							onChange={(e) => {
								setData({
									...data,
									title: e.target.value,
								});
							}}
						/>
					</Box>

					{ !isShort && (
						<Box className='Planification-field-item-container'>
							<Typography className='Planification-field-item-title' variant='body1'>
								Description
							</Typography>

							<TextField
								size='small'
								multiline
								fullWidth
								disabled={isDisabled}
								minRows={3}
								maxRows={10}
								variant="outlined"
								inputProps={{ maxLength: 2200 }}
								InputProps={{
									endAdornment: !isDisabled && (
										<InputAdornment position="end">
											<IconButton
												size='small'
												color={emojiPickerOpen ? 'secondary' : 'success'}
												sx={{
													backgroundColor: emojiPickerOpen ? '#999' : 'transparent',
													border: emojiPickerOpen ? '1px solid #000' : '1px solid #ccc',
												}}
												onClick={handleEmojiPicker}
											>
												<EmojiEmotionsIcon fontSize='small' />
											</IconButton>
										</InputAdornment>
									),
								}}
								helperText={
									<Typography variant='caption' color='text.secondary' sx={{
										fontFamily: 'IBM Plex Mono, Roboto Mono, monospace',
									}}>
										{data?.description?.length || 0} / 2200
									</Typography>
								}
								value={data?.description || ''}
								onChange={(e) => {
									setData({
										...data,
										description: e.target.value,
									});
								}}
							/>
						</Box>
					)}

					<Grow in={emojiPickerOpen} timeout={500} mountOnEnter unmountOnExit>
						<Box sx={{ margin: '0 auto' }}>
							<Picker
								data={emojiData}
								onEmojiSelect={(e: any) => {
									setData({
										...data,
										description: (data?.description || '') + e.native,
									});
								}}
								perLine={9}
								emojiButtonSize={30}
								emojiSize={22}
								previewPosition='none'
								maxFrequentRows={3}
							/>
						</Box>
					</Grow>

					<Box className='Planification-field-item-container'>
						<Typography className='Planification-field-item-title' variant='body1'>
							Qui peut voir cette vidéo
						</Typography>

						<Select
							fullWidth
							size='small'
							variant='outlined'
							disabled={isDisabled}
							value={data?.metadata?.privacy as YoutubePrivacyLevel || ''}
							onChange={(e) => {
								const privacy = e.target.value as YoutubePrivacyLevel;

								if (privacy !== YoutubePrivacyLevel.PUBLIC) {
									setData({
										...data,
										metadata: {
											...data.metadata,
											privacy: privacy,
											notifySubscribers: false,
										},
									});
								} else {
									setData({
										...data,
										metadata: {
											...data.metadata,
											privacy: privacy,
										},
									});
								}
							}}

							// change border radius
							sx={{
								borderRadius: '10px',
								textTransform: 'capitalize !important',
							}}
						>
							<MenuItem value={YoutubePrivacyLevel.PUBLIC}>
								Publique
							</MenuItem>

							<MenuItem value={YoutubePrivacyLevel.PRIVATE}>
								Privé
							</MenuItem>

							<MenuItem value={YoutubePrivacyLevel.UNLISTED}>
								Non répertorié
							</MenuItem>
						</Select>
					</Box>

					<Box className='Planification-field-item-container'>
						<Box sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: '10px',
							marginBottom: '4px',
						}}>
							<Typography className='Planification-field-item-title' variant='body1'>
								Notifier les abonnés
							</Typography>

							<Switch
								color="info"
								size='small'
								disabled={isDisabled || data?.metadata?.privacy !== YoutubePrivacyLevel.PUBLIC}
								checked={data?.metadata?.notifySubscribers as boolean || false}
								onChange={(e) => {
									setData({
										...data,
										metadata: {
											...data.metadata,
											notifySubscribers: e.target.checked,
										},
									});
								}}
							/>
						</Box>

						<Typography className='Planification-field-item-title' variant='body2' sx={{
							fontWeight: '600 !important',
							marginTop: '10px',
						}}>
							En publiant, vous acceptez les 
							<Link style={{ color: '#66d', textDecoration: 'none' }} to='https://www.youtube.com/t/terms' target='_blank'> Conditions d'utilisation </Link>
							de YouTube.
						</Typography>
					</Box>

					{ !!data.thumbnailID && (
						<Box className='Planification-field-item-container'>
							<Typography className='Planification-field-item-title' variant='body1'>
								Miniature
							</Typography>

							<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
								{ thumbnail && (
									<ContentWrapper files={[thumbnail]} style={{
										maxWidth: '120px',
										borderRadius: '10px',
										boxShadow: '0 20px 30px -15px rgba(0,0,0, .6)',
									}} />
								)}

								{ !thumbnail && (
									<Skeleton variant='rounded'
										animation='pulse'
										sx={{
											width: '120px',
											height: '120px',
											borderRadius: '10px',
											boxShadow: '0 20px 30px -15px rgba(0,0,0, .6)',
										}}
									/>
								)}
							</Box>
						</Box>
					)}

					<Box sx={{ flexGrow: 1 }} />

					{ !isDisabled && (
						<Fragment>
							{ !isSchedulable.loading && !isSchedulable.isSchedulable && (
								<Box className='Planification-is-schedulable-container'>
									<FeedbackIcon fontSize='medium' color='secondary' />

									<Typography variant='body2' color='secondary' className='Planification-is-schedulable-text'>
										{isSchedulable.errorMsg}
									</Typography>
								</Box>
							)}

							<Box className='Planification-action-buttons-container'>
								<Tooltip title="Supprimer l'évènement" placement='top'>
									<IconButton
										size='small'
										color='error'
										disabled={eventLoading}
										onClick={handleDeleteEvent}
									>
										<DeleteForeverIcon />
									</IconButton>
								</Tooltip>

								<LoadingButton
									fullWidth
									disabled={!eventIsSavable() || files.length === 0}
									loading={eventLoading}
									color='info'
									variant='contained'
									sx={{ fontWeight: 600 }}
									size='small'
									startIcon={<ScheduleSendIcon />}
									onClick={handleSaveEvent}
								>
									Planifier
								</LoadingButton>
							</Box>
						</Fragment>
					)}

					{ isPublished && (
						<Box className='Planification-action-buttons-container'>
							<Button
								component={Link}
								to={data?.permalink || '#'}
								disabled={!data?.permalink}
								target="_blank"
								fullWidth
								color='info'
								variant='contained'
								size='small'
								startIcon={<OpenInNewIcon />}
							>
								Voir le post
							</Button>
						</Box>
					)}
				</Box>

				<Box>
					{ !isPublished && (
						<DuplicateEventButton
							event={data}
							disabled={files.length === 0}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
}
