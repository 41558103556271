/* Libraries */

/* Components */
import TypeformButton from '../../assets/TypeformButton/TypeformButton';

/* MUI */
import { Box, Typography } from '@mui/material';

/* CSS */
import './SneakPeakSection.css';


const titleStyled = {
	color: '#222',
	fontWeight: 700,
	fontSize: { xs: 35, md: 45},
	width: '100%',
	textAlign: 'center',
	margin: '0 auto',
	maxWidth: {xs: '500px', md: '900px'},
};

export default function SneakPeakSection() {
	return (
		<Box className="SneakPeakSection-container">
			<Typography variant='h2' sx={titleStyled}>
				Un petit sneak peak de ce qui vous attend
			</Typography>

			<Box sx={{
				width: '100%',
				maxWidth: {xs: '600px', md: '1400px'},
				margin: '0 auto',
				padding: '30px 0',
			}}>
				<video width="100%" height="auto" controls loop autoPlay>
					<source src="/images/sneak-peak.mp4" type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</Box>

			<Box sx={{
				display: 'flex',
				justifyContent: 'center',
				padding: '40px 0 20px 0',
			}}>
				<TypeformButton size='large'>
					S'inscrire
				</TypeformButton>
			</Box>
		</Box>
	);
}
