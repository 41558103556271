/* Librairies */
import { useContext, useState } from 'react';
import moment from 'moment';

/* Components */

/* Services */
import { updateCalendarPosts } from '../../../services/calendar.service';

/* Context */
import { PlanificationCalendarContext } from '../PlanificationCalendar';

/* MUI */
import { Badge, Box, IconButton, Tooltip } from '@mui/material';

/* DTO */
import { CalendarActionType } from '../PlanificationCalendarDTO';
import { PlanificationCalendarDayProps } from './PlanificationCalendarDayDTO';
import { CalendarEvent } from '../../../services/calendar.service.dto';

/* Icons */
import AddIcon from '@mui/icons-material/Add';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ErrorIcon from '@mui/icons-material/Error';

/* Styles */
import './PlanificationCalendarDay.css';


const PlanificationCalendarDay = ({
	children,
	date,
	isEmpty,
	incompleteEvent,
	errorEvent,
	OnEmptyDayClick,
} : PlanificationCalendarDayProps) => {

	const {state, dispatch}: any = useContext(PlanificationCalendarContext);
	const dateInThePast = moment(date).isBefore(moment(), 'day');
	const [eventOnHover, setEventOnHover] = useState(false);


	const handleOnDrop = async () => {
		setEventOnHover(false);

		if (state.eventDragged) {
			const dateCopy = moment(date).set({
				hour: state.eventDragged.date.getHours(),
				minute: state.eventDragged.date.getMinutes(),
				second: state.eventDragged.date.getSeconds(),
			}).toDate();
			const eventCopy = new CalendarEvent(state.eventDragged);
			eventCopy.date = dateCopy;

			await updateCalendarPosts(eventCopy);
		}

		dispatch({type: CalendarActionType.DRAG_END, payload: {date: date}});
	};

	const handleOnDragOver = (ev: any) => {
		ev.preventDefault();
		ev.dataTransfer.dropEffect = 'move';

		setEventOnHover(true);
	};


	return (
		<Badge
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			badgeContent={
				<Tooltip placement="top" arrow title={
					errorEvent ? 'Erreur sur un événement' : incompleteEvent ? "Événement incomplet. Veuillez le modifier pour le publier." : ''
				}>
					<>
					{ errorEvent &&
						<ErrorIcon fontSize='small' sx={{ width: 12, height: 12 }} />
					}
					{ !errorEvent && incompleteEvent &&
						<PriorityHighIcon fontSize='small' sx={{ width: 12, height: 12 }} />
					}
					</>
				</Tooltip>
			}
			invisible={!incompleteEvent && !errorEvent}
			// variant="dot"
			color={ errorEvent ? 'error' : 'warning' }
		>
			<Box className={`PlanificationCalendar-day-container ${
				// add a class to the current day
				date.toDateString() === new Date().toDateString() ? 'PlanificationCalendar-day-container-current' : ''
			}`}>
				{ !isEmpty && !dateInThePast && (
					<Tooltip placement="top" arrow title="Ajouter un événement">
						<Box className="PlanificationCalendar-day-not-empty-add-container">
							<IconButton
								className="PlanificationCalendar-day-not-empty-add-button"
								onClick={() => OnEmptyDayClick()}
							>
								<AddIcon color='secondary' />
							</IconButton>
						</Box>
					</Tooltip>
				)}

				<Box className="PlanificationCalendar-day-header">
					<p className="PlanificationCalendar-day-header-day">
						{ date.toLocaleDateString('fr-FR', {weekday: 'short'}) }
					</p>

					<p className="PlanificationCalendar-day-header-date">
						{ date.toLocaleDateString('fr-FR', {
							day: '2-digit',
							month: 'short'
						})}
					</p>
				</Box>

				<Box className="PlanificationCalendar-day-separator" />

				<Box
					className="PlanificationCalendar-day-body"
					onDrop={handleOnDrop}
					onDragOver={handleOnDragOver}
					onDragLeave={() => setEventOnHover(false)}
					onDragEnd={() => setEventOnHover(false)}
				>
					{ eventOnHover && (
						<Box className="PlanificationCalendar-day-body-event"
							onDragOver={handleOnDragOver}
						/>
					)}

					{children}

					{ isEmpty && !dateInThePast && (
						<Box className="PlanificationCalendar-day-emptymessage-box">
							<IconButton
								className="PlanificationCalendar-day-emptymessage"
								onClick={() => OnEmptyDayClick()}
							>
								<AddIcon />
							</IconButton>
						</Box>
					)}
				</Box>
			</Box>
		</Badge>
	);
};

export default PlanificationCalendarDay;
