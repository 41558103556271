/* Libraries */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "../config/firebase.config";
import { toast } from "react-toastify";

/* Services */
import { expirationToTimestamp } from "./_utils";
import { SocialNetworkAccount, SocialNetworkName } from "./socialNetworks.service.dto";
import { updateSocialAccount } from "./socialNetworks.service";
import { addAllTiktokVideos } from "./tiktok.service";

/* DTO */
import { TiktokAccessToken, TiktokUserResponse } from "./tiktok.service.dto";

/* MUI */
import { Box, CircularProgress } from "@mui/material";


export default function TiktokServiceCallback() {
	const navigate = useNavigate();
	const location = useLocation();
	const [isCallbackHandled, setIsCallbackHandled] = useState(false);


	useEffect(() => {
		handleCallback();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const handleCallback = async () => {
		if (isCallbackHandled) {
			return;
		}

		setIsCallbackHandled(true);

		const params = new URLSearchParams(location.search);
		const code = params.get('code');

		if (!code) {
			console.log('no code', code);
			toast.warning('Une erreur est survenue, veuillez réessayer.');
			navigate('/dashboard/accounts');
			return ;
		}

		try {
			const tiktokCreateAccessToken = httpsCallable(functions, 'tiktokCreateAccessTokenV2');
			const request = await tiktokCreateAccessToken({ code: code });
			const res: any = request.data;

			if (res?.error) {
				console.log('errorResponse', res);
				toast.error('Une erreur est survenue, veuillez réessayer.');
			} else {
				const tokenResponse: TiktokAccessToken = res;
				console.log('tokenResponse', tokenResponse);

				const tiktokGetUserInfos = httpsCallable(functions, 'tiktokGetUserInfos');
				const userInfos = await tiktokGetUserInfos({ accessToken: tokenResponse.access_token });
				const userResponse = userInfos.data as TiktokUserResponse;

				const newUser = new SocialNetworkAccount({
					accountID: userResponse?.data.user.open_id,
					username: userResponse?.data.user.display_name,
					platformID: SocialNetworkName.TIKTOK,
					auth: {
						accessToken: tokenResponse.access_token,
						refreshToken: tokenResponse.refresh_token,
						accessTokenExpiration: expirationToTimestamp(tokenResponse.expires_in),
						refreshTokenExpiration: expirationToTimestamp(tokenResponse.refresh_expires_in),
					},
					data: {
						...userResponse?.data || {}
					}
				});

				await updateSocialAccount(newUser);
				await addAllTiktokVideos(newUser.id, newUser.accountID);

				toast.success('Connecté à TikTok.');
				navigate('/dashboard/accounts');
			}
		} catch (error) {
			// TODO: check response & return null or undefined on error
			toast.warning('Impossible de se connecter à TikTok pour le moment, veuillez réessayer.');
		}
	};


	return (
		<Box sx={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 'calc(100vh - 120px)'
		}}>
			<CircularProgress disableShrink size={30} />
		</Box>
	);
};
