/* Libraries */

/* Components */

/* CSS */
import './Logo.css';


interface LogoProps {
	width?: number;
}

export default function Logo(props: LogoProps) {

	const logo = "/icons/FREEKL_logo_hq.png";

	return (
		<img src={logo}
			alt="FREEKL Logo"
			width={ props.width ? props.width : 35 }
		/>
	);
}
