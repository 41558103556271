/* Libraries */
import { useEffect } from 'react';

/* Components */
import PageHeader from '../../assets/PageHeader/PageHeader';
import Modal from '../../assets/Modal/Modal';
import InstagramPlanification from './InstagramPlanification/InstagramPlanification';
import YoutubePlanification from './YoutubePlanification/YoutubePlanification';
import TiktokPlanification from './TiktokPlanification/TiktokPlanification';

/* Services */
import { SocialNetworkName } from '../../services/socialNetworks.service.dto';

/* MUI */

/* Icons */

/* DTO */
import { PlanificationModalProps } from './PlanificationModalDTO';
import { CalendarEventStatus } from '../../services/calendar.service.dto';

/* CSS */
import './PlanificationModal.css';


export default function PlanificationModal(props: PlanificationModalProps) {

	const {
		open,
		onClose,
		account,
		event,
		// setEvent,
		darkMode = false,
	} = props;


	useEffect(() => {
		if (!account) {
			onClose();
		}
		// eslint-disable-next-line
	}, [account]);


	return (
		<Modal
			open={open}
			setClose={onClose}
			className={`PlanificationModal-container ${darkMode ? 'PlanificationModal-container-dark' : ''}`}

			/**
			 * Enable click outside modal to close it only if event is published
			 */
			disableBackdropClick={event.status !== CalendarEventStatus.PUBLISHED}
		>
			<PageHeader title={`Votre post ${account?.name}`}
				sx={{
					// apply color to h2 child
					'& h2': {
						// apply color to h2 child only if darkMode is true
						color: darkMode ? '#ccc' : '#000',
					},
				}}
			/>

			{ account?.platformID === SocialNetworkName.INSTAGRAM && (
				<InstagramPlanification {...props} />
			)}

			{ account?.platformID === SocialNetworkName.YOUTUBE && (
				<YoutubePlanification {...props} />
			)}

			{ account?.platformID === SocialNetworkName.TIKTOK && (
				<TiktokPlanification {...props} />
			)}
		</Modal>
	);
}
