/* Libraries */
import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { toast } from 'react-toastify';

/* Components */

/* MUI */
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/* Icons */
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import WarningIcon from '@mui/icons-material/Warning';

/* CSS */
import './AccountDelete.css';


const deleteAccountDescriptionStyled = {
	fontWeight: 400,
	fontSize: '.85rem',
	marginBottom: '20px',
	color: '#333'
};

export default function AccountDelete() {

	const [account, setAccount] = useState<any>(null);
	const [email, setEmail] = useState('');
	const [openDialog, setOpenDialog] = useState(false);
	const [deleteIsLoading, setDeleteIsLoading] = useState(false);


	useEffect(() => {
		handleLoadAccount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const handleLoadAccount = async () => {
		const user = getAuth().currentUser;

		if (user) {
			setAccount(user);
		}
	};

	const handleDeleteAccount = async () => {
		setDeleteIsLoading(true);

		if (account) {
			/* Delete user's account */
			await account.delete()
				.then(() => {
					toast.success('Votre compte a bien été supprimé.');
				})
				.catch((error: any) => {
					toast.error(error.message);
				});
		}
		setDeleteIsLoading(false);
	};

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setEmail('');
	};


	return (
		<Box className='AccountDelete-account-container'>
			<Typography variant='body1' sx={deleteAccountDescriptionStyled}>
				Si vous supprimez votre compte, vous perdrez toutes vos données et vos fichiers seront supprimés.
			</Typography>

			<LoadingButton
				variant="contained"
				color='error'
				size='small'
				disabled={false}
				loading={false}
				onClick={handleOpenDialog}
				startIcon={<WarningIcon />}
			>
				Supprimer mon compte
			</LoadingButton>

			<Dialog
				open={openDialog}
				onClose={handleCloseDialog}
				sx={{
					'& .MuiDialog-paper': {
						width: '100%',
						maxWidth: '500px',
						borderRadius: '8px',
						padding: '15px 10px',
						backgroundColor: '#222',
					},
				}}
			>
				<DialogTitle sx={{
					color: 'red',
					fontWeight: 700,
					fontSize: '1.3rem',
				}}>
					Supprimer mon compte
				</DialogTitle>

				<DialogContent>
					<DialogContentText sx={{ color: '#ddd' }}>
						Pour supprimer votre compte, vous devez saisir votre adresse email pour confirmer votre choix.
					</DialogContentText>

					<br />

					<DialogContentText sx={{ color: '#ddd', fontWeight: 750 }}>
						Cette action est irréversible.
					</DialogContentText>


					<DialogContentText sx={{
						color: '#ddd',
						fontWeight: 300,
						fontSize: '0.72rem',
						margin: '25px 0 8px 0',
					}}>
						Email à saisir - <span style={{
							fontFamily: 'monospace',
							fontWeight: 500,
						}}>{account?.email || ''}</span>
					</DialogContentText>

					<TextField
						margin="dense"
						label="Adresse email"
						autoComplete='off'
						fullWidth
						variant="outlined"
						type='email'
						value={email}
						size='small'
						sx={{
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: '#666',
								},
								'&:hover fieldset': {
									borderColor: '#888',
								},
								'&.Mui-focused fieldset': {
									borderColor: '#888',
								},
							}
						}}
						InputProps={{ style: { color: '#ddd' } }}
						InputLabelProps={{ style: { color: '#ddd' } }}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</DialogContent>

				<DialogActions>
					<LoadingButton
						variant="contained"
						onClick={handleDeleteAccount}
						disabled={account?.email !== email}
						loading={deleteIsLoading}
						size='small'
						color='error'
						startIcon={<DeleteForeverIcon />}
					>
						Supprimer mon compte
					</LoadingButton>

					<Button
						variant="contained"
						size='small'
						onClick={handleCloseDialog}
					>
						Annuler
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
