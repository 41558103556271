/* Libraries */
import { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

/* Components */
import Logo from '../../assets/Logo/Logo';

/* MUI */
import { Box } from '@mui/system';
import { Button, Divider, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';

/* Icons */

/* CSS */
import './LoginPage.css';


const loginTitleStyled = {
	color: 'black',
	fontWeight: 'bold',
	fontSize: {
		xs: '1.5rem',
		sm: '2rem'
	}
};

export default function LoginPage() {

	const [auth, authIsLoading] = useAuthState(getAuth());
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loginIsLoading, setLoginIsLoading] = useState(false);

	const [error, setError] = useState({
		code: '',
		message: ''
	});


	const handleLogin = async (e: any) => {
		e.preventDefault();
		setLoginIsLoading(true);
		setError({code: '', message: ''});

		const auth = getAuth();
		await signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				if (userCredential.user?.emailVerified === false) {
					setError({
						code: 'auth/email-not-verified',
						message: 'Email not verified'
					});
				} else {
					setEmail('');
					setPassword('');
				}
			})
			.catch((error) => {
				setError({
					code: error.code,
					message: error.message
				});
			})
			.finally(() => {
				setLoginIsLoading(false);
			});
	};


	if (auth && auth.emailVerified) {
		return (
			<Navigate to='/dashboard' />
		)
	};

	if (authIsLoading) {
		return (
			<Box className='LoginPage-container'>
				<Box className='LoginPage-form-container'>
					<Box className='LoginPage-title-container'>
						<Logo width={50} />

						<Typography variant='h4' sx={loginTitleStyled}>
							Connexion
						</Typography>
					</Box>

					<CircularProgress disableShrink size={25} />
				</Box>
			</Box>
		)
	}

	return (
		<Box className='LoginPage-container'>
			<form className='LoginPage-form-container' onSubmit={handleLogin}>
				<Box className='LoginPage-title-container'>
					<Logo width={50} />

					<Typography variant='h4' sx={loginTitleStyled}>
						Connexion
					</Typography>
				</Box>

				<Box className='LoginPage-fields-container'>
					<TextField
						variant="outlined"
						size='small'
						type='email'
						label='Email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						InputLabelProps={{ style: { color: '#888' } }}
					/>

					<TextField
						variant="outlined"
						size='small'
						type='password'
						label='Mot de passe'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						InputLabelProps={{ style: { color: '#888' } }}
					/>
				</Box>

				<LoadingButton
					fullWidth
					variant="contained"
					size='large'
					color="primary"
					type='submit'
					onClick={handleLogin}
					loading={loginIsLoading}
					disabled={password.length < 6 || email.length < 6}
				>
					Se connecter
				</LoadingButton>

				{ error.code && (
					<Typography variant='body1' color='error'>
						Error: {error.code}
					</Typography>
				)}
			</form>


			<Button
				fullWidth
				variant="text"
				size='small'
				component={Link}
				to='/dashboard/forgot-password'
				sx={{ textTransform: 'none' }}
			>
				Mot de passe oublié ?
			</Button>

			<Divider orientation="horizontal" flexItem>
				<Typography variant='body1' color='textSecondary'>
					Ou
				</Typography>
			</Divider>

			<Button
				component={Link}
				to='/dashboard/register'
				fullWidth
				variant="text"
				size='small'
				sx={{ backgroundColor: '#f5f5f5' }}
			>
				Créer un compte
			</Button>
		</Box>
	);
}
