/* Libraries */
import { functions } from "../config/firebase.config";
import { httpsCallable } from "firebase/functions";
import moment from "moment";

/* Services */
import { getSocialAccountByAccountId } from "./socialNetworks.service";

/* Types */
import {
	FacebookFollowerDemographicResponse,
	FacebookFollowerDemographicRow,
	FacebookPagesDTO,
	InstagramAccountFieldsDTO,
	InstagramCarouselContainerDTO,
	InstagramImageContainerDTO,
	InstagramInsightResponse,
	InstagramMediaContainerResponseDTO,
	InstagramMediaContainerStatusCode,
	InstagramMediaContainerStatusDTO,
	InstagramMediaResponseDTO,
	InstagramMediaType,
	InstagramReelContainerDTO,
	InstagramUserMediaReponseDTO,
	InstagramVideoContainerDTO,
} from "./facebook.service.dto";
import { FileDTO } from "./clientStorage.service.dto";
import { SocialNetworkAccountAuth } from './socialNetworks.service.dto';
import { CalendarEvent, CalendarEventStatus } from "./calendar.service.dto";
import { addCalendarPosts, getCalendarPostsByAccountID } from "./calendar.service";
import { INSTAGRAM_ACCEPTED_FILETYPES } from "./clientStorage.service";


/**
 * @description
 * facebook app id.
 * used to initialize the facebook sdk and to make calls to the facebook api.
 */
export const FACEBOOK_APP_ID = '1275774289818051';


/**
 * @description
 * facebook api version.
 * used to in facebook api calls
 */
export const FACEBOOK_API_VERSION = 'v18.0';


/**
 * @description
 * facebook base url.
 * used to in facebook api calls
 */
export const FACEBOOK_BASE_URL = `https://graph.facebook.com/${FACEBOOK_API_VERSION}`;


/**
 * @description
 * Get Facebook access token
*/
export const getFacebookUser = async (accessToken: string): Promise<Record<string, any> | null> => {
	const fields = [
		'id',
		'name',
		'email',
		'picture'
	];

	try {
		const req = await fetch(`${FACEBOOK_BASE_URL}/me?fields=${fields.join(',')}&access_token=${accessToken}`);
		const response = await req.json();

		if (response?.error) {
			return null;
		}

		return response;
	} catch (error) {
		return null;
	}
}


/**
 * @description
 * In order to get the instagram accounts linked to the facebook user,
 * we need to get the facebook pages linked to that user first.
 */
export const getFacebookUserPages = async (accessToken: string) : Promise<FacebookPagesDTO> => {
	return await fetch(`${FACEBOOK_BASE_URL}/me/accounts?access_token=${accessToken}`)
		.then((response) => response.json());
}


/**
 * @description
 * Create a long-lived Facebook access token from an access token
 * 
 * @param accessToken
 * 		string: Facebook access token
 * 
 * @returns
 * 		void
 */
export const createLongLivedFacebookAccessToken = async (accessToken: string): Promise<SocialNetworkAccountAuth | null> => {
	const facebookCreateLongLivedAccessToken = httpsCallable(functions, 'facebookCreateLongLivedAccessToken');

	try {
		const response = await facebookCreateLongLivedAccessToken({ accessToken: accessToken });

		if (!response.data) {
			console.error('createLongLivedFacebookAccessToken(): response is empty', response);
			return null;
		}
		return response.data as SocialNetworkAccountAuth;
	} catch (error) {
		return null;
	}
}


/**
 * @description
 * This function will get the instagram account linked to the facebook page
 *
 * @param pageId
 * the facebook page id
 * @param accessToken
 * the facebook access token
 */
export const getInstagramAccountLinkedToFacebookPage = async (pageId: string, accessToken: string): Promise<InstagramAccountFieldsDTO | null> => {

	if (accessToken === '' || pageId === '') {
		return null;
	}

	const facebookPage = await fetch(`${FACEBOOK_BASE_URL}/${pageId}?fields=instagram_business_account&access_token=${accessToken}`)
	.then((response) => response.json());
	const { instagram_business_account } = facebookPage;

	if (instagram_business_account) {
		const { id } = instagram_business_account;
		const fields = [
			'id',
			'username',
			'profile_picture_url',
			'followers_count',
			'follows_count',
			'media_count'
		];

		return await fetch(`${FACEBOOK_BASE_URL}/${id}?fields=${fields.join(',')}&access_token=${accessToken}`)
		.then((response) => response.json());
	}
	return null;
}


/**
 * @description
 * This function will get all the instagram accounts linked to the facebook user
 */
export const getInstagramAccountsLinkedToFacebookPages = async (accessToken: string): Promise<InstagramAccountFieldsDTO[] | null> => {
	const facebookPages = await getFacebookUserPages(accessToken);
	const { data, error } = facebookPages;

	if (error) {
		console.error('error', error);
		return null;
	}

	if (!data) {
		return null;
	}

	const instagramAccounts = await Promise.all(
		data.map(async (page) => {
			const account: InstagramAccountFieldsDTO | null = await getInstagramAccountLinkedToFacebookPage(page.id, accessToken);

			if (account) {
				return account;
			} else {
				return null;
			}
		}
	));

	return instagramAccounts.filter((account: InstagramAccountFieldsDTO | null) => account !== null) as InstagramAccountFieldsDTO[];
}


export const getInstagramMediaType = (content: FileDTO[]): InstagramMediaType | undefined => {

	if (content.length === 0) {
		return undefined;
	} else if (content.length > 1) {
		return InstagramMediaType.CAROUSEL;
	}

	const file = content[0];
	const fileType = file?.metadata?.contentType || '';
	const types = INSTAGRAM_ACCEPTED_FILETYPES;
	const videoTypes = types.filter((type) => type.includes('video'));
	const imageTypes = types.filter((type) => type.includes('image'));

	if (videoTypes.includes(fileType || '')) {
		return InstagramMediaType.REELS;
	} else if (imageTypes.includes(fileType || '')) {
		return InstagramMediaType.IMAGE;
	}
	return undefined;
}

export const getInstagramMediaContainerStatus = async (
	containerID: string,
	accessToken: string
): Promise<InstagramMediaContainerStatusDTO | null> => {
	const fields = 'id,status,status_code';
	const url = `${FACEBOOK_BASE_URL}/${containerID}?fields=${fields}&access_token=${accessToken}`;

	const res = await fetch(url, { method: 'GET' })
		.then((res) => res.json())
		.catch((err) => {
			console.error('checkInstagramMediaContainerStatus(): err', err);
			return err;
		});

	console.log('checkInstagramMediaContainerStatus(): response', res);
	return res;
}

export const postInstagramMediaContainer = async (event: CalendarEvent): Promise<void> => {
	const res = await createInstagramContainer(event);

	if (!!res.error) {
		console.error('postInstagramMediaContainer()', res.error);
		return;
	}

	if (res.data && res.data.containerID && res.data.accessToken) {
		const mediaID = await publishInstagramMediaContainer(event.accountID, res.data.containerID, res.data.accessToken);

		if (mediaID) {
			const media = await getFacebookMedia(res.data.accessToken, mediaID);
			console.log('postInstagramMediaContainer(): media', media);
		}
	}
}

interface InstagramContainerCreationResponse {
	data?: {
		containerID: string;
		accessToken: string;
	};
	error?: string;
}

export const createInstagramContainer = async (event: CalendarEvent): Promise<InstagramContainerCreationResponse> => {
	const instagramUserID = event.accountID;

	const account = await event.account();
	if (!account) {
		return {
			error: 'postInstagramMediaContainer(): account is undefined'
		}
	}

	const accessToken = await account.token();
	if (!accessToken) {
		return {
			error: 'postInstagramMediaContainer(): accessToken is undefined'
		}
	}

	const content = await event.getFiles();
	if (!content) {
		return {
			error: 'postInstagramMediaContainer(): content is undefined'
		}
	}

	const media_type = getInstagramMediaType(content);
	console.log('createInstagramMediaContainer()', media_type);
	console.log('createInstagramMediaContainer(): content', content);

	switch (media_type) {
		case InstagramMediaType.IMAGE:
			const imageBody = createInstagramImageContainerBody({
				media_type: InstagramMediaType.IMAGE,
				access_token: accessToken,
				caption: event.description,
				image_url: content[0].url,
				is_carousel_item: false,
				product_tags: [],	// TODO: to get from user
			});

			const imageContainerCreationResponse = await createInstagramMediaContainer(instagramUserID, imageBody);
			if (!imageContainerCreationResponse.error && imageContainerCreationResponse.id) {
				return {
					data: {
						containerID: imageContainerCreationResponse.id,
						accessToken: accessToken
					}
				};
			}

			return {
				error: `Erreur: ${imageContainerCreationResponse.error?.error_user_msg}`
			};

		// video and reels are the same for now
		case InstagramMediaType.VIDEO:
		case InstagramMediaType.REELS:
			const reelBody = createInstagramReelContainerBody({
				media_type: InstagramMediaType.REELS,
				access_token: accessToken,
				audio_name: undefined,	// TODO: to get from user
				caption: event.description,
				cover_url: undefined,	// TODO: to get from user
				share_to_feed: true,	// TODO: to get from user
				thumb_offset: 0,		// TODO: to get from user
				user_tags: [],			// TODO: to get from user
				video_url: content[0].url,
			});

			const reelContainerCreationResponse = await createInstagramMediaContainer(instagramUserID, reelBody);
			if (!reelContainerCreationResponse.error && reelContainerCreationResponse.id) {
				const containerID = reelContainerCreationResponse.id;
				let containerStatus = await getInstagramMediaContainerStatus(containerID , accessToken);

				// wait for the container to be created
				while (containerStatus?.status_code !== InstagramMediaContainerStatusCode.FINISHED) {
					if (containerStatus?.status_code === InstagramMediaContainerStatusCode.ERROR || 
						containerStatus?.status_code === InstagramMediaContainerStatusCode.EXPIRED) {
						return {
							error: `Erreur: ${containerStatus.status_code} ${containerStatus.status}`
						}
					}
					await new Promise((resolve) => setTimeout(resolve, 4000));
					containerStatus = await getInstagramMediaContainerStatus(containerID, accessToken);
				}

				return {
					data: {
						containerID: containerID,
						accessToken: accessToken
					}
				};
			}

			return {
				error: `Erreur: ${reelContainerCreationResponse.error?.error_user_msg}`
			}

		case InstagramMediaType.CAROUSEL:

			if (content.length > 10) {
				return {
					error: 'Erreur: le nombre de fichiers ne peut pas dépasser 10'
				}
			}

			const children: string[] = [];

			// create a container for each file
			for (const file of content) {
				const fileType = getInstagramMediaType([file]);

				switch (fileType) {
					case InstagramMediaType.IMAGE:
						const imageBody = createInstagramImageContainerBody({
							media_type: InstagramMediaType.IMAGE,
							access_token: accessToken,
							image_url: file.url,
							is_carousel_item: true,
						});
			
						const imageContainerCreationResponse = await createInstagramMediaContainer(instagramUserID, imageBody);
						if (!imageContainerCreationResponse.error && imageContainerCreationResponse.id) {
							children.push(imageContainerCreationResponse.id);
						} else {
							return {
								error: `Erreur: ${imageContainerCreationResponse.error?.error_user_msg}`
							}
						}

						break;

					case InstagramMediaType.VIDEO:
					case InstagramMediaType.REELS:
						const videoBody = createInstagramVideoContainerBody({
							media_type: InstagramMediaType.VIDEO,
							access_token: accessToken,
							is_carousel_item: true,
							video_url: file.url,
						});
			
						const videoContainerCreationResponse = await createInstagramMediaContainer(instagramUserID, videoBody);
						if (!videoContainerCreationResponse.error && videoContainerCreationResponse.id) {
							const containerID = videoContainerCreationResponse.id;
							let containerStatus = await getInstagramMediaContainerStatus(containerID , accessToken);
			
							// wait for the container to be created
							while (containerStatus?.status_code !== InstagramMediaContainerStatusCode.FINISHED) {
								if (containerStatus?.status_code === InstagramMediaContainerStatusCode.ERROR || 
									containerStatus?.status_code === InstagramMediaContainerStatusCode.EXPIRED) {
									return {
										error: `Erreur: ${containerStatus.status_code} ${containerStatus.status}`
									}
								}
								await new Promise((resolve) => setTimeout(resolve, 4000));
								containerStatus = await getInstagramMediaContainerStatus(containerID, accessToken);
							}
			
							children.push(containerID);
						} else {
							return {
								error: `Erreur: ${videoContainerCreationResponse.error?.error_user_msg}`
							}
						}

						break;

					default:
						return {
							error: `Error: unknown media type`
						}
				}
			}

			// create the carousel container
			const carouselBody = createInstagramCarouselContainerBody({
				media_type: InstagramMediaType.CAROUSEL,
				access_token: accessToken,
				caption: event.description,
				children: children,
				product_tags: [],	// TODO: to get from user
			});


			const carouselContainerCreationResponse = await createInstagramMediaContainer(instagramUserID, carouselBody);
			if (!carouselContainerCreationResponse.error && carouselContainerCreationResponse.id) {
				const containerID = carouselContainerCreationResponse.id;
				let containerStatus = await getInstagramMediaContainerStatus(containerID, accessToken);

				// wait for the container to be created
				while (containerStatus?.status_code !== InstagramMediaContainerStatusCode.FINISHED) {
					if (containerStatus?.status_code === InstagramMediaContainerStatusCode.ERROR || 
						containerStatus?.status_code === InstagramMediaContainerStatusCode.EXPIRED) {
						return {
							error: `Erreur: ${containerStatus.status_code} ${containerStatus.status}`
						}
					}
					await new Promise((resolve) => setTimeout(resolve, 4000));
					containerStatus = await getInstagramMediaContainerStatus(containerID, accessToken);
				}

				return {
					data: {
						containerID: containerID,
						accessToken: accessToken
					}
				};
			}

			return {
				error: `Erreur: ${carouselContainerCreationResponse.error?.error_user_msg}`
			}

		default:
			return {
				error: `Error: unknown media type`
			}
	}
}


export const createInstagramMediaContainer = async (
	accountID: string,
	body: string[]
): Promise<InstagramMediaContainerResponseDTO> => {
	const url = `${FACEBOOK_BASE_URL}/${accountID}/media?${body.join('&')}`;

	const res = await fetch(url, { method: 'POST' })
		.then((res) => res.json())
		.catch((err) => {
			console.error('createInstagramMediaContainer(): err', err);
			return err;
		});
	
	console.log('createInstagramMediaContainer(): response', res);
	return res;
}

export const createInstagramImageContainerBody = (post: InstagramImageContainerDTO): string[] => {
	const body: string[] = [];

	body.push(`access_token=${post.access_token}`);
	body.push(`image_url=${encodeURIComponent(post.image_url)}`);

	if (post.caption) {
		body.push(`caption=${encodeURIComponent(post.caption)}`); // TODO: must sanitize caption
	}
	if (post.is_carousel_item) {
		body.push(`is_carousel_item=${post.is_carousel_item}`);
	}
	if (post.product_tags) {
		body.push(`product_tags=${JSON.stringify(post.product_tags)}`);
	}
	if (post.user_tags) {
		body.push(`user_tags=${JSON.stringify(post.user_tags)}`);
	}

	return body;
}

export const createInstagramReelContainerBody = (post: InstagramReelContainerDTO): string[] => {
	const body: string[] = [];

	body.push(`access_token=${post.access_token}`);
	body.push(`media_type=${post.media_type}`)
	body.push(`video_url=${encodeURIComponent(post.video_url)}`)

	if (post.audio_name) {
		body.push(`audio_name=${post.audio_name}`)
	}

	if (post.caption) {
		body.push(`caption=${encodeURIComponent(post.caption)}`); // TODO: must sanitize caption
	}

	if (post.cover_url) {
		body.push(`cover_url=${post.cover_url}`)
	} else {
		body.push(`thumb_offset=${post.thumb_offset || 0}`)
	}

	if (post.share_to_feed) {
		body.push(`share_to_feed=${post.share_to_feed}`)
	}

	if (post.user_tags) {
		body.push(`user_tags=${JSON.stringify(post.user_tags)}`)
	}

	return body;
}


export const createInstagramVideoContainerBody = (post: InstagramVideoContainerDTO): string[] => {
	const body: string[] = [];

	body.push(`access_token=${post.access_token}`);
	body.push(`media_type=${post.media_type}`)
	body.push(`video_url=${encodeURIComponent(post.video_url)}`)

	if (post.caption) {
		body.push(`caption=${encodeURIComponent(post.caption)}`); // TODO: must sanitize caption
	}

	if (post.is_carousel_item) {
		body.push(`is_carousel_item=${post.is_carousel_item}`)
	}

	if (post.product_tags) {
		body.push(`product_tags=${JSON.stringify(post.product_tags)}`)
	}

	// body.push(`thumb_offset=${post.thumb_offset || 0}`)

	if (post.user_tags) {
		body.push(`user_tags=${JSON.stringify(post.user_tags)}`)
	}

	return body;
}


export const createInstagramCarouselContainerBody = (post: InstagramCarouselContainerDTO): string[] => {
	const body: string[] = [];

	body.push(`access_token=${post.access_token}`);
	body.push(`media_type=${post.media_type}`)

	if (post.caption) {
		body.push(`caption=${encodeURIComponent(post.caption)}`); // TODO: must sanitize caption
	}

	if (post.children) {
		body.push(`children=${post.children.join(',')}`)
	}

	return body;
}


export const publishInstagramMediaContainer = async (accountID: string, containerID: string, accessToken: string): Promise<string> => {
	if (!accessToken) {
		console.error('publishInstagramMediaContainer(): accessToken is undefined');
		return '';
	}

	const url = `${FACEBOOK_BASE_URL}/${accountID}/media_publish?creation_id=${containerID}&access_token=${accessToken}`;
	console.log('publishInstagramMediaContainer(): url', url);

	const res = await fetch(url, {
			method: 'POST',
		})
		.then((res) => res.json())
		.then((res) => {
			if (res.error) {
				console.error('publishInstagramMediaContainer(): res.error', res.error);
				return res.error;
			}
			return res;
		})
		.catch((err) => {
			console.error('publishInstagramMediaContainer(): err', err);
			return err;
		});

	console.log('publishInstagramMediaContainer(): res', res);

	if (res.id) {
		return res.id;
	}
	return '';
}


// TODO: add return type
export const getFacebookInsightFollowers = async (accountID: string, accessToken: string): Promise<any> => {
	if (accessToken === '') {
		console.error('getFacebookInsightFollowers(): accessToken is undefined');
		return ;
	}

	const _metrics: string[] = [
		'audience_city',
		'audience_country',
		'audience_gender_age',
		'audience_locale',
	];
	const metric = _metrics.join(',');
	const period = 'lifetime';

	const url = `${FACEBOOK_BASE_URL}/${accountID}/insights?metric=${metric}&period=${period}&access_token=${accessToken}`;

	return await fetch(url, { method: 'GET' })
		.then((res) => res.json());
}


// TODO: add return type
export const getFacebookInsightAudience = async (accountID: string, accessToken: string): Promise<any> => {
	if (accessToken === '') {
		console.error('getFacebookInsightAudience(): accessToken is undefined');
		return ;
	}

	const metric = 'engaged_audience_demographics';
	const period = 'lifetime';
	const timeframe = 'last_30_days';
	const breakdown = 'age,gender,city,country';
	const metric_type = 'total_value';

	const url = `${FACEBOOK_BASE_URL}/${accountID}/insights?metric=${metric}&breakdown=${breakdown}&timeframe=${timeframe}&period=${period}&metric_type=${metric_type}&access_token=${accessToken}`;

	return await fetch(url, { method: 'GET' })
		.then((res) => res.json());
}


// TODO: add return type
export const getFacebookFollowersCount = async (accountID: string, accessToken: string): Promise<any> => {
	if (accessToken === '') {
		console.error('getFacebookFollowersCount(): accessToken is undefined');
		return ;
	}

	const fields = 'followers_count';

	const url = `${FACEBOOK_BASE_URL}/${accountID}?fields=${fields}&access_token=${accessToken}`;

	return await fetch(url, { method: 'GET' })
		.then((res) => res.json());
}


export const getFacebookInsightReach = async (accountID: string, accessToken: string, period: 'day' | 'week' | 'days_28' = 'week', daysDelta: number = 7): Promise<InstagramInsightResponse<number>> => {
	if (!accessToken) {
		console.error('getFacebookInsightReach(): accessToken is undefined');
		return {
			error: {
				message: 'accessToken is undefined',
				type: '',
				fbtrace_id: '',
				code: 0,
			},
		}
	}

	const metric = 'reach';

	// TODO: check if this is correct
	// since 1 week ago in unix timestamp
	const since = moment().subtract(daysDelta, 'days').unix();

	// until now in unix timestamp
	const until = moment().unix();

	const url = `${FACEBOOK_BASE_URL}/${accountID}/insights?metric=${metric}&period=${period}&since=${since}&until=${until}&access_token=${accessToken}`;

	const res = await fetch(url, { method: 'GET' })
		.then((res) => res.json())
		.catch((err) => err);

	return res;
}


/**
 * @description
 * get facebook follower demographic
 * 
 * @param accountID
 * 
 * @param accessToken
 * 
 * @returns {FacebookFollowerDemographicRow[] | null}
 * 
 * @example
 * [
 * 	{
 * 		age: "13-17",
 * 		gender: "F",
 * 		value: 12
 * 	},
 * 	{
 * 		age: "65+",
 * 		gender: "M",
 * 		value: 43
 * 	},
 * 	...
 * ]
 */
export const getFacebookFollowerDemographic = async (accountID: string, accessToken: string): Promise<FacebookFollowerDemographicRow[] | null> => {
	if (accessToken === '') {
		console.error('getFacebookFollowerDemographic(): accessToken is undefined');
		return null;
	}

	const metric = 'follower_demographics';
	const period = 'lifetime';
	const timeframe = 'this_week';
	const breakdown = [
		'age',
		'gender',
		// 'city',
		// 'country',
	];

	const url = `${FACEBOOK_BASE_URL}/${accountID}/insights?metric=${metric}&period=${period}&breakdown=${breakdown.join(',')}&metric_type=total_value&timeframe=${timeframe}&access_token=${accessToken}`;

	const res: FacebookFollowerDemographicResponse = await fetch(url, { method: 'GET' })
		.then((res) => res.json())
		.catch((err) => err.json());

	if (res?.error) {
		console.error('getFacebookFollowerDemographic(): res.error', res.error);
		return null;
	}

	if (!res.data?.[0]?.total_value?.breakdowns?.[0]) {
		return null;
	}

	const rows: FacebookFollowerDemographicRow[] = res.data[0].total_value.breakdowns[0].results.map((row) => {
		return {
			age: row.dimension_values[0],
			gender: row.dimension_values[1],
			value: row.value,
		}
	});

	return rows;
}

export const getFacebookOnlineFollowers = async (accountID: string, accessToken: string): Promise<InstagramInsightResponse<{ [key: number]: number }>> => {
	if (accessToken === '') {
		console.error('getFacebookOnlineFollowers(): accessToken is undefined');
		return {
			error: {
				message: 'accessToken is undefined',
				type: '',
				fbtrace_id: '',
				code: 0,
			}
		}
	}

	const metric = 'online_followers';
	const period = 'lifetime';

	// TODO: check if this is correct
	// since 1 week ago in unix timestamp
	const since = moment().subtract(14, 'days').unix();

	// until now in unix timestamp
	const until = moment().subtract(7, 'days').unix();

	const url = `${FACEBOOK_BASE_URL}/${accountID}/insights?metric=${metric}&period=${period}&since=${since}&until=${until}&access_token=${accessToken}`;

	const res = await fetch(url, { method: 'GET' })
		.then((res) => res.json())
		.catch((err) => err);

	return res;
}


export const getFacebookMedia = async (accessToken: string, mediaID: string): Promise<InstagramMediaResponseDTO | null> => {
	if (accessToken === '') {
		console.error('getFacebookMedia(): accessToken is undefined');
		return null;
	}

	const fields = [
		'id',
		'caption',
		'comments_count',
		'like_count',
		'media_type',
		'media_url',
		'permalink',
		'timestamp',
	];

	const url = `${FACEBOOK_BASE_URL}/${mediaID}?access_token=${accessToken}&fields=${fields.join(',')}`;

	const res = await fetch(url, { method: 'GET' })
		.then((res) => res.json());

	if (res?.error) {
		return null;
	}

	return res as InstagramMediaResponseDTO;
}

export const getFacebookAllMediaIDs = async (accountID: string, accessToken: string): Promise<string[] | null> => {
	if (accessToken === '') {
		console.error('getFacebookAllMediaIDs(): accessToken is undefined');
		return null;
	}
	let mediaIDs: string[] = [];
	let url = `${FACEBOOK_BASE_URL}/${accountID}/media?access_token=${accessToken}`;

	while (true) {
		const res = await fetch(url, { method: 'GET' })
			.then((res) => res.json());

		if (res?.error) {
			return null;
		}
		const response = res as InstagramUserMediaReponseDTO;

		// add new mediaIDs to array
		mediaIDs = mediaIDs.concat(response.data.map((media) => media.id));

		// break if no next page
		if (!response.paging?.next) {
			break;
		}

		// replace url with next page url
		url = response.paging.next;
	}
	return mediaIDs;
}


export const getFacebookAllMedias = async (accountID: string, accessToken: string): Promise<InstagramMediaResponseDTO[] | null> => {
	const mediaIDs = await getFacebookAllMediaIDs(accountID, accessToken);
	if (!mediaIDs) {
		return null;
	}

	const medias = await Promise.all(mediaIDs.map(async (mediaID) => {
		return await getFacebookMedia(accessToken, mediaID);
	}));

	// remove null
	const list = medias.filter((media) => media !== null) as InstagramMediaResponseDTO[];
	if (list.length === 0) {
		return null;
	}

	return list;
}


export const addAllFacebookMedias = async (accountID: string): Promise<void> => {
	const account = await getSocialAccountByAccountId(accountID);
	if (!account) {
		console.error('addAllFacebookMedias(): account is null');
		return ;
	}

	const token = await account.token();
	if (!token) {
		console.error('addAllFacebookMedias(): token is null');
		return ;
	}

	const medias = await getFacebookAllMedias(accountID, token);
	if (!medias) {
		return ;
	}

	const events: CalendarEvent[] = medias.map((media) => {

		/**
		 * If the media is a carousel, create an array of >1 medias in order for
		 * the front-end to display it correctly.
		 */
		let urls: string[] = [];
		if (media.media_url) {
			urls.push(media.media_url);

			if (media.media_type === InstagramMediaType.CAROUSEL_ALBUM) {
				urls.push(media.media_url);
			}
		}

		const post: CalendarEvent = new CalendarEvent({
			platformID: account.platformID,
			date: new Date(media.timestamp),
			accountID: account.accountID,
			description: media.caption,
			filesIds: urls,
			status: CalendarEventStatus.PUBLISHED,
			permalink: media.permalink,
			postID: media.id
		});
		return post;
	});


	/**
	 * add the events to the database if they don't already exist
	 */
	const calendarPosts = await getCalendarPostsByAccountID(accountID);
	if (!calendarPosts) {
		await addCalendarPosts(events);

		console.log('events', events);
	} else {
		const filteredEvents = events.filter((event) => {
			return !calendarPosts.find((post) => {
				return (
					post.accountID === event.accountID &&
					post.date.getTime() === event.date.getTime() &&
					post.platformID === event.platformID &&
					post.description === event.description
				);
			});
		});

		console.log('filteredEvents', filteredEvents);

		await addCalendarPosts(filteredEvents);
	}
}
