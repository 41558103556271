import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

/* Services */
import { expirationToTimestamp } from "./_utils";
import { SocialNetworkAccount, SocialNetworkName } from "./socialNetworks.service.dto";
import { updateSocialAccount } from "./socialNetworks.service";
import { createYoutubeAccessToken, getYoutubeChannelInfos } from "./youtube.service";

/* MUI */
import { Box, CircularProgress } from "@mui/material";


export default function YoutubeServiceCallback() {
	const navigate = useNavigate();
	const location = useLocation();
	const [isCallbackHandled, setIsCallbackHandled] = useState(false);


	useEffect(() => {
		handleCallback();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const handleCallback = async (): Promise<void> => {
		if (isCallbackHandled) {
			return;
		}

		setIsCallbackHandled(true);

		const params = new URLSearchParams(location.search);
		const code = params.get('code');

		if (!code) {
			console.log('no code', code);
			toast.warning('Une erreur est survenue, veuillez réessayer.');
			navigate('/dashboard/accounts');
			return ;
		}

		// Create a new access token
		const res = await createYoutubeAccessToken(code);
		if (!res || res?.error) {
			toast.warning('Une erreur est survenue, veuillez réessayer.');
			navigate('/dashboard/accounts');
			return ;
		}

		// Get channel infos
		const channelInfos = await getYoutubeChannelInfos(undefined, res.access_token);

		// Create a new social account with the infos
		await updateSocialAccount(new SocialNetworkAccount({
			accountID: channelInfos.items.id,
			platformID: SocialNetworkName.YOUTUBE,
			username: channelInfos.items.snippet.title,
			auth: {
				accessToken: res.access_token,
				refreshToken: res.refresh_token,
				accessTokenExpiration: expirationToTimestamp(res.expires_in),
			},
			data: {
				channelInfos: channelInfos
			}
		}));

		toast.success('Connecté à Youtube.');
		navigate('/dashboard/accounts');
	};


	return (
		<Box sx={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 'calc(100vh - 120px)'
		}}>
			<CircularProgress disableShrink size={30} />
		</Box>
	);
};
