/* Libraries */
import { httpsCallable } from 'firebase/functions';
import { functions } from '../config/firebase.config';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

/* Services */
import { compareTimestampToNow, expirationToTimestamp } from './_utils';
import { deleteSocialAccount, getSocialAccountById, updateSocialAccount } from './socialNetworks.service';
import { refreshTiktokAccessToken } from './tiktok.service';
import { getYoutubeChannelInfos, refreshYoutubeAccessToken } from './youtube.service';
import { createLongLivedFacebookAccessToken, getFacebookUser, getInstagramAccountsLinkedToFacebookPages } from './facebook.service';

/* Types */
import { FileDTO } from './clientStorage.service.dto';
import { TiktokUserResponse } from './tiktok.service.dto';


/**
 * @description
 * Enum defining the social network platforms.
 */
export enum SocialNetworkName {
	FACEBOOK = 'facebook',
	INSTAGRAM = 'instagram',
	YOUTUBE = 'youtube',
	TIKTOK = 'tiktok',
	SNAPCHAT = 'snapchat',
	TWITTER = 'twitter',
	LINKEDIN = 'linkedin',
}


/**
 * @description
 * Interface for the social network platforms.
 */
export interface SocialPlatform {
	/**
	 * name of the platform
	 * @see SocialNetworkName
	 */
	platformID: SocialNetworkName;

	/**
	 * display name of the platform
	 * @example 'Instagram'
	 */
	name: string;

	/**
	 * If the platform is ready to be used (if not, it will not be displayed),
	 * Used for the platforms that are not yet implemented.
	 * 
	 * @note
	 * Not to be confused with the availability of the platform (if the user is connected or not)
	 */
	implemented: boolean;

	/**
	 * platform's icon
	 */
	icon: any;

	/**
	 * platform's icon (bright color version)
	 */
	iconLight: any;

	/**
	 * platform's icon source (3D version)
	 */
	icon3D: string;

	/**
	 * platform's background color
	 */
	background: string;

	/**
	 * check if the platform is available (if the user is at least connected to
	 * one account of the platform)
	 * 
	 * @returns {Promise<boolean>} true if the platform is available, false otherwise
	 */
	isAvailable: () => Promise<boolean>;
	
	/**
	 * get the posts repartition of the platform for the files given
	 *
	 * @returns {Promise<Date[]>}
	 */
	getPostsRepartition: (account: SocialNetworkAccount, files: readonly FileDTO[]) => Promise<Date[]>;
}


/**
 * @description
 * Interface for each user's social account.
 * It contains the account object and the platform's basic information (extending 'SocialPlatform' interface)
 * 
 * @example
 * {
 * 	account: {
 * 		id: '123456789',
 * 		platformID: SocialNetworkName.INSTAGRAM,
 * 		accountID: '987654321',
 * 		username: 'my_account',
 * 		auth: {
 * 			accessToken: '123456789',
 * 			accessTokenExpiration: 123456789,
 * 			...
 * 		},
 * 		...
 * 	},
 * 	platformID: SocialNetworkName.INSTAGRAM,
 * 	name: 'Instagram',
 * 	implemented: true,
 * 	...
 * }
 */
export interface SocialAccount extends SocialPlatform {
	account: SocialNetworkAccount;
}


export enum SocialPlatformEventType {
	YOUTUBE_VIDEO = "YOUTUBE_VIDEO",
	YOUTUBE_SHORT = "YOUTUBE_SHORT",
	TIKTOK_VIDEO = "TIKTOK_VIDEO",
	INSTAGRAM_PHOTO = "INSTAGRAM_PHOTO",
	INSTAGRAM_VIDEO = "INSTAGRAM_VIDEO",
	INSTAGRAM_CAROUSEL = "INSTAGRAM_CAROUSEL",
	INSTAGRAM_REEL = "INSTAGRAM_REEL",
}


export interface SocialNetworkAccountData {
	/**
	 * @description
	 * account unique id
	 */
	id?: string; // uuid

	/**
	 * @description
	 * platform
	 */
	platformID?: SocialNetworkName | '';

	/**
	 * @description
	 * account id on the platform
	 * 
	 * @note
	 * (e.g. the instagram account id or the YouTube channel id)
	 */
	accountID?: string;

	/**
	 * @description
	 * account name on the platform
	 * 
	 * @note
	 * (e.g. instagram username or YouTube channel name)
	 */
	username?: string;

	/**
	 * @description
	 * authentication data
	 */
	auth?: Partial<SocialNetworkAccountAuth>;

	/**
	 * @description
	 * data of the account
	 */
	data?: Record<string, any>;

	/**
	 * @description
	 * if the account is linked to another one
	 * 
	 * @note
	 * (e.g. an instagram account linked to a facebook account)
	 */
	relatedAccountID?: string;
}


export interface SocialNetworkAccountAuth {
	accessToken: string;
	refreshToken: string;
	accessTokenExpiration: number; // timestamp
	refreshTokenExpiration: number; // timestamp
}


export class SocialNetworkAccount {
	id: string;
	platformID: SocialNetworkName | '';
	accountID: string;
	username: string;
	auth: SocialNetworkAccountAuth;
	data: Record<string, any>;
	relatedAccountID: string;
	relatedAccount: SocialNetworkAccount | null;

	constructor(account: SocialNetworkAccountData = {}) {
		this.id = account.id || uuidv4();
		this.platformID = account.platformID || '';
		this.accountID = account.accountID || '';
		this.username = account.username || '';
		this.auth = {
			accessToken: account.auth?.accessToken || '',
			refreshToken: account.auth?.refreshToken || '',
			accessTokenExpiration: account.auth?.accessTokenExpiration || 0,
			refreshTokenExpiration: account.auth?.refreshTokenExpiration || 0,
		};
		this.data = account.data || {};
		this.relatedAccountID = account.relatedAccountID || '';
		this.relatedAccount = null;
	}

	get isLinked(): boolean {
		return !!this.relatedAccountID;
	}

	get isExpired(): boolean {
		if (this.relatedAccount) {
			return this.relatedAccount.isExpired;
		}
		return this.auth.accessToken === '' || compareTimestampToNow(this.auth.accessTokenExpiration);
	}

	get isRefreshExpired(): boolean {
		if (this.relatedAccount) {
			return this.relatedAccount.isRefreshExpired;
		}
		return this.auth.refreshToken === '' || compareTimestampToNow(this.auth.accessTokenExpiration);
	}

	get pictureURL(): string | '' {
		switch (this.platformID) {
			case SocialNetworkName.FACEBOOK:
				return this.data?.user?.picture?.data?.url || ''
			case SocialNetworkName.INSTAGRAM:
				return this.data?.profile_picture_url || ''
			case SocialNetworkName.YOUTUBE:
				return this.data?.channelInfos?.items.snippet?.thumbnails?.default?.url || ''
			case SocialNetworkName.TIKTOK:
				return this.data?.user?.avatar_url_100 || ''
			default:
				return ''
		}
	}

	accountData(): SocialNetworkAccountData {
		return {
			id: this.id,
			platformID: this.platformID,
			accountID: this.accountID,
			username: this.username,
			auth: this.auth,
			data: this.data,
			relatedAccountID: this.relatedAccountID,
		}
	}

	merge(account: SocialNetworkAccount): void {
		this.platformID = account.platformID || this.platformID;
		this.accountID = account.accountID || this.accountID;
		this.username = account.username || this.username;
		this.auth = {
			accessToken: account.auth?.accessToken || this.auth.accessToken,
			refreshToken: account.auth?.refreshToken || this.auth.refreshToken,
			accessTokenExpiration: account.auth?.accessTokenExpiration || this.auth.accessTokenExpiration,
			refreshTokenExpiration: account.auth?.refreshTokenExpiration || this.auth.refreshTokenExpiration,
		};
		this.data = {
			...this.data,
			...account.data,
		};
		this.relatedAccountID = account.relatedAccountID || this.relatedAccountID;
	}

	async token(): Promise<string | undefined> {
		if (this.relatedAccountID) {
			this.relatedAccount = await getSocialAccountById(this.relatedAccountID);
		}

		if (!this.isExpired) {
			if (this.relatedAccount) {
				return this.relatedAccount.auth.accessToken;
			} else {
				return this.auth.accessToken;
			}
		} else {
			const auth = await this._refreshToken();

			if (auth) {
				// if there is a related account, update this one, not the current one
				if (this.relatedAccount) {
					this.relatedAccount.auth = auth;

					// update the related account first, then the current one
					await updateSocialAccount(this.relatedAccount);

					// update the current account
					await updateSocialAccount(this);

					return this.relatedAccount.auth.accessToken;
				} else {
					this.auth = auth; // already updated, for clarity
					await updateSocialAccount(this);
					return this.auth.accessToken;
				}
			} else {
				toast.error(`Votre session ${this.platformID} (${this.username}) a expirée. Veuillez vous reconnecter.`);
				await deleteSocialAccount(this.id);
				if (this.relatedAccountID) {
					await deleteSocialAccount(this.relatedAccountID);
				}
			}
		}
		return undefined;
	}

	private async _refreshToken(): Promise<SocialNetworkAccountAuth | null> {
		switch (this.platformID) {
			case SocialNetworkName.FACEBOOK:
				const facebookAuth = await createLongLivedFacebookAccessToken(this.auth.accessToken);
				if (!facebookAuth) {
					console.error("Error: can't refresh Facebook token");
					return null;
				}

				this.auth = facebookAuth;

				const fbUser = await getFacebookUser(facebookAuth.accessToken);
				if (fbUser) {
					this.username = fbUser.name;
					this.data = {
						...this.data,
						user: { ...this.data?.user, ...fbUser }
					};
				}
				return this.auth;

			case SocialNetworkName.INSTAGRAM:
				// get the linked facebook account & return its auth
				const facebookAccount = this.relatedAccount || await getSocialAccountById(this.relatedAccountID);
				if (!facebookAccount) {
					return null;
				}

				const refreshedFacebookAuth = await facebookAccount._refreshToken();
				if (!refreshedFacebookAuth) {
					return null;
				}

				// refresh the instagram account infos linked to the facebook account
				const linkedInstagramAccounts = await getInstagramAccountsLinkedToFacebookPages(refreshedFacebookAuth.accessToken) || [];
				const linkedInstagramAccount = linkedInstagramAccounts.find((account) => account.id === this.accountID);
				if (linkedInstagramAccount) {
					this.username = linkedInstagramAccount.username;
					this.data = { ...this.data, ...linkedInstagramAccount };
				}

				// refresh the facebook token
				return refreshedFacebookAuth;

			case SocialNetworkName.YOUTUBE:
				const youtubeToken = await refreshYoutubeAccessToken(this.auth.refreshToken);
				if (!youtubeToken) {
					console.error("Error: can't refresh Youtube token", this.auth);
					return null;
				}

				this.auth.accessToken = youtubeToken.access_token;
				this.auth.refreshToken = youtubeToken?.refresh_token || this.auth.refreshToken || '';
				this.auth.accessTokenExpiration = expirationToTimestamp(youtubeToken.expires_in);
				this.auth.refreshTokenExpiration = 0;

				// refresh the youtube channel infos
				const channelInfos = await getYoutubeChannelInfos(undefined, this.auth.accessToken);
				this.username = channelInfos.items.snippet.title;
				this.data = { ...this.data, channelInfos: channelInfos };

				return this.auth;

			case SocialNetworkName.TIKTOK:
				const tiktokToken = await refreshTiktokAccessToken(this.auth.refreshToken);
				if (!tiktokToken) {
					console.error("Error: can't refresh TikTok token", this.auth);
					return null;
				}

				this.auth.accessToken = tiktokToken.access_token;
				this.auth.refreshToken = tiktokToken.refresh_token;
				this.auth.accessTokenExpiration = expirationToTimestamp(tiktokToken.expires_in);
				this.auth.refreshTokenExpiration = expirationToTimestamp(tiktokToken.refresh_expires_in);

				// refresh the TikTok user infos
				const tiktokGetUserInfos = httpsCallable(functions, 'tiktokGetUserInfos');
				const userInfos = await tiktokGetUserInfos({ accessToken: this.auth.accessToken });
				const userResponse = userInfos.data as TiktokUserResponse;
				this.username = userResponse?.data.user.display_name;
				this.data = { ...this.data, ...userResponse?.data || {} };

				return this.auth;

			default:
				break;
		}

		return null;
	}
}
