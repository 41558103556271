import { CalendarEvent } from "../../services/calendar.service.dto";
import { SocialAccount } from "../../services/socialNetworks.service.dto";


export interface PlanificationCalendarProps {
	events: CalendarEvent[];
	loading: boolean;
	openNewEventModal: (event: SocialAccount | null, date?: Date) => void;
}

export interface CreateEventModalType {
	open: boolean;
	date: Date;
}

export interface PlanificationCalendarState {
	days: CalendarEvent[];
	startingDate: Date;
	endingDate: Date;
	eventDragged: CalendarEvent | null;
	createEventModal: CreateEventModalType;
}

export enum CalendarActionType {
	UPDATE_CALENDAR = 'UPDATE_CALENDAR',
	NEXT_WEEK = 'NEXT_WEEK',
	PREVIOUS_WEEK = 'PREVIOUS_WEEK',
	RESET_WEEK = 'RESET_WEEK',
	NEXT_MONTH = 'NEXT_MONTH',
	PREVIOUS_MONTH = 'PREVIOUS_MONTH',
	DRAG_START = 'DRAG_START',
	DRAG_END = 'DRAG_END',
	UPDATE_CREATE_EVENT_MODAL = 'UPDATE_CREATE_EVENT_MODAL',
}
