// DuplicateEventButton
/* Libraries */
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

/* Components */

/* Services */
import { useAvailableSocialNetworks } from '../../../services/socialNetworks.service';
import { addGeneratedCalendarPosts, generateUserCalendar } from '../../../services/calendar.service';
import { getFilePlatformsAvailability, getFilesById } from '../../../services/clientStorage.service';

/* MUI */
import { Box, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* Icons */
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

/* DTO */
import { DuplicateEventButtonProps } from './DuplicateEventButtonDTO';
import { CalendarEvent } from '../../../services/calendar.service.dto';

/* CSS */
import './DuplicateEventButton.css';


export default function DuplicateEventButton(props: DuplicateEventButtonProps) {

	const {
		event,
		disabled = false,
	} = props;

	const [open, setOpen] = useState(false);
	const anchorRef = useRef<HTMLDivElement>(null);
	const [socialNetworks, socialNetworksLoading] = useAvailableSocialNetworks();
	const [loading, setLoading] = useState(false);
	// const [generationSuccess, setGenerationSuccess] = useState(false);


	const handleMenuItemClick = async (_: any, accID: string) => {
		const account = socialNetworks.find((account) => account.account.id === accID);
		if (!account) {
			return;
		}

		setOpen(false);
		setLoading(true);

		const toastID = toast('Génération du calendrier en cours...', {
			type: toast.TYPE.INFO,
			isLoading: true,
			closeButton: false,
			autoClose: false,
		});

		const files = await getFilesById(event.filesIds || []);
		if (!files) {
			setLoading(false);
			toast.update(toastID, {
				type: toast.TYPE.ERROR,
				render: 'Une erreur est survenue lors de la génération du calendrier',
				isLoading: false,
				closeButton: true,
				autoClose: 5000,
			});
			return;
		}


		// Check if files are available on the new social network
		await Promise.all(files.map(async (file) => {
			const availability = await getFilePlatformsAvailability(file);

			if (!availability.availablePlatforms.includes(account.platformID)) {
				toast.update(toastID, {
					type: toast.TYPE.ERROR,
					render: `Le fichier ${file.name} ne peut pas être publié sur ${account.account.platformID}`,
					isLoading: false,
					closeButton: true,
					autoClose: 5000,
				});
				setLoading(false);
				return Promise.reject();
			}
		}));

		// Check if the new event is schedulable on the new social network
		const tmpEvent = new CalendarEvent({ ...event, platformID: account.platformID });
		const isSchedulable = await tmpEvent.isSchedulable();
		if (!isSchedulable.isSchedulable) {
			toast.update(toastID, {
				type: toast.TYPE.ERROR,
				render: isSchedulable.errorMsg,
				isLoading: false,
				closeButton: true,
				autoClose: 5000,
			});
			return;
		}

		const generatedEvents = await generateUserCalendar([files[0]], [account]);
		if (generatedEvents.length === 0) {
			toast.update(toastID, {
				type: toast.TYPE.ERROR,
				render: 'Une erreur est survenue lors de la génération du calendrier',
				isLoading: false,
				closeButton: true,
				autoClose: 5000,
			});
		} else {
			tmpEvent.merge(new CalendarEvent((generatedEvents[0])));
			await addGeneratedCalendarPosts([tmpEvent]);
			// setGenerationSuccess(true);
			toast.update(toastID, {
				type: toast.TYPE.SUCCESS,
				render: 'Génération du calendrier terminée. Vous devez les compléter afin de les planifier.',
				isLoading: false,
				closeButton: true,
				autoClose: 10000,
			});
		}

		setLoading(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event) => {
		if (anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}
		setOpen(false);
	};


	return (
		<Box className='DuplicateEventButton-container'>
			<ButtonGroup variant='contained' ref={anchorRef} size='small' color='success' disabled={disabled}>
				<LoadingButton
					variant='contained'
					color='success'
					onClick={handleToggle}
					startIcon={<ContentCopyIcon />}
					endIcon={ open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
					loading={socialNetworksLoading || loading}
				>
					Dupliquer le post
				</LoadingButton>
			</ButtonGroup>

			<Popper
				sx={{ zIndex: 1 }}
				open={open}
				anchorEl={anchorRef.current}
				placement='bottom-start'
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper sx={{
							width: '100%',
							minWidth: '200px',
							border: '1px solid #ddd',
						}}>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList autoFocusItem dense>
								{ socialNetworks.filter((account) => {
									return account.account.accountID !== event.accountID;
								}).map((account, accountIndex) => (
									<MenuItem
										className='DuplicateEventButton-account-item-container'
										key={account.account.username}
										disabled={false}
										onClick={(event) => handleMenuItemClick(event, account.account.id)}
									>
										<img className='DuplicateEventButton-account-item-image'
											src={account.account.pictureURL}
											alt={account.account.username}
										/>

										<Typography variant='body2' sx={{ color: '#000', fontWeight: 600 }}>
											{account.account.username}
										</Typography>

										<Box sx={{ flexGrow: 1 }} />

										<img className='DuplicateEventButton-account-item-icon'
											src={account.icon3D}
											alt={account.platformID}
										/>
									</MenuItem>
								))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Box>
	);
}
