/* Libraries */
import { Link } from 'react-router-dom';

/* Components */
import Logo from '../../assets/Logo/Logo';

/* MUI */
import { Box, IconButton, Typography } from '@mui/material';

/* Icons */
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TikTokIcon from '../../assets/TikTokIcon/TikTokIcon';

/* CSS */
import './Footer.css';


const brandNameStyled = {
	fontWeight: 650,
	color: '#444',
	fontSize: '1rem',
};

const copyrightStyled = {
	color: '#999',
	fontWeight: 600,
	fontSize: '.8rem',
};

const linkStyled = {
	color: '#222',
	textDecoration: 'none',
	fontSize: '.8rem',
};

const iconLinkStyled = {
	...linkStyled,
	borderRadius: '50%',
	backgroundColor: '#000',
};

export default function Footer() {

	const links = [
		{
			icon: <LinkedInIcon fontSize='large' />,
			alt: 'LinkedIn',
			link: 'https://www.linkedin.com/company/freekl/',
		},
		{
			icon: <InstagramIcon fontSize='large' />,
			alt: 'Instagram',
			link: 'https://www.instagram.com/freeklc/',
		},
		{
			icon: <TikTokIcon />,
			alt: 'TikTok',
			link: 'https://www.tiktok.com/@freeklc',
		},
	];


	return (
		<Box className='Footer-container'>
			<Box className='Footer-brand-container'>
				<Logo />

				<Typography variant='h5' sx={brandNameStyled}>
					FREEKL
				</Typography>
			</Box>

			<Box className='Footer-links-container'>
			{ links.map((link, index) => (
				<Link to={link.link}
					key={`Footer-external-link-${index}`}
					rel="noopener noreferrer"
					target='_blank'
					style={iconLinkStyled}
				>
					<IconButton size='large' color='secondary'>
						{link.icon}
					</IconButton>
				</Link>
			))}
			</Box>

			<Box className='Footer-links-container'>
				<Link to='/legal/privacy' style={linkStyled}>
					Politique de confidentialité
				</Link>

				<Typography variant='h5' sx={copyrightStyled}>
					|
				</Typography>

				<Link to='/legal/terms' style={linkStyled}>
					Conditions d'utilisation
				</Link>
			</Box>

			<Box>
				<Typography variant='h5' sx={copyrightStyled}>
					Copyright © {new Date().getFullYear()} FREEKL - Tous droits réservés
				</Typography>
			</Box>
		</Box>
	);
}
