/* Libraries */
import {db} from '../config/firebase.config';
import {Auth, getAuth} from 'firebase/auth';
import {doc, DocumentData, DocumentReference, DocumentSnapshot, getDoc, setDoc} from 'firebase/firestore';
import _ from 'lodash';


/**
 * @description
 * return a document reference with the user id as the document name
 * 
 * @example
 * docName = "social_accounts" => "social_accounts/<user_id>"
 * 
 * @param docName document name
 * 
 * @returns document reference or null
 */
export const getUserDocReference = (docName: string): DocumentReference<DocumentData> | null => {
	const auth: Auth = getAuth();
	const userID: string | undefined = auth.currentUser?.uid;

	if (userID) {
		return doc(db, docName, userID);
	}
	return null;
};


/**
 * @description
 * return the user document snapshot if it exists
 * 
 * @overload
 * @param ref document reference
 * 
 * @overload
 * @param fn function that returns a document reference
 * 
 * @returns document snapshot or null
 */
export async function getUserDocSnapshot(ref: DocumentReference<DocumentData> | null): Promise<DocumentSnapshot<DocumentData> | null>;
export async function getUserDocSnapshot(fn: () => DocumentReference<DocumentData> | null): Promise<DocumentSnapshot<DocumentData> | null>;
export async function getUserDocSnapshot(ref: unknown): Promise<DocumentSnapshot<DocumentData> | null> {
	try {
		if (typeof ref === 'function') {
			return await getDoc(ref());
		} else if (ref instanceof DocumentReference) {
			return await getDoc(ref);
		} else {
			return null;
		}
	} catch (error) {
		console.error(error);
		return null;
	}
}


/**
 * @description
 * create a document if it doesn't exist
 * 
 * @param fn function that returns a document reference
 * 
 * @returns void
 */
export const checkDocumentExistence = async (fn: () => DocumentReference<DocumentData> | null): Promise<void> => {
	const ref = fn();

	if (ref) {
		const docSnap = await getDoc(ref);
		
		if (!docSnap.exists()) {
			await setDoc(ref, {});
		}
	}
};


/**
 * @description
 * returns the value with suffix K or M if it's greater than 1000 or 1000000 respectively
 * 
 * @returns string
 */
export const convertValueToHumanReadable = (value: number): string => {
	const sizeInK = value / 1000;
	const sizeInM = sizeInK / 1000;

	if (sizeInM >= 1) {
		return `${sizeInM.toFixed(1)}M`;
	} else if (sizeInK >= 1) {
		return `${sizeInK.toFixed(1)}K`;
	}
	return `${value}`;
};


/**
 * @description
 * Distribute n elements based on a list of ratios
 * 
 * @param n number of elements to distribute evenly
 * @param ratios normalized list of ratios (sum of ratios should be 1)
 * 
 * @returns list of numbers, distributed evenly based on the ratios
 * 
 * @example
 * distributeRatios(2, [0.5, 0.5]) => [1, 1]
 * distributeRatios(3, [0.5, 0.5]) => [2, 1]
 * distributeRatios(3, [0.3, 0.7]) => [1, 2]
 * distributeRatios(1, [0.2, 0.3, 0.5]) => [0, 0, 1]
 * distributeRatios(7, [0.24, 0.48, 0.28]) => [2, 3, 2]
 */
export const distributeRatios = (n: number, ratios: readonly number[]): number[] => {
	const listLength = ratios.length;

	if (listLength === 0) {
		return [];
	}

	// Check sum of ratios
	const sum = ratios.reduce((a: number, b: number) => a + b, 0);

	// if the sum is not 1 (or close enough due to floating point errors), return an empty list
	if (Math.abs(sum - 1) > 0.0001) {
		console.error('distributeRatios(): Sum of ratios should be 1');
		return [];
	}

	// Calculate the integer part of the distribution
	const integerPart: number = Math.floor(n / listLength);

	// Calculate the remaining decimal part of the distribution
	let decimalPart: number = n % listLength;

	// Allocate the integer part to each element in the list based on the ratios
	const sortedIndices: number[] = ratios.map((_, i) => i).sort((a, b) => ratios[b] - ratios[a]);
	const allocation: number[] = Array(listLength).fill(integerPart);

	for (let i = 0; i < decimalPart; ++i) {
		allocation[sortedIndices[i]] += 1;
	}

	return allocation;
};


/**
 * @description
 * Deep copy an object
 * 
 * @param obj object to copy
 * 
 * @returns deep copy of the object
 */
export function deepCopy<T>(obj: T): T {
	return _.cloneDeep(obj);
}


/**
 * @description
 * Shuffle an array in place
 * 
 * @param array array to shuffle
 *
 * @returns shuffled array
 */
export function shuffleArray<T>(array: T[]): T[] {
	let cpy: T[] = deepCopy(array);

	for (let i = cpy.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		const tmp = cpy[i];
		cpy[i] = cpy[j];
		cpy[j] = tmp;
	}
	return cpy;
}


/**
 * @description
 * Find the index of the highest number in a list
 * If there are multiple indices with the same highest number, return a random one
 * If all numbers are negative or 0, return -1
 * 
 * @param ranks list of numbers
 * 
 * @returns index of the highest number in the list or -1 if all numbers are negative or 0
 */
export function findIndexOfHighestRank(ranks: number[]): number {
	let maxRank = Number.NEGATIVE_INFINITY;
	let indicesWithMaxRank: number[] = [];

	for (let i = 0; i < ranks.length; i++) {
		const rank = ranks[i];
	
		if (rank > maxRank) {
			// We found a new highest rank; reset the list of indices with max rank
			maxRank = rank;
			indicesWithMaxRank = [i];
		} else if (rank === maxRank) {
			// This rank is tied with the current highest rank
			indicesWithMaxRank.push(i);
		}
	}

	// Return a random index from the list of indices with max rank
	if (maxRank === Number.NEGATIVE_INFINITY || maxRank === 0) {
		// All ranks are either negative or 0
		return -1;
	} else {
		return indicesWithMaxRank[Math.floor(Math.random() * indicesWithMaxRank.length)];
	}
}


/**
 * @description
 * convert the expiration time to a timestamp
 * 
 * @param expiresIn in seconds
 * 
 * @returns timestamp in seconds
 */
export const expirationToTimestamp = (expiresIn: number): number => {
	const expirationInMilliseconds = expiresIn * 1000;
	const expirationDate = new Date(Date.now() + expirationInMilliseconds);
	return Math.floor(expirationDate.getTime() / 1000);
}


/**
 * @description
 * compare a timestamp to the current time
 * 
 * @param inputTimestamp timestamp in seconds or milliseconds
 * 
 * @returns true if the current time is after the input timestamp
 */
export const compareTimestampToNow = (inputTimestamp: number | string): boolean => {
	const now = Date.now();

	// Convert inputTimestamp to a number if it's a string
	const inputTimestampNum = typeof inputTimestamp === 'string' ? parseInt(inputTimestamp) : inputTimestamp;

	// Check the length of inputTimestamp to determine the time unit (seconds or milliseconds)
	if (inputTimestampNum.toString().length === 10) {
		// Timestamp is in seconds
		return now > inputTimestampNum * 1000;
	} else if (inputTimestampNum.toString().length === 13) {
		// Timestamp is in milliseconds
		return now > inputTimestampNum;
	} else {
		// Invalid timestamp format
		return true;
	}
}
