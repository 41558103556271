/* Libraries */
import { Fragment, useEffect, useState } from 'react';

/* Components */

/* Services */
import { SocialNetworkAccount, SocialNetworkName } from '../../services/socialNetworks.service.dto';
import { getSocialAccountsByPlatform } from '../../services/socialNetworks.service';
import { getYoutubeOauth2URL, youtubeLogout } from '../../services/youtube.service';
import { convertValueToHumanReadable } from '../../services/_utils';

/* MUI */
import { Box } from '@mui/system';
import { Button, Icon, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

/* Icons */
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LogoutIcon from '@mui/icons-material/Logout';

/* DTO */

/* CSS */
import '../../pages/AccountsLoginPage/SocialAccountLogin.css';
import './YouTubeLoginStep.css';


const youtubeNameStyled = {
	color: 'black',
	fontWeight: 'bold',
	fontSize: {
		xs: '17px',
		md: '22px'
	}
};

const youtubeEmailStyled = {
	color: 'black',
	fontWeight: 'thinner',
	fontSize: {
		xs: '12px',
		md: '14px'
	}
};

const youtubeAccountInfoStyled = {
	color: 'black',
	fontSize: {
		xs: '11px',
		md: '13px'
	}
};

const youtubeUsernameStyled = {
	color: 'black',
	fontWeight: 'bold',
	fontSize: {
		xs: '15px',
		md: '16px'
	}
};

const youtubeHighlightStyled = {
	color: '#0095f6',
	fontWeight: 'bold'
};

export default function YouTubeLoginStep() {

	const [youtubeState, setYoutubeState] = useState<SocialNetworkAccount[]>([]);


	useEffect(() => {
		refreshYoutubeState();
	}, []);


	/**
	 * Load Facebook account from Firestore
	 * @returns
	 * 		- void
	 */
	const refreshYoutubeState = async (): Promise<void> => {
		const acc = await getSocialAccountsByPlatform(SocialNetworkName.YOUTUBE);

		if (acc) {
			setYoutubeState(acc);
		}
	};

	/**
	 * @description
	 * redirect to the oauth2 endpoint for login
	 */
	const youtubeOauth2SignIn = async () => {
		const url = getYoutubeOauth2URL();
		
		/**
		 * redirect to the oauth2 endpoint.
		 * After the user login, the endpoint will redirect to the
		 * YoutubeServiceCallback component.
		 * This component will save the user access token in the firestore and
		 * redirect to this page.
		*/
		window.location.href = url;
	}

	const handleYoutubeLogout = async (id: string) => {
		await youtubeLogout(id);
		setYoutubeState((prev) => prev && prev.filter((acc) => acc.id !== id));
	};


	return (
		<Box className='SocialAccountLogin-container'>
			<Box className='SocialAccountLogin-connection-container'>
				{ youtubeState.length === 0 && (
					<Button
						color='primary'
						sx={{ backgroundColor: 'rgb(234,51,35)' }}
						variant='contained'
						size='large'
						onClick={youtubeOauth2SignIn}
					>
						Se connecter avec YouTube
					</Button>
				)}

				{ youtubeState.length > 0 && (
					<Fragment>
						<Box sx={{ flexGrow: 1 }} />

						<Button
							color='primary'
							sx={{ backgroundColor: 'rgb(234,51,35)' }}
							variant='contained'
							onClick={youtubeOauth2SignIn}
							startIcon={<AddCircleOutlineIcon />}
						>
							Connecter un autre compte
						</Button>
					</Fragment>
				)}
			</Box>

			{ youtubeState.length > 0 && (
				<Box className='SocialAccountLogin-items-container'>
				{ youtubeState.map((youtubeAccount: SocialNetworkAccount, index: number) => (
					<Box className='SocialAccountLogin-item-container' key={index}>
						<Box className='SocialAccountLogin-profile-header'>
							<img className='SocialAccountLogin-profile-picture'
								src={youtubeAccount.data.channelInfos.items.snippet.thumbnails.default.url}
								alt={youtubeAccount.username}
							/>

							<Box className='SocialAccountLogin-profile-infos'>
								<Typography variant="h5" sx={youtubeNameStyled}>
									{youtubeAccount.username}
								</Typography>

								<Typography variant="h4" sx={youtubeEmailStyled}>
									Depuis le {new Date(youtubeAccount.data.channelInfos.items.snippet.publishedAt).toLocaleDateString(
										'fr-FR',
										{ year: 'numeric', month: 'long', day: 'numeric' }
									)}
								</Typography>
							</Box>

							<Box sx={{ flexGrow: 1 }} />

							<Box className='SocialAccountLogin-profile-buttons'>
								<Button
									size='small'
									variant="contained"
									color="primary"
									sx={{ backgroundColor: 'rgb(234,51,35)' }}
									startIcon={<LogoutIcon />}
									onClick={() => handleYoutubeLogout(youtubeAccount.id)}
								>
									Déconnexion
								</Button>
							</Box>
						</Box>

						{/* Youtube account */}
						<Box className='SocialAccountLogin-accounts-list-container'>
							<Box className='SocialAccountLogin-account-container SocialAccountLogin-account-selected'>
								<img className='SocialAccountLogin-account-profile-picture'
									src={youtubeAccount.data.channelInfos.items.snippet.thumbnails.default.url}
									alt="YouTube profile"
								/>

								<Box className='SocialAccountLogin-account-profile-infos-container'>
									<Typography variant="h4" sx={youtubeUsernameStyled}>
										{youtubeAccount.data.channelInfos.items.snippet.customUrl}
									</Typography>

									{/* profile infos */}
									<Box className='SocialAccountLogin-account-profile-infos'>
										<Typography variant="h4" sx={youtubeAccountInfoStyled}>
											<span style={youtubeHighlightStyled}>
												{convertValueToHumanReadable(youtubeAccount.data.channelInfos.items.statistics.subscriberCount)}
											</span> abonnés
										</Typography>

										<Typography variant="h4" sx={youtubeAccountInfoStyled}>
											<span style={youtubeHighlightStyled}>
												{convertValueToHumanReadable(youtubeAccount.data.channelInfos.items.statistics.videoCount)}
											</span> videos
										</Typography>

										<Typography variant="h4" sx={youtubeAccountInfoStyled}>
											<span style={youtubeHighlightStyled}>
												{convertValueToHumanReadable(youtubeAccount.data.channelInfos.items.statistics.viewCount)}
											</span> vues
										</Typography>
									</Box>
								</Box>

								<Tooltip
									title='Compte lié'
									placement="top"
								>
									<Icon color='info'>
										<CheckCircleOutlineIcon fontSize='small' />
									</Icon>
								</Tooltip>
							</Box>
						</Box>
					</Box>
				))}
				</Box>
			)}
		</Box>
	);
}
