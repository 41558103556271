/* Libraries */
import { DocumentData, setDoc, updateDoc } from 'firebase/firestore';

/* Icons */

/* Services */
import { getUserDocReference, getUserDocSnapshot } from './_utils';

/* Types */
import { Customer, CustomerSocialAccountsPreference, LanguageOptionsType } from './customers.service.dto';
import { SocialNetworkName } from './socialNetworks.service.dto';


export const DEFAULT_POSTS_PER_WEEK: number = 7;
export const MIN_POSTS_PER_WEEK: number = 1;
export const MAX_POSTS_PER_WEEK: number = 100;
export const SOCIAL_ACCOUNT_PRIORITY_STEP: number = 0.01;


/**
 * @description
 * default social accounts preferences
 */
export const DEFAULT_SOCIAL_ACCOUNTS_PREFERENCE: CustomerSocialAccountsPreference = {
	preferred: "",
	postsPerWeek: DEFAULT_POSTS_PER_WEEK,
	accounts: [
		{
			platformID: SocialNetworkName.INSTAGRAM,
			priority: 0.35
		},
		{
			platformID: SocialNetworkName.YOUTUBE,
			priority: 0.3
		},
		{
			platformID: SocialNetworkName.TIKTOK,
			priority: 0.35
		}
	]
}

/**
 * @description
 * default language for the customer
 */
export const DEFAULT_LANGUAGE: LanguageOptionsType = 'fr';


/**
 * @description
 * return the customers document reference if it exists
 */
export const getCustomerDocReference = () => {
	return getUserDocReference("customers");
}


/**
 * @description
 * return the customers document
 */
export const getCustomerDocSnapshot = async () => {
	return await getUserDocSnapshot(getCustomerDocReference);
}


/**
 * @description
 * return the customers data if it exists
 */
export const getCustomerData = async (): Promise<DocumentData | undefined> => {
	const docSnap = await getCustomerDocSnapshot();

	if (docSnap?.exists()) {
		return docSnap.data();
	}
	return undefined;
}


export const getCustomer = async (): Promise<Customer | undefined> => {
	const customer = await getCustomerData();

	if (customer) {
		return customer as Customer;
	} else {
		return await createCustomer();
	}
};


/**
 * @description
 * return the customer social accounts preferences if it exists
 * create it if it doesn't exist
 */
export const getCustomerSocialAccountsPreference = async (): Promise<CustomerSocialAccountsPreference> => {
	const customer = await getCustomer();

	if (customer && customer?.socialAccounts) {
		return customer.socialAccounts;
	} else {
		await createCustomer();
		return DEFAULT_SOCIAL_ACCOUNTS_PREFERENCE;
	}
}


/**
 * @description
 * create the customer social accounts preferences
 */
export const createCustomer = async (): Promise<Customer | undefined> => {
	const docRef = getCustomerDocReference();

	if (!docRef) {
		return undefined;
	}

	const newCustomer: Customer = {
		lang: DEFAULT_LANGUAGE,
		socialAccounts: DEFAULT_SOCIAL_ACCOUNTS_PREFERENCE
	};

	const docSnap = await getUserDocSnapshot(docRef);
	if (docSnap?.exists()) {
		await updateDoc(docRef, {...newCustomer});
	} else {
		await setDoc(docRef, newCustomer);
	}

	return newCustomer;
}


/**
 * @description
 * update the customer social accounts preferences
 * 
 * @param newPreferredPlatform
 * the new preferred platform.
 * will add +SOCIAL_ACCOUNT_PRIORITY_STEP to its priority and wisely remove -SOCIAL_ACCOUNT_PRIORITY_STEP from the old preferred
 * platform.
 */
export const updateCustomerSocialAccountsPreferredPlatform = async (newPreferredPlatform: SocialNetworkName | ""): Promise<void> => {
	const docRef = getCustomerDocReference();
	if (!docRef) {
		return ;
	}

	const docSnap = await getUserDocSnapshot(docRef);
	if (docSnap?.exists()) {
		const data = docSnap.data();
		const oldPreferredPlatform = data.socialAccounts.preferred;
		let newAccounts = data.socialAccounts.accounts;

		/**
		 * @description
		 * if the preferred platform is already the same, set it to "" to reset
		 * the preferred platform.
		 */
		if (oldPreferredPlatform === newPreferredPlatform) {
			newPreferredPlatform = '';
		}


		/**
		 * @description
		 * if there was no old preferred platform and no new preferred platform
		 * special case, it should never happen
		 */
		if (oldPreferredPlatform === "" && newPreferredPlatform === "") {
			return ;

		/**
		 * @description
		 * if there was no old preferred platform, just add +SOCIAL_ACCOUNT_PRIORITY_STEP to the new preferred platform
		 * and remove adequate priority from all the other ones
		 */
		} else if (oldPreferredPlatform === "") {
			newAccounts = data.socialAccounts.accounts.map((account: { platformID: SocialNetworkName, priority: number }) => {
				if (account.platformID === newPreferredPlatform) {
					return {
						...account,
						priority: account.priority + SOCIAL_ACCOUNT_PRIORITY_STEP
					}
				} else {
					return {
						...account,
						priority: account.priority - (SOCIAL_ACCOUNT_PRIORITY_STEP / (data.socialAccounts.accounts.length - 1))
					}
				}
			});

		/**
		 * @description
		 * if the new preferred platform is "", just remove -SOCIAL_ACCOUNT_PRIORITY_STEP from the old preferred platform
		 * and add adequate priority to all the other ones
		 */
		} else if (newPreferredPlatform === "") {
			newAccounts = data.socialAccounts.accounts.map((account: { platformID: SocialNetworkName, priority: number }) => {
				if (account.platformID === oldPreferredPlatform) {
					return {
						...account,
						priority: account.priority - SOCIAL_ACCOUNT_PRIORITY_STEP
					}
				} else {
					return {
						...account,
						priority: account.priority + (SOCIAL_ACCOUNT_PRIORITY_STEP / (data.socialAccounts.accounts.length - 1))
					}
				}
			});

		/**
		 * @description
		 * if there was an old preferred platform and a new one, just add +SOCIAL_ACCOUNT_PRIORITY_STEP and the adequate priority to the new preferred platform
		 * and remove adequate priority from all the other ones
		 */
		} else {
			newAccounts = data.socialAccounts.accounts.map((account: { platformID: SocialNetworkName, priority: number }) => {
				if (account.platformID === newPreferredPlatform) {
					return {
						...account,
						priority: account.priority + SOCIAL_ACCOUNT_PRIORITY_STEP + (SOCIAL_ACCOUNT_PRIORITY_STEP / (data.socialAccounts.accounts.length - 1))
					}
				} else if (account.platformID === oldPreferredPlatform) {
					return {
						...account,
						priority: account.priority - SOCIAL_ACCOUNT_PRIORITY_STEP - (SOCIAL_ACCOUNT_PRIORITY_STEP / (data.socialAccounts.accounts.length - 1))
					}
				} else {
					return account;
				}
			});
		}

		await updateDoc(docRef, {
			"socialAccounts.preferred": newPreferredPlatform,
			"socialAccounts.accounts": newAccounts
		});
	}
}


/**
 * @description
 * update the customer social accounts posts per week
 * the new number must be between MIN_POSTS_PER_WEEK and MAX_POSTS_PER_WEEK
 */
export const updateCustomerSocialAccountsPostsPerWeek = async (postsPerWeek: number): Promise<void> => {

	if (postsPerWeek < MIN_POSTS_PER_WEEK || postsPerWeek > MAX_POSTS_PER_WEEK) {
		return ;
	}

	const docRef = getCustomerDocReference();
	if (!docRef) {
		return ;
	}

	const docSnap = await getUserDocSnapshot(docRef);
	if (docSnap?.exists()) {
		await updateDoc(docRef, {
			"socialAccounts.postsPerWeek": postsPerWeek
		});
	} else {
		await updateDoc(docRef, {
			socialAccounts: {
				postsPerWeek: postsPerWeek
			}
		});
	}
}


export const updateCustomerSocialAccountsLang = async (lang: LanguageOptionsType): Promise<void> => {
	const docRef = getCustomerDocReference();
	if (!docRef) {
		return ;
	}

	await updateDoc(docRef, {
		lang: lang
	});
};
