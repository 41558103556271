/* Libraries */

/* Components */

/* CSS */
import './TikTokIcon.css';


interface TikTokIconProps {
	width?: number;
	className?: string;
	style?: React.CSSProperties;
}

export default function TikTokIcon(props: TikTokIconProps) {

	const logo = "/icons/tiktok-icon.png";

	return (
		<img src={logo}
			className={props.className}
			style={props.style}
			alt="TikTok Icon"
			width={ props.width ? props.width : 30 }
		/>
	);
}
