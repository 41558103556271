/* Libraries */
import { storage } from '../config/firebase.config';
import { getAuth } from 'firebase/auth';
import { DocumentData, setDoc, updateDoc } from 'firebase/firestore';
import {
	deleteObject,
	getDownloadURL,
	getMetadata,
	listAll,
	ref,
	StorageReference,
	uploadBytesResumable
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

/* Services */
import { getUserDocReference, getUserDocSnapshot } from './_utils';
import { ALL_SOCIAL_PLATFORMS } from './socialNetworks.service';

/* Types */
import { FileAvailabilityDTO, FileDTO } from './clientStorage.service.dto';
import { SocialNetworkName } from './socialNetworks.service.dto';


/**
 * @description
 * Size limit for the client storage (in bytes)
 * Limited to 50Gb
 */
export const CLIENT_STORAGE_LIMIT: number = 50000000000;


/**
 * @description
 * Instagram file types accepted & remove duplicates
*/
export const INSTAGRAM_ACCEPTED_FILETYPES: string[] = [
	"image/jpeg",
	"image/jpg",
	"video/mp4",
	"video/quicktime"
].filter((type: string, index: number, self: string[]) => {
	return self.indexOf(type) === index;
});


/**
 * @description
 * Instagram file types accepted
*/
export const INSTAGRAM_ACCEPTED_FILE_EXTENSIONS: string[] = INSTAGRAM_ACCEPTED_FILETYPES.map((type: string) => {
	return type.split("/")[1];
});


/**
 * @description
 * TikTok file types accepted & remove duplicates
*/
export const TIKTOK_ACCEPTED_FILETYPES: string[] = [
	"video/mp4",
	"video/quicktime",
	"video/webm"
].filter((type: string, index: number, self: string[]) => {
	return self.indexOf(type) === index;
});


/**
 * @description
 * TikTok file types accepted
*/
export const TIKTOK_ACCEPTED_FILE_EXTENSIONS: string[] = TIKTOK_ACCEPTED_FILETYPES.map((type: string) => {
	return type.split("/")[1];
});


/**
 * @description
 * file types accepted by the app & remove duplicates
 */
export const APP_ACCEPTED_FILETYPES: string[] = [
	...INSTAGRAM_ACCEPTED_FILETYPES,
	...TIKTOK_ACCEPTED_FILETYPES,
].filter((type: string, index: number, self: string[]) => {
	return self.indexOf(type) === index;
});


/**
 * @description
 * file types accepted by the app
*/
export const APP_ACCEPTED_FILE_EXTENSIONS: string[] = APP_ACCEPTED_FILETYPES.map((type: string) => {
	return type.split("/")[1];
});


/**
 * @description
 * return the user calendar document reference if it exists
 */
export const getUserStorageDocReference = () => {
	return getUserDocReference("storage");
}


/**
 * @description
 * return the user calendar document
 */
export const getUserStorageDocSnapshot = async () => {
	return await getUserDocSnapshot(getUserStorageDocReference);
}


/**
 * @description
 * return the user calendar data if it exists
 */
export const getUserStorageData = async (): Promise<DocumentData | undefined> => {
	const docSnap = await getUserStorageDocSnapshot();

	if (docSnap?.exists()) {
		return docSnap.data();
	}
	return undefined;
}


/**
 * Get the base reference URL for the current user
 * 
 * @returns {string | null} The base reference URL for the current user
 */
export const getClientStorageBaseRef = (): string | null => {
	const auth = getAuth();

	if (auth.currentUser && auth.currentUser.uid) {
		return `user/${auth.currentUser.uid}`;
	} else {
		return null;
	}
}


/**
 * @description
 * Get the list of files for the current user & sum the size of all the files
 * 
 * @returns {number | undefined} The size of all the files for the current user
 */
export const getClientStorageUsed = async (): Promise<number | undefined> => {
	const baseRefURL = getClientStorageBaseRef();

	if (baseRefURL === null) {
		return undefined;
	}

	const listRef = ref(storage, baseRefURL);
	const fileList = await listAll(listRef).then((res) => res.items);

	// for each file in the list, get the size
	const fileSizes = await Promise.all(fileList.map(async (itemRef) => {
		return await getMetadata(itemRef).then((metadata) => metadata.size);
	}));

	// sum all the sizes
	return fileSizes.reduce((a, b) => a + b, 0);
}


/**
 * @description
 * Check if the client storage limit would be reached if the file(s) were uploaded
 * 
 * @param {number} fileSize The size of the file(s) to upload (in bytes)
 * 
 * @returns {[boolean, number | undefined]} An array with a boolean indicating if the limit is reached and the size of the files for the current user
 */
export const checkClientStorageLimit = async (fileSize: number): Promise<[boolean, number]> => {
	const used = await getClientStorageUsed();

	if (used === undefined) {
		return [true, 0];
	}

	return [(used + fileSize) > CLIENT_STORAGE_LIMIT, used];
}


/**
 * @description
 * Get the size of all the files in the list
 * 
 * @param {FileList} files The list of files (event.target.files)
 * 
 * @returns {number} The size of all the files in the list (in bytes)
 */
export const getFileListSize = (files: FileList): number => {
	return Array.from(files).reduce((a, b) => a + b.size, 0);
}


/**
 * @description
 * Get the basic metadata of a file
 * 
 * @param file
 * 
 * @returns
 * - width: width of the image / video
 * - height: height of the image / video
 * - duration: duration of the video (0 if the file is an image)
 */
export const getFileBasicMetadata = async (file: File): Promise<{ width: number, height: number, duration: number }> => {
	try {
		return new Promise((resolve, reject) => {
			if (file.type.includes('image')) {
				const img = new Image();

				img.onload = () => {
					resolve({ width: img.width, height: img.height, duration: 0 });
				};
				img.onerror = () => {
					reject(`Failed to load file: ${file.name}`);
				};
				img.src = URL.createObjectURL(file);
			} else if (file.type.includes('video')) {
				const video = document.createElement('video');
				video.preload = 'metadata';

				video.onloadedmetadata = () => {
					resolve({ width: video.videoWidth, height: video.videoHeight, duration: video.duration });
				}
				video.onerror = () => {
					reject(`Failed to load file: ${file.name}`);
				}

				video.src = URL.createObjectURL(file);
			} else {
				reject(`Failed to load file: ${file.name}`);
			}
		});
	} catch (error) {
		return {
			width: 0,
			height: 0,
			duration: 0,
		}
	}
}


/**
 * @description
 * return the files of the current user
 */
export const getUserStorageFiles = async (): Promise<FileDTO[] | null> => {
	const data = await getUserStorageData();

	if (!data) {
		return null;
	}

	if (data?.files) {
		// convert the metadata string to an object
		return data.files.map((file: any) => {
			return {
				...file,
				metadata: JSON.parse(file.metadata)
			} as FileDTO;
		});
	}

	return null;
}


/**
 * @description
 * get all the platforms on which we can post the file
 * 
 * @param file The file to check
 * @returns The file with the available platforms
 */
export const getFilePlatformsAvailability = async (file: FileDTO): Promise<FileAvailabilityDTO> => {

	let filesAvailability: FileAvailabilityDTO = {
		...file,
		availablePlatforms: []
	};
	const isVideo = file.metadata?.contentType?.includes('video');
	const isImage = file.metadata?.contentType?.includes('image');
	const fileSize = file.metadata?.size || Infinity; // if the file size is not available, set it to Infinity to avoid errors

	// loop through all the platforms to check if the file is available on them
	for (const platform of ALL_SOCIAL_PLATFORMS) {

		/**
		 * Check if the file is available on the platform
		 * 
		 * The process is as follow:
		 * 1. check if the file has an accepted file extension for the platform
		 * 2. check if the file type (image / video) is accepted by the platform
		 * 3. check if the file size is accepted by the platform
		 * 4. if all the above are true, add the platform to the list of available platforms
		 */
		switch (platform.platformID) {
			case SocialNetworkName.INSTAGRAM:
				const instaImageMaxSize = 8 * 1000 * 1000; // 8Mb
				const instaReelMaxSize = 1000 * 1000 * 1000; // 1Gb for single videos (reels)

				/**
				 * Check if the file has an accepted file extension for Instagram
				 */
				if (INSTAGRAM_ACCEPTED_FILETYPES.indexOf(file.metadata?.contentType || "") === -1) {
					break;
				}

				/**
				 * Check if the file size is not too big
				 */
				if ((isImage && fileSize < instaImageMaxSize) ||
					(isVideo && fileSize < instaReelMaxSize)) {
					filesAvailability.availablePlatforms.push(platform.platformID);
				}
				break;

			case SocialNetworkName.TIKTOK:
				const tiktokVideoMaxSize = 4 * 1000 * 1000 * 1000; // 4gb

				if (TIKTOK_ACCEPTED_FILETYPES.indexOf(file.metadata?.contentType || "") === -1) {
					break;
				}

				if (isVideo && fileSize < tiktokVideoMaxSize) {
					filesAvailability.availablePlatforms.push(platform.platformID);
				}
				break;

			case SocialNetworkName.YOUTUBE:
				const youtubeVideoMaxSize = 256 * 1000 * 1000 * 1000; // 256gb

				// TODO: check file types

				if (isVideo && fileSize < youtubeVideoMaxSize) {
					filesAvailability.availablePlatforms.push(platform.platformID);
				}
				break;

			default:
				break;
		}
	}

	return filesAvailability;
}


/**
 * @description
 * Get the list of files for the current user
 */
export const getFileList = async (filteredIDs?: string[]): Promise<FileDTO[] | null> => {
	const baseRefURL = getClientStorageBaseRef();

	if (baseRefURL === null) {
		return null;
	}

	const listRef = ref(storage, baseRefURL);

	// get the list of files from the storage
	const referenceList: StorageReference[] = await listAll(listRef).then((res) => res.items);

	// get the list of files from the database
	const fileList = await getUserStorageFiles();

	// for each file in the list, get the download URL and convert it to a FileDTO
	const promises = await Promise.all(
		referenceList.map(async (itemRef: StorageReference): Promise<FileDTO | null> => {

			// get the file from the database
			const file = fileList?.find((file) => file.fullPath === itemRef.fullPath);

			// if the file is not searched for, do not bother to fetch it
			if (filteredIDs && filteredIDs.length > 0 && !filteredIDs.includes(file?.id || '')) {
				return null;
			}

			const url = await getDownloadURL(itemRef);
			const metadata = await getMetadata(itemRef);

			const thumbnails = file?.thumbnails || {
				thumb_offset: 0,
				sm: {url: '', fullPath: ''},
				md: {url: '', fullPath: ''},
				preview: {url: '', fullPath: ''}
			};

			if (thumbnails.sm?.fullPath) {
				thumbnails.sm.url = await getDownloadURL(ref(storage, thumbnails.sm.fullPath));
			}
			if (thumbnails.md?.fullPath) {
				thumbnails.md.url = await getDownloadURL(ref(storage, thumbnails.md.fullPath));
			}
			if (thumbnails.preview?.fullPath) {
				thumbnails.preview.url = await getDownloadURL(ref(storage, thumbnails.preview.fullPath));
			}

			return {
				id: file?.id || uuidv4(),
				name: file?.name || itemRef.name,
				fullPath: file?.fullPath || itemRef.fullPath,
				url: url,
				metadata: file?.metadata || metadata,
				basicMetadata: file?.basicMetadata || { width: 0, height: 0, duration: 0 },
				thumbnails: thumbnails
			};
		})
	);

	return promises.filter((file) => file !== null) as FileDTO[];
}


/**
 * @description
 * Get a file by its ID
 * 
 * @param {string} id The ID of the file to get
 * 
 * @returns {FileDTO | null} The file with the given ID
 */
export const getFileById = async (id: string): Promise<FileDTO | null> => {

	if (!id) {
		return null;
	}

	const files = await getFileList([id]);
	if (!files) {
		return null;
	}

	return files.find((file) => file.id === id) || null;
}


/**
 * @description
 * Get a list of files by their IDs
 * 
 * @param {string} ids The IDs of the files to get
 * 
 * @returns {FileDTO[] | null} The files with the given IDs
 */
export const getFilesById = async (ids: string[]): Promise<FileDTO[] | null> => {

	if (ids.length === 0) {
		return null;
	}

	const files = await getFileList(ids);
	if (!files) {
		return null;
	}

	// filter the files by the IDs & sort them by the same order as the IDs
	return files.filter((file) => ids.includes(file.id))
				.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
}


export const updateFile = async (newFile: FileDTO): Promise<void> => {
	const docRef = getUserStorageDocReference();

	if (!docRef) {
		return;
	}

	const docSnap = await getUserDocSnapshot(docRef);

	if (!docSnap || !docSnap.exists()) {
		await setDoc(docRef, {
			files: [{...newFile, metadata: JSON.stringify(newFile.metadata)}]
		}, { merge: true });
		return;
	}

	const docData = docSnap.data();
	if (!docData) {
		return;
	}

	const files = await getFileList();

	if (!files) {
		await setDoc(docRef, {
			files: [{...newFile, metadata: JSON.stringify(newFile.metadata)}]
		}, { merge: true });

		// If there is already files, we update the document
	} else {
		// convert file metadata to string for firestore & remove thumbnails' URLs
		const fileList = files.map((file) => {

			const thumbs = file.thumbnails;

			if (thumbs?.sm?.url) {
				thumbs.sm.url = '';
			}
			if (thumbs?.md?.url) {
				thumbs.md.url = '';
			}
			if (thumbs?.preview?.url) {
				thumbs.preview.url = '';
			}

			return {
				...file,
				metadata: JSON.stringify(file.metadata),
				thumbnails: thumbs
			}
		});

		const file = fileList.find((file) => file.fullPath === newFile.fullPath);
		if (file) {
			const mergedFile: FileDTO = {
				id: newFile.id || file.id,
				name: newFile.name || file.name,
				fullPath: newFile.fullPath || file.fullPath,
				url: newFile.url || file.url,
				metadata: newFile.metadata || JSON.parse(file.metadata),
				basicMetadata: newFile.basicMetadata || file.basicMetadata,
				thumbnails: {
					sm: {
						url: newFile.thumbnails?.sm?.url || file.thumbnails?.sm?.url || '',
						fullPath: newFile.thumbnails?.sm?.fullPath || file.thumbnails?.sm?.fullPath || '',
					},
					md: {
						url: newFile.thumbnails?.md?.url || file.thumbnails?.md?.url || '',
						fullPath: newFile.thumbnails?.md?.fullPath || file.thumbnails?.md?.fullPath || '',
					},
					preview: {
						url: newFile.thumbnails?.preview?.url || file.thumbnails?.preview?.url || '',
						fullPath: newFile.thumbnails?.preview?.fullPath || file.thumbnails?.preview?.fullPath || '',
					}
				}
			};

			await updateDoc(docRef, {
				files: [
					...fileList.filter((file) => file.fullPath !== mergedFile.fullPath),
					{
						...mergedFile,
						metadata: JSON.stringify(mergedFile.metadata),
					}
				]
			});
		} else {
			await updateDoc(docRef, {
				files: [
					...fileList.filter((file) => file.fullPath !== newFile.fullPath),
					{
						...newFile,
						metadata: JSON.stringify(newFile.metadata),
					}
				]
			});
		}
	}
}


/**
 * @description
 * delete the user calendar file(s) in the database
 * @param fileID file ID(s) to delete
 * - string: delete the file with the given ID
 * - string[]: delete the files with the given IDs
 * 
 * @returns Promise<void>
 * 
 * @example
 * deleteFile('fileID'); // delete the file with the given ID
 * deleteFile(['postID1', 'postID2']); // delete the files with the given IDs
*/
export async function deleteFile(fileID: string): Promise<void>;
export async function deleteFile(fileIDs: string[]): Promise<void>;
export async function deleteFile(fileID?: unknown): Promise<void> {
	if (typeof fileID === 'string') {
		return deleteFile([fileID]);
	} else if (Array.isArray(fileID)) {
		const reference = getUserStorageDocReference();
		const files = await getUserStorageFiles();

		if (reference && files && files.length > 0) {
			// remove the files from the database
			const newList = files.map((file) => {
				return {
					...file,
					metadata: JSON.stringify(file.metadata),
				}
			}).filter((file) => !fileID.includes(file.id));

			// remove the files from the storage
			for (const id of fileID) {
				const file = files.find((file) => file.id === id);

				if (file) {
					const fileRef = ref(storage, file.fullPath);
					await deleteObject(fileRef);

					if (file.thumbnails?.sm?.fullPath) {
						const smThumbRef = ref(storage, file.thumbnails.sm.fullPath);
						await deleteObject(smThumbRef);
					}
					if (file.thumbnails?.md?.fullPath) {
						const mdThumbRef = ref(storage, file.thumbnails.md.fullPath);
						await deleteObject(mdThumbRef);
					}
					if (file.thumbnails?.preview?.fullPath) {
						const prevThumbRef = ref(storage, file.thumbnails.preview.fullPath);
						await deleteObject(prevThumbRef);
					}
				}
			}

			await setDoc(reference, {
				files: newList,
			}, { merge: true });
		}
	} else {
		throw new Error('Invalid parameter type');
	}
}


/**
 * @description
 * React hook to upload a FileList to the client storage
 * 
 * @param {FileList} files The list of files to upload
 * @param {Function} onProgress Callback function to call when the upload progress changes
 * @param {Function} onError Callback function to call when an error occurs
 * @param {Function} onComplete Callback function to call when an upload is complete
 * @param {Function} onAllCompleted Callback function to call when all the uploads are complete
 */
export const uploadFileList = async (
	files: FileList | null,
	onProgress?: (progress: number) => Promise<void>,
	onError?: (error: Error) => Promise<void>,
	onComplete?: (file: File) => Promise<void>,
	onAllCompleted?: () => Promise<void>,
): Promise<void> => {
	const baseRefURL = getClientStorageBaseRef();

	if (baseRefURL === null || files === null) {
		onError && await onError(new Error('Une erreur est survenue'));
		return;
	}

	// get the size of all the files in the list
	const fileSize = getFileListSize(files);

	// check if the client storage limit is reached
	const [limitReached, usedStorage] = await checkClientStorageLimit(fileSize);

	if (limitReached) {
		const toFree = fileSize - usedStorage;

		// if the user has no space left, tell him to upgrade his plan
		if (toFree > CLIENT_STORAGE_LIMIT) {
			onError && await onError(new Error(`Vous n'avez pas assez d'espace dans votre stockage. Passez à un abonnement supérieur pour pouvoir uploader ce(s) fichier(s)`));
		} else {
			onError && await onError(new Error(`Vous n'avez pas assez d'espace dans votre stockage. Libérez ${Math.round(toFree / 1000000)} Mo pour pouvoir uploader ce(s) fichier(s)`));
		}
		return;
	}

	// counter in order to know when all the uploads are complete
	let completedTasks = 0;

	// for each file in the list, upload it
	await Promise.all(
		Array.from(files).map(async (file) => {
			const fileRef = ref(storage, `${baseRefURL}/${file.name}`);
			const uploadTask = uploadBytesResumable(fileRef, file);

			uploadTask.on('state_changed',
				async (snapshot) => {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					onProgress && await onProgress(progress);
				},
				async (error) => {
					onError && await onError(error);
				},
				async () => {
					const thumbnails = {
						sm: {url: '', fullPath: ''},
						md: {url: '', fullPath: ''},
						preview: {url: '', fullPath: ''}
					};

					/**
					 * When the upload is an image, add the small thumbnail to
					 * the database directly since it's generated by the extension
					 */
					// if (file.type.startsWith('image/')) {
					// 	const name = file.name.split('.');
					// 	const extension = name.pop();
					// 	const thumbName = `${name.join('.')}_550x550.${extension}`;
					// 	const thumbFullPath = `${baseRefURL}/thumbs/${thumbName}`;

					// 	thumbnails.sm.fullPath = thumbFullPath;
					// }

					const basicMetadata = await getFileBasicMetadata(file);

					// add the file to the database
					await updateFile({
						id: uuidv4(),
						name: file.name,
						fullPath: fileRef.fullPath,
						url: await getDownloadURL(fileRef),
						metadata: await getMetadata(fileRef),
						basicMetadata: basicMetadata,
						thumbnails: thumbnails,
					});

					onComplete && await onComplete(file);

					++completedTasks;
					if (completedTasks === files.length) {
						onAllCompleted && await onAllCompleted();
					}
				}
			);

			return await uploadTask;
		})
	);
}
