/* Libraries */

/* Components */
import PageHeader from '../../assets/PageHeader/PageHeader';

/* DTO */
import { IChangelog } from './ChangelogPageDTO';

/* MUI */
import { Box, Grow, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

/* Icons */
import CircleIcon from '@mui/icons-material/Circle';

/* CSS */
import './ChangelogPage.css';


const changelogTitleStyled = {
	fontWeight: 900,
	color: '#333',
	fontSize: '1.5rem',
};

const changelogDateStyled = {
	fontWeight: 600,
	color: '#888',
	fontSize: '.8rem',
};

const changelogTextStyled = {
	color: '#444',
	fontWeight: 500,
	fontSize: '.8rem',
};

export default function ChangelogPage() {

	// TODO: Get changes from database
	const changes: IChangelog[] = [
		{
			version: 'v1.5.2',
			date: new Date('2024-02-01'),
			changes: [
				'Find the best time to post a single post - NEW FEATURE',
				'Some video formats were not showing properly in the Library - FIXED',
				'When reloading, the page was always redirecting to the calendar - FIXED',
			]
		},
		{
			version: 'βeta 1.1.0',
			date: new Date('2023-07-17'),
			changes: [
				'Initial release',
			]
		},
		{
			version: 'βeta 1.0.0',
			date: new Date('2023-05-08'),
			changes: [
				'Beta release',
			]
		},
		{
			version: 'αlpha 0.3.2',
			date: new Date('2023-04-26'),
			changes: [
				'Alpha release',
			]
		},
	];


	return (
		<Box className='ChangelogPage-container'>
			<PageHeader title='Changelog' subtitle="Quoi de neuf sur FREEKL ?" divider />

			<Grow in={true} timeout={1000}>
				<Box className='ChangelogPage-changelog-container'>
				{ changes.map((change, index) => {

					const date = change.date.toLocaleDateString('en-US', {
						year: 'numeric',
						month: 'long',
						day: 'numeric',
					});

					return (
						<Box key={index} className='ChangelogPage-changelog-item-container'>
							<Box className='ChangelogPage-changelog-item-title-container'>
								<Typography variant='h5' sx={changelogTitleStyled}>
									FREEKL <span style={{ fontWeight: 400 }}> - {change.version}</span>
								</Typography>

								<Typography variant='h5' sx={changelogDateStyled}>
									{date}
								</Typography>
							</Box>

							<List dense className='ChangelogPage-changelog-changes-container'>
							{ change.changes.map((change, index) => {
								return (
									<ListItem dense key={`change-item-${index}`} sx={{
										padding: 0,
									}}>
										<ListItemIcon sx={{ minWidth: '40px' }}>
											<CircleIcon sx={{ color: '#888', fontSize: '13px' }} />
										</ListItemIcon>

										<ListItemText primary={
											<Typography key={index} variant='h5' sx={changelogTextStyled}>
												{change}
											</Typography>
										} />
									</ListItem>
								);
							})}
							</List>
						</Box>
					);
				}) }
				</Box>
			</Grow>
		</Box>
	);
}
