/* Libraries */
import { Link } from 'react-router-dom';

/* Components */
import Logo from '../../assets/Logo/Logo';

/* MUI */
import { Box, Typography } from '@mui/material';

/* CSS */
import './LegalTermsPage.css';


const brandNameStyled = {
	fontWeight: 750,
	color: '#111',
	fontSize: '1.2rem',
};

const titleStyled = {
	fontWeight: 650,
	color: '#333',
	fontSize: '1.4rem',
};

const legalTextStyled = {
	color: '#333',
	fontWeight: 400,
	fontSize: '.9rem',

	'& a': {
		color: '#0096FF',
		textDecoration: 'none',
	}
};

export default function LegalTermsPage() {
	return (
		<Box className='LegalTermsPage-container'>
			<Link to='/' style={{ textDecoration: 'none' }}>
				<Box className='LegalTermsPage-brand-container'>
					<Logo />

					<Typography variant='h5' sx={brandNameStyled}>
						FREEKL
					</Typography>
				</Box>
			</Link>

			<Box>
				<Typography variant='h5' sx={titleStyled}>
					Conditions d'utilisation
				</Typography>
			</Box>

			<Box className='LegalTermsPage-text-container'>
				<Typography variant='h5' sx={legalTextStyled}>
				Bienvenue sur notre site, une plateforme en ligne conçue pour aider les créateurs de contenu à télécharger leurs photos et vidéos, que nous publions ensuite en leur nom sur divers réseaux sociaux. Avant d'utiliser notre plateforme, nous vous invitons à lire attentivement les conditions d'utilisation suivantes. En accédant à notre site et en l'utilisant, vous consentez à être lié par ces conditions. Si vous n'êtes pas d'accord avec l'une d'entre elles, veuillez ne pas utiliser notre site.

				<br /><br />
				1. Droits de propriété intellectuelle <br />
				Notre site et tout son contenu, incluant, sans limitation, les textes, graphiques, images, logos, et logiciels, sont la propriété de notre entreprise ou de nos partenaires. Ils sont protégés par les lois sur la propriété intellectuelle. Vous vous engagez à ne pas exploiter notre contenu à des fins commerciales sans obtenir notre autorisation écrite au préalable.

				<br /><br />
				2. Utilisation du site <br />
				Vous vous engagez à utiliser notre site en respectant toutes les lois et réglementations en vigueur, ainsi que les présentes conditions d'utilisation. Il est interdit d'utiliser notre site pour des activités illégales, frauduleuses, malveillantes, ou nuisibles, ou de tenter de contourner ou d'altérer les mesures de sécurité de notre site.

				<br /><br />
				3. Contenu de l'utilisateur <br />
				Vous êtes entièrement responsable du contenu que vous téléchargez sur notre site. Vous garantissez détenir tous les droits nécessaires sur ce contenu et affirmez ne violer aucun droit de propriété intellectuelle ou autre droit de tiers. Nous nous réservons le droit de retirer tout contenu jugé inapproprié ou en violation de ces conditions d'utilisation.

				<br /><br />
				4. Confidentialité <br />
				Votre vie privée nous est précieuse. Nous nous engageons à protéger vos données personnelles conformément à notre politique de confidentialité. En utilisant notre site, vous consentez à la collecte, à l'utilisation et au partage de vos données personnelles tel que décrit dans notre politique.

				<br /><br />
				5. Limitation de responsabilité <br />
				Nous déclinons toute responsabilité pour les dommages directs ou indirects pouvant résulter de l'utilisation de notre site. Nous ne garantissons pas que le site sera toujours disponible, exempt d'erreurs ou de virus. Nous pouvons modifier, suspendre ou interrompre l'accès à notre site à tout moment sans préavis.

				<br /><br />
				6. Modifications des conditions d'utilisation <br />
				Nous nous réservons le droit de modifier ces conditions à tout moment, sans préavis. Votre utilisation continue de notre site suite à ces modifications vaudra acceptation des nouvelles conditions.

				<br /><br />
				7. API de réseaux sociaux et acceptation des conditions tiers <br />
				En utilisant les API proposées par Freekl pour publier du contenu sur <a href='https://www.youtube.com/t/terms' target='_blank' rel='noreferrer'>YouTube</a>, <a href='https://www.meta.com/fr/legal/supplemental-terms-of-service/' target='_blank' rel='noreferrer'>Meta</a> (Facebook, Instagram), <a href='https://www.tiktok.com/legal/page/eea/terms-of-service/fr' target='_blank' rel='noreferrer'>TikTok</a>, et autres plateformes, vous acceptez également d'être lié par les conditions d'utilisation de ces services. Il est de votre responsabilité de consulter et de respecter ces conditions d'utilisation tierces.

				<br /><br />
				8. Gestion des Tokens d'Accès aux API des Réseaux Sociaux <br />
				Dans le cadre de notre service, Freekl requiert l'utilisation de tokens d'accès pour interagir avec les API des réseaux sociaux en votre nom. Ces tokens d'accès nous sont fournis lorsque vous connectez vos comptes de réseaux sociaux à notre plateforme. En acceptant nos conditions d'utilisation, vous nous autorisez explicitement à stocker et à utiliser ces tokens d'accès pour publier du contenu sur les réseaux sociaux conformément à vos instructions. <br />
				Nous conservons ces tokens d'accès de manière sécurisée dans notre base de données pour faciliter la publication de votre contenu sans nécessiter une nouvelle authentification à chaque session. Cette procédure est mise en place pour améliorer l'efficacité de nos services et pour vous offrir une expérience utilisateur fluide. <br />
				Il est important de noter que l'accès que nous obtenons via ces tokens est strictement limité aux actions que vous avez préalablement approuvées. Nous nous engageons à ne jamais dépasser les permissions que vous nous avez accordées et à respecter votre vie privée et la sécurité de vos données à tout moment.

				<br /><br />
				9. Gestion des Tokens d'Accès aux API des Réseaux Sociaux <br />
				Important: Ces étapes sont générales et peuvent varier légèrement en fonction du client d'API spécifique. <br />
				- Accédez à vos paramètres de sécurité Google: Rendez-vous sur la page Paramètres de sécurité Google à l'adresse suivante: <a href='https://myaccount.google.com/security' target='_blank' rel='noreferrer'>https://myaccount.google.com/security</a>. <br />
				- Gérer la sécurité: Sous "Applications et comptes tiers", choisissez "Gérer les applications connectées". Une liste des applications et plateformes connectées à votre compte Google s'affiche. <br />
				- Trouvez le client d'API: Recherchez le client d'API dont vous souhaitez révoquer l'accès. Il peut être listé par son nom ou par l'application qu'il représente. <br />
				- Révoquer l'accès: Cliquez sur l'entrée du client d'API. Vous devriez voir une option "Supprimer" ou "Déconnecter" (le libellé exact peut varier). Cliquez sur cette option pour révoquer l'accès. <br />
				- Confirmation: Confirmez la révocation en cliquant à nouveau sur "Supprimer" ou "Déconnecter" (si nécessaire).

				<br /><br />
				10. Loi applicable et juridiction <br />
				Ces conditions sont régies par les lois françaises. Tout litige lié à ces conditions sera soumis à la juridiction exclusive des tribunaux de Paris, France.

				<br /><br />
				Si vous avez des questions ou des préoccupations concernant ces conditions d'utilisation, veuillez nous contacter à <a href='mailto:jonas@freekl.com' target='_blank' rel='noreferrer'>
				jonas@freekl.com</a>.

				<br /><br />
				En utilisant notre site, vous acceptez ces conditions d'utilisation dans leur intégralité.

				<br /><br />
				Merci d'avoir choisi notre plateforme pour publier vos contenus sur les réseaux sociaux.
				</Typography>
			</Box>
		</Box>
	);
}
