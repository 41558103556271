/* Libraries */
import { useEffect, useState } from 'react';
import { DocumentData, FirestoreError, getDoc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { functions } from '../config/firebase.config';
import { httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';

/* Services */
import { getCustomerSocialAccountsPreference } from './customers.service';
import {
	checkDocumentExistence,
	deepCopy,
	distributeRatios,
	getUserDocReference,
	getUserDocSnapshot,
	shuffleArray
} from './_utils';

/* Types */
import { FileAvailabilityDTO, FileDTO } from './clientStorage.service.dto';
import { SocialAccount, SocialNetworkAccount } from './socialNetworks.service.dto';
import {
	CalendarEvent,
	CalendarEventData,
	CalendarEventStatus,
	GeneratedEvent,
	IPostRepartitionByAccount
} from './calendar.service.dto';
import { getFilePlatformsAvailability } from './clientStorage.service';
import { toast } from 'react-toastify';
import { ALL_SOCIAL_PLATFORMS } from './socialNetworks.service';
import moment, { Moment } from 'moment';


/**
 * @description
 * return the user calendar document reference if it exists
 */
export const getUserCalendarDocReference = () => {
	return getUserDocReference("calendar_posts");
};


/**
 * @description
 * return the user calendar document
 */
export const getUserCalendarDocSnapshot = async () => {
	return await getUserDocSnapshot(getUserCalendarDocReference);
};


/**
 * @description
 * return the user calendar data if it exists
 */
export const getUserCalendarData = async (): Promise<DocumentData | undefined> => {
	const docSnap = await getUserCalendarDocSnapshot();

	if (docSnap?.exists()) {
		return docSnap.data();
	}
	return undefined;
};


/**
 * @description
 * get all the calendar posts from the user
 * 
 * @returns {Promise<CalendarEvent[] | null>}
 */
export const getCalendarPosts = async (): Promise<CalendarEvent[] | null> => {
	const docSnap = await getUserCalendarDocSnapshot();

	if (!docSnap || !docSnap.exists()) {
		return null;
	}

	const docData = docSnap.data();
	if (!docData) {
		return null;
	}

	const rawPosts = docData?.posts ? docData.posts : null;
	if (!rawPosts) {
		return null;
	}

	/**
	 * at this point, the post is almost a CalendarEventData,
	 * but the date is a Timestamp, so we need to convert it to a Date
	 */
	const posts: CalendarEvent[] = rawPosts.map((post: any) => {
		const eventData = {
			...post,
			date: new Timestamp(post.date.seconds, post.date.nanoseconds).toDate(),
		} as CalendarEventData;

		return new CalendarEvent(eventData);
	});

	return posts;
};


/**
 * @description
 * get all the calendar posts from the user by their ID
 * 
 * @param {string} accountID the account ID to filter the posts by
 * 
 * @returns {Promise<CalendarEvent[] | null>}
 */
export const getCalendarPostsByAccountID = async (accountID: string): Promise<CalendarEvent[] | null> => {
	const posts = await getCalendarPosts();

	if (!posts) {
		return null;
	} else {
		return posts.filter((post) => post.accountID === accountID);
	}
};


const getEventStatus = (newEvent: CalendarEvent): CalendarEventStatus => {
	// if the date is in the past
	if (newEvent.date.getTime() < Date.now()) {
		if (newEvent.status === CalendarEventStatus.SCHEDULED || 
			newEvent.status === CalendarEventStatus.PROCESSING) {
			return CalendarEventStatus.ERROR_PUBLISHING;
		}
		return newEvent.status;
	} else {
		if (newEvent.status === CalendarEventStatus.PUBLISHED || 
			newEvent.status === CalendarEventStatus.PUBLISHING) {
			return newEvent.status;
		}
		return CalendarEventStatus.SCHEDULED;
	}
};

/**
 * @description
 * this function is used to add or update a calendar post
 * - if the post doesn't exist, it will be added
 * - if the post already exists, it will be updated
 * 
 * @param {CalendarEvent} newPost the account to add or update
 * 
 * @returns {Promise<void>}
 */
export const updateCalendarPosts = async (newPost: CalendarEvent): Promise<void> => {
	const docRef = getUserCalendarDocReference();
	if (!docRef) {
		return;
	}

	const docSnap = await getUserDocSnapshot(docRef);
	if (!docSnap || !docSnap.exists()) {
		await setDoc(docRef, {
			posts: [{...newPost, status: getEventStatus(newPost)}]
		}, { merge: true });
		await addSocialPostingTask(newPost);
		return;
	}
	const docData = docSnap.data();
	if (!docData) {
		return;
	}

	const posts = await getCalendarPosts();
	// If there is no post yet, we create the document
	if (!posts) {
		await setDoc(docRef, {
			posts: [{...newPost, status: getEventStatus(newPost)}]
		}, { merge: true });
		await addSocialPostingTask(newPost);

	// If there is posts already, we update the document
	} else {
		const post = posts.find((post: CalendarEvent) => post.id === newPost.id);

		// If the post already exists, we update it
		if (post) {
			// if the date has changed, we need to update the task
			const hasChangedDate = post.date.getTime() !== newPost.date.getTime();

			post.shallowMerge(newPost);

			const newStatus = getEventStatus(post);

			await updateDoc(docRef, {
				posts: [
					...posts.filter((post: CalendarEvent) => post.id !== newPost.id)
						.map((post) => post.eventData()),
					{...post, status: newStatus}
				]
			});
			if (hasChangedDate || newStatus !== post.status) {
				await findDeleteSocialPostingTask(post.id);
				await addSocialPostingTask(post);
			}

		// If the post doesn't exist, we add it to the list
		} else {
			await updateDoc(docRef, {
				posts: [
					...posts.map((post) => post.eventData()),
					{...newPost, status: getEventStatus(newPost)}
				]
			});
			await addSocialPostingTask(newPost);
		}
	}
};


export const addGeneratedCalendarPosts = async (posts: readonly GeneratedEvent[], scheduleTasks: boolean = false): Promise<void> => {
	// convert the generated events to calendar events
	const newPosts = posts.map((post: GeneratedEvent) => {
		return new CalendarEvent(post);
	});

	await addCalendarPosts(newPosts, scheduleTasks);
};


export const addCalendarPosts = async (posts: readonly CalendarEvent[], scheduleTasks: boolean = false): Promise<void> => {
	const docRef = getUserCalendarDocReference();
	
	if (docRef) {
		const docSnap = await getDoc(docRef);
		const data = docSnap.data();

		if (docSnap.exists() && data?.posts) {
			await updateDoc(docRef, {
				posts: [...data.posts, ...posts.map((post) => post.eventData())]
			});
		} else {
			await setDoc(docRef, {
				posts: posts.map((post) => post.eventData())
			}, { merge: true });
		}

		// add the social posting tasks
		if (scheduleTasks) {
			await Promise.all(posts.map((post) => addSocialPostingTask(post)));
		}
	}
};


/**
 * @description
 * create a Google cloud task to schedule a post
 * 
 * @param event the event to schedule
 */
export const addSocialPostingTask = async (event: CalendarEvent): Promise<void> => {

	if (event.date.getTime() < Date.now()) {
		console.error('addSocialPostingTask(): date is in the past');
		return;
	}

	const fn = httpsCallable<{
		docID: string,
		postID: string,
		targetDate: {
			seconds: number,
			nanoseconds: number
		}
	}>(functions, 'addSocialPostingTask');

	const postID = event.id;
	const uid = getAuth().currentUser?.uid || '';
	const date = Timestamp.fromDate(event.date);

	if (!postID || !uid) {
		return;
	}

	try {
		await fn({
			postID: postID,
			docID: uid,
			targetDate: {
				seconds: date.seconds,
				nanoseconds: date.nanoseconds
			}
		});
	} catch (error) {
		console.error(error);
	}
};


/**
 * @description
 * delete a Google cloud task based on the postID
 * 
 * @param postID the post ID to delete
 */
export const findDeleteSocialPostingTask = async (postID: string): Promise<void> => {
	if (!postID) {
		return;
	}

	const fn = httpsCallable(functions, 'findDeleteSocialPostingTask');
	try {
		await fn({ postID: postID });
	} catch (error) {
		console.error(error);
	}
};


/**
 * @description
 * delete the user calendar post(s) in the database
 * @param postID the post ID to delete
 * - undefined: delete all posts
 * - string: delete the post with the given ID
 * - string[]: delete the posts with the given IDs
 * 
 * @returns Promise<void>
 * 
 * @example
 * deleteCalendarPost(); // delete all posts
 * deleteCalendarPost('postID'); // delete the post with the given ID
 * deleteCalendarPost(['postID1', 'postID2']); // delete the posts with the given IDs
*/
export async function deleteCalendarPost(): Promise<void>;
export async function deleteCalendarPost(postID: string): Promise<void>;
export async function deleteCalendarPost(postsID: string[]): Promise<void>;
export async function deleteCalendarPost(postID?: unknown): Promise<void> {
	if (typeof postID === 'string') {
		return deleteCalendarPost([postID]);
	} else if (Array.isArray(postID)) {
		const ref = getUserCalendarDocReference();
		const data = await getUserCalendarData();

		if (ref && data) {
			const posts = data.posts as CalendarEventData[];
			const newPosts = posts.filter((post) => !postID.includes(post.id));
			const oldPosts = posts.filter((post) => postID.includes(post.id));
			const oldPostsToDelete = oldPosts.filter((post) => post?.status !== CalendarEventStatus.PUBLISHED);

			await setDoc(ref, {
				posts: newPosts,
			}, { merge: true });

			await Promise.all(oldPostsToDelete.map((post) => findDeleteSocialPostingTask(post?.id || '')));
		}
	} else if (postID === undefined) {
		const ref = getUserCalendarDocReference();
		const data = await getUserCalendarData();

		if (ref && data) {
			const posts = data.posts as CalendarEventData[];
			const postsToDelete = posts.filter((post) => post?.status !== CalendarEventStatus.PUBLISHED);

			await setDoc(ref, {
				posts: [],
			}, { merge: true });

			await Promise.all(postsToDelete.map((post) => findDeleteSocialPostingTask(post?.id || '')));
		}
	} else {
		throw new Error('Invalid parameter type');
	}
}


/**
 * @description
 * get the user calendar posts from the database and listen to changes
 * 
 * @returns {CalendarEvent[] | null} the user calendar posts
 * @returns {boolean} true if the snapshot is loading
 * @returns {FirestoreError | undefined} the snapshot error
 */
export const useCalendarPosts = (): [CalendarEvent[] | null, boolean, FirestoreError | undefined] => {
	const [calendarSnapshot, calendarSnapshotLoading, calendarSnapshotError] = useDocumentData(getUserCalendarDocReference());
	const [calendarPosts, setCalendarPosts] = useState<CalendarEvent[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);


	useEffect(() => {
		checkDocumentExistence(getUserCalendarDocReference);
		refreshCalendarPosts();
		// eslint-disable-next-line
	}, [calendarSnapshot]);

	useEffect(() => {
		setLoading(calendarSnapshotLoading ?? loading);
	}, [loading, calendarSnapshotLoading]);

	useEffect(() => {
		setError(error ?? !!calendarSnapshotError);
	}, [error, calendarSnapshotError]);


	const refreshCalendarPosts = async () => {
		setError(false);
		setLoading(true);

		if (calendarSnapshot && calendarSnapshot.posts) {
			const posts: CalendarEvent[] = calendarSnapshot.posts.map((post: any) => {
				/**
				 * at this point, the post is almost a CalendarEventData,
				 * but the date is a Timestamp, so we need to convert it to a Date
				 */
				const newPost: CalendarEventData = {
					...post,
					date: new Timestamp(post.date.seconds, post.date.nanoseconds).toDate(),
				};

				return new CalendarEvent(newPost);

			// sort the posts by date (descending)
			}).sort((a: CalendarEvent, b: CalendarEvent) => b.date.getTime() - a.date.getTime());

			setCalendarPosts(posts);
		}

		setLoading(false);
	};


	return [calendarPosts, calendarSnapshotLoading, calendarSnapshotError];
}


/**
 * @description
 * distribute the files to the accounts based on the accounts priorities and the
 * files available platforms. The files are distributed in a way that the
 * accounts with the highest priority will have the most files.
 * 
 * @param files the files to distribute
 * @param accounts the accounts to distribute the files to
 * @returns {IPostRepartitionByAccount[]} the files repartition by account
 */
const _getFileRepartitionByAccount = async (
	files: FileAvailabilityDTO[],
	accounts: readonly SocialAccount[],
): Promise<IPostRepartitionByAccount[]> => {
	const socialAccountsPreference = await getCustomerSocialAccountsPreference();

	// divide the priorities of each platform by the number of accounts of the same platform
	const accountsPriorities: number[] = accounts.map((account: SocialAccount): number => {
		const platformID = account.platformID;

		// get the accounts of the same platform
		const accountsByPlatform = accounts.filter((account) => account.platformID === platformID);

		// get the priority of the platform
		const platformPref = socialAccountsPreference.accounts.find((account) => account.platformID === platformID);
		const platformPrefPriority = platformPref?.priority || 0;

		// divide the platform priority by the number of accounts of the same platform
		return platformPrefPriority / accountsByPlatform.length;
	});


	/**
	 * In the case where the user is not logged in on all the platforms, the sum
	 * of the priorities will be less than 1.
	 * We need to normalize the priorities to get a sum of 1 prior to the
	 * distribution.
	 */
	const accountsPrioritiesSum = accountsPriorities.reduce((a, b) => a + b, 0);
	const accountsPrioritiesNormalized = accountsPriorities.map((priority: number): number => priority / accountsPrioritiesSum);


	// create the repartition object
	const postRepartitionByAccount: IPostRepartitionByAccount[] = accounts.map((account: SocialAccount, index: number): IPostRepartitionByAccount => {
		return {
			account,
			files: [],
			platformID: account.platformID,
			priority: accountsPrioritiesNormalized[index],
			postCount: 0,
		}
	}).sort((a, b) => {
		// sort depending on the number of files available for the platform (ascending)
		const aFiles = files.filter((file) => file.availablePlatforms.includes(a.platformID));
		const bFiles = files.filter((file) => file.availablePlatforms.includes(b.platformID));
		
		return aFiles.length - bFiles.length;
	});


	let filesCopy = deepCopy(files);
	const socialsPlatforms = [...ALL_SOCIAL_PLATFORMS].sort((a, b) => {
		// sort depending on the number of files available for the platform (ascending)
		const aFiles = files.filter((file) => file.availablePlatforms.includes(a.platformID));
		const bFiles = files.filter((file) => file.availablePlatforms.includes(b.platformID));
		
		return aFiles.length - bFiles.length;
	});


	// distribute the files to the accounts
	for (const platform of socialsPlatforms) {
		const platformID = platform.platformID;
		const accountsByPlatform = postRepartitionByAccount.filter((account) => account.platformID === platformID);

		if (accountsByPlatform.length === 0) {
			continue;
		}

		// distribute the files to the accounts of the platform
		const availableFiles = filesCopy.filter((file: FileAvailabilityDTO) => file.availablePlatforms.includes(platformID));

		// distribute the files to the accounts of the platform
		const prioritiesSum = accountsByPlatform.reduce((a, b) => a + b.priority, 0);
		const prioritiesNormalized = accountsByPlatform.map((account) => account.priority / prioritiesSum);

		const distribution = distributeRatios(availableFiles.length, prioritiesNormalized);

		// add the files to the accounts
		distribution.forEach((filesCount: number, index: number) => {
			const account = accountsByPlatform[index];
			account.files = availableFiles.slice(0, filesCount);
			account.postCount = filesCount;
		});

		// remove the files from the filesCopy
		filesCopy = filesCopy.filter((file) => !availableFiles.map((file) => file.id).includes(file.id));

		// if there are no more files, stop the loop
		if (filesCopy.length <= 0) {
			break;
		}
	}


	/**
	 * Randomize the order of the accounts to avoid posting on the same platform
	 * multiple times in a row.
	 * (If the user has multiple accounts on the same platform)
	 */
	return shuffleArray(postRepartitionByAccount);
};


const _getFileListByPlatform = async (files: readonly FileDTO[]): Promise<FileAvailabilityDTO[]> => {

	const fileListByPlatform: FileAvailabilityDTO[] = await Promise.all(
		files.map((file) => getFilePlatformsAvailability(file))
	);

	// filter the files that cannot be posted on any platform
	const listFiltered = fileListByPlatform.filter((file) => {
		const isEmptyPlatform = file.availablePlatforms.length <= 0;

		if (isEmptyPlatform) {
			toast.info(`The file "${file.name}" cannot be posted on any platform because its format is not supported by any of your accounts.`);
		}

		return !isEmptyPlatform;
	});

	// sort the files by the rarest platform first
	return listFiltered.sort((a: FileAvailabilityDTO, b: FileAvailabilityDTO): number => {
		const platformCounts: Record<string, number> = {};

		// count the number of files for each platform
		listFiltered.forEach((file) => {
			file.availablePlatforms.forEach((platform) => {
				if (!platformCounts[platform]) {
					platformCounts[platform] = 0;
				}
				platformCounts[platform]++;
			});
		});
	
		// sort by the least available platform first
		const aPlatformCount = Math.min(...a.availablePlatforms.map((platform) => platformCounts[platform]));
		const bPlatformCount = Math.min(...b.availablePlatforms.map((platform) => platformCounts[platform]));
		return aPlatformCount - bPlatformCount;
	});
};


/**
 * @description
 * generate the user calendar by applying the algorithm
 */
export const generateUserCalendar = async (
	files: readonly FileDTO[],
	accounts: readonly SocialAccount[]
): Promise<GeneratedEvent[]> => {

	// results of the algorithm
	const generatedEvents: GeneratedEvent[] = [];

	// should never happen
	if (files.length <= 0 || accounts.length <= 0) {
		return generatedEvents;
	}

	// get the list of files that can be posted on each platform
	const fileListByPlatform = await _getFileListByPlatform(files);

	// distribute the files by account
	const postRepartition = await _getFileRepartitionByAccount(fileListByPlatform, accounts);

	/**
	 * Generate the posts for each account and add them to the 'generatedEvents'
	 * array.
	 */
	await Promise.all(
		postRepartition.map(async (repartition: IPostRepartitionByAccount) => {
			const socialNetworkAccount: SocialNetworkAccount = repartition.account.account;

			const datesRepartition = await repartition.account.getPostsRepartition(socialNetworkAccount, repartition.files);
			console.log('generateUserCalendar(): datesRepartition', datesRepartition, repartition.files);

			if (datesRepartition.length <= 0 || repartition.files.length !== datesRepartition.length) {
				return ;
			}

			// create the events based on the post repartition
			datesRepartition.forEach((date: Date, i: number) => {
				generatedEvents.push({
					id: uuidv4(),
					date: date,
					accountID: socialNetworkAccount.accountID,
					platformID: socialNetworkAccount.platformID,
					filesIds: [repartition.files[i].id],
				});
			});
		})
	);

	// sort the generated events by date
	return generatedEvents.sort((a, b) => (a?.date?.getTime() || 0) - (b?.date?.getTime() || 0));
}


export const generateBestPostingTime = async (
	account: SocialAccount,
	event: CalendarEvent,
	loadingFunc: (loading: React.SetStateAction<boolean>) => void,
	updateDateFunc: (date: Date) => void,
) => {
	loadingFunc(true);

	try {
		// get the files from the event
		const tmpFiles = await event.getFiles();
		if (!tmpFiles || tmpFiles.length === 0) {
			throw new Error('No files');
		}

		// create an array of duplicate of the first file to generate a calendar
		const dupFileList = Array.from({ length: 20 }, () => tmpFiles[0]);

		// generate the calendar
		const calendar = await generateUserCalendar(dupFileList, [account]);
		if (!calendar || calendar.length === 0) {
			throw new Error('No calendar');
		}

		// sort the generated calendar by date
		const sortedEvents = calendar.sort((a, b) => moment(a.date).diff(moment(b.date)));

		/**
		 * find the base date to compare with the nearest event:
		 * - If the event date is in the past, we use the current date
		 * - If the event date is in the future, we use the event date at 00:00.
		 * 
		 * We don't want to base our logic on the event's date AND time because
		 * if it's set, let's say, at 23:59 in 2 days, and the calendar
		 * generated an event at 11:00 for the same day, when we try to find the
		 * best event we will do the research after 23:59, which is wrong
		 * because the event at 11:00 is the best event.
		 * Though, if the event's date is today, actually want to find the
		 * nearest event after the current time.
		 */
		let baseDate: Moment;
		if (moment(event.date).isBefore()) {
			baseDate = moment();
		} else {
			baseDate = moment(event.date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
		}
		
		// find the best event in the calendar to the base date calculated
		const bestEvent = sortedEvents.find((e) => moment(e.date).isAfter(baseDate));
		if (!bestEvent) {
			throw new Error('No nearest event');
		}

		// if the date is not the same as the current event date, we want to show a toast explaining this
		if (!moment(bestEvent.date).isSame(moment(event.date), 'day')) {
			toast.info(`Le jour de publication a dû être changé car il n'y a plus de créneau optimal pour le jour initial.`);
		}

		bestEvent.date && updateDateFunc(bestEvent.date);
	} catch (error) {
		console.error(error);
	} finally {
		loadingFunc(false);
	}
}
