/* Libraries */
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { getBlob, ref } from 'firebase/storage';
import { storage } from '../../../config/firebase.config';
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';

/* Components */

/* Services */
import {
	CalendarEvent,
	CalendarEventData
} from '../../../services/calendar.service.dto';

/* MUI */
import { Box } from '@mui/system';
import { Skeleton } from '@mui/material';

/* Icons */

/* DTO */
import { ThumbnailSelectorProps } from './ThumbnailSelectorDTO';

/* CSS */
import './ThumbnailSelector.css';


export default function ThumbnailSelector(props: ThumbnailSelectorProps) {

	const {
		setEvent,
		event,
		files,
	} = props;

	const [thumbs, setThumbs] = useState<string[]>([]);
	const [thumbsLoading, setThumbsLoading] = useState<boolean>(false);
	const [thumbCursor, setThumbCursor] = useState(event.metadata?.videoCoverPosition || 0);
	const [isDraggingThumb, setIsDraggingThumb] = useState(false);
	const thumbVideoRef = useRef<any>(null);


	useEffect(() => {
		loadThumbnails();

		return () => {
			setThumbs([]);
			setThumbsLoading(false);
			setThumbCursor(0);
			setIsDraggingThumb(false);
		}
		// eslint-disable-next-line
	}, [files]);


	const loadThumbnails = async () => {
		setThumbsLoading(true);
		if (event) {
			const tmpEvent = new CalendarEvent(event as CalendarEventData);
			const files = await tmpEvent.getFiles();

			if (files && files.length === 1 && files[0].metadata?.contentType?.includes('video')) {
				// load file from url as a new video
				const f = files[0];

				if (f?.thumbnails?.md?.fullPath) {
					const fileRef = ref(storage, f.thumbnails.md.fullPath);
					const blob = await getBlob(fileRef);
					const file = new File([blob], f.name, { type: f.metadata?.contentType || 'video/mp4' });
					const thumbs = await generateVideoThumbnails(file, 10, f.metadata?.contentType || 'video/mp4')

					setThumbs(thumbs);
					setThumbsLoading(false);
					return ;
				}
			}
		}

		// if the event has no thumbnail, we don't need to load it
		setThumbs([]);
		setThumbsLoading(false);
	};

	const handleThumbMouseSelection = (e: MouseEvent<any>) => {
		const cursorWidth = thumbVideoRef.current?.getBoundingClientRect().width || 0;
		const currentX = e.clientX - e.currentTarget.getBoundingClientRect().left;
		const currentWidth = e.currentTarget.clientWidth;

		let newPosition = (currentX - (cursorWidth / 2)) / currentWidth;
		if (currentX + (cursorWidth / 2) > currentWidth) {
			newPosition = (currentWidth - cursorWidth) / currentWidth;
		} else if (currentX - (cursorWidth / 2) < 0) {
			newPosition = 0;
		}
		setThumbCursor(newPosition);
		thumbVideoRef.current.currentTime = newPosition * thumbVideoRef.current.duration;
	}

	const handleThumbMouseUp = () => {
		setIsDraggingThumb(false);
		setEvent({
			...event,
			metadata: {
				...event.metadata,
				videoCoverTimestamp: thumbVideoRef.current.currentTime,
				videoCoverPosition: thumbCursor,
			},
		});
	}


	return (
		<>
			{ thumbsLoading && (
				<Box className='ThumbnailSelector-container'>
					<Skeleton variant='rectangular' className='ThumbnailSelector-skeleton' />
				</Box>
			)}

			{ !thumbsLoading && (
				<Box className='ThumbnailSelector-container'
					onMouseDown={(e) => {
						e.preventDefault(); // Prevent text selection
						setIsDraggingThumb(true);
						handleThumbMouseSelection(e);
					}}
					onMouseUp={handleThumbMouseUp}
					onMouseLeave={handleThumbMouseUp}
					onMouseMove={(e) => {
						if (!isDraggingThumb)
							return ;
						handleThumbMouseSelection(e);
					}}
				>
					{/* cursor on the images */}
					{/* still frame on top of the cursor */}
					<video className='ThumbnailSelector-mini-video'
						style={{ left: `${thumbCursor * 100}%` }}
						ref={thumbVideoRef}
						src={files[0]?.thumbnails?.md?.url || ''}
						controls={false}
						preload='auto'

						// when it has loaded, we set the cursor to the right position
						onLoadedData={() => {
							thumbVideoRef.current.currentTime = event?.metadata?.videoCoverTimestamp || 0;
						}}
					/>

					{ thumbs.map((thumb, index, thumbsArray) => (
						// display the thumbnail (in base64)
						<img
							key={index}
							src={thumb}
							alt={`thumbnail-${index}`}
							style={{
								position: 'relative',
								height: '100%',
								width: `${100 / thumbsArray.length}%`,
								borderRadius: `${index === 0 ? '12px 0 0 12px' : index === thumbsArray.length - 1 ? '0 12px 12px 0' : '0'}`,
							}}
						/>
					))}
				</Box>
			)}
		</>
	);
}