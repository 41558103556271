/* Libraries */

/* Components */
import PageHeader from '../../assets/PageHeader/PageHeader';
import LibraryFileList from '../../components/LibraryFileList/LibraryFileList';

/* MUI */
import { Box } from '@mui/system';

/* Icons */

/* CSS */
import './LibraryPage.css';


export default function LibraryPage() {
	return (
		<Box className='LibraryPage-container'>
			<PageHeader title='Bibliothèque de fichiers' />

			<LibraryFileList />
		</Box>
	);
}
