/* Libraries */
import { functions } from '../config/firebase.config';
import { httpsCallable } from 'firebase/functions';
import moment from 'moment';

/* Services */
import { deleteSocialAccount, getSocialAccounts } from "./socialNetworks.service";

/* Types */
import { YoutubeInsightReachResponse, YoutubeInsightReachRow, YoutubeTokenResponse } from './youtube.service.dto';


/**
 * @description
 * youtube base url.
 * used in youtube api calls
 */
export const YOUTUBE_BASE_URL = `https://www.googleapis.com/youtube/v3`;


/**
 * @description
 * youtube client id
 */
export const YOUTUBE_CLIENT_ID = '531382514324-9o1i6chnleu5jn0jgp6ee3hqvoauhegb.apps.googleusercontent.com';


/**
 * @description
 * youtube client id
 */
export const YOUTUBE_API_KEY = 'AIzaSyB4hc6JJCWbrzdDHb14hXQHzcuaoAhn4Zk';


/**
 * @description
 * create the YouTube oauth2 url to redirect the user to
 */
export const getYoutubeOauth2URL = (): string => {
	const domain = process.env.NODE_ENV === 'production' ? 'freekl.com' : 'localhost:3000';

	const scope: string[] = [
		'https://www.googleapis.com/auth/youtube.readonly',
		'https://www.googleapis.com/auth/youtube',
		'https://www.googleapis.com/auth/youtube.force-ssl',
		'https://www.googleapis.com/auth/youtube.upload',
		'https://www.googleapis.com/auth/youtubepartner',
		'https://www.googleapis.com/auth/youtubepartner-channel-audit',
		'https://www.googleapis.com/auth/youtube.download',
		'https://www.googleapis.com/auth/yt-analytics.readonly',
		'https://www.googleapis.com/auth/yt-analytics-monetary.readonly',
	];

	// Parameters to pass to OAuth 2.0 endpoint.
	const params: any = {
		client_id: YOUTUBE_CLIENT_ID,
		redirect_uri: `https://${domain}/dashboard/_auth/youtube`,
		response_type: 'code',
		scope: scope.join(' '),
		include_granted_scopes: 'true',
		state: 'pass-through value',
		access_type: 'offline',
		// TODO: crsf token here
	};

	// Convert object to encoded URL query string
	const searchParams = new URLSearchParams(params);

	// Create OAuth 2.0 endpoint URL
	return `https://accounts.google.com/o/oauth2/v2/auth?${searchParams}`;
}


/**
 * @description
 * update the user's YouTube data in the database
 */
export const youtubeLogout = async (id: string): Promise<void> => {
	await deleteSocialAccount(id);
};


/**
 * @description
 * get the YouTube access token from the database
 * 
 * @param channelID the channel id (accountID in the database)
 * 
 * @returns the access token
 */
export const getYoutubeAccessToken = async (channelID: string): Promise<string | undefined> => {
	if (!channelID) {
		return undefined;
	}

	const accounts = await getSocialAccounts();
	if (!accounts) {
		return undefined;
	}

	const account = accounts.find(account => account.accountID === channelID);
	if (account) {
		return await account.token();
	}

	return undefined;
}


export const createYoutubeAccessToken = async (code: string): Promise<YoutubeTokenResponse | null> => {
	if (!code) {
		return null;
	}
	console.log(code);
	const youtubeCreateAccessToken = httpsCallable(functions, 'youtubeCreateAccessToken');

	try {
		const req = await youtubeCreateAccessToken({ code: code, env: process.env.NODE_ENV });
		const response = req.data as YoutubeTokenResponse;
		console.log(req.data);

		if (!response?.error) {
			return response;
		} else {
			throw new Error('Error refreshing token');
		}
	} catch (error) {
		return null;
	}
}


export const refreshYoutubeAccessToken = async (refreshToken: string): Promise<YoutubeTokenResponse | null> => {
	if (!refreshToken) {
		return null;
	}

	const youtubeRefreshToken = httpsCallable(functions, 'youtubeRefreshToken');

	try {
		const req = await youtubeRefreshToken({ refreshToken: refreshToken });
		const response = req.data as YoutubeTokenResponse;
		console.log(req.data);

		if (!response?.error) {
			return response;
		} else {
			throw new Error('Error refreshing token');
		}
	} catch (error) {
		return null;
	}
}


/**
 * @description
 * get the YouTube channel infos
 */
export const getYoutubeChannelInfos = async (channelID?: string, accessToken?: string): Promise<Record<string, any>> => {
	const token = accessToken || await getYoutubeAccessToken(channelID || '');
	if (!token) {
		return {};
	}

	return await fetch(`${YOUTUBE_BASE_URL}/channels?part=snippet,contentDetails,statistics&mine=true`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'Authorization': `Bearer ${token}`
		}
	})
	.then(res => res.json())
	.then(res => {

		// return only the first item (which is the only one)
		const items = res.items[0];

		return {
			...res,
			items: items
		}
	})
	.catch(err => err);
}


/**
 * @description
 * Get the YouTube channel insights for a period
 * 
 * @param channelID the channel id (accountID in the database)
 * 
 * @param period
 * 	- day
 * 	- week
 * 	- month
 * 	- year
 */
export const getYoutubeInsightReach = async (channelID: string, period: 'day' | 'week' | 'month' | 'year' = 'week'): Promise<YoutubeInsightReachRow[] | null> => {
	const accessToken = await getYoutubeAccessToken(channelID);
	if (!accessToken) {
		console.log('no accessToken')
		return null;
	}

	const metrics = [
		'averageViewDuration',
		'estimatedMinutesWatched',
		'views',
		'likes',
		'shares',
		'comments'
	];
	const dimensions = [
		'day'
	];
	const endDate = moment().subtract(1, 'day');
	const startDate = moment().subtract(1, 'day').subtract(1, period);
	const endDateString = endDate.format('YYYY-MM-DD');
	const startDateString = startDate.format('YYYY-MM-DD');

	const url = `https://youtubeanalytics.googleapis.com/v2/reports?ids=channel==MINE&metrics=${metrics.join(',')}&dimensions=${dimensions.join(',')}&startDate=${startDateString}&endDate=${endDateString}`;

	const res: YoutubeInsightReachResponse = await fetch(url, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'Authorization': `Bearer ${accessToken}`
		}
	})
	.then(res => res.json())
	.catch(err => err);

	if (res?.error) {
		return null;
	}


	/**
	 * conversion of the response to a list of objects
	 * @see YoutubeInsightReachRow
	 */
	const list: YoutubeInsightReachRow[] = res.rows.map((row) => {
		return res.columnHeaders.reduce((acc, header, index: number) => {
			return {
				...acc,
				[header.name]: row[index]
			};
		}, {});
	}) as YoutubeInsightReachRow[];

	return list;
}


export const getYoutubeMetrics = async (channelID: string) => {
	if (!channelID) {
		return null;
	}

	const youtubeMetrics = httpsCallable(functions, 'youtubeMetrics');

	try {
		const req = await youtubeMetrics({ accountID: channelID });
		// const response = req.data as YoutubeTokenResponse;
		console.log(req.data);

		return req.data;

		// if (!response?.error) {
		// 	return response;
		// } else {
		// 	throw new Error('Error refreshing token');
		// }
	} catch (error) {
		return null;
	}
}
