import { FileDTO } from "../../services/clientStorage.service.dto";


export enum LibraryFileListFilterType {
	ALL = 'all',
	IMAGE = 'image',
	VIDEO = 'video',
};


/**
 * @description: DTO for LibraryFileList component
 * 
 * @param fileSelectionMode boolean (false by default)
 * if true, the user can select files (to delete them or call a callback on them)
 * 
 * @param enforceSelectionMode boolean (false by default)
 * if true, the user can't change the mode & the mode is always fileSelectionMode
 * 
 * @param disableSearchBar boolean (false by default)
 * if true, the search bar is not displayed
 * 
 * @param disableStorageProgress boolean (false by default)
 * if true, the storage progress is not displayed
 * 
 * @param disableUpload boolean (false by default)
 * if true, the upload button is not displayed
 * 
 * @param singleFileSelection boolean (false by default)
 * if true, when a file is selected, the onValidateSelection() callback is called
 * it is equivalent to `validateOnClick'
 * 
 * @param validateOnClick boolean (false by default)
 * if true, the user can validate his selection by clicking on a file
 * 
 * @param selectedFilesIDs string[] ([] by default)
 * file ids that are pre-selected when opening the library
 * 
 * @param showSelectionOrder boolean (false by default)
 * if true, the user can see the order of his selection (1st, 2nd, 3rd, ...)
 * 
 * @param filetypeFilterBy @type LibraryFileListFilterType
 * pre-filter by type (i.e: display only videos)
 * 
 * @param onValidateSelection callback when a file is selected
 * triggers when the user selects a file
 */
export interface LibraryFileListDTO {
	fileSelectionMode?: boolean; // false by default
	enforceSelectionMode?: boolean; // false by default, if true, the user can't change the mode
	disableSearchBar?: boolean; // false by default
	disableStorageProgress?: boolean; // false by default
	disableUpload?: boolean; // false by default
	singleFileSelection?: boolean; // false by default
	validateOnClick?: boolean; // false by default
	selectedFilesIDs?: string[]; // [] by default
	showSelectionOrder?: boolean; // false by default
	filetypeFilterBy?: LibraryFileListFilterType; // all by default
	onValidateSelection?: (files: FileDTO[]) => void; // callback when a file is selected
}


/**
 * @description
 * files sorting modes
 */
export type sortingModeType = 'up' | 'down';
