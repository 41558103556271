/* Libraries */
import { ChangeEvent, Fragment, MouseEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

/* Components */
import ClientStorageProgressBar from '../ClientStorageProgressBar/ClientStorageProgressBar';
import Modal from '../../assets/Modal/Modal';

/* Services */
import { FileDTO } from '../../services/clientStorage.service.dto';
import {
	APP_ACCEPTED_FILETYPES,
	APP_ACCEPTED_FILE_EXTENSIONS,
	deleteFile,
	getClientStorageBaseRef,
	getFileList,
	getFilePlatformsAvailability,
	updateFile,
	uploadFileList
} from '../../services/clientStorage.service';
import { ALL_SOCIAL_PLATFORMS } from '../../services/socialNetworks.service';
import { getCalendarPosts } from '../../services/calendar.service';

/* MUI */
import { Box } from '@mui/system';
import { Button, Checkbox, CircularProgress, Collapse, Divider, Grow, IconButton, InputAdornment, LinearProgress, List, Skeleton, TextField, Tooltip, Typography } from '@mui/material' ;
import { LoadingButton } from '@mui/lab';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

/* Icons */
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';
import PageviewIcon from '@mui/icons-material/Pageview';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import BlurOffIcon from '@mui/icons-material/BlurOff';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import WindowIcon from '@mui/icons-material/Window';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import VideocamIcon from '@mui/icons-material/Videocam';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

/* DTO */
import { LibraryFileListDTO, LibraryFileListFilterType, sortingModeType } from './LibraryFileListDTO';
import { SocialNetworkName } from '../../services/socialNetworks.service.dto';
import { CalendarEvent } from '../../services/calendar.service.dto';

/* CSS */
import './LibraryFileList.css';


const fileNameStyled = {
	color: '#000',
	fontWeight: 'bold',
	fontSize: '.8rem',
};

const fileTypesStyled = {
	color: '#000',
	fontSize: '.7rem',
};

const modalInfoItemTitleStyled = {
	color: '#666',
	fontWeight: 600,
};

const modalInfoItemContentStyled = {
	color: '#222',
	fontWeight: 400,
	fontFamily: 'IBM Plex Mono, Roboto Mono, monospace',
};

export default function LibraryFileList(props: LibraryFileListDTO) {

	const {
		fileSelectionMode = false,
		enforceSelectionMode = false,
		disableSearchBar = false,
		disableStorageProgress = false,
		disableUpload = false,
		validateOnClick = false,
		singleFileSelection = false,
		selectedFilesIDs = [],
		showSelectionOrder = false,
		filetypeFilterBy = LibraryFileListFilterType.ALL,
		// onValidateSelection -> didn't use default value because if it's not defined, we DON'T want to show the `validate selection` button
	} = props;

	const [selectionMode, setSelectionMode] = useState(enforceSelectionMode ? true : fileSelectionMode);
	const [selectedFile, setSelectedFile] = useState<FileDTO | null>(null);
	const [selectedFileName, setSelectedFileName] = useState<{
		fullname: string;
		name: string;
		extension: string;
	}>({ fullname: '', name: '', extension: '' });
	const [selectedFilePlatforms, setSelectedFilePlatforms] = useState<SocialNetworkName[]>([]);
	const [selectedFileEventsUsage, setSelectedFileEventsUsage] = useState<Array<CalendarEvent & {imageURL: string}>>([]);
	const [sortContentMode, setSortContentMode] = useState<sortingModeType>('down');
	const [searchContent, setSearchContent] = useState('');
	const [filterByType, setFilterByType] = useState<LibraryFileListFilterType>(filetypeFilterBy);

	const [filelist, setFilelist] = useState<FileDTO[]>([]);
	const [filelistWithUsage, setFilelistWithUsage] = useState<{ id: string, used: boolean }[]>([]);
	const [filterByUsage, setFilterByUsage] = useState<{ checked: boolean, loading: boolean }>({ checked: false, loading: false });
	const [displayableFilelist, setDisplayableFilelist] = useState<FileDTO[]>([]);
	const [loadingList, setLoadingList] = useState(true);
	const [loadingUpload, setLoadingUpload] = useState(false);
	const [loadingDeleteSelectedFiles, setLoadingDeleteSelectedFiles] = useState(false);
	const [loadingFilenameEdition, setLoadingFilenameEdition] = useState(false);
	const [uploadLoadingProgress, setUploadLoadingProgress] = useState(0);
	const [filtersOpen, setFiltersOpen] = useState(false);

	const [refreshStorageProgressBar, setRefreshStorageProgressBar] = useState(0);


	useEffect(() => {
		handleLoadListFiles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const handleLoadListFiles = async (showLoading: boolean = true) => {
		if (showLoading) {
			setLoadingList(true);
		}

		const fileList = await getFileList() || [];

		// select the files that have been selected before
		const filteredFileList = fileList.map((file: FileDTO) => {
			file.checked = selectedFilesIDs.includes(file.id);
			return file;
		});

		setFilelist(filteredFileList);
		setDisplayableFilelist(filteredFileList);
		sortListByCurrentMode(sortContentMode);

		if (showLoading) {
			setLoadingList(false);
		}
		setRefreshStorageProgressBar((prev) => prev + 1);
	};

	const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
		const onProgressUpload = async (progress: number) => {
			setUploadLoadingProgress(progress);
		};

		const onErrorUpload = async (error: Error) => {
			toast.error(error.message);
		};

		const onCompletedUpload = async (file: File) => {
			toast.success(`${file.name} chargé avec succès`);
		};

		const onAllCompletedUploads = async () => {
			setUploadLoadingProgress(0);
			await handleLoadListFiles(false);
		};

		setLoadingUpload(true);
		await uploadFileList(
			e.target.files,
			onProgressUpload,
			onErrorUpload,
			onCompletedUpload,
			onAllCompletedUploads
		);
		setLoadingUpload(false);
	};

	const handleSelectFile = (url: string) => {
		// if we're not in the selection mode, we can't select a file
		if (!selectionMode) {
			return ;
		}

		const newFilelist = filelist.map((file: FileDTO) => {
			if (file.url === url) {
				file.checked = !file.checked;
			}
			return file;
		});
		setFilelist(newFilelist);

		// when we want to validate the selection on click
		if (validateOnClick || singleFileSelection) {
			const f = newFilelist.find((file: FileDTO) => file.url === url);

			handleValidateSelection(f);
		}
	};

	const handleDeleteSelectedFiles = async () => {
		const baseRef = getClientStorageBaseRef();

		if (null === baseRef) {
			return ;
		}

		setLoadingDeleteSelectedFiles(true);
		const selectedFiles = filelist.filter((file: FileDTO) => file.checked);
		const fileIDs = selectedFiles.map((file: FileDTO) => file.id);

		await deleteFile(fileIDs);
		await handleLoadListFiles(false);
		setSelectionMode(false);
		setLoadingDeleteSelectedFiles(false);
	};

	const handleDeleteFile = async (file: FileDTO) => {
		const baseRef = getClientStorageBaseRef();

		if (null === baseRef) {
			return ;
		}

		setLoadingDeleteSelectedFiles(true);
		await deleteFile(file.id);
		await handleLoadListFiles(false);
		setLoadingDeleteSelectedFiles(false);
		handleCloseItemModal();
	};

	const handleDeselectAllFiles = () => {
		const newFilelist = filelist.map((file: FileDTO) => {
			file.checked = false;
			return file;
		});
		setFilelist(newFilelist);
	};

	const handleToggleSelectMode = () => {
		if (!enforceSelectionMode) {
			if (selectionMode) {
				handleDeselectAllFiles();
			}
			setSelectionMode((prev) => !prev);
		}
	};

	const handleValidateSelection = (currentSelectedFile?: FileDTO) => {
		if (!props.onValidateSelection) {
			return ;
		}

		// sort the filelist by the order of selection
		if (showSelectionOrder) {
			const selectedFiles = filelist.filter((file: FileDTO) => file.checked && file.url !== currentSelectedFile?.url).sort((a: FileDTO, b: FileDTO) => {
				// sort by the order of selectedFilesIDs array
				const aIndex = selectedFilesIDs.indexOf(a.id);
				const bIndex = selectedFilesIDs.indexOf(b.id);

				if (aIndex > bIndex) {
					return 1;
				}
				if (aIndex < bIndex) {
					return -1;
				}
				return 0;
			});

			// add the current selected file to the list if it's not already in it
			if (currentSelectedFile && currentSelectedFile.checked) {
				selectedFiles.push(currentSelectedFile);
			}

			props.onValidateSelection(selectedFiles);
		} else {
			props.onValidateSelection(filelist.filter((file: FileDTO) => file.checked));
		}
	};

	const handleOpenItemModal = async (e: MouseEvent, file: FileDTO) => {
		e.preventDefault();
		e.stopPropagation();

		// if we're not in the selection mode, we can't select a file
		if (!selectionMode) {
			const name = file.name.split('.').slice(0, -1).join('.'); // get the name without the extension
			const extension = file.name.split('.').pop(); // get the extension

			setSelectedFile(file);
			setSelectedFileName({
				name: name,
				extension: extension || '',
				fullname: name + '.' + extension
			});

			const filePlatforms = await getFilePlatformsAvailability(file);
			setSelectedFilePlatforms(filePlatforms.availablePlatforms);

			const events = await getCalendarPosts();
			if (events) {
				const fileEvents = events.filter((event: CalendarEvent) => event.filesIds.includes(file.id));

				// add account picture URL to the event
				const fileEventsWithAccountPicture: Array<CalendarEvent & {imageURL: string}> = await Promise.all(fileEvents.map(async (event: CalendarEvent) => {
					const account = await event.account();
					return {
						...event,
						imageURL: account?.pictureURL || '',
					} as CalendarEvent & {imageURL: string};
				}));

				setSelectedFileEventsUsage(fileEventsWithAccountPicture);
			}
		}
	};

	const handleCloseItemModal = () => {
		setSelectedFile(null);
		setSelectedFileName({
			name: '',
			extension: '',
			fullname: ''
		});
		setSelectedFilePlatforms([]);
		setSelectedFileEventsUsage([]);
	};

	const handleRenameFile = async (file: FileDTO) => {
		setLoadingFilenameEdition(true);
		await updateFile({
			...file,
			name: selectedFileName.name + '.' + selectedFileName.extension
		});

		// update the selected file
		setSelectedFile({
			...file,
			name: selectedFileName.name + '.' + selectedFileName.extension
		});
		
		await handleLoadListFiles(false);
		setLoadingFilenameEdition(false);
	};

	const sortListByCurrentMode = (sortingMode: sortingModeType) => {
		const sortByDate = (a: FileDTO, b: FileDTO): number => {
			const aDate = a.metadata?.timeCreated;
			const bDate = b.metadata?.timeCreated;

			if (aDate && bDate) {
				return aDate > bDate ? -1 : 1;
			}
			return 0;
		};

		switch (sortingMode) {
			case 'down':
				// sort by date DESC (newest first)
				setFilelist((prev) => [...prev].sort((a, b) => sortByDate(a, b)));
				setDisplayableFilelist((prev) => [...prev].sort((a, b) => sortByDate(a, b)));
				break;

			case 'up':
				// sort by date ASC (oldest first)
				setFilelist((prev) => [...prev].sort((a, b) => sortByDate(b, a)));
				setDisplayableFilelist((prev) => [...prev].sort((a, b) => sortByDate(b, a)));
				break;

			default:
				break;
		}
	};

	const handleSortContentMode = (e: any, newSortingMode: sortingModeType) => {
		setSortContentMode(newSortingMode);
		sortListByCurrentMode(newSortingMode);
	};

	const handleSearchContent = (e: ChangeEvent<HTMLInputElement>) => {
		const searchValue = e.target.value;
		setSearchContent(searchValue);

		if (searchValue.length > 0) {
			const filteredFileList = filelist.filter((file: FileDTO) => file.name.toLowerCase().includes(searchValue.toLowerCase()));
			setDisplayableFilelist(filteredFileList);
		} else {
			setDisplayableFilelist(filelist);
		}
	};

	const filterByTypeCallback = (file: FileDTO) => {
		if (filterByType === LibraryFileListFilterType.ALL) {
			return true;
		}
		if (filterByType === LibraryFileListFilterType.IMAGE) {
			return file.metadata?.contentType?.includes('image') || false;
		}
		if (filterByType === LibraryFileListFilterType.VIDEO) {
			return file.metadata?.contentType?.includes('video') || false;
		}

		return true;
	};

	const filterByUsageCallback = (file: FileDTO) => {
		const fileIndex = filelistWithUsage.findIndex((fileWithUsage: { id: string, used: boolean }) => fileWithUsage.id === file.id);

		if (filterByUsage.checked) {
			return fileIndex !== -1 && filelistWithUsage[fileIndex].used;
		}
		return true;
	};

	const handleFilterByUsage = async (files: FileDTO[]) => {
		setFilterByUsage((prev) => { return { ...prev, checked: !prev.checked } });

		if (files.length === 0 || (filelistWithUsage.length !== 0 && filelistWithUsage.length === files.length)) {
			return ;
		}

		setFilterByUsage((prev) => { return { ...prev, loading: true } });

		const newFilelistWithUsage: { id: string, used: boolean }[] = filelist.map((file: FileDTO) => {
			return {
				id: file.id,
				used: false,
			};
		});

		const events = await getCalendarPosts();
		if (events) {
			const fileEvents = events.filter((event: CalendarEvent) => {
				return event.filesIds.some((fileId: string) => filelist.some((file: FileDTO) => file.id === fileId));
			});

			fileEvents.forEach((event: CalendarEvent) => {
				event.filesIds.forEach((fileId: string) => {
					const fileIndex = newFilelistWithUsage.findIndex((file: { id: string, used: boolean }) => file.id === fileId);

					if (fileIndex !== -1) {
						newFilelistWithUsage[fileIndex].used = true;
					}
				});
			});
		}

		setFilelistWithUsage(newFilelistWithUsage);
		setFilterByUsage((prev) => { return { ...prev, loading: false } });
	};

	const convertMetadataSizeToHumanReadable = (size: number) => {
		const sizeInKb = size / 1024;
		const sizeInMb = sizeInKb / 1024;
		const sizeInGb = sizeInMb / 1024;

		if (sizeInGb > 1) {
			return `${sizeInGb.toFixed(2)} Go`;
		}
		if (sizeInMb > 1) {
			return `${sizeInMb.toFixed(2)} Mo`;
		}
		if (sizeInKb > 1) {
			return `${sizeInKb.toFixed(2)} Ko`;
		}
		return `${size} o`;
	};


	return (
		<Box className='LibraryFileList-container'>
			{ !disableStorageProgress && (
				<Box sx={{ mb: 2 }}>
					<Typography variant="h6" color="text.secondary" sx={{
						fontWeight: 700
					}}>
						Stockage
					</Typography>

					<ClientStorageProgressBar refresh={refreshStorageProgressBar} />
				</Box>
			)}

			<Box className='LibraryFileList-header-buttons-container'>
				{ loadingUpload && (
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
						<Box sx={{ width: '160px' }}>
							<LinearProgress
								color='info'
								variant="buffer"
								value={uploadLoadingProgress}
								valueBuffer={uploadLoadingProgress}
							/>
						</Box>

						<Typography variant="body2" color="text.secondary">
							{`${uploadLoadingProgress.toFixed(0)}%`}
						</Typography>
					</Box>
				)}

				{ !disableUpload && (
					<Box>
						<LoadingButton
							disabled={loadingDeleteSelectedFiles || loadingList}
							className='LibraryFileList-upload-button'
							variant="contained"
							component="label"
							startIcon={<UploadFileIcon />}
							loading={loadingUpload}
						>
							Charger un fichier
							<input
								type="file"
								accept={APP_ACCEPTED_FILETYPES.join(',')}
								onChange={handleUploadFile}
								multiple
								hidden
							/>
						</LoadingButton>

						<Typography variant="h6" sx={fileTypesStyled}>
							{APP_ACCEPTED_FILE_EXTENSIONS.join(', ')}
						</Typography>
					</Box>
				)}
			</Box>

			{ filelist.length > 0 && (
				<Fragment>
					<Box className='LibraryFileList-secondary-buttons-container'>
					{ !enforceSelectionMode && !selectionMode && (
						<Button variant="outlined"
							disabled={loadingList}
							size='small'
							onClick={handleToggleSelectMode}
							startIcon={<BlurOnIcon />}
						>
							Sélectionner
						</Button>
					)}

					{ !loadingList && selectionMode && !validateOnClick && (
						<Fragment>
							{ !enforceSelectionMode && (
								<Button variant="contained"
									size='small'
									onClick={handleToggleSelectMode}
									startIcon={<BlurOffIcon />}
								>
									Annuler
								</Button>
							)}

							<Button variant="outlined"
								size='small'
								disabled={filelist.filter((file: FileDTO) => file.checked).length === 0}
								onClick={handleDeselectAllFiles}
								startIcon={<ClearAllIcon />}
							>
								Tout déselectionner
							</Button>

							{ props.onValidateSelection && (
								<Button variant="contained"
									color="success"
									size='small'
									disabled={filelist.filter((file: FileDTO) => file.checked).length === 0}
									onClick={() => handleValidateSelection()}
									startIcon={<DoneIcon />}
								>
									Valider la sélection
								</Button>
							)}

							<Box sx={{ flexGrow: 1 }} />
							
							<Grow in={filelist.filter((file: FileDTO) => file.checked).length > 0} mountOnEnter unmountOnExit>
								<LoadingButton
									variant="contained"
									size='small'
									color="error"
									startIcon={<DeleteForeverIcon />}
									disabled={filelist.filter((file: FileDTO) => file.checked).length === 0}
									loading={loadingDeleteSelectedFiles}
									onClick={handleDeleteSelectedFiles}
									sx={{ textTransform: 'none' }}
								>
									Supprimer {filelist.filter((file: FileDTO) => file.checked).length} fichiers
								</LoadingButton>
							</Grow>
						</Fragment>
					)}
					</Box>


					<Box sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}>
						<Box sx={{ flexGrow: 1 }} />

						<Button
							disabled={loadingList}
							variant={ !filtersOpen ? 'contained' : 'outlined' }
							size='small'
							color='info'
							startIcon={ filtersOpen ? <FilterAltOffIcon /> : <FilterAltIcon /> }
							onClick={() => setFiltersOpen((prev) => !prev)}
							sx={{ marginBottom: '10px' }}
						>
							Filtres
						</Button>
					</Box>

					<Collapse in={filtersOpen} mountOnEnter unmountOnExit>
						<Box className='LibraryFileList-filters-container'>
							{ !disableSearchBar && (
								<TextField
									helperText={
										<Fragment>
											<Typography variant='caption' sx={{
												fontFamily: 'IBM Plex Mono, Roboto Mono, monospace',
												color: '#333',
												fontWeight: 700,
											}}>
												{displayableFilelist.filter(filterByTypeCallback).filter(filterByUsageCallback).length}
											</Typography>
											{' '}
											<Typography variant='caption' sx={{
												color: '#666',
												fontWeight: 500,
											}}>
												fichiers
											</Typography>
										</Fragment>
									}
									fullWidth
									variant='standard'
									size='small'
									value={searchContent}
									label='Rechercher un fichier'
									onChange={handleSearchContent}
									sx={{ maxWidth: '250px' }}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
									}}
								/>
							)}

							<Box sx={{ flexGrow: 1 }} />

							<Box className='LibraryFileList-filter-by-file-type-container'>
								<LoadingButton
									variant="text"
									size='small'
									loading={filterByUsage.loading}
									loadingPosition='start'
									startIcon={ filterByUsage.checked ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon /> }
									onClick={() => handleFilterByUsage(filelist)}
								>
									Utilisé dans un post
								</LoadingButton>
							</Box>

							{/* if the default filter is not set to ALL, we don't show the filter by type buttons */}
							{ filetypeFilterBy === LibraryFileListFilterType.ALL && (
							<Box className='LibraryFileList-filter-by-file-type-container'>
								<Typography variant="body2" sx={{
									color: '#666',
									fontWeight: 600,
								}}>
									Filtrer par type de fichier
								</Typography>

								<Box className='LibraryFileList-filter-by-file-type-btns-container'>
									<Tooltip title="Tous les fichiers" arrow>
										<span>
											<IconButton
												className='LibraryFileList-filter-by-file-type-item'
												size='small'
												color={filterByType === LibraryFileListFilterType.ALL ? 'info' : 'primary'}
												onClick={() => setFilterByType(LibraryFileListFilterType.ALL)}
											>
												<WindowIcon />
											</IconButton>
										</span>
									</Tooltip>

									<Tooltip title="Images" arrow>
										<span>
											<IconButton
												className='LibraryFileList-filter-by-file-type-item'
												size='small'
												color={filterByType === LibraryFileListFilterType.IMAGE ? 'info' : 'primary'}
												onClick={() => setFilterByType(LibraryFileListFilterType.IMAGE)}
											>
												<PhotoCameraIcon />
											</IconButton>
										</span>
									</Tooltip>

									<Tooltip title="Vidéos" arrow>
										<span>
											<IconButton
												className='LibraryFileList-filter-by-file-type-item'
												size='small'
												color={filterByType === LibraryFileListFilterType.VIDEO ? 'info' : 'primary'}
												onClick={() => setFilterByType(LibraryFileListFilterType.VIDEO)}
											>
												<VideocamIcon />
											</IconButton>
										</span>
									</Tooltip>
								</Box>
							</Box>
							)}

							<Box className='LibraryFileList-filter-by-file-type-container'>
								<Typography variant="body2" sx={{
									color: '#666',
									fontWeight: 600,
								}}>
									Trier par date d'ajout
								</Typography>

								<Tooltip title="Trier par date d'ajout" arrow>
									<ToggleButtonGroup
										value={sortContentMode}
										size='small'
										exclusive
										sx={{
											'& .MuiToggleButton-root': {
												borderRadius: '10px',
												padding: '6px 10px',
												border: 'none',
												background: 'linear-gradient(0deg, #ccc, 4%, #fff)',
											},
											'& .MuiToggleButton-root svg': {
												fontSize: '.95rem',
											},
											'& .MuiToggleButton-root.Mui-selected': {
												background: '#ddd',
											},
										}}
										onChange={handleSortContentMode}
									>
										<ToggleButton value="down" defaultChecked>
											<ArrowDownwardIcon />
										</ToggleButton>

										<ToggleButton value="up">
											<ArrowUpwardIcon />
										</ToggleButton>
									</ToggleButtonGroup>
								</Tooltip>
							</Box>
						</Box>
					</Collapse>
				</Fragment>
			)}


			{ loadingList && (
				<List className='LibraryFileList-list'>
				{ Array(8).fill(0).map((_, skeletonIndex) => (
					<Box key={'Skeleton-account-id-' + skeletonIndex} className='LibraryFileList-list-item'>
						<Skeleton className='LibraryFileList-list-item-img-container' variant="rounded" />
					</Box>
				))}
				</List>
			)}

			<List className='LibraryFileList-list'>
			{ displayableFilelist.filter(filterByTypeCallback).filter(filterByUsageCallback).map((file: FileDTO, index: number) => (
				<Grow key={index + '-' + file.name}
					in={true}
					style={{ transitionDelay: `${index * 20}ms` }}
					mountOnEnter
					unmountOnExit
				>
					<Box className={`LibraryFileList-list-item ${file?.checked ? 'LibraryFileList-list-item-checked' : ''}`}
						onClick={(e) => handleOpenItemModal(e, file)}
					>
						<Checkbox className={`LibraryFileList-list-item-select-button ${selectionMode ? 'LibraryFileList-list-item-select-button-visible' : ''}`}
							checked={file.checked || false}
							checkedIcon={ !showSelectionOrder ?
								<CheckCircleIcon /> :
								<p>
									{selectedFilesIDs.indexOf(file.id) + 1}
								</p>
							}
							size="small"
							color="secondary"
							sx={{
								'& .MuiSvgIcon-root': {
									fontSize: '1.1rem',
									color: '#fff',
								},
							}}
							onChange={() => handleSelectFile(file.url)}
						/>

						<Tooltip title="Voir les détails" placement="top">
							<PageviewIcon
								className={`LibraryFileList-list-item-view-icon ${selectionMode ? 'LibraryFileList-list-item-view-icon-hidden' : ''}`}
								color='secondary'
							/>
						</Tooltip>

						<Box className='LibraryFileList-list-item-img-container'
							component={file.metadata?.contentType?.includes('video') ? 'video' : 'div'}
							controls={false}
							autoPlay={true}
							loop={true}
							muted={true}
							playsInline
							src={file.thumbnails?.sm?.url || file.url || ''}
							onClick={(e: MouseEvent) => {
								e.preventDefault();
								handleSelectFile(file.url)
							}}
							style={{
								background: `url(${file.thumbnails?.preview?.url || file.thumbnails?.sm?.url || file.url || ''})`,
								backgroundColor: '#bbb',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								objectFit: 'cover',
							}}
						/>

						<Box sx={{
							position: 'absolute',
							bottom: '5px',
							left: '50%',
							transform: 'translateX(-50%)',
							borderRadius: '12px',
							width: '95%',
							backgroundColor: 'rgba(0,0,0, .9)',
							backdropFilter: 'blur(5px)',
							WebkitBackdropFilter: 'blur(5px)',
						}}>
							<Box sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								gap: '12px',
								padding: '3px 10px',
							}}>
								<Typography variant="body2" sx={{
									color: '#eee',
									fontWeight: 700,
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
								}}>
									{file.name.split('.').slice(0, -1).join('.')}<span style={{
										color: '#999',
										fontWeight: 600,

									}}>.{file.name.split('.').pop()}</span>
								</Typography>

								{ file.metadata?.contentType && file.metadata.contentType.includes('image') && (
									<Box className='LibraryFileList-list-item-filetype-icon-container'>
										<PhotoCameraIcon color='secondary' fontSize='small' />
									</Box>
								)}

								{ file.metadata?.contentType && file.metadata.contentType.includes('video') && (
									<Box className='LibraryFileList-list-item-filetype-icon-container'>
										<VideocamIcon color='secondary' fontSize='small' />
									</Box>
								)}
							</Box>
						</Box>
					</Box>
				</Grow>
			))}
			</List>

			<Modal
				open={selectedFile !== null}
				setClose={handleCloseItemModal}
				sx={{
					background: 'linear-gradient(200deg, #f5f5f5, 75%, #ddd)',
					// background: 'linear-gradient(200deg, #666, 75%, #222)',
					// background: '#222',
				}}
			>
			{ selectedFile && (
				<Box className='LibraryFileList-modal-container'>
					<Box className='LibraryFileList-modal-item LibraryFileList-modal-img-container'>
						<Box className='LibraryFileList-modal-img'
							component={selectedFile.metadata?.contentType?.includes('video') ? 'video' : 'div'}
							controls={true}
							autoPlay={true}
							loop={true}
							muted={true}
							playsInline
							src={selectedFile.thumbnails?.md?.url || selectedFile.thumbnails?.sm?.url || selectedFile.thumbnails?.preview?.url || selectedFile.url || ''}
							sx={{
								background: `url(${selectedFile.thumbnails?.md?.url || selectedFile.thumbnails?.sm?.url || selectedFile.thumbnails?.preview?.url || selectedFile.url || ''})`, //TODO: selectedFile.thumbnails?.md?.url
								backgroundSize: 'contain',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
							}}
						/>

						<Box className='LibraryFileList-modal-infos-island'>
							<Box className='LibraryFileList-modal-infos-island-item'>
								<Typography variant='body2' color='secondary' sx={{ color: '#eee', fontWeight: 800 }}>
									{selectedFileName.extension.toUpperCase()}
								</Typography>
							</Box>

							<Divider orientation="vertical" flexItem sx={{ bgcolor: '#555', width: '1px' }} />

							<Box className='LibraryFileList-modal-infos-island-item'>
								<Typography variant='body2' color='secondary' sx={{ color: '#eee', fontWeight: 800 }}>
									{selectedFile.basicMetadata?.width && selectedFile.basicMetadata?.height ? (
										selectedFile.basicMetadata.width + 'x' + selectedFile.basicMetadata.height
									) : (
										'Format inconnu'
									)}
								</Typography>
							</Box>

							{ selectedFile.metadata?.contentType && selectedFile.metadata.contentType.includes('video') && (
								<>
									<Divider orientation="vertical" flexItem sx={{ bgcolor: '#555', width: '1px' }} />

									<Box className='LibraryFileList-modal-infos-island-item'>
										<Typography variant='body2' color='secondary' sx={{ color: '#eee', fontWeight: 800 }}>
											{ selectedFile.basicMetadata?.duration ? (
												selectedFile.basicMetadata.duration.toFixed(0) + 's'
											) : (
												'Durée inconnue'
											)}
										</Typography>
									</Box>
								</>
							)}
						</Box>
					</Box>

					<Box className='LibraryFileList-modal-item LibraryFileList-modal-infos-container'>
						<TextField
							variant='outlined'
							size='small'
							value={selectedFileName.name}
							label='Nom'
							onChange={(e) => {
								setSelectedFileName((prev) => {
									return {
										...prev,
										fullname: e.target.value + '.' + selectedFileName.extension,
										name: e.target.value,
									}
								})
							}}
							disabled={selectedFileName.name === selectedFile.name || loadingFilenameEdition}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										.{selectedFileName.extension}

										<IconButton
											disabled={loadingFilenameEdition || selectedFileName.fullname === selectedFile.name || selectedFileName.name === ''}
											edge='end'
											size='small'
											color='primary'
											onClick={() => handleRenameFile(selectedFile)}
											sx={{
												'& .MuiSvgIcon-root': {
													fontSize: '.85rem',
													borderRadius: '8px',
													backgroundColor: '#AFE1AF',
													padding: '3px 4px',
													color: '#444',
												},
												// when disabled
												'&.Mui-disabled': {
													'& .MuiSvgIcon-root': {
														backgroundColor: '#00000000',
														color: '#ccc',
													},
												},
											}}
										>
											{ !loadingFilenameEdition && <EditIcon /> }
											{ loadingFilenameEdition && <CircularProgress disableShrink size={10} /> }
										</IconButton>
									</InputAdornment>
								),
							}}
						/>

						<Box sx={{ flexGrow: 1 }} />

						<Box className='LibraryFileList-modal-metadata-container'>
						{ selectedFile.metadata?.size && (
							<Box className='LibraryFileList-modal-infos-item'>
								<Typography variant="body2" sx={modalInfoItemTitleStyled}>
									Taille
								</Typography>

								<Typography variant="body2" sx={modalInfoItemContentStyled}>
									{convertMetadataSizeToHumanReadable(selectedFile.metadata.size)}
								</Typography>
							</Box>
						)}

						{ selectedFile.metadata?.contentType && (
							<Box className='LibraryFileList-modal-infos-item'>
								<Typography variant="body2" sx={modalInfoItemTitleStyled}>
									Type
								</Typography>

								<Typography variant="body2" sx={modalInfoItemContentStyled}>
									{selectedFile.metadata.contentType}
								</Typography>
							</Box>
						)}

						{ selectedFile.metadata?.timeCreated && (
							<Box className='LibraryFileList-modal-infos-item'>
								<Typography variant="body2" sx={modalInfoItemTitleStyled}>
									Date d'ajout
								</Typography>

								<Typography variant="body2" sx={modalInfoItemContentStyled}>
									{new Date(selectedFile.metadata.timeCreated).toLocaleDateString('fr-FR', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })}
								</Typography>
							</Box>
						)}
						</Box>

						<Box className='LibraryFileList-modal-infos-item'>
							<Typography variant="body2" sx={modalInfoItemTitleStyled}>
								Plateformes disponibles
							</Typography>

							<Box className='LibraryFileList-modal-platforms-container'>
								{ selectedFilePlatforms.map((platform: SocialNetworkName, index: number) => (
									<img key={index}
										className='LibraryFileList-modal-platform-item'
										src={ALL_SOCIAL_PLATFORMS.find((p) => p.platformID === platform)?.icon3D || ''}
										alt={platform + '-icon'}
										style={{
											background: ALL_SOCIAL_PLATFORMS.find((p) => p.platformID === platform)?.background || '#000',
										}}
									/>
								))}
							</Box>

							{ selectedFilePlatforms.length === 0 && (
								<Typography variant="body2" sx={{...modalInfoItemContentStyled, color: 'red'}}>
									Ce fichier n'est compatible avec aucune plateforme
								</Typography>
							)}
						</Box>

						<Box className='LibraryFileList-modal-infos-item'>
							<Typography variant="body2" sx={modalInfoItemTitleStyled}>
								{ selectedFileEventsUsage.length > 0 ? 'Utilisation dans des posts' : 'Non utilisé dans un post' }
							</Typography>

							{ selectedFileEventsUsage.length > 0 && (
								<Box className='LibraryFileList-modal-linked-events-container'>
									{ selectedFileEventsUsage.map((event, index: number) => (
										<Box key={index} className='LibraryFileList-modal-linked-event-item'>
											<img className='LibraryFileList-modal-linked-event-item-account-picture'
												src={event.imageURL}
												alt={event.platformID + '-account-picture'}
											/>

											<Typography variant="body2" sx={modalInfoItemContentStyled}>
												{event.date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
											</Typography>

											<img className='LibraryFileList-modal-linked-event-item-platform-icon'
												src={ALL_SOCIAL_PLATFORMS.find((p) => p.platformID === event.platformID)?.icon3D || ''}
												alt={event.platformID + '-icon'}
											/>
										</Box>
									))}
								</Box>
							)}
						</Box>

						<Box sx={{ height: '.2rem' }} />

						<Button
							component={Link}
							to={selectedFile.url}
							download
							target='_blank'
							variant='outlined'
							color='secondary'
							size='small'
							startIcon={<OpenInNewIcon />}
						>
							Voir l'original
						</Button>

						<LoadingButton
							variant="contained"
							size='small'
							color="error"
							onClick={() => handleDeleteFile(selectedFile)}
							startIcon={<DeleteForeverIcon />}
							loading={loadingDeleteSelectedFiles}
						>
							Supprimer
						</LoadingButton>
					</Box>
				</Box>
			)}
			</Modal>

			<List sx={{ display: 'flex', justifyContent: 'right' }}>
			{ filelist.length === 0 && !loadingList && (
				<Box className='LibraryFileList-list-item LibraryFileList-empty-list'>
					<Typography variant="h6" sx={fileNameStyled}>
						Vous n'avez pas encore de fichier dans votre bibliothèque.
					</Typography>

					<Box sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: '10px',
					}}>
						<Typography variant="h6" sx={fileNameStyled}>
							Cliquez ici pour en ajouter
						</Typography>

						<ArrowUpwardIcon className='LibraryFileList-empty-list-animated-icon' />
					</Box>
				</Box>
			)}
			</List>
		</Box>
	);
}
