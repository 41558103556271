/* Libraries */
import React from 'react';

/* Components */
import { Button } from '@mui/material';

/* CSS */
import './TypeformButton.css';


interface TypeformButtonProps {
	variant?: 'contained' | 'outlined';
	color?: 'primary' | 'secondary';
	size?: 'small' | 'medium' | 'large';
	children?: React.ReactNode;
}

const buttonProportions = {
	small: {
		padding: '6px 22px',
	},
	medium: {
		padding: '10px 45px',
	},
	large: {
		padding: '12px 70px',
	},
}

export default function TypeformButton(props: TypeformButtonProps) {

	const {
		variant = 'contained',
		color = 'secondary',
		size = 'medium',
		children = "S'inscrire"
	} = props;

	const proportion = size === 'small' ?
		buttonProportions.small :
		size === 'medium' ?
			buttonProportions.medium :
			buttonProportions.large;


	return (
		<Button
			variant={variant}
			color={color}
			className="TypeformButton-button"
			href='/dashboard/login'
			target="_blank"
			size={size}
			sx={{
				...proportion,
				// color: props.color ? '' : 'black',
				// backgroundColor: props.color ? '' : 'white',
				fontSize: { xs: 12, md: 14 },
				fontWeight: 600,
				borderRadius: '18px',
				border: '2px solid rgba(160,160,255, .8)',
				boxShadow: '0 12px 22px rgba(0,0,0, .1)'
			}}
		>
			{children}
		</Button>
	);
}
