/* Libraries */

/* Components */

/* MUI */
import { Box, Typography } from '@mui/material';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import FavoriteIcon from '@mui/icons-material/Favorite';

/* CSS */
import './MetricsSection.css';


const titleStyled = {
	color: '#000',
	fontWeight: 700,
	fontSize: { xs: 35, md: 45},
	mb: { xs: '25px', md: '30px' },
};

const subtitleStyled = {
	color: '#333',
	fontWeight: 500,
	fontSize: { xs: 18, md: 22 },
	width: '100%',
	maxWidth: {xs: '500px', md: '900px'},
	margin: '0 auto',
	padding: '30px 0',
};

export default function MetricsSection() {

	const imgs = [
		{
			src: "/images/youtube-metrics.png",
			alt: 'YouTube metrics',
			preFloatingText:
				<Box sx={{ display: { xs: 'none', md: 'block' }, width: '180px', }}>
					<AutoGraphIcon sx={{ color: 'blue', fontSize: '40px' }} />
					<Typography variant='h4' sx={{ fontSize: 24, fontWeight: 700, color: 'blue' }}>
						<span style={{ fontWeight: 1000 }}>19 634</span> Impressions
					</Typography>
				</Box>
		},
		{
			src: "/images/tiktok-metrics.png",
			alt: 'TikTok metrics',
			floatingText:
				<Box sx={{
					textAlign: { xs: 'right', sm: 'center' },
					width: '180px',
					float: { xs: 'right', sm: 'none' },
				}}>
					<Diversity1Icon sx={{ color: 'green', fontSize: '40px' }} />
					<Typography variant='h4' sx={{ fontSize: {xs: 18, sm: 18}, fontWeight: 700, color: 'green' }}>
						Taux d'engagement de <span style={{ fontWeight: 1000 }}>3,38%</span>
					</Typography>
				</Box>
		},
		{
			src: "/images/instagram-metrics.png",
			alt: 'Instagram metrics',
			preFloatingText:
				<Box sx={{
					display: 'flex',
					gap: '15px',
					alignItems: 'center',
					justifyContent: { xs: 'left', md: 'center' },
				}}>
					<FavoriteIcon sx={{ color: 'red', fontSize: '40px' }} />
					<Typography variant='h4' sx={{ fontSize: 24, fontWeight: 1000, color: 'red' }}>
						1324
					</Typography>
				</Box>
		},
		{
			src: "/images/snapchat-metrics.png",
			alt: 'Snapchat metrics',
			floatingText: <></>
		},
	]

	return (
		<Box className="MetricsSection-container">
			<Box className="MetricsSection-title-container">
				<Typography variant='h2' sx={titleStyled}>
					Plus de vues et d'abonnés tout en gagnant du temps.
				</Typography>
			</Box>

			<Box className="MetricsSection-imgs-container">
			{ imgs.map((img, index) => (
				<Box key={index}>
					<Box className="MetricsSection-pre-floating-text">
						{img.preFloatingText}
					</Box>

					<img className="MetricsSection-img"
						src={img.src}
						alt={img.alt}
					/>

					<Box className="MetricsSection-floating-text">
						{img.floatingText}
					</Box>
				</Box>
			))}
			</Box>

			<Box className="MetricsSection-title-container">
				<Typography variant='h3' sx={subtitleStyled}>
					Grâce à notre IA nous créons des stratégies de planification personnalisées et optimisées, mettant naturellement en avant votre contenu.
					<br />
					Cela vous fait gagner du temps afin de vous concentrer sur l'essentiel: <br /><br />
					<span style={{ fontWeight: 700 }}>La Création</span>.
				</Typography>
			</Box>
		</Box>
	);
}
