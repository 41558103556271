/* Libraries */
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react'
import moment from 'moment';
import 'moment/locale/fr'

/* Components */
import LibraryFileList from '../../LibraryFileList/LibraryFileList';
import ContentCarrousel from '../../ContentCarrousel/ContentCarrousel';
import DuplicateEventButton from '../DuplicateEventButton/DuplicateEventButton';
import ThumbnailSelector from '../ThumbnailSelector/ThumbnailSelector';

/* Services */
import {
	CalendarEvent,
	CalendarEventData,
	CalendarEventStatus,
	EventSchedulableResponse
} from '../../../services/calendar.service.dto';
import {
	deleteCalendarPost,
	generateBestPostingTime,
	updateCalendarPosts
} from '../../../services/calendar.service';
import { getTiktokQueryCreatorInfos } from '../../../services/tiktok.service';

/* MUI */
import { Box } from '@mui/system';
import {
	Alert,
	Avatar,
	Badge,
	Button,
	Checkbox,
	CircularProgress,
	Collapse,
	FormControlLabel,
	Grow,
	IconButton,
	InputAdornment,
	MenuItem,
	Select,
	Switch,
	TextField,
	Tooltip,
	Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

/* Icons */
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

/* DTO */
import { PlanificationModalProps } from '../PlanificationModalDTO';
import { FileDTO } from '../../../services/clientStorage.service.dto';
import { TiktokCreatorInfoQueryResponse, TiktokPrivacyLevelPrivate, TiktokPrivacyLevelPublic } from '../../../services/tiktok.service.dto';

/* CSS */
import '../PlanificationModal.css';
import './TiktokPlanification.css';


export default function TiktokPlanification(props: PlanificationModalProps) {

	const {
		// open,
		onClose,
		account,
		event,
		setEvent,
	} = props;

	const [data, setData] = useState<Partial<CalendarEvent>>(event || {});
	const preOpenFileSelector = data?.status === undefined ? true : false;
	const isDisabled = data?.status === CalendarEventStatus.PUBLISHED || data?.status === CalendarEventStatus.PUBLISHING; // || !data?.description;
	const isPublished = data?.status === CalendarEventStatus.PUBLISHED;
	const [files, setFiles] = useState<Partial<FileDTO>[]>([]);
	const [permissions, setPermissions] = useState<TiktokCreatorInfoQueryResponse['data'] | null>(null);
	const [selectFilesModalOpen, setSelectFilesModalOpen] = useState(preOpenFileSelector);
	const [eventLoading, setEventLoading] = useState(false);
	const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
	const [isSchedulable, setIsSchedulable] = useState<EventSchedulableResponse & {loading: boolean}>({isSchedulable: true, errorMsg: '', loading: false});
	const [generatingBestPostingTime, setGeneratingBestPostingTime] = useState(false);


	useEffect(() => {
		loadFiles();
		loadPermissions();

		return () => {
			setFiles([]);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		checkSchedulable();
		// eslint-disable-next-line
	}, [files]);


	const loadPermissions = async () => {
		if (!account?.account.id) {
			return ;
		}

		const permissions = await getTiktokQueryCreatorInfos(account.account.id);
		setPermissions(permissions);
	}

	const checkSchedulable = async () => {
		setIsSchedulable((prev) => {return {...prev, loading: true}});
		const ev = new CalendarEvent({
			...data,
			filesIds: files.map((file) => file.id || '').filter((id) => id !== ''),
		});
		const res = await ev.isSchedulable(files);
		setIsSchedulable({...res, loading: false});
	};

	const loadFiles = async () => {
		setEventLoading(true);

		// remove duplicates
		const filesIds = data.filesIds?.filter((fileId, index) => data.filesIds?.indexOf(fileId) === index) || [];

		// If the event has no files, we don't need to load them
		if (filesIds.length === 0) {
			setEventLoading(false);
			return ;
		}

		// if the fileIds are links to external files, we don't need to load them
		if (filesIds[0].startsWith('http')) {
			const files = filesIds.map((url) => {
				// get the file type from the url
				let type = 'image/jpeg'; // TODO: maybe 'unknown'

				if (url.includes('.mp4')) {
					type = 'video/mp4';
				} else if (url.includes('.webm')) {
					type = 'video/webm';
				} else if (url.includes('.mov')) {
					type = 'video/mov';
				} else if (url.includes('.gif')) {
					type = 'image/gif';
				} else if (url.includes('.png')) {
					type = 'image/png';
				} else if (url.includes('.webp')) {
					type = 'image/webp';
				} else if (url.includes('.jpg')) {
					type = 'image/jpeg';
				} else if (url.includes('.jpeg')) {
					type = 'image/jpeg';
				} else if (url.includes('.svg')) {
					type = 'image/svg+xml';
				}

				return {
					url: url,
					metadata: {
						contentType: type,
					} as any,
				};
			});
			setFiles(files);
		} else {
			const tmpEvent = new CalendarEvent(data as CalendarEventData);
			const files = await tmpEvent.getFiles();

			if (files) {
				setFiles(files);
			}
		}
		setEventLoading(false);
	};

	const eventIsSavable = () => {
		return !!data?.description &&
			!!data?.date &&
			data?.description.length > 0 &&

			/**
			 * Check if one of the property has been modified since the last time
			 */
			(data.description !== event.description ||
				moment(data.date).format('DD/MM/YYYY HH:mm') !== moment(event.date).format('DD/MM/YYYY HH:mm') ||
				data.filesIds?.length !== event.filesIds?.length ||
				data.filesIds?.some((fileId, index) => fileId !== event.filesIds?.[index]) ||
				data.isSponsored !== event.isSponsored ||
				data.thumbnailID !== event.thumbnailID ||
				data?.metadata?.discloseVideoContent !== event?.metadata?.discloseVideoContent ||
				data?.metadata?.brandContentToggle !== event?.metadata?.brandContentToggle ||
				data?.metadata?.brandOrganicToggle !== event?.metadata?.brandOrganicToggle ||
				data?.metadata?.allowComment !== event?.metadata?.allowComment ||
				data?.metadata?.allowDuet !== event?.metadata?.allowDuet ||
				data?.metadata?.allowStitch !== event?.metadata?.allowStitch ||
				data?.metadata?.privacyLevel !== event?.metadata?.privacyLevel || 
				data?.metadata?.videoCoverTimestamp !== event?.metadata?.videoCoverTimestamp) &&
			!!data?.metadata?.privacyLevel &&

			/**
			 * Either discloseVideoContent is false or, in the case it's true,
			 * check if one of the two checkboxes is checked.
			 */
			(!!!data?.metadata?.discloseVideoContent || (!!data?.metadata?.discloseVideoContent && (!!data?.metadata?.brandContentToggle || !!data?.metadata?.brandOrganicToggle))) &&
			!eventLoading && 
			isSchedulable.loading === false &&
			isSchedulable.isSchedulable;
	};

	const handleValidateSelection = (selectedFiles: FileDTO[]) => {
		setData({
			...data,
			filesIds: selectedFiles.map((file) => file.id),
		});
		setFiles(selectedFiles);
	};

	const handleOpenFileSelector = () => {
		setSelectFilesModalOpen(true);
	};

	const handleCloseFileSelector = () => {
		setSelectFilesModalOpen(false);
	};

	const handleEmojiPicker = () => {
		setEmojiPickerOpen((prev) => !prev);
	};

	const handleSaveEvent = async () => {
		setEventLoading(true);
		const newEvent = new CalendarEvent({
			...data,
			accountID: account?.account.accountID || '',
			platformID: account?.platformID || '',
		} as CalendarEventData);
		setEvent(newEvent);
		await updateCalendarPosts(newEvent);
		setEventLoading(false);
		onClose();
	};

	const handleDeleteEvent = async () => {
		if (data?.id) {
			setEventLoading(true);
			await deleteCalendarPost(data.id);
			setEvent({});
			// setData({});
			setEventLoading(false);
			onClose();
		} else {
			toast.warning('Impossible de supprimer un post non enregistré');
		}
	};


	return (
		<Box className='Planification-content-container'>
			<Box className='' sx={{
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '100%',
				maxHeight: '650px',
				height: 'fit-content',
				overflowY: 'auto',
				gap: '40px',
				paddingBottom: '35px',
			}}>
				{ selectFilesModalOpen && (
					<Box sx={{ width: '100%' }}>
						{ files.length > 0 && (
							<Box sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								position: 'sticky',
								top: 0,
								right: 0,
								margin: '0 0 18px 0',
								zIndex: 1001,
							}}>
								<Button
									onClick={handleCloseFileSelector}
									color='success'
									variant='contained'
									sx={{
										width: '90%',
										textTransform: 'none',
									}}
									startIcon={<CheckCircleIcon />}
								>
									Valider la sélection
								</Button>
							</Box>
						)}

						{ files.length === 0 && (
							<IconButton
								onClick={handleCloseFileSelector}
								color='primary'
								size='small'
								sx={{
									position: 'sticky',
									top: 0,
									right: 0,
									backgroundColor: '#666',
									zIndex: 1001,
								}}
								>
								<CloseIcon sx={{ color: '#eee' }} />
							</IconButton>
						)}

						<LibraryFileList
							onValidateSelection={handleValidateSelection}
							enforceSelectionMode
							disableSearchBar
							disableStorageProgress
							validateOnClick
							showSelectionOrder
							selectedFilesIDs={files.map((file) => file.id || '')}
						/>
					</Box>
				)}

				{ !selectFilesModalOpen && (
					<Fragment>
						{ eventLoading && files.length === 0 && (
							<CircularProgress disableShrink size={25} color='primary' sx={{ mb: '50px' }}/>
						)}

						{ files.length > 0 && (
							<Fragment>
								<Box
									sx={{
										position: 'relative',
										display: 'flex',
										width: '100%',
										backgroundColor: '#000',
										borderRadius: '6px',
									}}
								>
									<ContentCarrousel files={files} />
								</Box>

								{/* thumbnail selection with images cursor */}
								{ files.length === 1 && files[0].metadata?.contentType?.includes('video') && (
									<ThumbnailSelector
										event={data}
										setEvent={setData}
										files={files}
									/>
								)}
							</Fragment>
						)}

						{ !isDisabled && (
							<Box className='Planification-infos-island'>
								<Button
									className='Planification-file-selector-button'
									variant='contained'
									color='success'
									disabled={eventLoading}
									startIcon={<ChangeCircleIcon />}
									onClick={handleOpenFileSelector}
								>
									Choisir des fichiers
								</Button>
							</Box>
						)}
					</Fragment>
				)}
			</Box>

			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '15px',
			}}>
				<Box className='Planification-fields-container'>
					<Badge
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						badgeContent={<img src={account?.icon3D} width={28} alt={`${account?.platformID || ''}-logo`} />}
						sx={{
							'& .MuiBadge-badge': {
								top: '10px',
								right: '10px',
								background: account?.background || 'linear-gradient(210deg, #777, #000)',
								width: '36px',
								height: '36px',
								borderRadius: '13px',
								boxShadow: '0 8px 18px -6px rgba(0,0,0, .6)',
							},
						}}
					>
						<Box className='Planification-avatar-container'>
							<Avatar className='Planification-avatar'
								sx={{
									background: account?.background,
									padding: '4px',
									'& img': {
										borderRadius: '50%',
									},
								}}
								src={account?.account.pictureURL}
							/>
								<Typography className='Planification-avatar-name' color='secondary' variant='body1'>
									{account?.account.username}
								</Typography>
						</Box>
					</Badge>

					<Box className='Planification-field-item-container'>
						<Box sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: '10px',
						}}>
							{ files.length === 1 && files[0].metadata?.contentType?.includes('video') && (
								<Fragment>
									<Typography className='Planification-field-item-title' variant='body1'>
										Vidéo
									</Typography>
									<SlowMotionVideoIcon fontSize='medium' color='info' className='Planification-media-type-icon' />
								</Fragment>
							)}

							{ files.length > 1 && (
								<Fragment>
									<Typography className='Planification-field-item-title' variant='body1'>
										Carrousel
									</Typography>
									<ViewCarouselIcon fontSize='medium' color='info' className='Planification-media-type-icon' />
								</Fragment>
							)}
						</Box>
					</Box>

					<Box className='Planification-field-item-container'>
						<Typography className='Planification-field-item-title' variant='body1'>
							Date de publication
						</Typography>

						<Box sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: '8px'
						}}>
							<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'fr'}>
								<DateTimePicker
									sx={{ width: '100%' }}
									disablePast
									disabled={isDisabled}
									slotProps={{ textField: { size: 'small' } }}
									value={ moment(data?.date) }
									onChange={(date) => {

										/**
										 * If the date is in the past, we set the
										 * date to right now.
										 * 
										 * This is to prevent the user from
										 * scheduling a post in the past.
										 */
										if (moment(date).isBefore(moment().add(1, 'minute'))) {
											setData({
												...data,
												date: moment().add(1, 'minute').toDate(),
											});
										} else {
											setData({
												...data,
												date: date?.toDate(),
											});
										}
									}}
								/>
							</LocalizationProvider>

							{ !isDisabled && (
								<Tooltip title="Identifier l'instant optimal pour partager votre contenu." placement='top' arrow>
									<IconButton
										className='Planification-media-type-icon'
										color='primary'
										disabled={isDisabled || files.length === 0 || !account || generatingBestPostingTime}
										sx={{ borderRadius: '12px' }}
										onClick={() => {
											if (!account) {
												return ;
											}

											generateBestPostingTime(
												account,
												new CalendarEvent(data as CalendarEventData),
												setGeneratingBestPostingTime,
												(date) => {
													setData({
														...data,
														date: date
													});
												}
											);
										}}
									>
										{ !generatingBestPostingTime && <AutoFixHighIcon fontSize='small' /> }
										{ generatingBestPostingTime && <CircularProgress disableShrink color='info' size={15} /> }
									</IconButton>
								</Tooltip>
							)}
						</Box>
					</Box>

					<Box className='Planification-field-item-container'>
						<Typography className='Planification-field-item-title' variant='body1'>
							Caption
						</Typography>

						<TextField
							size='small'
							multiline
							fullWidth
							disabled={isDisabled}
							minRows={3}
							maxRows={10}
							variant="outlined"
							inputProps={{ maxLength: 4000 }}
							InputProps={{
								endAdornment: !isDisabled && (
									<InputAdornment position="end">
										<IconButton
											size='small'
											color={emojiPickerOpen ? 'secondary' : 'success'}
											sx={{
												backgroundColor: emojiPickerOpen ? '#999' : 'transparent',
												border: emojiPickerOpen ? '1px solid #000' : '1px solid #ccc',
											}}
											onClick={handleEmojiPicker}
										>
											<EmojiEmotionsIcon fontSize='small' />
										</IconButton>
									</InputAdornment>
								),
							}}
							helperText={
								<Typography variant='caption' color='text.secondary' sx={{
									fontFamily: 'IBM Plex Mono, Roboto Mono, monospace',
								}}>
									{data?.description?.length || 0} / 4000
								</Typography>
							}
							value={data?.description || ''}
							onChange={(e) => {
								setData({
									...data,
									description: e.target.value,
								});
							}}
						/>
					</Box>

					<Grow in={emojiPickerOpen} timeout={500} mountOnEnter unmountOnExit>
						<Box sx={{ margin: '0 auto' }}>
							<Picker
								data={emojiData}
								onEmojiSelect={(e: any) => {
									setData({
										...data,
										description: (data?.description || '') + e.native,
									});
								}}
								perLine={9}
								emojiButtonSize={30}
								emojiSize={22}
								previewPosition='none'
								maxFrequentRows={3}
							/>
						</Box>
					</Grow>

					<Box className='Planification-field-item-container'>
						<Typography className='Planification-field-item-title' variant='body1'>
							Qui peut voir cette vidéo
						</Typography>

						<Select
							fullWidth
							size='small'
							variant='outlined'
							disabled={isDisabled}
							value={data?.metadata?.privacyLevel ? data.metadata.privacyLevel as (TiktokPrivacyLevelPublic | TiktokPrivacyLevelPrivate) : ''}
							onChange={(e) => {
								const privacyLevel = e.target.value as (TiktokPrivacyLevelPublic | TiktokPrivacyLevelPrivate);

								if (privacyLevel === TiktokPrivacyLevelPublic.SELF_ONLY) {
									setData({
										...data,
										metadata: {
											...data.metadata,
											privacyLevel: privacyLevel,
											discloseVideoContent: false,
											brandContentToggle: false,
											brandOrganicToggle: false,
										},
									});
								} else {
									setData({
										...data,
										metadata: {
											...data.metadata,
											privacyLevel: privacyLevel,
										},
									});
								}
							}}

							// change border radius
							sx={{
								borderRadius: '10px',
								textTransform: 'capitalize !important',
							}}
						>
							{ permissions?.privacy_level_options?.map((option) => (
								<MenuItem key={option} value={option} sx={{
									textTransform: 'capitalize !important',
								}}>
									{option.split('_').join(' ').toLowerCase()}
								</MenuItem>
							))}
						</Select>
					</Box>

					<Box className='Planification-field-item-container'>
						<Typography className='Planification-field-item-title' variant='body1'>
							Permettre aux utilisateurs de
						</Typography>

						<Box>
							<FormControlLabel
								disabled={permissions?.comment_disabled || isDisabled}
								checked={data?.metadata?.allowComment as boolean || false}
								control={
									<Checkbox color='info' onChange={(e) => {
										setData({
											...data,
											metadata: {
												...data.metadata,
												allowComment: e.target.checked,
											},
										});
									}} />
								}
								label="Commenter"
							/>

							<FormControlLabel
								disabled={permissions?.duet_disabled || isDisabled}
								checked={data?.metadata?.allowDuet as boolean || false}
								control={
									<Checkbox color='info' onChange={(e) => {
										setData({
											...data,
											metadata: {
												...data.metadata,
												allowDuet: e.target.checked,
											},
										});
									}} />
								}
								label="Duos"
							/>

							<FormControlLabel
								disabled={permissions?.stitch_disabled || isDisabled}
								checked={data?.metadata?.allowStitch as boolean || false}
								control={
									<Checkbox color='info' onChange={(e) => {
										setData({
											...data,
											metadata: {
												...data.metadata,
												allowStitch: e.target.checked,
											},
										});
									}} />
								}
								label="Collages"
							/>
						</Box>
					</Box>

					<Box className='Planification-field-item-container'>
						<Tooltip
							title={ !!!data?.metadata?.discloseVideoContent ?
								"Unable to change this setting when the post is private" : ""
							}
							placement='bottom'
							arrow
						>
							<Box sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
								gap: '10px',
								marginBottom: '4px',
							}}>
								<Typography className='Planification-field-item-title' variant='body1'>
									Divulgation de Contenu
								</Typography>

								<Switch
									color="info"
									size='small'
									disabled={data?.metadata?.privacyLevel === TiktokPrivacyLevelPrivate.SELF_ONLY || isDisabled}
									checked={data?.metadata?.discloseVideoContent as boolean || false}
									onChange={(e) => {
										setData({
											...data,
											metadata: {
												...data.metadata,
												discloseVideoContent: e.target.checked,
												brandContentToggle: false,
												brandOrganicToggle: false,
											},
										});
									}}
								/>
							</Box>
						</Tooltip>
					
						<Collapse in={!!data?.metadata?.discloseVideoContent || false}>
						<Box sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '18px',
							padding: '10px',
							backgroundColor: '#eee',
							borderRadius: '12px',
							border: '1px solid #ccc',
						}}>
							{ (!!data?.metadata?.brandContentToggle || !!data?.metadata?.brandOrganicToggle) && (
								<Alert icon={<InfoIcon fontSize="inherit" />} severity="info" variant='filled'>
									Votre vidéo sera étiquetée {' '}
									{ !!!data?.metadata?.brandOrganicToggle && '"Contenu promotionnel"' }
									{ !!data?.metadata?.brandOrganicToggle && '"Partenariat rémunéré"' }
									{'.'}
									<br />
									Ce paramètre ne peut pas être modifié une fois votre vidéo publiée.
								</Alert>
							)}

							<Typography className='Planification-field-item-title' variant='body2' sx={{ fontWeight: '500 !important' }}>
								Vous faites la promotion d'une autre marque ou d'une tierce partie. Cette vidéo sera classée comme Contenu de marque.
							</Typography>

							<Box className='Planification-field-item-container'>
								<Box sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}>
									<Typography className='Planification-field-item-title' variant='body1' >
										Votre propre marque
									</Typography>

									<Checkbox
										color='info'
										disabled={isDisabled}
										checked={data?.metadata?.brandContentToggle || false}
										onChange={(e) => {
											setData({
												...data,
												metadata: {
													...data.metadata,
													brandContentToggle: e.target.checked,
												},
											});
										}}
									/>
								</Box>

								<Typography className='Planification-field-item-title' variant='body2' sx={{ fontWeight: '500 !important' }}>
									Vous faites la promotion de vous-même ou de votre propre entreprise. Cette vidéo sera classée comme Contenu de marque organique.
								</Typography>
							</Box>

							<Box className='Planification-field-item-container'>
								<Box sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}>
									<Typography className='Planification-field-item-title' variant='body1' >
										Contenu de marque
									</Typography>

									<Checkbox
										color='info'
										disabled={isDisabled}
										checked={data?.metadata?.brandOrganicToggle || false}
										onChange={(e) => {
											setData({
												...data,
												metadata: {
													...data.metadata,
													brandOrganicToggle: e.target.checked,
												},
											});
										}}
									/>
								</Box>

								<Typography className='Planification-field-item-title' variant='body2' sx={{ fontWeight: '500 !important' }}>
									Vous faites la promotion d'une autre marque ou d'une tierce partie. Cette vidéo sera classée comme Contenu de marque.
								</Typography>
							</Box>
						</Box>
						</Collapse>

						<Typography className='Planification-field-item-title' variant='body2' sx={{
							fontWeight: '600 !important',
							marginTop: '10px',
						}}>
							En publiant, vous acceptez le {' '}
							{ !!data?.metadata?.brandOrganicToggle && (
								<>
									<Link style={{ color: '#66d', textDecoration: 'none' }} to='https://www.tiktok.com/legal/page/global/bc-policy/en' target='_blank'>Branded Content Policy</Link> {'et '}
								</>
							)}
							<Link style={{ color: '#66d', textDecoration: 'none' }} to='https://www.tiktok.com/legal/page/global/music-usage-confirmation/en' target='_blank'>Music Usage Confirmation</Link>
							
							{' '}
							de TikTok
						</Typography>
					</Box>

					<Box sx={{ flexGrow: 1 }} />

					{ !isDisabled && (
						<Fragment>
							{ !isSchedulable.loading && !isSchedulable.isSchedulable && (
								<Box className='Planification-is-schedulable-container'>
									<FeedbackIcon fontSize='medium' color='secondary' />

									<Typography variant='body2' color='secondary' className='Planification-is-schedulable-text'>
										{isSchedulable.errorMsg}
									</Typography>
								</Box>
							)}

							<Box className='Planification-action-buttons-container'>
								<Tooltip title="Supprimer l'évènement" placement='top'>
									<IconButton
										size='small'
										color='error'
										disabled={eventLoading}
										onClick={handleDeleteEvent}
									>
										<DeleteForeverIcon />
									</IconButton>
								</Tooltip>

								<LoadingButton
									fullWidth
									disabled={!eventIsSavable() || files.length === 0}
									loading={eventLoading}
									color='info'
									variant='contained'
									sx={{ fontWeight: 600 }}
									startIcon={<ScheduleSendIcon />}
									onClick={handleSaveEvent}
								>
									Planifier
								</LoadingButton>
							</Box>
						</Fragment>
					)}

					{ isPublished && event.permalink && (
						<Box className='Planification-action-buttons-container'>
							<Button
								component={Link}
								to={data.permalink || ''}
								target="_blank"
								fullWidth
								color='info'
								variant='contained'
								size='small'
								startIcon={<OpenInNewIcon />}
							>
								Voir le post
							</Button>
						</Box>
					)}
				</Box>

				<Box>
					{ !isPublished && (
						<DuplicateEventButton
							event={data}
							disabled={files.length === 0}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
}
