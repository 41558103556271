/* Libraries */

/* Components */
import TypeformButton from '../../assets/TypeformButton/TypeformButton';

/* MUI */
import { Box, Typography } from '@mui/material';

/* CSS */
import './IASection.css';


const titleColorizedStyled = {
	background: "-webkit-linear-gradient(0deg, #5755c8 30%, purple, magenta, #E0144C, #FFB100, #f3c16d, yellow)",
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent",
	fontSize: '40px',
};

const subtitleStyled = {
	color: 'white',
	fontSize: '30px',
};

const textStyled = {
	color: 'white',
	fontSize: '17px',
	padding: '30px 0',
};

export default function IASection() {

	const video = '/images/freekl-instagram-account-followers-animation.mp4';


	return (
		<Box className="IASection-container">
			<Box className="IASection-content-container">
				<Box className="IASection-title-container">
					<Typography variant="h2" sx={titleColorizedStyled}>
						On vous fait grandir avec l'IA
					</Typography>

					<Typography variant="h3" sx={subtitleStyled}>
						Une façon innovante de gérer vos réseaux
					</Typography>
				</Box>

				<Typography variant="h5" sx={textStyled}>
					L'intelligence artificielle de FREEKL optimise la publication de votre contenu et poste tout à votre place. <br />
					En publiant au bon moment et en fonction de votre communauté, votre contenu est mis en avant et vous gagnez en visibilité sur les réseaux.
				</Typography>

				<Box sx={{ mt: '70px' }}>
					<TypeformButton color='secondary'>
						En savoir plus
					</TypeformButton>
				</Box>

				<Box className="IASection-video-container">
					<video className="IASection-video"
						autoPlay
						loop
						muted
						playsInline
						controls={false}
					>
						<source src={video} type="video/mp4" />
					</video>
				</Box>
			</Box>
		</Box>
	);
}
